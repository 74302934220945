import { useState, useEffect, useRef } from "react";
import { DeleteFilled, EyeFilled } from "@ant-design/icons";
import { message, Tooltip, Popconfirm } from "antd";
import { FilterAgGrid } from "../commonComponents/AgGridHeader/components";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import LajmerimetPrint from "./LajmerimetPrint";
import { useReactToPrint } from "react-to-print";
import AgGridComponent from "../AG-grid/AgGridComponent";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import {
  deleteAnnouncement,
  getAnnouncements,
  sortDates,
} from "./utils/methods";
import HappyHolidaysModal from "../../assets/holidays/Modals/HappyHolidaysModal";
import ModalAnnounce from "./ModalAnnounce/ModalAnnounce";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./Announcements.scss";
import { htmlParser } from "../../utils";
import { IconEditWhite, IconExcel, IconNgargo } from "../../assets/icons";
import AnnouncementReplies from "./AnnouncementReplies/AnnouncementReplies";
import NewAnnouncement from "./NewAnnouncement/NewAnnouncement";
import CustomAgPaginator from "../punonjesit/EmployeeView/CustomAgPaginator/CustomAgPaginator";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";
import MondayButton from "../commonComponents/MondayButton/MondayButton";
import { useMediaQuery } from "react-responsive";
import MobileViewAnnouncements from "./MobileViewAnnouncements";

const initialData = {
  announcementTitle: "",
  announcementBody: "",
  announcementUrl: "https://portal.flex.al/kryefaqja",
  announcementTarget: {
    departments: [],
  },
  announcementTeam: {
    teams: [],
  },
  endAnnouncementDate: null,
  userResponses: {},
  announcementCategory: "needAction",
  createdBy: "",
};

const FILTER_KEYS = [
  { key: "createdBy", label: "Krijuar nga", type: "string" },
  { key: "announcementTitle", label: "Titulli", type: "string" },
  { key: "announcementCategory", label: "Kategoria", type: "string" },
  { key: "createdAt", label: "Koha e krijimit", type: "date-range" },
];
const EXPORT_COLS = [
  { en: "announcementTitle", al: "Titulli" },
  { en: "announcementCategory", al: "Kategoria" },
  { en: "announcementBody", al: "Përmbajtja" },
  { en: "announcementUrl", al: "Url" },
  { en: "createdBy", al: "Krijuar nga" },
  { en: "usersResponses", al: "Përgjigjet" },
];
const ITEMS = {
  icons: {
    filter: {
      filterKeys: FILTER_KEYS,
    },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function Announcements() {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialData);
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState({
    modeType: "new",
    otherData: {},
  }); // OTHER MODES: edit, view

  const retrievedData = async () => {
    await getAnnouncements().then((r) => {
      console.log(r);
      setRowData(
        r?.sort((a, b) => dayjsTZ(b.createdAt).diff(dayjsTZ(a.createdAt)))
      );
      setLoading(false);
    });
  };

  const retrieveEditData = (data) => {
    setData(data);
    setVisible(true);
    setMode({ modeType: "edit", otherData: data });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onReset = () => {
    setData(initialData);
    setMode({ modeType: "new", otherData: {} });
  };

  // region MODIFYROWSTOEXP
  const modifyRowsToExport = (rows) => {
    if (!rows?.length) return [];
    let modifiedRows = [];
    rows?.forEach((row) => {
      const structuredResponses = [];
      for (const [key, obj] of Object.entries(row?.usersResponses || [])) {
        structuredResponses.push({ key: key, ...obj });
      }
      let userResponses = Object.groupBy(
        structuredResponses,
        ({ response }) => response
      );
      const responses = [];
      for (const [res, arr] of Object.entries(userResponses)) {
        responses.push(`${res}: ${arr?.length}`);
      }
      const toReturn = {
        ...row,
        announcementCategory: categories[row?.announcementCategory],
        usersResponses: !!responses?.length ? responses : 0,
      };
      modifiedRows.push(toReturn);
    });
    return modifiedRows;
  };

  const sortedAnnouncements = (data = []) =>
    data?.sort(
      (a, b) =>
        dayjsTZ(a?.createdAt).valueOf() - dayjsTZ(b?.createdAt).valueOf()
    );

  useEffect(() => {
    retrievedData();
  }, []);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  //region RETURN
  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        <div className="announcemenet-container">
          <div className="punonjesit-ag-grid">
            <AgGridHeader
              gridApi={gridApi}
              gridData={rowData?.map((row) => ({
                ...row,
                announcementCategory: categories[row?.announcementCategory],
                userResponses: row?.userResponses?.map((res) => res?.response),
              }))}
              items={ITEMS}
              exportTitle={"Lista e Lajmerimëve"}
              modifyRows={modifyRowsToExport}
              children={
                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => setVisible(true)}
                >
                  Lajmërim i Ri
                </MondayButton>
              }
            />
            {!!isMobileView ? (
              <>
                <AgGridComponent
                  rowData={sortedAnnouncements(rowData)}
                  // rowData={aggridData}
                  // rowSelection={"multiple"}
                  onGridReady={onGridReady}
                  // paginationPageSize={10}
                  columnDefs={columnDefs({ retrieveEditData })}
                  // suppressRowClickSelection={true}
                  // onSelectionChanged={handleSelectionChanged}
                  // onPaginationChanged={onPaginationChanged}
                />
                <CustomAgPaginator gridApi={gridApi} />
              </>
            ) : (
              <MobileViewAnnouncements
                tableData={sortedAnnouncements(rowData)}
              />
            )}
          </div>
          {!!visible ? (
            <NewAnnouncement
              {...{
                mode,
                data: !!Object.keys(data).length ? data : initialData,
                gridApi,
                onReset,
                visible,
                setVisible,
                retrievedData,
              }}
            />
          ) : null}
        </div>
      </div>
    </LoadableComp>
  );
}

export default Announcements;

//region COLUMN DEFS
const columnDefs = ({ retrieveEditData = () => {} }) => {
  return [
    {
      headerName: "Titulli",
      field: "announcementTitle",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Kategoria",
      field: "announcementCategory",
      sortable: true,
      filter: true,
      width: 200,
      valueGetter: (params) => {
        return categories[params?.data?.announcementCategory];
      },
    },
    {
      headerName: "Përmbajtja",
      field: "announcementBody",
      sortable: true,
      filter: true,
      width: 200,
      cellRenderer: (params) => {
        return <>{htmlParser(params?.data?.announcementBody)}</>;
      },
    },
    {
      headerName: "Url",
      field: "announcementUrl",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Krijuar ne",
      field: "createdAt",
      sort: "desc",
      filter: "agDateColumnFilter",
      valueGetter: ({ data }) => data?.createdAt,
      // comparator: (date1, date2) => sortDates(date1, date2, "YYYY-MM-DD"),
      hide: true,
    },

    {
      headerName: "Krijuar Nga",
      field: "createdBy",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Përgjigjet",
      field: "userResponses",
      sortable: true,
      filter: true,
      width: 200,
      cellRenderer: (params) => {
        return (
          <AnnouncementReplies
            userResponses={params?.data?.usersResponses || {}}
            id={params?.data?.announcementId}
            title={params?.data?.announcementTitle}
          />
        );
      },
    },
    {
      headerName: "Shiko/Fshi",
      field: "actions",
      sortable: true,
      filter: true,
      // width: 200,
      cellRenderer: (params) => {
        return (
          <div
            className="announcements-actions"
            style={{
              display: "flex",
              height: "40px",
              alignItems: "center",
              gap: 10,
            }}
          >
            <span
              style={{
                backgroundColor: "#02686b",
                width: "30px",
                height: "30px",
                fontSize: "22px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                color: "white",
                justifyContent: "center",
                borderRadius: "5px",
                cursor: "pointer",
                fill: "#fff !important",
              }}
              onClick={() => retrieveEditData(params?.data)}
            >
              <IconEditWhite />
            </span>
            {/* <Button onClick={() => retrieveEditData(params?.data)}>Edit</Button> */}
            {/* <Tooltip title="Actions">
							<PreviewModal {...{ params }} />
						</Tooltip> */}
            <Popconfirm
              title="Je i sigurt"
              // description="Je i sigurt që don me fshi lajmërimin?"
              onConfirm={async () => {
                await deleteAnnouncement(params?.data?.announcementId)
                  .then(() => {
                    params.api.applyTransaction({ remove: [params?.data] });
                    message.success("Announcement deleted successfully");
                  })
                  .catch((e) => {
                    message.error("Error deleting announcement");
                  });
              }}
              okText="Yes"
              cancelText="No"
            >
              <span
                style={{
                  backgroundColor: "red",
                  width: "30px",
                  height: "30px",
                  fontSize: "20px",
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  borderRadius: "5px",
                  cursor: "pointer",
                  fill: "#fff !important",
                }}
              >
                <DeleteFilled className="announcements-actions__delete" />
              </span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
};

//region PREVIEW MODAL
const PreviewModal = ({ params }) => {
  const [visible, setVisible] = useState(false);
  console.log("params", params);
  return (
    <>
      <span
        style={{
          cursor: "pointer",
          width: 28,
          height: 32,
          marginRight: 10,
          fontSize: 28,
          // backgroundColor: "#f5f5f5",
          padding: 5,
          borderRadius: 5,
          color: "#02686b",
        }}
        onClick={() => setVisible(true)}
      >
        <EyeFilled
          style={{
            color: "#fff",
            padding: 3,
            borderRadius: 3,
            backgroundColor: "#02686b",
          }}
        />
      </span>
      {visible && (
        <ModalAnnounce
          {...{
            previewMode: true,
            previewAnnouncement: structuredClone(params.data),
            setPreview: setVisible,
          }}
        />
      )}
    </>
  );
};
export const data = [
  [
    "Lloji",
    "Numri i pergjigjeve",
    { role: "style" },
    {
      sourceColumn: 0,
      role: "annotation",
      type: "string",
      calc: "stringify",
    },
  ],
  ["Konfirmoi", 8.94, "#b87333", null],
  ["Anashkaloi", 10.49, "silver", null],
  ["Refuzoi", 19.3, "gold", null],
];

export const categories = {
  importantAnnouncement: "Lajmërim",
  needAction: "Lajmërim që ka nevojë për përgjigje",
  otherWish: "Urim",
};
