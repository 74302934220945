import Modal from "antd/lib/modal/Modal";
import "./StatusetPunojsveLogs.scss";
import { ModalXIcon } from "../../../../assets/icons";
import { Button } from "antd";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import moment from "moment";
import dayjsTZ from "../../../../utils/dayjs";
import { useCallback, useEffect, useState } from "react";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

const StatusetPunojsveLogs = ({ data, isVisible, setIsVisible, cardTitle }) => {
  const [tableData, setTableData] = useState([]);
  const authorFormatter = (params) => {
    if (params.value) {
      const author = `${params.value.employeeFirstName} ${params.value.employeeLastname}`;
      return author;
    }
    return "";
  };

  const dateFormatter = (params) => {
    if (params.value) {
      const formattedDate = dayjsTZ(params.value).format("DD/MM/YYYY HH:mm ");
      return formattedDate;
    }
    return "";
  };

  const columnDefs = [
    {
      headerName: "Autori",
      field: "author",
      valueFormatter: authorFormatter,
    },
    {
      headerName: "Koha e ndryshimit",
      field: "changeDate",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Activity",
      field: "activity",
    },
    {
      headerName: `${cardTitle}`,
      field: "name",
      //   valueFormatter: nameFormatter,
    },
    {
      headerName: "Ngjyra",
      field: "color",
      cellRenderer: (params) => {
        return (
          <span
            style={{
              backgroundColor: `${params.value}`,
              padding: 5,
              borderRadius: 5,
              color: "#fff",
            }}
          >
            {params.value}
          </span>
        );
      },
    },
  ];

  const onFirstDataRendered = useCallback((param) => {
    param.api.autoSizeAllColumns(false);
  }, []);

  useEffect(() => {
    let values = [];
    let newData = data?.fieldOptions?.find((item) => item.name === "Logs");
    if (newData) {
      newData?.value?.forEach((item) => {
        values.push({ ...item, ...item?.currentData });
      });
    }

    setTableData(values);
  }, [isVisible]);

  return (
    <Modal
      className="StatusetPunojsveLogs lightHeader"
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      destroyOnClose
      title={`${cardTitle} Logs`}
      closeIcon={<ModalXIcon />}
      width={800}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={() => setIsVisible(false)}
        >
          Mbyllni faqen
        </MondayButton>,
      ]}
    >
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%", borderRadius: "5px" }}
      >
        <AgGridComponent
          columnDefs={columnDefs}
          customDefaultColDef={{ flex: undefined }}
          rowData={tableData}
          overlayLoadingTemplate="Historiku është bosh!"
          autoSizeStrategy={{ type: "fitCellContents" }}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </Modal>
  );
};

export default StatusetPunojsveLogs;
