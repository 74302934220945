import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";

export const addPrepayment = async (employeeId, earnings, deductions, period, author, userInfo) => {
	return await API.post("prepayments", "/prepayments", {
		body: {
			employeeId: employeeId,
			earnings: earnings || {},
			deductions: deductions || {},
			period: [...period],
			createdBy: {
				username: author,
				name: `${userInfo?.given_name} ${userInfo?.family_name}`,
			},
		},
	});
};

export const editPrepayment = async (preId, earnings, deductions, author, userInfo) => {
	return await API.put("prepayments", `/prepayments/${preId}`, {
		body: {
			earnings: earnings || {},
			deductions: deductions || {},
			createdBy: author,
			createdBy: {
				username: author,
				name: `${userInfo?.given_name} ${userInfo?.family_name}`,
			},
		},
	});
};

export const validatePrevious = async (employeeId, period) => {
	let result = null;
	await API.get("prepayments", "/prepayments").then((res) => {
		// console.log("Lejdo", res);
		let temp = res.find(
			(el) =>
				el.employeeId === employeeId &&
				dayjsTZ(period[0]).format("DD/MM/YYYY") === dayjsTZ(el?.period[0]).format("DD/MM/YYYY")
		);
		if (!!temp) {
			result = temp;
		} else {
			result = false;
		}
	});
	return result;
};

export const onDeletePrepayment = async (info) => {
	// console.log("info", info);
	return await API.del("prepayments", `/prepayments/${info.prepaymentId}`)
		.then((res) => message.success("U fshi me sukses!"))
		.catch((err) => {
			console.log(err);
			message.error("Ndodhi nje gabim ne fshirjen e ketij rekordi!");
		});
};
