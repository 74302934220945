import axios from "axios";
import moment from "moment";
import dayjsTZ from "../../../../utils/dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { weatherIconPicker } from "../../../header/components/weatherIconPicker";
import "./weather_card.scss";

const OPTIONS_WEATHER = ["Sot", "Nesër", "3 ditët e ardhshme"];
const TIRANA_URL = `https://api.openweathermap.org/data/2.5/forecast?q=tirana&units=imperial&appid=adaf1809160dd551dd81d02077f56678`;
const NEW_YORK_URL = `https://api.openweathermap.org/data/2.5/forecast?q=new%20york&units=imperial&appid=adaf1809160dd551dd81d02077f56678`;

function WeatherCard() {
  // options to display the weather
  // state for the view of the weather
  const [weatherView, setWeatherView] = useState("Sot");
  // weather data stored in state
  const [weatherTr, setWeatherTr] = useState(null);
  const [weatherNy, setWeatherNy] = useState(null);
  // free api link for new yourk and tirana
  // convert the temprature from fahrenheit to celsius for NY and TR for all days
  const fToCelTirana = ((weatherTr?.list[0]?.main?.temp - 32) * 5) / 9;
  const fToCelTiranaNeser = ((weatherTr?.list[8]?.main?.temp - 32) * 5) / 9;
  const fToCelTiranaPasNeser = ((weatherTr?.list[15]?.main?.temp - 32) * 5) / 9;
  const fToCelTiranaPas3Dit = ((weatherTr?.list[22]?.main?.temp - 32) * 5) / 9;
  const fToCelNewYork = ((weatherNy?.list[0]?.main?.temp - 32) * 5) / 9;
  const fToCelNewYorkNeser = ((weatherNy?.list[8]?.main?.temp - 32) * 5) / 9;
  const fToCelNewYorkPasNeser =
    ((weatherNy?.list[15]?.main?.temp - 32) * 5) / 9;
  const fToCelNewYorkPas3Dit = ((weatherNy?.list[22]?.main?.temp - 32) * 5) / 9;

  // make the api call and store the data in state for Tirana weather
  const getWeatherTr = async () => {
    await axios.get(TIRANA_URL).then((response) => {
      setWeatherTr(response.data);
    });
  };

  // make the api call and store the data in state for NY weather
  const getWeatherNy = async () => {
    await axios.get(NEW_YORK_URL).then((response) => {
      setWeatherNy(response.data);
    });
  };

  // change the view of the weather
  const changeDay = (e) => {
    setWeatherView(e);
  };

  // will run when page will render to make the api calls
  useEffect(() => {
    getWeatherTr();
    getWeatherNy();
  }, []);

  return (
    <div className="weatherCard">
      <div className="header-div">
        {OPTIONS_WEATHER?.map?.((el) => (
          <div
            className={
              weatherView === el ? "item-weater-active" : "item-weater"
            }
            onClick={() => changeDay(el)}
            key={el}
          >
            {el}
          </div>
        ))}
      </div>
      <div className="weather-content">
        {/* jsx for weather today and tomorrow  the weather will be displayed for the day exact time 15:00h*/}
        {weatherView === "Sot" || weatherView === "Nesër" ? (
          <div className="content-single">
            {/* weather for tirana card */}
            <div className="Tr-Div">
              <div className="icon-content">
                <div className="icon">
                  {/* weather icon for tirana card for today or tomorrow */}
                  {weatherView === "Sot"
                    ? weatherIconPicker(weatherTr?.list[0]?.weather[0]?.main)
                    : weatherIconPicker(weatherTr?.list[8]?.weather[0]?.main)}
                </div>
                <div className="temp">
                  <div style={{ marginTop: -20 }}>
                    {/* weather temprature for tirana card for today or tomorrow */}
                    {weatherView === "Sot" ? (
                      <>{Math.round(fToCelTirana)}&deg;</>
                    ) : (
                      <>{Math.round(fToCelTiranaNeser)}&deg;</>
                    )}
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="contry">
                  Tiranë, Shqipëri
                  <br />
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 400,
                      marginTop: "-25px",
                    }}
                  >
                    {/* weather date for tirana card for today or tomorrow */}
                    {weatherView === "Sot" ? (
                      <>
                        {dayjsTZ(weatherTr?.list[0]?.dt_txt).format("dddd")}
                        &nbsp;-&nbsp;
                        {dayjsTZ(weatherTr?.list[0]?.dt_txt).format(
                          "DD/MM/YYYY"
                        )}
                      </>
                    ) : (
                      <>
                        {dayjsTZ(weatherTr?.list[8]?.dt_txt).format("dddd")}
                        &nbsp;-&nbsp;
                        {dayjsTZ(weatherTr?.list[8]?.dt_txt).format(
                          "DD/MM/YYYY"
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="line-weather" style={{ marginTop: 0 }}></div>
            {/* weather for New Yourk card */}
            <div className="Ny-Div">
              <div className="icon-content">
                <div className="icon">
                  {weatherView === "Sot"
                    ? weatherIconPicker(weatherNy?.list[0]?.weather[0]?.main)
                    : weatherIconPicker(weatherNy?.list[8]?.weather[0]?.main)}
                </div>
                <div className="temp">
                  <div style={{ marginTop: -20 }}>
                    {/* weather icon for NY card for today or tomorrow */}
                    {weatherView === "Sot" ? (
                      <>{Math.round(fToCelNewYork)}&deg;</>
                    ) : (
                      <>{Math.round(fToCelNewYorkNeser)}&deg;</>
                    )}
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="contry">
                  New York, USA
                  <br />
                  <p
                    style={{
                      fontSize: 18,
                      fontWeight: 400,
                      marginTop: "-25px",
                    }}
                  >
                    {/* weather Temprature for NY card for today or tomorrow */}
                    {weatherView === "Sot" ? (
                      <>
                        {dayjsTZ(weatherNy?.list[0]?.dt_txt).format("dddd")}
                        &nbsp;-&nbsp;
                        {dayjsTZ(weatherNy?.list[0]?.dt_txt).format(
                          "DD/MM/YYYY"
                        )}
                      </>
                    ) : (
                      <>
                        {dayjsTZ(weatherNy?.list[8]?.dt_txt).format("dddd")}
                        &nbsp;-&nbsp;
                        {dayjsTZ(weatherNy?.list[8]?.dt_txt).format(
                          "DD/MM/YYYY"
                        )}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-multiple">
            {/* JSX for next 3 days   */}
            <div className="Tr-Div">
              {/* JSX for Tirana card */}
              <div className="item">
                <div className="icon-headr">
                  <div className="celcius">
                    {weatherIconPicker(weatherTr?.list[8]?.weather[0]?.main)}
                  </div>
                  <>{Math.round(fToCelTiranaNeser)}&deg;</>
                </div>
                <div
                  className="content-days"
                  style={{ fontSize: 13, textAlign: "center", fontWeight: 600 }}
                >
                  Tiranë, Shqipëri
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      fontWeight: 400,
                    }}
                  >
                    {dayjsTZ(weatherTr?.list[8]?.dt_txt).format("dddd")}
                    <br />
                    {dayjsTZ(weatherTr?.list[8]?.dt_txt).format(
                      "DD/MM/YYYY"
                    )}{" "}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon-headr">
                  <div className="celcius">
                    {weatherIconPicker(weatherTr?.list[15]?.weather[0]?.main)}
                  </div>
                  <>{Math.round(fToCelTiranaPasNeser)}&deg;</>
                </div>
                <div
                  className="content-days"
                  style={{ fontSize: 13, textAlign: "center", fontWeight: 600 }}
                >
                  Tiranë, Shqipëri
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      fontWeight: 400,
                    }}
                  >
                    {dayjsTZ(weatherTr?.list[15]?.dt_txt).format("dddd")}
                    <br />
                    {dayjsTZ(weatherTr?.list[15]?.dt_txt).format(
                      "DD/MM/YYYY"
                    )}{" "}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon-headr">
                  <div className="celcius">
                    {weatherIconPicker(weatherTr?.list[22]?.weather[0]?.main)}
                  </div>
                  <>{Math.round(fToCelTiranaPas3Dit)}&deg;</>
                </div>
                <div
                  className="content-days"
                  style={{ fontSize: 13, textAlign: "center", fontWeight: 600 }}
                >
                  Tiranë, Shqipëri
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      fontWeight: 400,
                    }}
                  >
                    {dayjsTZ(weatherTr?.list[22]?.dt_txt).format("dddd")}
                    <br />
                    {dayjsTZ(weatherTr?.list[22]?.dt_txt).format(
                      "DD/MM/YYYY"
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="line-weather"></div>
            {/* JSX for NY card */}
            <div className="Ny-Div">
              <div className="item">
                <div className="icon-headr">
                  <div className="celcius">
                    {weatherIconPicker(weatherNy?.list[8]?.weather[0]?.main)}
                  </div>
                  <>{Math.round(fToCelNewYorkNeser)}&deg;</>
                </div>
                <div
                  className="content-days"
                  style={{
                    fontSize: 13,
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  New York, USA
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      fontWeight: 400,
                    }}
                  >
                    {dayjsTZ(weatherNy?.list[8]?.dt_txt).format("dddd")}
                    <br />
                    {dayjsTZ(weatherNy?.list[8]?.dt_txt).format(
                      "DD/MM/YYYY"
                    )}{" "}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon-headr">
                  <div className="celcius">
                    {weatherIconPicker(weatherNy?.list[15]?.weather[0]?.main)}
                  </div>
                  {Math.round(fToCelNewYorkPasNeser)}&deg;
                </div>
                <div
                  className="content-days"
                  style={{
                    textAlign: "center",
                    fontWeight: 600,
                    fontSize: 13,
                  }}
                >
                  New York, USA
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      fontWeight: 400,
                    }}
                  >
                    {dayjsTZ(weatherNy?.list[15]?.dt_txt).format("dddd")}
                    <br />
                    {dayjsTZ(weatherNy?.list[15]?.dt_txt).format(
                      "DD/MM/YYYY"
                    )}{" "}
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon-headr">
                  <div className="celcius">
                    {weatherIconPicker(weatherNy?.list[22]?.weather[0]?.main)}
                  </div>
                  {Math.round(fToCelNewYorkPas3Dit)}&deg;
                </div>
                <div
                  className="content-days"
                  style={{ fontSize: 13, textAlign: "center", fontWeight: 600 }}
                >
                  New York, USA
                  <br />
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 11,
                      fontWeight: 400,
                    }}
                  >
                    {dayjsTZ(weatherNy?.list[22]?.dt_txt).format("dddd")}
                    <br />
                    {dayjsTZ(weatherNy?.list[22]?.dt_txt).format(
                      "DD/MM/YYYY"
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default WeatherCard;
