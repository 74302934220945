import dayjsTZ from "../../utils/dayjs";
import { FormGridStatusRenderer } from "./FormGridStatusRenderer/FormGridStatusRenderer";
import UploadedFilesRenderer from "../Documentation/UploadedFilesRenderer/UploadedFilesRenderer";
import { RedWarning } from "../../assets/icons";
import { Tooltip } from "antd";
import { htmlParser } from "../../utils";
import colorShade from "../../utils/colorShade";
import getAgThemePreference from "../../utils/getAgThemePreference";

const DATE_FORMAT = "DD/MM/YYYY";
const { theme, accentColor } = getAgThemePreference();

export const documentationsPageColDef = ({
  accessToken,
  statuses,
  employees,
  navigate,
}) => [
  {
    headerName: "Nr.",
    field: "identityId",
    valueGetter: "node.rowIndex + 1",
  },
  {
    headerName: "Punonjësi",
    field: "name",
    filter: "agTextColumnFilter",
    cellRenderer: function (params) {
      try {
        return (
          <div
            style={{
              cursor: "pointer",
              ...(theme.includes("dark")
                ? { color: "#FFF" }
                : { color: colorShade(accentColor, 0.25) }),
              fontWeight: 600,
            }}
            onClick={() =>
              navigate(`/punonjesit/specifikat/${params?.data?.recordId}`)
            }
          >
            {params?.data?.name}
          </div>
        );
      } catch (err) {
        if (params.value) {
          return params.value;
        } else {
          return null;
        }
      }
    },
  },
  {
    headerName: "Departamenti",
    field: "employeeDepartmentName",
    filter: "agTextColumnFilter",
    cellRenderer: function (params) {
      try {
        return (
          <div>
            {
              employees?.find(
                (item) => item?.employeeId === params?.data?.recordId
              )?.employeeDepartmentName
            }
          </div>
        );
      } catch (err) {
        if (params.value) {
          return params.value;
        } else {
          return null;
        }
      }
    },
    // hide: true,
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: "Roli",
    field: "employeeRoleName",
    filter: "agTextColumnFilter",
    cellRenderer: function (params) {
      try {
        return (
          <div>
            {
              employees?.find(
                (item) => item?.employeeId === params?.data?.recordId
              )?.employeeRoleName
            }
          </div>
        );
      } catch (err) {
        if (params.value) {
          return params.value;
        } else {
          return null;
        }
      }
    },
    // hide: true,
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: "Lloji i Dokumentacionit",
    field: "docType",
    filter: "agTextColumnFilter",
    cellRenderer: function (params) {
      try {
        return (
          <div
            style={{
              cursor: "pointer",
              ...(theme.includes("dark")
                ? { color: "#FFF" }
                : { color: colorShade(accentColor, 0.25) }),
              fontWeight: 600,
            }}
            onClick={() =>
              navigate(`/punonjesit/specifikat/${params?.data?.recordId}`, {
                state: {
                  tab: "Dokumentacione",
                  key: "3",
                  openDocModal: params?.data?.docId,
                },
              })
            }
          >
            {params.value}
          </div>
        );
      } catch (err) {
        if (params.value) {
          return params.value;
        } else {
          return null;
        }
      }
    },
    enableRowGroup: true,
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Statusi",
    field: "docStatus",
    filter: "agTextColumnFilter",
    cellRenderer: ({ value }) =>
      htmlParser(FormGridStatusRenderer({ value, statuses })),
    cellClass: "centeredCell",
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  // {
  //   headerName: "Kategoria",
  //   field: "categoryName",
  //   filter: "agTextColumnFilter",
  //   resizable: true,
  //   sortable: true,
  //   enableRowGroup: true,
  //   cellClass: "centeredCell",
  // },
  {
    headerName: "Skedarët e Ngarkuar",
    field: "googleDriveUploads",
    filter: "agTextColumnFilter",
    cellRenderer: (params) =>
      UploadedFilesRenderer({
        node: { data: { googleDriveUpload: params?.value } },
        accessToken,
      }),
    cellClass: "centeredCell",
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
  {
    headerName: "Data e Skadencës",
    field: "expirationDate",
    filter: "agTextColumnFilter",
    cellClass: "centeredCell",
    cellRenderer: (params) => {
      if (!!params.value) {
        let isExpiring = false;
        const currentTimestamp = parseInt(dayjsTZ().format("x"));
        const expirationTimestamp = dayjsTZ(parseInt(params?.value));
        if (
          currentTimestamp + 604800000 > expirationTimestamp &&
          expirationTimestamp > currentTimestamp
        ) {
          isExpiring = true;
        }
        return (
          <Tooltip
            placement="top"
            title={`${!!isExpiring ? "Po skadon" : ""}`}
            overlayClassName="expirationDateTooltip"
          >
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <span>
                {dayjsTZ(parseInt(params?.value)).format(DATE_FORMAT)}
              </span>
              {!!isExpiring && <RedWarning />}
            </div>
          </Tooltip>
        );
      } else {
        return "";
      }
    },
    resizable: true,
    sortable: true,
    enableRowGroup: true,
  },
];
