import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  WeatherBora,
  WeatherDjell,
  WeatherMjegull,
  WeatherPakRe,
  WeatherReMeNderprerje,
  WeatherRrufe,
  WeatherShi,
  WeatherShumShi,
  WeatherVrenjtur,
} from "../../../assets/icons";

//Zgjedh icon sipas emrit te kohes
export const weatherIconPicker = (weatherCase) => {
  switch (weatherCase) {
    case "Clear":
      return <WeatherDjell />;
    //   break;
    case "few clouds":
      return <WeatherPakRe />;
    //   break;
    case "scattered clouds":
      return <WeatherReMeNderprerje />;
    //   break;
    case "Clouds":
      return <WeatherReMeNderprerje />;
    case "overcast clouds":
      return <WeatherRrufe />;
    //   kjo ikona duhet ndrruar  break;
    case "shower rain":
      return <WeatherShumShi />;
    //   break;
    case "Rain":
      return <WeatherShi />;
    case "Drizzle":
      return <WeatherShi />;
    case "light rain":
      return <WeatherShi />;
    //   break;
    case "haze":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Thunderstorm":
      return <WeatherRrufe />;
    //   break;
    case "Snow":
      return <WeatherBora />;
    // break;
    case "Mist":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Dust":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Sand":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Smoke":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Tornado":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Squall":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Ash":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Fog":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    case "Haze":
      return <WeatherMjegull width={30} height={30} />;
    //   break;
    default:
      return <QuestionCircleOutlined />;
    //   break;
  }
};
