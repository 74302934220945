import { createElement } from "react";
import { IconNgargo } from "../../../../assets/icons";
import { Tooltip } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import dayjsTZ from "../../../../utils/dayjs";
import { htmlParser } from "../../../../utils";
import { formatChanges } from "../../../punonjesit/EmployeeView/components/ActivityModal/formatChanges";
import { useReactToPrint } from "react-to-print";

const AgGridToPrint = ({
  gridApi,
  title,
  tableCols,
  modifyRows,
  className,
  style
}) => {
  const generateTable = () => {
    let filteredRows = [];
    gridApi.forEachNodeAfterFilter((e) => filteredRows.push(e.data));
    console.log(filteredRows);
    if (!filteredRows || filteredRows.length === 0) {
      return undefined;
    }

    if (!!modifyRows && typeof modifyRows === "function") {
      filteredRows = modifyRows(filteredRows);
    }

    let div = document.createElement("div");
    div.innerHTML = `<div>${title}</div><br>
    <table>
      <thead>
        <tr>
          ${tableCols?.map((el) => `<th>${el?.al || el}</th>`)}
        </tr>
      </thead>
      <tbody>
        ${filteredRows?.map((row) => {
          let tableRow = [];
          tableCols?.forEach((col) => {
            let value = row[col?.en || col];
            let formattedValue = `${
              !!value
                ? Array.isArray(value)
                  ? value.join(", ")
                  : dayjs.isDayjs(value) ||
                    value instanceof dayjs ||
                    dayjsTZ(value).isValid() ||
                    dayjsTZ(value, dayjs.ISO_8601).isValid()
                  ? dayjsTZ(value)
                      .locale("al")
                      .format(col?.format || "DD/MM/YYYY HH:mm")
                  : typeof value === "string" || value instanceof String
                  ? !!htmlParser(value)?.props?.children
                    ? htmlParser(value)?.props.children
                    : formatChanges(value)
                  : formatChanges(value)
                : ""
            }`;
            tableRow.push(formattedValue);
          });
          return `<tr>
              ${tableRow?.map((val) => `<td>${val}</td>`)}
            </tr>`;
        })}
      </tbody>
    </table>`;

    return div;
  };
  const handlePrint = useReactToPrint({
    content: () => generateTable(),
  });
  console.log({style})
  return (
    <div
      className={`headerIcon ${className}`}
      data-testid="headerPrint"
      onClick={handlePrint}
      style={style}
    >
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Printo"}
      >
        <IconNgargo width={20} height={20} fill={style?.fill || "#323338"} />
      </Tooltip>
    </div>
  );
};

export default AgGridToPrint;
