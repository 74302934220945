import { Modal } from "antd";
import dayjsTZ from "../../../utils/dayjs";
import React, { useEffect, useRef, useState } from "react";
import { Snowflake } from "../icons";
// import HappyHolidays from "../pictures/HappyHolidays.png";
// import HappyAutumn from "../pictures/HappyAutumn.jpg";
import HappyThanksGiving from "../pictures/FlexThanksGiving.png";
import "./HappyHolidays.scss";
import dayjs from "dayjs";
function HappyHolidaysModal() {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		let holidayTime = localStorage.getItem("gezuarVitinERi");
		if (!!holidayTime) {
			if (dayjsTZ().diff(dayjsTZ(parseInt(holidayTime)), "hours", true) > 8) {
				localStorage.setItem("gezuarVitinERi", dayjsTZ().valueOf());
				setVisible(true);
			}
		} else {
			localStorage.setItem("gezuarVitinERi", dayjsTZ().valueOf());
			setVisible(true);
		}
	}, []);

	return (
		<Modal
			wrapClassName="happy-holidays-modal"
			centered
			open={visible}
			width={"900px"}
			title={null}
			footer={null}
			closeIcon={<Snowflake />}
			onCancel={() => setVisible(false)}
		>
			{/* <img className="image-happy-holidays" src={HappyHolidays} alt="Happy Holidays" /> */}
			<img
				className="image-happy-holidays"
				src={
					"https://i.ibb.co/nQXG1VN/Blue-and-White-Winter-Merry-Christmas-Poster-A3.png"
				}
				alt="Happy Autumn"
				style={{
					width: "100%",
					height: "auto",
					borderRadius: "10px",
					display: "flex",
					justifyContent: "center",
				}}
			/>
		</Modal>
	);
}

export default HappyHolidaysModal;
