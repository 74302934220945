import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { prepareMultis } from "../../../Pagat/wages/multiGenerate/prepare";
import { getTranslation } from "../../generate/translations";

export const employeesList = (data, filter, columns, manualColumns) => {
	let result = {
		columnsDef: [],
		allColumns: [],
		rowData: [],
		selectedColumns: [],
		allColumns: [],
	};
	if (!!data && !!filter) {
		result.selectedColumns = manualColumns || columns.columnDefs;
		result.allColumns = columns.allAppliedColumnDefs;
		const processedData = data
			?.map((item) => ({
				...item,
				employeeDayOfBirth: dayjsTZ(item?.employeeDayOfBirth).format("DD/MM/YYYY"),
				employeeHireDate: dayjsTZ(item?.employeeHireDate).format("DD/MM/YYYY"),
			}))
			.filter((em) => em?.employeeStatus === "Aktiv");
		console.log("processed", processedData);
		if (!!manualColumns && Array.isArray(manualColumns)) {
			manualColumns.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			processedData.forEach((emplRecord) => {
				let toAdd = [];
				manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		} else {
			columns?.columnDefs.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			processedData.forEach((emplRecord) => {
				let toAdd = [];
				columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		}
	}
	console.log("result to return", result);
	return result;
};

// iterate through object keys and values
function iterate(obj) {
	let result = [];
	for (let key in obj) {
		if (obj.hasOwnProperty(key)) {
			result.push(obj[key]);
		}
	}
	return result;
}

// const getTranslation = (key) => {
// 	if (albanianTranslations.hasOwnProperty(key)) {
// 		return albanianTranslations[key];
// 	}
// 	return "";
// };
