import Modal from "antd/lib/modal/Modal";
import { Button } from "antd";
import moment from "moment";
import dayjsTZ from "../../../../../utils/dayjs";
import { useCallback, useEffect, useState } from "react";
import { ModalXIcon } from "../../../../../assets/icons";
import AgGridComponent from "../../../../AG-grid/AgGridComponent";
import "./FestatZyrtareLogs.scss";

const FestatZyrtareLogs = ({ data, isVisible, setIsVisible, logsName }) => {
  const [tableData, setTableData] = useState([]);
  const authorFormatter = (params) => {
    if (params.value) {
      const author = `${params.value.employeeFirstName} ${params.value.employeeLastname}`;
      return author;
    }
    return "";
  };

  const dateFormatter = (params) => {
    if (params.value) {
      const formattedDate = dayjsTZ(params.value).format("DD/MM/YYYY HH:mm ");
      return formattedDate;
    }
    return "";
  };
  const newDateFormatter = (params) => {
    if (params.value) {
      const formattedDate = dayjsTZ(params.value).format("DD/MM/YYYY");
      return formattedDate;
    }
    return "";
  };

  const columnDefs = [
    {
      headerName: "Autori",
      field: "author",
      valueFormatter: authorFormatter,
    },
    {
      headerName: "Festa Zyrtare",
      field: "title",
    },
    {
      headerName: "Koha e ndryshimit",
      field: "changeDate",
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Fusha  e ndryshuar",
      field: "field",
    },
    {
      headerName: "Activity",
      field: "activity",
    },
    {
      headerName: "Si Ishte",
      field: "previousData",
      valueFormatter: newDateFormatter,
    },
    {
      headerName: "Si u Be",
      field: "currentData",
      valueFormatter: newDateFormatter,
    },
  ];

  const onFirstDataRendered = useCallback((param) => {
    param.api.autoSizeAllColumns(false);
  }, []);

  useEffect(() => {
    let values = [];

    if (data?.length !== 0) {
      data?.forEach((item) => {
        if (item?.logs && item?.logs.length !== 0) {
          item?.logs?.forEach((e) => {
            values.push({ ...e, title: item.title });
          });
        }
      });
    }

    setTableData(values);
  }, [isVisible]);

  return (
    <Modal
      className="festatZyrtareLogs"
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      destroyOnClose
      title={`Festat Zyrtare Logs`}
      closeIcon={<ModalXIcon />}
      width={1000}
      footer={[
        <Button className="decline" onClick={() => setIsVisible(false)}>
          Mbyllni faqen
        </Button>,
      ]}
    >
      <div
        className="ag-theme-alpine"
        style={{ width: "100%", height: "100%", borderRadius: "5px" }}
      >
        <AgGridComponent
          columnDefs={columnDefs}
          customDefaultColDef={{ flex: undefined }}
          rowData={tableData}
          overlayLoadingTemplate="Historiku është bosh!"
          autoSizeStrategy={{ type: "fitCellContents" }}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
    </Modal>
  );
};

export default FestatZyrtareLogs;
