// Calculate the wage based on hourly rate (employee.employeeSalaryPerHour)
// Group Clockings per Employee
import { groupByKey } from "../../../../utils/loadash/methods";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { multiply, add, divide } from "lodash";
import { message } from "antd";
import { currencyformatter } from "./formatter";
import { paySubFactory } from "../../PaySub/employeeCalc/employeeSubFactory";

/**
 * Used To Calculate the Wages for Employees given clockings and moment instance (start, end)
 * Does processing using other function helpers (not direct dependency)
 * @param {Array Of Objects} employees
 * @param {Array of Objects} clockings
 * @param {Object} locationState
 * @returns {Array of Objects} with calculated Wages
 */

export const calculateWagePerHours = (employees, clockings, locationState) => {
	// console.log(
	//   "Location state",
	//   locationState,
	//   dayjsTZ(locationState.data.muaji).startOf("month").format("DD/MM/YYYY")
	// );
	let result = [];
	let processedShifts = processShift(locationState, clockings);
	if (processedShifts.length > 0) {
		let grouped = groupByKey(processedShifts, "employeeId");
		// console.log("Grouped:", grouped);
		Object.entries(grouped).map((item) => {
			let coefficent = employees.find((el) => el.employeeId === item[0]);
			// console.log("Coefficent", coefficent);
			let calculatedEmpl = {
				employeeId: item[0],
				calculatedWage: 0,
				departmentId: coefficent?.employeeDepartmentId,
			};
			let hoursEmpl = 0;
			item[1].forEach((clock) => {
				hoursEmpl += dayjsTZ(clock.clockOutDate).diff(clock.clockInDate, "hours", true);
			});
			calculatedEmpl.totalHours = hoursEmpl;
			calculatedEmpl.calculatedWage =
				!!coefficent && coefficent?.employeePayrollType === "Page bazuar ne ore"
					? multiply(coefficent.employeeSalaryPerHour, hoursEmpl)
					: parseFloat(coefficent?.employeeMonthlyNetSalary) || 0;

			result.push(calculatedEmpl);
		});
		// console.log("Grouoed2:", result);

		return result;
	}
	return result;
};

/**
 * Used to calculate monthly wages totals, with another function as its predicate
 * @param {Array of Objects} employees
 * @param {Array of Objects} clockings
 * @param {Object} locationState
 * @returns {Array} with all Month Payments
 */

export const calculateMonthAllPayment = (employees, clockings, locationState) => {
	let listOfPayments = calculateWagePerHours(employees, clockings, locationState);
	let allPaymentsPreview = listOfPayments
		.map((el) => el.calculatedWage)
		.reduce((previous, curr) => {
			return previous + curr;
		}, 0);
	return allPaymentsPreview;
};

/**
 * Used to calculate the wages, grouped by department keys and those without (other)
 * @param {Object} monthInstance which has start, end of moment Instance
 * @param {Array of Objects} clockings
 * @param {Array of Objects} employees
 * @param {Array of Objects} departments
 * @returns {Array of Objects} with calculated Wages totalHours, calculatedWage, departmentName
 */

export const calculateDepartmentsWage = (monthInstance, clockings, employees, departments) => {
	let result = [];
	try {
		const listOfWages = calculateWagePerHours(employees, clockings, {
			data: { muaji: monthInstance },
		});
		if (listOfWages.length > 0) {
			const groupedDepartments = groupByKey(listOfWages, "departmentId");
			groupedDepartments.other = groupedDepartments?.undefined;
			delete groupedDepartments.undefined;
			for (const [key, value] of Object.entries(groupedDepartments)) {
				let departmentInstance = {
					departmentId: key,
					totalHours: 0,
					calculatedWage: 0,
				};
				value?.map((el) => {
					departmentInstance.totalHours = add(departmentInstance.totalHours, el.totalHours);
					departmentInstance.calculatedWage = add(
						departmentInstance.calculatedWage,
						el.calculatedWage
					);
				});
				result.push(departmentInstance);
			}
			result = result.map((r) => {
				let department = departments.find((el) => el.departmentId === r.departmentId);
				// console.log("List Of Wages", department);
				if (!!department)
					return {
						...r,
						departmentName: department?.departmentName || "Other",
					};
				else return { ...r, departmentName: "Other" };
			});
			// console.log("List Of Wages", result);
			return prepareWageStatistics(result);
		} else return [];
	} catch (err) {
		console.error("Nuk ka te dhena", err);
		// message.error("Nodhi nje gabim ne llogaritjen e pages per departamente!");
		return result;
	}
};

/**
 * A helper function used to process shifts with timestamps instances
 * @param {Object} locationState
 * @param {Array of Objects} clockings
 * @returns {Array of Objects} of filtered shifts given the moment instance
 */

export const processShift = (locationState, clockings) => {
	const result =
		locationState !== null
			? clockings.filter(
					(el) =>
						el.clockInDate !== null &&
						el.clockOutDate !== null &&
						el.clockInDate > dayjsTZ(locationState.data.muaji).startOf("month") &&
						el.clockInDate < dayjsTZ(locationState.data.muaji).endOf("month")
			  )
			: clockings.filter((el) => el.clockInDate !== null && el.clockOutDate !== null);
	return result;
};

/**
 * Used to prepare the initialized data structure for Google Chart Visual Dashboard Card
 * @param {Object} wageObj || Wage Object containing departmentName, totalHours and calculatedWage
 * @returns {Array of Arrays of Objects}
 */

export const prepareWageStatistics = (wageObj) => {
	let data = [["Element", "", { role: "style" }, ""]];
	wageObj.map((w) => {
		data.push([w.departmentName, w.calculatedWage, getRandomColor(), w.totalHours]);
	});
	return data;
};

/**
 * Used to retrievce a random color
 * @returns A random string color in the "#ColorNumber" format
 */

export const getRandomColor = () => {
	const colors = ["#7AC14D", "#FCC94A", "#BCBCBC", "#F04F4E", "#840032", "#ADD9F4", "#B0E298"];
	const random = (min, max) => Math.floor(Math.random() * (max - min) + min);
	let id = random(0, colors.length - 1);
	return colors[id];
};

/// Year (each month) calcs

export const getMonthlyAmounts = (type, wages, month) => {
	// console.log("WAGES", wages);
	if (wages.length > 0) {
		if (type === "bruto") {
			let bruto = 0;
			const temp = wages.filter(
				(el) =>
					el?.wageStatus === "Finalized" &&
					dayjsTZ(month).startOf("month").format("DD/MM/YYYY") ===
						dayjsTZ(el?.startPeriod).startOf("month").format("DD/MM/YYYY")
			);
			if (temp.length > 0) {
				temp.forEach((el) => {
					let tempBruto = el?.bruto?.totalBruto || 0;
					bruto = add(bruto, tempBruto);
				});
				return currencyformatter.format(bruto);
			}
			return "Asnje rekord";
		} else if (type === "neto") {
			let neto = 0;
			const temp = wages.filter(
				(el) =>
					el?.wageStatus === "Finalized" &&
					dayjsTZ(month).startOf("month").format("DD/MM/YYYY") ===
						dayjsTZ(el?.startPeriod).startOf("month").format("DD/MM/YYYY")
			);
			if (temp.length > 0) {
				temp.forEach((el) => {
					// console.log("el", el);
					let tempNeto = el?.taxes?.netoPay?.netoPay || 0;
					neto = add(neto, tempNeto);
				});
				return currencyformatter.format(neto);
			}
			return "Asnje rekord";
		} else if (type === "taxes") {
			let taxes = 0;
			const temp = wages.filter(
				(el) =>
					el?.wageStatus === "Finalized" &&
					dayjsTZ(month).startOf("month").format("DD/MM/YYYY") ===
						dayjsTZ(el?.startPeriod).startOf("month").format("DD/MM/YYYY")
			);
			if (temp.length > 0) {
				temp.forEach((el) => {
					let tempTaxes = el?.taxes?.netoPay?.totalEmployeeerIns || 0;
					taxes = add(taxes, tempTaxes);
				});
				return currencyformatter.format(taxes);
			}
			return "Asnje rekord";
		} else if (type === "totalCost") {
			let totalCost = 0;
			const temp = wages.filter(
				(el) =>
					el?.wageStatus === "Finalized" &&
					dayjsTZ(month).startOf("month").format("DD/MM/YYYY") ===
						dayjsTZ(el?.startPeriod).startOf("month").format("DD/MM/YYYY")
			);
			if (temp.length > 0) {
				temp.forEach((el) => {
					let tempTotal = el?.bruto?.totalBruto + el?.taxes?.netoPay?.totalEmployeeerIns;
					totalCost = add(totalCost, tempTotal);
				});
				return currencyformatter.format(totalCost);
			}
			return "Asnje rekord";
		}
	}
	return "Asnje rekord";
};

// Amount per departments
export const getDepartmentsWages = (departments, wages, employees) => {
	// start of month
	let data = [["Element", "", { role: "style" }, ""]];
	if (wages.length > 0 && employees.length > 0) {
		let wagesTemp = wages.filter(
			(w) =>
				dayjsTZ().startOf("month").format("DD/MM/YYYY") ===
					dayjsTZ(w?.startPeriod).startOf("month").format("DD/MM/YYYY") &&
				dayjsTZ().endOf("month").format("DD/MM/YYYY") ===
					dayjsTZ(w?.endPeriod).endOf("month").format("DD/MM/YYYY") &&
				w?.wageStatus === "Finalized"
		);

		let empTemp = employees.map((e) => {
			const appliedWage = wagesTemp.find((wage) => wage.employeeId === e?.employeeId);

			return {
				employeeId: e?.employeeId,
				departmentName: e?.employeeDepartmentName,
				neto: !!appliedWage ? appliedWage?.taxes?.netoPay?.netoPay || 0 : 0,
			};
		});

		let groupedWagesByDep = groupByKey(empTemp, "departmentName");
		Object.entries(groupedWagesByDep).forEach(([key, value]) => {
			let amount = 0;
			if (Array.isArray(value)) {
				value?.map((el) => (amount += el?.neto));
			}
			data.push([key, amount, getRandomColor(), 0]);
		});

		return data;
	}
	return false;
};

export const getWagesPieChart = (
	wages,
	clockings,
	requests,
	employees,
	prepayments,
	programFields
) => {
	let data = [
		["Neto", "Shuma"],
		// ["Work", 11],
		// ["Eat", 2],
		// ["Commute", 2],
		// ["Watch TV", 2],
		// ["Sleep", 7],
	];
	let taxes = 0;
	let neto = 0;
	let progressiveTax = 0;
	let healthIns = 0;
	let socialIns = 0;
	let sigurimet = 0;
	const momentInstance = { start: dayjsTZ().startOf("month"), end: dayjsTZ() };
	const diferenca = dayjsTZ().diff(momentInstance.start, "days", false);
	const totaliDitete = dayjsTZ().endOf("month").diff(dayjsTZ().startOf("month"), "days", false);

	employees?.map((el) => {
		const calculatedWage = new paySubFactory({
			type: "allEarnings",
			employee: el,
			clockings: clockings.filter((el) => el?.approved === true),
			employeeRequests: requests,
			momentInstance: momentInstance,
			prepayments: prepayments,
			programFields: programFields,
		});
		// console.log("CALUD", calculatedWage);
		neto = add(neto, getNeto(calculatedWage, diferenca, totaliDitete));
		taxes = add(taxes, getTaxes(calculatedWage, diferenca, totaliDitete));
		let applied = getAppliedTaxes(calculatedWage, diferenca, totaliDitete);
		// console.log("progressive", applied);

		progressiveTax += applied?.progressiveTax || 0;
		healthIns += applied?.healthIns || 0;
		socialIns += applied?.socialIns || 0;
	});
	sigurimet = healthIns + socialIns;

	data.push([`Neto: ${currencyformatter.format(neto)}`, neto]);
	data.push([`Sigurimet: ${currencyformatter.format(sigurimet)}`, sigurimet]);
	data.push([`Progresive: ${currencyformatter.format(progressiveTax)}`, progressiveTax]);
	return data;
};

export const getIndEmplWagesPieChart = (
	employee,
	wages,
	clockings,
	requests,
	employees,
	prepayments,
	programFields
) => {
	let data = [["Neto", "Tax"]];
	let taxes = 0;
	let neto = 0;
	const momentInstance = { start: dayjsTZ().startOf("month"), end: dayjsTZ() };
	const diferenca = dayjsTZ(momentInstance.end).diff(momentInstance.start, "days", false);
	const totaliDitete = dayjsTZ().endOf("month").diff(dayjsTZ().startOf("month"), "days", false);
	const currentEmp = employees.find((el) => el.employeeId === employee);

	const calculatedWage = new paySubFactory({
		type: "allEarnings",
		employee: currentEmp,
		clockings: clockings.filter((el) => el?.approved === true && el?.employeeId === employee),
		employeeRequests: requests,
		momentInstance: momentInstance,
		prepayments: prepayments,
		programFields: programFields,
	});

	neto = getNeto(calculatedWage, diferenca, totaliDitete);
	taxes = getTaxes(calculatedWage, diferenca, totaliDitete);

	data.push([`Neto: ${currencyformatter.format(neto)}`, neto]);
	data.push([`Taksat e punonjesit: ${currencyformatter.format(taxes)}`, taxes]);
	// console.log(data, calculatedWage);
	return data;
};

export const getNumberWages = (
	type,
	wages,
	clockings,
	requests,
	employees,
	prepayments,
	programFields
) => {
	let data = [
		["Neto", "Tax"],
		// ["Work", 11],
		// ["Eat", 2],
		// ["Commute", 2],
		// ["Watch TV", 2],
		// ["Sleep", 7],
	];
	let taxes = 0;
	let neto = 0;
	let cost = 0;
	// let bruto = 0;
	const momentInstance = { start: dayjsTZ().startOf("month"), end: dayjsTZ() };
	const diferenca = dayjsTZ(momentInstance.end).diff(momentInstance.start, "days", false);
	const totaliDitete = dayjsTZ().endOf("month").diff(dayjsTZ().startOf("month"), "days", false);

	employees?.forEach((el, idx) => {
		const calculatedWage = new paySubFactory({
			type: "allEarnings",
			employee: el,
			clockings: clockings.filter((el) => el?.approved === true),
			employeeRequests: requests,
			momentInstance: momentInstance,
			prepayments: prepayments,
			programFields: programFields,
		});
		// console.log("CALUD", calculatedWage);
		// bruto = add(bruto, getBruto(calculatedWage, diferenca, totaliDitete));
		neto = add(neto, getNeto(calculatedWage, diferenca, totaliDitete));
		taxes = add(taxes, getTaxes(calculatedWage, diferenca, totaliDitete));
		cost = add(taxes, getEmplTotal(calculatedWage, diferenca, totaliDitete));
	});

	// console.log("FINISHED", { taxes: taxes, neto: neto, bruto: bruto });
	if (type === "bruto") {
		return currencyformatter.format(add(neto, taxes));
	} else if (type === "neto") {
		return currencyformatter.format(neto);
	} else if (type === "total") {
		return currencyformatter.format(add(add(neto, taxes), cost));
	}
};

const getNeto = (calculatedWage, diferenca, totaliDitete) => {
	let neto = 0;
	if (
		calculatedWage?.taxes?.netoPay?.netoPay < 28417 ||
		calculatedWage?.employeeInfo?.employeePayrollType === "Page Fikse"
	) {
		let temp = divide(diferenca, totaliDitete);
		neto = multiply(temp, calculatedWage?.taxes?.netoPay?.netoPay);
	} else {
		neto = calculatedWage?.taxes?.netoPay?.netoPay;
	}

	return neto || 0;
};

const getTaxes = (calculatedWage, diferenca, totaliDitete) => {
	let employeeTax = 0;

	if (
		calculatedWage?.taxes?.netoPay?.netoPay < 28416 ||
		calculatedWage?.employeeInfo?.employeePayrollType === "Page Fikse"
	) {
		let temp = divide(diferenca, totaliDitete);
		employeeTax =
			temp *
			add(
				calculatedWage?.taxes?.netoPay?.progressiveTax || 0,
				calculatedWage?.taxes?.netoPay?.totalIns || 0
			);
	} else {
		employeeTax =
			calculatedWage?.taxes?.netoPay?.progressiveTax + calculatedWage?.taxes?.netoPay?.totalIns;
	}

	return employeeTax || 0;
};

const getBruto = (calculatedWage, diferenca, totaliDitete) => {
	// console.log("calc 12", calculatedWage);
	let bruto = 0;
	if (
		calculatedWage?.bruto?.totalBruto < 32001 ||
		calculatedWage.employeeInfo?.employeePayrollType === "Page Fikse"
	) {
		let temp = divide(diferenca, totaliDitete);
		bruto = multiply(temp, calculatedWage?.bruto?.totalBruto);
	} else {
		bruto = calculatedWage?.bruto?.totalBruto;
	}

	return bruto || 0;
};

const getEmplTotal = (calculatedWage, diferenca, totaliDitete) => {
	let employeeTotal = 0;

	if (
		calculatedWage?.taxes?.netoPay?.netoPay < 28416 ||
		calculatedWage?.employeeInfo?.employeePayrollType === "Page Fikse"
	) {
		let temp = divide(diferenca, totaliDitete);
		employeeTotal = temp * calculatedWage?.taxes?.netoPay?.totalEmployeeerIns;
	} else {
		employeeTotal = calculatedWage?.taxes?.netoPay?.totalEmployeeerIns;
	}

	return employeeTotal || 0;
};

const getAppliedTaxes = (calculatedWage, diferenca, totaliDitete) => {
	let employeeTax = {
		socialIns: 0,
		healthIns: 0,
		progressiveTax: 0,
	};

	if (
		calculatedWage?.taxes?.netoPay?.netoPay < 28416 ||
		calculatedWage?.employeeInfo?.employeePayrollType === "Page Fikse"
	) {
		let temp = divide(diferenca, totaliDitete);
		employeeTax.socialIns = multiply(temp, calculatedWage?.taxes?.netoPay?.socialIns);
		employeeTax.healthIns = multiply(temp, calculatedWage?.taxes?.netoPay?.healthIns);
		employeeTax.progressiveTax = multiply(temp, calculatedWage?.taxes?.netoPay?.progressiveTax);
	} else {
		employeeTax.socialIns = calculatedWage?.taxes?.netoPay?.socialIns;
		employeeTax.healthIns = calculatedWage?.taxes?.netoPay?.healthIns;
		employeeTax.progressiveTax = calculatedWage?.taxes?.netoPay?.progressiveTax;
	}

	return employeeTax || 0;
};
