import { Button, Form, message, Modal } from "antd";
import { API } from "aws-amplify";
import dayjsTZ from "../../../utils/dayjs";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeaderStepper } from "../..";
import { requestData } from "../../../helpers/API/RequestFactory";
import { employees } from "../../../store/actions";
import DocumentationEmployeeCreation from "../AddEmployeeModal/DocumentationEmployeeCreation";
import GeneralInformations from "../AddEmployeeModal/GeneralInformations";
import CancelAddEmployeeModalPopover from "../AddEmployeeModal/Popover";
import TypeOfWork from "../AddEmployeeModal/TypeOfWork";
import { ModalXIcon } from "../../../assets/icons";
import { driveApi } from "../../DriveRequest";
import { notificationUserToAdmin } from "../../../utils/sendNotification";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";

function AddEmployeModal({
  setVisible,
  visible,
  tableData,
  setTableData,
  userData,
  files,
  setActiveEmployees,
  socketHandler,
  users,
  form,
  setPopoverVisible,
  setCurrentStep,
  currentStep,
}) {
  const dispatch = useDispatch();

  const { departments } = useSelector((state) => state.departments);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });

  const [spin, setSpin] = useState(false);
  const [documentationsAvailable, setDocumentationsAvailable] = useState([]);

  //* steps for the modal form
  const steps = [
    {
      title: "Informacione të përgjithshme",
      Comp: GeneralInformations,
    },
    {
      title: "Lloji i punësimit",
      Comp: TypeOfWork,
      disabled: true,
    },
    {
      title: "Dokumentacione",
      Comp: DocumentationEmployeeCreation,
      disabled: true,
    },
  ];

  //region ON CANCEL
  const handleCancel = () => {
    let isEdited;
    for (const [key, value] of Object.entries(form.getFieldsValue())) {
      if (!!value) {
        setPopoverVisible(true);
        isEdited = true;
        break;
      }
    }
    if (!isEdited) setVisible(false);
  };

  //region ON CONTINUE
  const handleVazhdoMeTej = () => {
    if (currentStep === 0) {
      form
        .validateFields()
        .then((formData) => {
          if (
            tableData?.find(
              (el) =>
                el.employeeFathersName?.toUpperCase() ===
                  formData?.employeeFathersName?.toUpperCase() &&
                el.employeeFirstName?.toUpperCase() ===
                  formData?.employeeFirstName?.toUpperCase() &&
                el.employeeLastName?.toUpperCase() ===
                  formData?.employeeLastName?.toUpperCase()
            )
          ) {
            message.warning("Ky punonjës ekziston tashmë");
          } else {
            setCurrentStep(currentStep + 1);
          }
        })
        .catch((err) => console.log("err: ", err));
    } else {
      form
        .validateFields()
        .then(() => setCurrentStep(currentStep + 1))
        .catch((err) => console.log("err: ", err));
    }
  };

  //region ON FINISH
  const finish = async () => {
    setSpin(true);
    let tmpDocFolderId = "";
    //* gets departmentId form departmentsData
    const departmentName = form.getFieldValue("employeeDepartmentName");
    const correctDepartment = departments?.find(
      (department) => department?.departmentName === departmentName
    );
    const departmentId = correctDepartment?.departmentId;
    setVisible(false);
    setCurrentStep(0);

    //! takes the data from the form and makes it ready to be thrown into the database
    let toSend = {
      employeeFirstName: form.getFieldValue("employeeFirstName"),
      employeeFathersName: form.getFieldValue("employeeFathersName"),
      employeeLastName: form.getFieldValue("employeeLastName"),
      employeeGender: form.getFieldValue("employeeGender"),
      employeeDayOfBirth: !!form.getFieldValue("employeeDayOfBirth")
        ? dayjsTZ(form.getFieldValue("employeeDayOfBirth")).valueOf()
        : "",
      employeePersonalNumber: form.getFieldValue("employeePersonalNumber"),
      employeeDepartmentId: departmentId,
      employeeDepartmentName: form.getFieldValue("employeeDepartmentName"),
      employeeRoleName: form.getFieldValue("employeeRoleName"),
      employeePhoneNumber: form.getFieldValue("employeePhoneNumber"),
      employeePersonalEmail: form.getFieldValue("employeePersonalEmail"),
      employeeWorkStartDate: dayjsTZ(
        form.getFieldValue("employeeWorkStartDate")
      ).valueOf(),
      employeeTypeOfEmployment: form.getFieldValue("employeeTypeOfEmployment"),
      employeePayrollType: form.getFieldValue("employeePayrollType"),
      employeeMonthlyNetSalary:
        form.getFieldValue("employeeMonthlyNetSalary") || "",
      employeeDateOfReEmployment: "",
      employeeSalaryPerHour: form.getFieldValue("employeeSalaryPerHour") || "",
      employeeAddress: form.getFieldValue("employeeAddress") || "",
      employeeTeam: form.getFieldValue("employeeTeam"),
      employeeContractType: form.getFieldValue("employeeContractType") || "",
      employeeHireDate:
        dayjsTZ(form.getFieldValue("employeeHireDate")).valueOf() || "",
      employeeDateOfReEmployment: "",
      employeeContractDuration:
        form.getFieldValue("employeeContractDuration") || "",
      contractEndDate:
        dayjsTZ(dayjsTZ(form.getFieldValue("employeeHireDate")).valueOf())
          .add(form.getFieldValue("employeeContractDuration"), "years")
          .valueOf() || "",
      googleDriveFolderIds: {},
      keylog: [],
    };
    const parentFolderValue = hotCredentials?.find(
      (el) => el?.credentialId === "9"
    )?.credentialValue;
    const folderName = `${toSend.employeeFirstName} ${
      toSend.employeeLastName
    } - ${Date.now()}`;

    await driveRequest
      .getFolderIdOrCreate({
        name: folderName,
        parent: parentFolderValue,
      })
      .then(async (userFolderId) => {
        toSend = {
          ...toSend,
          googleDriveFolderIds: {
            userFolderId,
          },
        };
        await driveRequest
          .getFolderIdOrCreate({
            name: "Dokumentacione",
            parent: userFolderId,
          })
          .then((docFolderId) => {
            toSend = {
              ...toSend,
              googleDriveFolderIds: {
                ...toSend.googleDriveFolderIds,
                docFolderId,
              },
            };
            tmpDocFolderId = docFolderId;
          })
          .catch((err) => console.log("err: ", err));
      })
      .catch((err) => console.log("err: ", err));
    setVisible(false);

    API.post("employees", "/employees", {
      body: { ...toSend },
    }).then(async (res) => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */

      // if (socketHandler.readyState === socketHandler.OPEN)
      //   socketHandler.send(
      //     JSON.stringify({
      //       request: "user_added",
      //       body: res,
      //       id: auth?.employeeId,
      //     })
      //   );
      form.resetFields();
      setTableData((prev) => [...prev, res]);
      setActiveEmployees((prev) => [...prev, res]);

      //! it does not allow to add documentation at documentation gridData if you haven't selected one
      if (files?.length > 0) {
        /**
         * @param {Array} docsFolders -> [{ folderName: 'folderId' }]
         */

        const docsSettings = [];
        const defaultPermissions = [];
        files?.forEach((file) => {
          const docSettings = documentationsAvailable?.find(
            (el) => el.docName === file
          );
          docsSettings.push(docSettings);
          defaultPermissions.push(docSettings?.docPermissions || []);
        });

        const docsFolders = await driveRequest.createFolders({
          defaultPermissions,
          folderNames: files,
          parent: tmpDocFolderId,
        });

        //! posts documentations
        files?.forEach(async (file, index) => {
          await API.post("documentation", "/documentation", {
            body: {
              docType: file,
              expirationDate: "",
              docStatus: "Kerkuar",
              googleDriveUploads: [],
              recordId: res?.employeeId,
              folderId: docsFolders[file],
              docObject: docsSettings[index]?.docObject,
              recordName: `${res?.employeeFirstName} ${res?.employeeLastName}`,
              keylog: [],
            },
          });
        });
        //! updates employees agGrid
        requestData("employees").then((res) =>
          dispatch(employees(res, "GET_ACTIVE_EMPLOYEES"))
        );
      }
    });
    notificationUserToAdmin(
      {
        isAnnouncement: false,
        notificationPriority: false,
        notificationCategory: "Info",
        notificationUrl: "/punonjesit",
        notificationTitle: "Punonjës i ri",
        createdAt: dayjsTZ().format("DD/MM/YYYY"),
        notificationFirstName: userData?.userAccess[0]?.given_name,
        notificationLastName: userData?.userAccess[0]?.family_name,
        notificationBody: `${userData?.userAccess[0]?.given_name} ${userData?.userAccess[0]?.family_name} krijoi një punonjës të ri, ${toSend.employeeFirstName} ${toSend.employeeLastName}`,
      },
      users?.allUsers?.Items
    );
    setSpin(false);
    form.resetFields();
  };

  const getDocConfiguration = async () => {
    await API.get("docConfiguration", "/docConfiguration").then((r) => {
      setDocumentationsAvailable(r[0]?.documentationsAvailable);
    });
  };

  useEffect(() => {
    getDocConfiguration();
  }, []);

  //region RETURN
  return (
    <Modal
      className="employeeModal lightHeader"
      open={visible}
      autoComplete="off"
      onCancel={handleCancel}
      maskClosable={false}
      closeIcon={<ModalXIcon />}
      footer={[
        <>
          <MondayButton
            key="1"
            className="mondayButtonRed"
            onClick={handleCancel}
          >
            Anuloje
          </MondayButton>
          {currentStep !== 0 && (
            <MondayButton
              className="mondayButtonBlue"
              onClick={() => {
                if (currentStep !== steps.length + 1) {
                  setCurrentStep(currentStep - 1);
                }
              }}
            >
              Kthehu pas
            </MondayButton>
          )}
          {currentStep !== 2 ? (
            <MondayButton
              key="2"
              className="mondayButtonGreen"
              onClick={handleVazhdoMeTej}
            >
              Vazhdo më tej
            </MondayButton>
          ) : (
            <MondayButton
              className="mondayButtonGreen"
              onClick={() => finish(files)}
              key="submit"
              spin={spin}
            >
              Përfundo
            </MondayButton>
          )}
        </>,
      ]}
      title="Krijimi i një profili të ri punonjësi"
      centered
    >
      <div className="addEmployeeContainer">
        <Form form={form} onFinish={finish}>
          <HeaderStepper
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
          />
        </Form>
      </div>
    </Modal>
  );
}

export default AddEmployeModal;
