import { Button, Spin } from "antd";
import { cloneElement } from "react";
import "./MondayButton.scss";
import { motion } from "motion/react";

const MondayButton = ({
  children,
  disabled = false,
  Icon,
  onClick,
  className = "",
  hasIcon = false,
  containerClassName,
  style,
  key,
  testid = "",
  spin = false,
}) => (
  <div
    className={`mondayButtonContainer ${containerClassName}`}
    style={style}
    key={key}
  >
    <Button
      data-testid={testid}
      onClick={onClick}
      className={`mondayButtonStyle ${className}`.trim()}
      disabled={spin || disabled}
    >
      {children && (
        <div className="mondayButtonText" style={{ gap: spin ? 5 : 0 }}>
          <motion.div
            style={{ display: "flex" }}
            animate={{
              transform: spin ? "translateX(0px)" : "translateX(15px)",
            }}
          >
            <Spin spinning={spin} size="small" />
          </motion.div>
          {children}
        </div>
      )}
      {children && hasIcon && (
        <div
          className="mondayButtonDivider"
          style={!!disabled ? { backgroundColor: "#C8C8C8" } : {}}
        />
      )}
      {Icon && cloneElement(Icon, { className: "mondayButtonIcon" })}
    </Button>
  </div>
);

export default MondayButton;
