import React, { useState, useLayoutEffect, useEffect } from "react";
import { AdminCards } from "./AdminCards/AdminCards";
import InfoCards from "./AdminCards/InfoCards";
import Njoftimet from "./NjoftimetCard/Njoftimet";
import "./admin_dashboard.scss";
import { API } from "aws-amplify";
import DragContainer from "./Draggable/DragContainer";
import { message, notification } from "antd";
import { automateFactory } from "../../../automation/automation";
import { useAuth } from "../../../authentication/authHelpers";
import useReactIpLocation from "react-ip-details";
import { useSelector } from "react-redux";
import {
  requestAllPages,
  requestMultipleTables,
} from "../../../helpers/API/RequestFactory";
import { clockings } from "../../../store/actions";
import { useDispatch } from "react-redux";
import axios from "axios";

function AdminDashboard() {
  const [alreadySent, setAlreadySent] = useState(false);
  const userData = useAuth();
  const ipResponse = "";
  const [ip, setIp] = useState("");
  const dispatch = useDispatch();
  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res?.data?.ip);
  };
  useLayoutEffect(() => {
    if (!alreadySent && !!userData) {
      automateFactory("requestsToApprovals", userData, {
        ...ipResponse,
        Ipv4: ip,
      })
        .then((res) => {
          // message.success("Automation Successful");
          console.log("Converted Requests to Approvals!", res);
        })
        .catch((err) => {
          // console.log("error", err);
          console.log(
            "THere was an error in the tasks converter requestsToApprovals",
            err
          );
        });
      setAlreadySent(true);
    }
  }, [userData]);
  useEffect(() => {
    getIp();
  }, []);

  const refreshClockIns = async () => {
    await requestMultipleTables(["clocking"])
      .then((res) => {
        dispatch(clockings(res[0]));
      })
      .catch((err) => {});
  };

  return (
    // <LoaderComponent loading={tableData.length > 0 ? false : true}>
    // </LoaderComponent>
    <div className="admin-dashboard">
      <DragContainer view={"Admin"} />
    </div>
  );
}

export default AdminDashboard;
