export default ({ prevStep, nextStep, array = [], element }) => {
  let newFieldOptions = [];

  let increasing = nextStep > prevStep;
  let middleFields = [];

  middleFields = array?.slice(
    increasing ? prevStep : nextStep - 1,
    increasing ? nextStep : prevStep - 1
  );

  for (let i = 0; i < middleFields.length; i++) {
    const field = middleFields[i];
    middleFields[i] = {
      ...field,
      step: increasing ? field?.step - 1 : field?.step + 1,
    };
  }

  // add previous elements
  newFieldOptions.push(
    ...array.slice(0, increasing ? prevStep - 1 : nextStep - 1)
  );
  //add middle elements & current element
  if (!!increasing) {
    newFieldOptions.push(...middleFields);
    newFieldOptions.push(element);
  } else {
    newFieldOptions.push(element);
    newFieldOptions.push(...middleFields);
  }
  //add next elements
  newFieldOptions.push(
    ...array.slice(increasing ? nextStep : prevStep, array.length)
  );

  return newFieldOptions;
};
