import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Form, Input, Row, Col, Popconfirm } from "antd";
import { ModalLogo, ModalXIcon, Flex } from "../../../../assets/icons";
import { CaretDownOutlined } from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

function SiguriaPerdoruesitModal({
  form,
  formSubmit,
  userConfig,
  setOpen,
  open,
  onChange,
  HandleChange,
  user,
  onClose,
  setData,
  from,
}) {
  const { Option } = Select;
  const [selcetValue, setSelectValue] = useState(null);
  const { programFields } = useSelector((state) => state.programFields);
  const { departments } = useSelector((state) => state.departments);

  const filterData = programFields?.find(
    (data) => data?.fieldId === "c0156b01-161f-4333-97f3-9f2bdd6bc1fc"
  );

  // find the role for that department name
  const findRole = departments?.find(
    (data) => data.departmentName === selcetValue
  );

  // change the value of department dropdown
  const handleChangeDep = (objField, e) => {
    const value = objField;
    setData((prev) => ({ ...prev, [value]: e }));
    setSelectValue(e);
  };

  return (
    <div>
      <Modal
        title="Krijimi i një përdoruesi"
        centered
        open={open}
        wrapClassName="sistmei-perdoruesi-modal"
        className="lightHeader"
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        closeIcon={<ModalXIcon />}
        footer={[
          <Form.Item>
            <Popconfirm
              placement="top"
              overlayClassName="popup-perdoruesit-modal"
              title={
                "Ti nuk ke përfunduar, a je i sigurt që dëshiron të largohesh nga krijimi i një përdoruesi?"
              }
              onConfirm={() => onClose()}
              okText="Po"
              cancelText="Jo"
              icon={<Flex />}
            >
              <MondayButton className="mondayButtonRed">Anuloje</MondayButton>
            </Popconfirm>
            {!user ? (
              <Button
                disabled={
                  form.getFieldValue("password") ===
                  form.getFieldValue("konfirmopassword")
                    ? false
                    : true
                }
                className="mondayButtonGreen"
                form="Perdoruesit-modal-form"
                key="submit"
                htmlType="submit"
              >
                Përfundo
              </Button>
            ) : (
              <Button
                className="mondayButtonGreen"
                form="konfirmo-modal-form"
                key="submit"
                htmlType="submit"
              >
                Konfirmo
              </Button>
            )}
          </Form.Item>,
        ]}
      >
        <Row>
          <Col flex={3}>
            {!user ? (
              <Form
                form={form}
                name="Perdoruesit-modal-form"
                onFinish={formSubmit}
              >
                <Form.Item
                  name="given_name"
                  label="Emri"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem shkruani emrin e përdoruesit!",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => onChange("given_name", e.target.value)}
                    disabled={from === "employee" ? true : false}
                    autoComplete="off"
                    placeholder="Shkruaj emrin e përdoruesit"
                    style={{ width: 345 }}
                    value={"kot m"}
                  />
                </Form.Item>
                <Form.Item
                  name="family_name"
                  label="Mbiemri"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem shkruani mbiemrin e përdoruesit!",
                    },
                  ]}
                >
                  <Input
                    disabled={from === "employee" ? true : false}
                    onChange={(e) => onChange("family_name", e.target.value)}
                    autoComplete="off"
                    placeholder="Shkruaj mbiemrin e përdoruesit"
                    style={{ width: 345 }}
                    value={"testingg"}
                  />
                </Form.Item>
                <Form.Item
                  name="gender"
                  label="Gjinia:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem selekto gjininë e përdoruesit!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selekto gjininë e përdoruesit"
                    style={{
                      width: 345,
                      background: "#F5F5F7",
                      borderRadius: "5px",
                    }}
                    onChange={(val) => onChange("gender", val)}
                    variant={false}
                    popupClassName="dropdown-konfiguro"
                    suffixIcon={<CaretDownOutlined />}
                    disabled={from === "employee" ? true : false}
                  >
                    <Option key="Femër" value="Femër">
                      Femër
                    </Option>
                    <Option key="Mashkull" value="Mashkull">
                      Mashkull
                    </Option>
                    {/* <Option key="Tjetër" value="Tjetër">
                      Tjetër
                    </Option> */}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem shkruani emailin e përdoruesit!",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    disabled={from === "employee" ? true : false}
                    onChange={(e) => onChange("email", e.target.value)}
                    autoComplete="off"
                    placeholder="Shkruaj emailin e përdoruesit"
                    style={{ width: 345 }}
                  />
                </Form.Item>
                <Form.Item
                  name="phone_number"
                  label="Nr.Tel"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem shkruani numrin e telefonit!",
                    },
                  ]}
                >
                  <PhoneInput
                    className="number-input"
                    disabled={from === "employee" ? true : false}
                    country={"al"}
                    onChange={(value) => onChange("phone_number", value)}
                    autoComplete="off"
                    placeholder="Shkruaj Nr.Tel e përdoruesit"
                    style={{ width: 345 }}
                    masks={{ al: "... ... ..." }}
                  />
                </Form.Item>
                <Form.Item
                  name="department"
                  label="Departamenti:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem selekto departamentin e përdoruesit!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selekto Departamentin e përdoruesit"
                    style={{
                      width: 345,
                      background: "#F5F5F7",
                      borderRadius: "5px",
                    }}
                    disabled={from === "employee" ? true : false}
                    onChange={(val) => handleChangeDep("department", val)}
                    variant={false}
                    popupClassName="dropdown-konfiguro"
                    suffixIcon={<CaretDownOutlined />}
                  >
                    {departments?.map((data) => (
                      <Option
                        value={data.departmentName}
                        key={data.departmentId}
                      >
                        {data.departmentName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="departmentRole"
                  label="Pozicioni:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem selekto rolin e përdoruesit!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selekto Rolin e përdoruesit"
                    disabled={from === "employee" ? true : false}
                    style={{
                      width: 345,
                      background: "#F5F5F7",
                      borderRadius: "5px",
                    }}
                    onChange={(val) => onChange("departmentRole", val)}
                    variant={false}
                    popupClassName="dropdown-konfiguro"
                    suffixIcon={<CaretDownOutlined />}
                  >
                    {findRole?.departmentRoles?.map((data) => (
                      <Option key={data.id} value={data.name}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Fjalëkalimi:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem shkruani fjalëkalimin e përdoruesit!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    onChange={(e) => onChange("password", e.target.value)}
                    autoComplete="off"
                    placeholder="Shkruaj fjalëkalimin e përdoruesit"
                    style={{
                      width: 345,
                      background: "#F5F5F7",
                      border: 0,
                      borderRadius: 5,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="konfirmopassword"
                  label="Konfirmo Fjalëkalimin:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem konfirmo fjalëkalimin e përdoruesit!",
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    onChange={(e) =>
                      onChange("konfirmopassword", e.target.value)
                    }
                    autoComplete="off"
                    placeholder="Konfirmo fjalëkalimin e përdoruesit"
                    style={{
                      width: 345,
                      background: "#F5F5F7",
                      border: 0,
                      borderRadius: 5,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="userRole"
                  label="Roli i userit:"
                  rules={[
                    {
                      required: true,
                      message: "Ju lutem selekto rolin e userit !",
                    },
                  ]}
                >
                  <Select
                    placeholder="Selekto Rolin e userit"
                    style={{
                      width: 345,
                      background: "#F5F5F7",
                      borderRadius: "5px",
                    }}
                    onChange={(val) => onChange("userRole", val)}
                    variant={false}
                    popupClassName="dropdown-konfiguro"
                    suffixIcon={<CaretDownOutlined />}
                  >
                    {filterData?.fieldOptions?.map((el, index) => (
                      <Option value={el} key={index}>
                        {el}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            ) : (
              <Form
                onFinish={userConfig}
                name="konfirmo-modal-form"
                form={form}
              >
                <Form.Item name="email" label="Emaili:">
                  <Input
                    disabled
                    style={{ width: 250 }}
                    placeholder="Konfirmo emailin"
                    onChange={(e) => HandleChange("email", e.target.value)}
                  />
                </Form.Item>
                <Form.Item name="verifyCode" label="Kodin i verifikimit">
                  <Input
                    style={{ width: 250 }}
                    placeholder="Konfirmo kodin e verifikimit"
                    onChange={(e) => HandleChange("verifyCode", e.target.value)}
                  />
                </Form.Item>
              </Form>
            )}
          </Col>
          <Col flex={2}>
            <div className="modal-logo">
              <ModalLogo />
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default SiguriaPerdoruesitModal;
