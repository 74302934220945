import { Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { HeaderStepper } from "../../../components";
import ClockIn from "../../dashboard/components/clockInOut/ClockIn";
import moment from "moment-timezone";
function ReportGenerateModal({ visible, setGenerateVisib, onReset }) {
  const [current, setCurrent] = useState(0);
  const { clockings } = useSelector((state) => state.clockings);

  const ReportTypes = () => (
    <div>
      <button onClick={() => setCurrent(1)}>Clock In</button>
    </div>
  );

  const data = [
    {
      name: "Janar 2022",
      punesimi: 20,
      largimi: 2,
    },
    {
      name: "Shkurt 2022",
      punesimi: 30,
      largimi: 10,
    },
    {
      name: "Mars 2022",
      punesimi: 24,
      largimi: 1,
    },
    {
      name: "Prill 2022",
      punesimi: 22,
      largimi: 5,
    },
  ];

  const TimeStance = () => (
    <button onClick={() => setCurrent(2)}>Kete Muaj</button>
  );

  const Raport = () => (
    <div>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="punesimi"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        <Line type="monotone" dataKey="largimi" stroke="#82ca9d" />
      </LineChart>
    </div>
  );

  const steps = [
    {
      title: "Zgjidh Llojin e Reportit",
      Comp: ReportTypes,
      disabled: true,
    },
    {
      title: "Zgjidh Periudhen",
      Comp: TimeStance,
      disabled: true,
    },
    { title: "Raporti", Comp: Raport, disabled: true },
  ];

  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      width={863}
      // width={current > steps.length - 1 ? "100%" : " 863px"}
      className="generate-modal"
      title="Gjenero Raportin"
      open={visible}
      onOk={() => setGenerateVisib(false)}
      onCancel={() => setGenerateVisib(false)}
      centered={true}
      footer={[
        <>
          {" "}
          <button className="close-btn" onClick={() => setGenerateVisib(false)}>
            Mbyll Faqen
          </button>
        </>,
      ]}
    >
      <div className="generate-modal-container">
        <HeaderStepper
          currentStep={current}
          setCurrentStep={setCurrent}
          steps={steps}
        />
      </div>
    </Modal>
  );
}

export default ReportGenerateModal;
