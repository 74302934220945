import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { getTranslation } from "../../generate/translations";
export const detailedTaxes = (data, filter, columns, manualColumns) => {
	let result = {
		columnsDef: [],
		allColumns: [],
		rowData: [],
		allData: [],
		selectedColumns: [],
		allColumns: [],
		totalities: {
			bruto: 0,
			neto: 0,
			individual: 0,
			employeer: 0,
			bank: 0,
			total: 0,
		},
	};
	if (!!data && !!filter) {
		const filtered = preFilter(data, filter).map((w, idx) => {
			result.totalities.bruto += w?.bruto?.totalBruto || 0;
			result.totalities.neto += w?.taxes?.netoPay?.netoPay || 0;
			result.totalities.individual += w?.taxes?.netoPay?.totalIns || 0;
			result.totalities.employeer += w?.taxes?.netoPay?.totalEmployeeerIns || 0;
			result.totalities.bank += w?.taxes?.netoPay?.netoPay || 0;
			let temp = idx + 1;
			temp = temp.toString();
			return {
				...w,
				...w?.employeeDetails,
				idNumber: temp,
				totalBruto: w?.bruto?.totalBruto || "",
				totalNeto: w?.taxes?.netoPay?.netoPay || "",
				healthIns: w?.taxes?.netoPay?.healthIns || "",
				socialIns: w?.taxes?.netoPay?.socialIns || "",
				progressiveTax: w?.taxes?.netoPay?.progressiveTax || "",
				totalIns: w?.taxes?.netoPay?.totalIns || "",
				healthInsEmployeer: w?.taxes?.netoPay?.totalIns,
				insuranceEmployeer: w?.taxes?.netoPay?.insuranceEmployeer,
				total: w?.taxes?.netoPay?.totalEmployeeerIns + w?.bruto?.totalBruto || "",
			};
		});
		result.selectedColumns = manualColumns || columns.columnDefs;
		result.allColumns = columns.allAppliedColumnDefs;
		if (!!manualColumns && Array.isArray(manualColumns)) {
			manualColumns.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			filtered.forEach((emplRecord) => {
				let toAdd = [];
				manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		} else {
			columns?.columnDefs.forEach((e) => {
				result.columnsDef.push(getTranslation(e));
			});
			filtered.forEach((emplRecord) => {
				let toAdd = [];
				columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
				result.rowData.push(toAdd);
			});
		}
		return result;
	}
	return result;
};

function renameObjectKeys(obj, oldKey, newKey) {
	obj[newKey] = obj[oldKey];
	delete obj[oldKey];
	return obj;
}

const preFilter = (data, filter) => {
	return data
		.filter(
			(el) =>
				dayjsTZ(el.startPeriod).isSameOrAfter(filter?.timeInterval[0]) &&
				dayjsTZ(el.endPeriod).isSameOrBefore(filter?.timeInterval[1])
		)
		.sort((a, b) => a.startPeriod - b.startPeriod);
};
