import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  TimePicker,
  Tooltip,
} from "antd";
import { API, formContainer } from "aws-amplify";
import moment from "moment";
import dayjsTZ from "../../../../utils/dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Overwork } from "../../../../assets/icons";
import { useAuth } from "../../../../authentication/authHelpers";
import { notificationUserToAdmin } from "../../../../utils/sendNotification";
import {
  getDisabledAfterCustomHours,
  getDisabledBeforeCustomHours,
} from "../../utils/disabledTimeFilters";
import "./WorkBeforeShift.scss";
import dayjs from "dayjs";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useMediaQuery } from "react-responsive";

const WorkBeforeShiftModal = ({ visible, setVisible, setOvertimeUser }) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [requestPeriod, setRequestPeriod] = useState([]);
  const { employee } = useAuth();
  const [state, setState] = useState(true);
  const [onSelectType, setOnSelectType] = useState("");
  const [onDescription, setOnDescription] = useState("");
  const { users } = useSelector((state) => state.usersList);

  const options = ["Para pune", "Pas pune"];

  const onSelect = (e) => {
    setOnSelectType(e);
    setState(false);
    form.setFieldsValue({ requestPeriod: [] });
  };

  const disabledHours1 = () => {
    const time = dayjsTZ().set("hour", 13).set("minute", 30);
    return getDisabledAfterCustomHours(time);
  };
  const disabledHours2 = () => {
    const time = dayjsTZ().set("hour", 22).set("minute", 30);
    return getDisabledBeforeCustomHours(time);
  };

  const disabledMinutes1 = () => {
    return [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,
      58, 59,
    ];
  };

  console.log({ disabledHours1 });
  const disabledTime = (now, type) => {
    if (onSelectType === "Para pune") {
      if (type === "start") {
        return {
          disabledHours: disabledHours1,
          disabledMinutes: (hour) => disabledMinutes1(hour),
        };
      } else {
        return { disabledHours: null, disabledMinutes: disabledMinutes1 };
      }
    } else {
      if (type === "start") {
        return {
          disabledHours: disabledHours2,
          disabledMinutes: disabledMinutes1,
        };
      } else {
        return { disabledHours: null, disabledMinutes: disabledMinutes1 };
      }
    }
  };
  const onPostDb = async () => {
    const bodyDBObject = {
      requestType: onSelectType,
      overtimeDay: dayjsTZ(form.getFieldValue("overtimeDay")).valueOf(),
      requestDescription: onDescription,
      requestStatus: "Në pritje",
      family_name: employee?.employeeLastName,
      requestPeriod: requestPeriod,
      given_name: employee?.employeeFirstName,
      creatorIsAdmin: "No",
      userSub: employee?.userSub,
    };
    if (
      !onDescription?.trim() ||
      !onSelectType ||
      !form.getFieldValue("overtimeDay") ||
      requestPeriod?.length === 0
    ) {
      message.error("Ju lutem plotësoni të gjitha fushat!");
    } else {
      message.loading({ content: "Në krijim...", duration: 1 });
      return await API.post("employeeOvertime", "/employeeOvertime", {
        body: bodyDBObject,
      })
        .then(() => {
          setOvertimeUser((prev) => [...prev, bodyDBObject]);
          message.success("Kërkesa u krijua!");
          setVisible(false);
          form.resetFields();
          setOnDescription("");
          setOnSelectType(null);
          setRequestPeriod([]);
          notificationUserToAdmin(
            {
              notificationTitle: "Kërkesë për orë shtesë",
              createdAt: dayjsTZ().format("DD/MM/YYYY"),
              notificationPriority: false,
              notificationCategory: "Critical",
              notificationBody: `${employee?.employeeFirstName}${
                employee?.employeeLastName
              } bëri një kërkesë për orë shtesë në datën ${dayjsTZ(
                form.getFieldValue("overtimeDay")
              ).format("DD/MM/YYYY")} nga ora ${requestPeriod[0]} në ${
                requestPeriod[1]
              }.`,
              notificationUrl: "/kryefaqja",
              notificationFirstName: employee?.employeeFirstName,
              notificationLastName: employee?.employeeLastName,
            },
            users?.allUsers?.Items
          );
        })
        .catch((e) => {
          message.error("Ndodhi nje gabim!");
          console.error(e);
        });
    }
  };

  useEffect(() => {
    if (!visible) {
      setState(true);
    }
  }, [visible]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 460px)",
  });

  return (
    <div>
      <Modal
        className="workBeforeShiftModal darkHeader"
        title="Kërkesë për orë shtesë"
        destroyOnClose={true}
        centered
        width={isMobileView ? "calc(100% - 20px)" : 650}
        open={visible}
        onCancel={() => {
          return (
            setVisible(false),
            form.resetFields(),
            setOnDescription(""),
            setOnSelectType(null),
            setRequestPeriod([])
          );
        }}
        footer={[
          <MondayButton
            onClick={() => {
              return (
                setVisible(false),
                form.resetFields(),
                setOnDescription(""),
                setOnSelectType(null)
              );
            }}
            className="mondayButtonRed"
          >
            Anulo kërkesën
          </MondayButton>,
          <MondayButton onClick={onPostDb} className="mondayButtonGreen">
            Dërgo kërkesën
          </MondayButton>,
        ]}
      >
        <Form form={form} autoComplete="off">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              // gap: "5rem",
            }}
          >
            <div className="formOverwork">
              <Form.Item
                name="overtimeDay"
                label="Data"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem zgjdhni daten!",
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(current) => current <= dayjsTZ().endOf("day")}
                  format={"DD/MM/YYYY"}
                  placeholder="Zgjidh daten"
                  className="formFields"
                />
              </Form.Item>
              <Form.Item
                name="requestType"
                label="Lloji i kerkeses"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem zgjidhni llojin e orarit!",
                  },
                ]}
              >
                <Select
                  value={onSelectType}
                  placeholder="Zgjidh llojin"
                  onSelect={onSelect}
                >
                  {options?.map((el) => {
                    return <Option value={el}>{el}</Option>;
                  })}
                </Select>
              </Form.Item>
              {/* {!onSelectType && (
                
                  <Alert
                    message="Ju mund te zgjidhni orarin kur doni te punoni pasi te keni zgjedhur llojin e kerkeses!"
                    type="error"
                  />
              )} */}
              <Form.Item
                name="requestPeriod"
                label="Ora e caktuar"
                rules={[
                  {
                    required: true,
                    message: "Ju lutem zgjidhni orarin kur doni të punoni!",
                  },
                ]}
              >
                <TimePicker.RangePicker
                  value={requestPeriod}
                  className="formFields"
                  format={"HH:mm"}
                  disabled={state}
                  disabledTime={(now, type) => {
                    if (type === "start") {
                      return disabledTime(now, type);
                    } else {
                      return disabledTime(now, type);
                    }
                  }}
                  onChange={(val) => {
                    setRequestPeriod([
                      val[0].format("HH:mm"),
                      val[1].format("HH:mm"),
                    ]);
                  }}
                />
              </Form.Item>
            </div>
            {!isMobileView ? <Overwork /> : null}
          </div>
          <Form.Item
            name="description"
            label="Shenimi"
            rules={[
              {
                required: true,
                message:
                  "Ju duhet me patjeter te lini arsyen e kohes qe do punoni!",
              },
            ]}
          >
            <TextArea
              value={onDescription}
              onChange={(e) => setOnDescription(e.target.value)}
              style={{ height: 140 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default WorkBeforeShiftModal;
