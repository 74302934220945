import { message, Modal, Popconfirm, Select } from "antd";
import { API } from "aws-amplify";
import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Employees from "../../../../dashboard/AdminDashboard/AdminCards/Employees/Employees";
import { users } from "../../../../../../src/store/actions";
import "./MergeEmpl.scss";
import { requestMultipleTables } from "../../../../../helpers/API/RequestFactory";
import { ModalXIcon } from "../../../../../assets/icons";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
const { Option } = Select;
function MergeEmployeeUser({ visible, setVisible, selected }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.usersList);
  const { employees } = useSelector((state) => state.employeesList);

  const [userSub, setUserSub] = useState(null);
  const [spin, setSpin] = useState(false);

  const handleOk = async () => {
    await onMerge();
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleReduxRefresh = async () => {
    await requestMultipleTables(["users", "employees"]).then((res) => {
      dispatch(users(res));
      dispatch(employees(res));
    });
    await API.get("users", "/users").then((res) => {
      dispatch(users(res[0]));
      dispatch(employees(res[1]));
    });
  };

  //region ON MERGE
  const onMerge = async () => {
    let emplId = selected?.employeeId || null;
    if (!!emplId && !!userSub) {
      setSpin(true);
      await API.put("employees", `/employees/${emplId}`, {
        body: {
          userSub: userSub,
          accountStatus: "Merged",
        },
      })
        .then(() => {
          handleReduxRefresh();
          message.success("U lidh me sukses!");
          navigate(`/punonjesit/specifikat/${emplId}`, {
            state: { ...selected, userSub: userSub, accountStatus: "Merged" },
          });
          handleCancel();
        })
        .catch((err) => {
          message.error("Ndodhi nje gabim!");
          console.error(err);
        });
      setSpin(false);
    } else {
      message.error("Ndodhi nje gabim ne perditesimin e te dhenave!");
    }
  };

  //region userOptions
  const userOptions = useMemo(() => {
    if (!!users && !!employees) {
      let tempUsers = users?.allUsers?.Items || [];
      let userOptions = [];
      tempUsers.map((user) => {
        let isFound = employees.find((empl) => empl?.userSub === user?.userSub);
        userOptions.push(
          <Option value={user?.userSub} disabled={!!isFound ? true : false}>
            {user?.given_name + " " + user?.family_name}
          </Option>
        );
      });
      return userOptions;
    }
  }, [users, employees]);

  const handleChange = (val) => {
    setUserSub(val);
  };

  //region RETURN
  return (
    <div>
      <Modal
        className="merge-user-empl lightHeader"
        width={618}
        destroyOnClose={true}
        title="Lidh punonjësin me një adresë përdoruesi ekzistues!"
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<ModalXIcon />}
        footer={[
          <MondayButton className="mondayButtonRed" onClick={handleCancel}>
            Mbyll Faqen
          </MondayButton>,
          <Popconfirm
            title={"A jeni te sigurt?"}
            okText={"Po, konfirmo."}
            cancelText={"Jo, anullo"}
            onConfirm={onMerge}
          >
            <MondayButton className="mondayButtonGreen" spin={spin}>
              Lidh Userin
            </MondayButton>
          </Popconfirm>,
        ]}
      >
        <div className="merge-user-wrapper">
          <span className="title-merge">
            Llogaria ekzistuese do të lidhet me punonjësin e selektuar.
          </span>
          <br />
          <Select
            style={{ width: 220 }}
            showSearch
            onChange={handleChange}
            optionFilterProp="children"
            placeholder="Zgjidh një user"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {userOptions}
          </Select>
          {/* <button onClick={onMerge}>Lidh</button> */}
        </div>
      </Modal>
    </div>
  );
}
export default MergeEmployeeUser;
