import { useAuth } from "./authHelpers";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
// import { useContext } from "react";
// import { NotifContext } from "../components/dashboard/components/notifications/context/notifContext";
import { Auth } from "aws-amplify";
import { useState, useEffect, useMemo } from "react";
import LoadableComp from "../components/commonComponents/LoadableComp/LoadableComp";
// import PageNotFound from "../components/commonComponents/PageNotFound";
import { useUserSession } from "../hooks/useUserSession";

function PrivateRoute({ children, currentUser, accessRoute }) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const auth = useAuth();
  const navigate = useNavigate();
  const userSession = useUserSession();

  // const redirectOnLogin = (error) => {
  //   console.log("error signing in", error);
  //   navigate("/login", { state: { from: location } });
  //   setLoading(false);
  // };

  // const validateUser = async () => {
  //   if (!!auth?.isAuthenticated) {
  //     await Auth.currentAuthenticatedUser().then((res) => {
  //       {
  //         setLoading(false);
  //         auth.signin(res, () => {
  //           navigate("kryefaqja");
  //         });
  //       }
  //     }, redirectOnLogin);
  //   } else {
  //     redirectOnLogin();
  //   }
  // };

  const logoutUser = async () => {
    let current = auth?.userAccess[0] || [];
    await auth.signout(() => navigate("/login"));
    await userSession.close(current);
    await Auth.signOut();
  };

  const checkDisabled = async () => {
    if (Array.isArray(auth?.userAccess)) {
      if (auth.userAccess.length > 0) {
        let disabled = auth.userAccess[0]?.disabled;
        let forceReset = auth.userAccess[0]?.forceReset;
        let activeSessions = auth.userAccess[0]?.activeSessions;

        if (disabled) {
          await auth.signout(() => navigate("/login"));
          await Auth.signOut();
          setLoading(false);
        }
        if (!!activeSessions) {
          const { logout } =
            activeSessions?.find?.(
              ({ sessionId }) =>
                sessionId ===
                JSON.parse(window.localStorage.getItem("sessionId"))
            ) || {};
          if (!!logout) {
            await logoutUser();
          }
        }

        if (forceReset) {
          navigate("/ndryshoFjalekalimin");
        }
      }
    }
  };

  useEffect(() => {
    // getCustomMessage();
    // validateUser();
    auth?.onRefresh(() => setLoading(false), { state: { from: location } });
  }, []);

  useEffect(() => {
    checkDisabled();
  }, [auth]);

  // useEffect(() => {
  // 	if (!loading) getCustomMessage();
  // }, [loading]);
  if (loading) {
    return <LoadableComp loading={loading} />;
  }

  return <Outlet />;
}

export default PrivateRoute;
