import React from "react";
import { useLocation } from "react-router-dom";
function DynamicHeaderTitle() {
  let location = useLocation();
  let title = "";
  // if location is in one of this routes will show the title to the header
  if (location.pathname === "/konfigurimet") {
    title = "Paneli Administratorit";
  } else if (
    location.pathname === "/konfigurimet/kompania/info/rreth/komapnise"
  ) {
    title = "Paneli i Administratorit - Info rreth kompanisë";
  } else if (
    location.pathname === "/konfigurimet/kompania/info/rrethsektorit"
  ) {
  } else if (location.pathname.startsWith("/konfigurimet/kompania/rolet")) {
    title = "Paneli i Administratorit - Rolet për departament";
  } else if (location.pathname === "/konfigurimet/pagat/kalkulimi") {
    title = "Paneli i Administratorit - Kalkulimi i pagës";
  } else if (location.pathname === "/konfigurimet/kompania/pozicionet") {
    title = "Paneli i Administratorit - Pozicionet";
  } else if (location.pathname === "/konfigurimet/sistemi/turnet") {
    title = "Paneli i Administratorit - Turnet";
  } else if (location.pathname === "/konfigurimet/sistemi/pajisjet/orarit") {
    title = "Paneli i Administratorit - Pajisjet e orarit";
  } else if (location.pathname === "/konfigurimet/sistemi/arsyet/largimit") {
    title = "Paneli i Administratorit - Arsyet e largimeve";
  } else if (location.pathname === "/konfigurimet/sistemi/statuset/punojesve") {
    title = "Paneli i Administratorit - Statuset e punonjësve";
  } else if (location.pathname === "/konfigurimet/sistemi/llojet/kerkesave") {
    title = "Paneli i Administratorit - Llojet e kërkesave";
  } else if (location.pathname === "/konfigurimet/sistemi/dokumentacionet") {
    title = "Paneli i Administratorit - Dokumentacionet";
  } else if (location.pathname === "/konfigurimet/politikat/mungesave") {
    title = "Paneli i Administratorit - Politikat e mungesave";
  } else if (location.pathname === "/konfigurimet/politikat/ditet/pushimit") {
    title = "Paneli i Administratorit - Politikat për ditët e pushimit";
  } else if (location.pathname === "/konfigurimet/politikat/festave/zyrtare") {
    title = "Paneli i Administratorit - Politikat e festave zyrtare";
  } else if (
    location.pathname === "/konfigurimet/politikat/oreve/jashte/orarit"
  ) {
    title = "Paneli i Administratorit - Politikat e orëve jashtë orarit";
  } else if (location.pathname === "/konfigurimet/pagat/pagesat") {
    title = "Paneli i Administratorit - Mënyrat e Pagesës";
  } else if (location.pathname === "/konfigurimet/kompania/menaxhimi/orarit") {
    title = "Paneli i Administratorit - Menaxhimi Orarit";
  } else if (location.pathname === "/konfigurimet/pagat/fitimet") {
    title = "Paneli Administratorit - Fitimet";
  } else if (location.pathname === "/konfigurimet/pagat/zbritje") {
    title = "Paneli i Administratorit - Zbritje";
  } else if (location.pathname === "/konfigurimet/pagat/periudhat/pagesave") {
    title = "Paneli i Administratorit - Periudhat e pagesave";
  } else if (location.pathname === "/konfigurimet/pagat/pagesat/planifikuara") {
    title = "Paneli i Administratorit - Pagesat e planifikuara";
  } else if (
    location.pathname === "/konfigurimet/pagat/eksportet/listes/pagave"
  ) {
    title = "Paneli i Administratorit - Eksportet e listës së pagave";
  } else if (location.pathname === "/konfigurimet/perdoruesit") {
    title = "Paneli i Administratorit - Përdoruesit";
  } else if (location.pathname === "/konfigurimet/perdoruesit/:identityId") {
    title = "Paneli i Administratorit - Rolet e sigurisë";
  } else if (location.pathname === "/konfigurimet/kompania/profili") {
    title = "Paneli i Administratorit - Profili";
  }

  return (
    <div className="user-title">
      {location.pathname === "/konfigurimet" ? (
        <h1>{title}</h1>
      ) : location.pathname ===
        "/konfigurimet/kompania/info/rreth/komapnise" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/kompania/info/rrethsektorit" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/kompania/pozicionet" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/sistemi/pajisjet/orarit" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/sistemi/turnet" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/sistemi/arsyet/largimit" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/sistemi/statuset/punojesve" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/sistemi/llojet/kerkesave" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/sistemi/llojet/kerkesave" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/sistemi/dokumentacionet" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/politikat/ditet/pushimit" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/politikat/festave/zyrtare" ? (
        <h1>{title}</h1>
      ) : location.pathname ===
        "/konfigurimet/politikat/oreve/jashte/orarit" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/pagat/fitimet" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/pagat/zbritje" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/pagat/periudhat/pagesave" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/pagat/pagesat/planifikuara" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/pagat/kalkulimi" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/pagat/pagesat" ? (
        <h1>{title}</h1>
      ) : location.pathname ===
        "/konfigurimet/pagat/eksportet/listes/pagave" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/perdoruesit" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/perdoruesit/:identityId" ? (
        <h1>{title}</h1>
      ) : location.pathname === "/konfigurimet/kompania/profili" ? (
        <h1>{title}</h1>
      ) : location.pathname.startsWith("/konfigurimet/kompania/rolet") ? (
        <h1>{title}</h1>
      ) : null}
    </div>
  );
}

export default DynamicHeaderTitle;
