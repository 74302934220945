import dayjsTZ from "../../../../../../utils/dayjs";

export const weekFiltering = (inst, filterType, instance = false) => {

  const getValuesOf = (array) => {
    if (!instance) {
      return array?.map((el) => el.valueOf());
    } else {
      return array;
    }
  };

  if (inst !== null) {
    let start = dayjsTZ(inst).startOf("month").valueOf();

    switch (filterType) {
      case "firstWeek":
        return getValuesOf([
          dayjsTZ(start).startOf("month"),
          dayjsTZ(start).weekday(6),
        ]);
      case "secondWeek":
        return getValuesOf([
          dayjsTZ(start).weekday(7),
          dayjsTZ(start).weekday(13),
        ]);
      case "thirdWeek":
        return getValuesOf([
          dayjsTZ(start).weekday(14),
          dayjsTZ(start).weekday(20),
        ]);
      case "fourthWeek":
        return getValuesOf([
          dayjsTZ(start).weekday(21),
          dayjsTZ(start).weekday(27),
        ]);
      case "fifthWeek":
        return getValuesOf([
          dayjsTZ(start).weekday(28),
          dayjsTZ(start).endOf("month"),
        ]);
      case "month":
        return getValuesOf([
          dayjsTZ(start).startOf("month"),
          dayjsTZ(start).endOf("month"),
        ]);
      default:
        return getValuesOf([
          dayjsTZ(start).weekday(0),
          dayjsTZ(start).weekday(6),
        ]);
    }
  }
};
