import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button, Input, Tooltip, DatePicker } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import { IconExcel, IconKerko, IconNgargo } from "../../assets/icons";
import AgGridComponent from "../AG-grid/AgGridComponent";
import DetailsModal from "./DetailsModal";
import "./raportet.scss";
import HtmlExelTabel from "../Konfigurimet/dynamic components/HtmlExelTabel";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import ShiftModal from "../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import ShiftGrid from "../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftGrid";
import "./ClockIns.scss";
import PunonjesitContext from "../punonjesit/store/PunonjesitContext";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";
import FooterCounter from "../commonComponents/FooterCounter/FooterCounter";
import getAgThemePreference from "../../utils/getAgThemePreference";

const EXPORT_COLS = [
  { en: "clockInDate", al: "Data" },
  { en: "clockInDate", al: "Fillimi" },
  { en: "clockOutDate", al: "Mbarimi" },
  { en: "approved", al: "Statusi" },
  { en: "clockOutDate", al: "Kohëzgjatja" },
];
const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function ClockIns() {
  const { employee } = useContext(PunonjesitContext);
  
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { clockings } = useSelector((state) => state.clockings);

  const [aggridData, setAggridData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [visible, setVisible] = useState(false);
  // const [isModalVisible, setIsModalVisible] = useState(true);
  const [dateFilter, setDateFilter] = useState(dayjsTZ());
  
  const allLeaves = employeeRequests?.filter(
    (rqs) => rqs?.userSub === employee?.userSub
  );

  const { style } = getAgThemePreference();
  
  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#02686b";
      case "Refuzuar":
        return "#c43428";
      case "Pending":
        return "#FCC94A";
    }
  };
  const columnDefs = [
    {
      headerName: "Nr.",
      field: "fieldId",
      valueGetter: "node.rowIndex + 1",
      flex: 1,
    },
    {
      headerName: "Data",
      field: "clockInDate",
      flex: 3,
      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <div className="status-column">
          <span style={{ color: style?.["--ag-foreground-color"] }}>
            {dayjsTZ(params.data?.clockInDate).format("DD/MM/YYYY")}
          </span>
        </div>
      ),
    },
    {
      headerName: "Fillimi",
      field: "clockInDate",
      flex: 3,

      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <div className="status-column">
          <span style={{ color: style?.["--ag-foreground-color"] }}>
            {dayjsTZ(params.data?.clockInDate).format("HH:mm")}
          </span>
        </div>
      ),
    },
    {
      headerName: "Mbarimi",
      field: "clockOutDate",
      flex: 3,

      filter: "agTextColumnFilter",
      cellRenderer: (params) => (
        <div className="status-column">
          <span style={{ color: style?.["--ag-foreground-color"] }}>
            {!!params?.data?.clockOutDate ? (
              dayjsTZ(params.data?.clockOutDate).format("HH:mm")
            ) : (
              <strong style={{ color: "red" }}>I pacaktuar</strong>
            )}
          </span>
        </div>
      ),
    },
    {
      headerName: "Statusi",
      field: "approved",
      flex: 3,

      filter: "agTextColumnFilter",
      valueGetter: ({ data }) => {
        if (data?.approved === true) {
          return "Aprovuar";
        } else {
          return "PaAprovuar";
        }
      },
      cellRenderer: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          <div variant="outlined">
            {data?.approved === true ? (
              <div
                style={{
                  background: "#02686b",
                  // width: "80px",
                  marginTop: "8px",

                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Aprovuar
              </div>
            ) : data?.approved === false ? (
              <div
                style={{
                  background: "#c43428",
                  // width: "80px",
                  marginTop: "8px",

                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Pa Aprovuar
              </div>
            ) : (
              <div
                style={{
                  background: "grey",
                  marginTop: "8px",
                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Pa Përcaktuar
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      headerName: "Kohëzgjatja",
      field: "clockOutDate",
      flex: 3,
      filter: "agTextColumnFilter",
      cellRenderer: ({ data }) => (
        <span>
          {!!data.clockOutDate
            ? dayjsTZ(data.clockOutDate)
                .diff(dayjsTZ(data.clockInDate), "hours", true)
                .toFixed(2)
            : 0}
        </span>
      ),
    },
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    if (!!clockings && Array.isArray(clockings)) {
      setAggridData(
        clockings
          .filter(
            (el) =>
              el.employeeId === employee?.employeeId &&
              el.clockInDate >= dayjsTZ(dateFilter).startOf("month").valueOf() &&
              el.clockInDate <= dayjsTZ(dateFilter).endOf("month").valueOf()
          )
          .sort((a, b) => b?.clockInDate - a?.clockInDate)
      );
    }
  }, [clockings, employee, dateFilter]);

  return (
    <>
      <LoadableComp loading={!!employee ? false : true}>
        <div className="globalPageWrapper">
          <div className="punonjesit-ag-grid">
            <AgGridHeader
              gridApi={gridApi}
              gridData={aggridData}
              items={ITEMS}
              exportTitle={`Oraret - ${employee?.employeeFirstName} ${employee?.employeeLastName}`}
              children={
                <DatePicker
                  onChange={(val) => setDateFilter(val)}
                  picker="month"
                  allowClear={false}
                  format={"MMMM"}
                  placeholder="Zgjidh Muajin"
                />
              }
            />
            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridComponent
                rowData={aggridData}
                rowSelection="single"
                onGridReady={onGridReady}
                paginationPageSize={15}
                columnDefs={columnDefs}
              />
              {/* <HtmlExelTabel
                tableData={allLeaves?.map(
                  ({ requestType, requestStatus, requestPeriod }) => ({
                    requestType,
                    requestStatus,
                    requestPeriod: dayjsTZ(requestPeriod[0]).format(
                      "DD/MM/YYYY"
                    ),
                    requestPeriodEnd: dayjsTZ(requestPeriod.slice(-1)[0]).format(
                      "DD/MM/YYYY"
                    ),
                  })
                )}
                header={[
                  "Lloji i lejes",
                  "Statusi i lejes",
                  "Data e fillimit",
                  "Data e mbarimit",
                ]}
                id="Lejet-employees-view"
                componentRef={componentRef}
              /> */}
            </div>
            <FooterCounter
              className={"rowFlex"}
              title={"Totali i orareve (Aprovuar)"}
              data={aggridData?.map((el) =>
                !!el.clockOutDate
                  ? dayjsTZ(el.clockOutDate)
                      .diff(dayjsTZ(el.clockInDate), "hours", true)
                      .toFixed(2)
                  : 0
              )}
              derivedData={aggridData?.map((el) =>
                !!el.clockOutDate && !!el?.approved
                  ? dayjsTZ(el.clockOutDate)
                      .diff(dayjsTZ(el.clockInDate), "hours", true)
                      .toFixed(2)
                  : 0
              )}
            />
          </div>
          <DetailsModal
            {...{
              visible,
              setVisible,
              allLeaves,
            }}
          />
        </div>
      </LoadableComp>
    </>
  );
}

export default ClockIns;
