import { Tooltip } from "antd";
import { PDFIcon } from "../../../../assets/icons";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import dayjs from "dayjs";
import dayjsTZ from "../../../../utils/dayjs";
import { htmlParser } from "../../../../utils";
import { formatChanges } from "../../../punonjesit/EmployeeView/components/ActivityModal/formatChanges";

const AgGridToPDF = ({
  gridApi,
  title,
  tableCols,
  modifyRows,
  className,
  style,
}) => {
  const handleClick = () => {
    let filteredRows = [];
    gridApi.forEachNodeAfterFilter((e) => filteredRows.push(e.data));

    if (!filteredRows || filteredRows.length === 0) {
      return;
    }

    if (!!modifyRows && typeof modifyRows === "function") {
      filteredRows = modifyRows(filteredRows);
    }

    const doc = new jsPDF({ orientation: "landscape" });
    const tableBody = [];
    filteredRows?.forEach((row) => {
      const tableRow = [];
      tableCols?.forEach((col) => {
        let value = row[col?.en || col];
        let formattedValue = `${
          !!value
            ? Array.isArray(value)
              ? value.join(", ")
              : dayjs.isDayjs(value) ||
                value instanceof dayjs ||
                dayjsTZ(value).isValid() ||
                dayjsTZ(value, dayjs.ISO_8601).isValid()
              ? dayjsTZ(value)
                  .locale("al")
                  .format(col?.format || "DD/MM/YYYY HH:mm")
              : typeof value === "string" || value instanceof String
              ? !!htmlParser(value)?.props?.children
                ? htmlParser(value)?.props.children
                : formatChanges(value)
              : formatChanges(value)
            : ""
        }`;
        tableRow.push(formattedValue);
      });
      tableBody.push(tableRow);
    });
    autoTable(doc, {
      head: [tableCols?.map((el) => el?.al || el)],
      body: tableBody,
      margin: { top: 20 },
      didDrawPage: () => {
        doc.setFontSize(20);
        doc.text(110, 15, title);
      },
    });
    doc.save(`${title}.pdf`);
  };
  return (
    <div
      className={`headerIcon ${className}`}
      data-testid="headerPDF"
      onClick={handleClick}
      style={style}
    >
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Eksporto në PDF"}
      >
        <PDFIcon width={20} height={20} fill={style?.fill || "#323338"} />
      </Tooltip>
    </div>
  );
};

export default AgGridToPDF;
