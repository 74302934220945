import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import React from "react";
import { htmlParser } from "../../../../../utils";
import MobileHtmlTabel from "../../../../../utils/MobileComponents/MobileHtmlTabel";

function MobileLlojetKerkesave({ tableData }) {
	return (
		<MobileHtmlTabel
			tableData={tableData?.map?.(
				({ statusTitle, statusDescription, statusCreator, statusCreationDate }) => ({
					statusHeaderKey: statusTitle,
					statusDescription: htmlParser(statusDescription),
					statusCreator,
					statusCreationDate: dayjsTZ(statusCreationDate).format("DD/MM/YYYY"),
				})
			)}
			header={["Titulli kërkesës", "Përshkrimi", "Krijuar nga", "Krijuar më"]}
			isHeader={true}
			isLink={false}
		/>
	);
}

export default MobileLlojetKerkesave;
