import React, { useState, useEffect, useContext } from "react";
import {
  // BrowserRouter as Router,
  Routes,
  Route,
  // Navigate,
} from "react-router-dom";

import PrivateRoute from "./authentication/PrivateRoute";
import { AuthRouteData } from "./AuthenticatedRouteData";
import { useSelector } from "react-redux";
import { NotifContext } from "./components/dashboard/components/notifications/context/notifContext";
// import PageNotFound from "./components/commonComponents/PageNotFound";
import { API, Auth } from "aws-amplify";
// import LoaderComponent from "./components/commonComponents/LoadableComp/LoadableComp";
import { useAuth } from "./authentication/authHelpers";
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import {
  departments,
  programFields,
  employees,
  clockings,
  users,
  employeeRequests as employeeRequestsAction,
  earnings,
  deductions,
  prepayments,
  wages,
  editUser,
  problematikat,
  candidates,
} from "./store/actions";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
  requestMultipleTables,
} from "./helpers/API/RequestFactory";
import ResetPassword from "./components/Auth/ResetPassword";
import { useUserSession } from "./hooks/useUserSession";
import { useLocation } from "react-router";
import { useActive } from "./hooks/useActive";
import { useTabNotification } from "./hooks/useTabNotification";
import { getSocketUrl } from "./utils/websocketConfig";
import { addClocking, editClocking } from "./store/actions/addClockings";
import moment from "moment-timezone";
import dayjsTZ from "./utils/dayjs";

// * @EneaXharau - Websocket handler and listener
const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));
// ----------------------

function AuthenticatedRoute({ isAuthenticated }) {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let path = location?.pathname;
  const [accessRoute, setAccessRoute] = useState([]);
  const auth = useAuth();
  const active = useActive(888000);
  // const [isIdle, setIsIdle] = useState(
  //   JSON.parse(window.localStorage.getItem("isIdle")) || false
  // );
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  // const [userDBInfo, setUserDBInfo] = useState({});
  const [setTabMessage, setIcon, clearTabMessage] = useTabNotification(500);
  const dispatch = useDispatch();
  const userSession = useUserSession();
  // * @EneaXharau - Added a state to set notification count used on tab notifications
  const [countRequestNotif, setCountRequestNotif] = useState(1);
  const clockingsSel = useSelector((state) => state.clockings);
  // const handleOnIdle = () => {
  //   auth?.user !== null && setIsIdle(true);
  //   start();
  // };

  // const { start } = useIdleTimer({
  //   timeout: userDBInfo?.idleTime || 600000,
  //   onIdle: handleOnIdle,
  //   debounce: 1500,
  // });

  // useEffect(() => {
  //   window.localStorage.setItem("isIdle", isIdle);
  // }, [isIdle]);

  useEffect(() => {
    if (!!Object.keys(auth)?.length && !!auth?.userAccess?.length) {
      setAccessRoute(auth.userAccess);
      setLoading(false);
    }
  }, [auth]);

  const updateLogin = async () => {
    let getItem = localStorage.getItem("isActive");
    if (
      !getItem ||
      dayjsTZ().diff(dayjsTZ(parseInt(getItem)), "minutes", true) > 14
    ) {
      await API.put("users", `/users/${auth?.userAccess[0]?.identityId}`, {
        body: { lastLoggedIn: dayjsTZ().valueOf() },
      }).then(() => {
        // setTimeout(() => {
        // 	if (socketHandler.readyState === socketHandler.OPEN) {
        // 		let temp = auth?.userAccess[0];
        // 		delete temp["allUsers"];
        // 		socketHandler.send(
        // 			JSON.stringify({
        // 				request: "user_loggedIn",
        // 				body: temp,
        // 				id: auth?.employeeId,
        // 			})
        // 		);
        // 	}
        // }, 700);
        localStorage.setItem("isActive", dayjsTZ().valueOf());
      });
    }
  };

  useEffect(() => {
    if (
      !!Object.keys(auth)?.length &&
      !!auth?.userAccess?.[0]?.identityId &&
      loading === false
    ) {
      updateLogin();

      // sendDontForget();
    }
  }, [active, auth]);

  useEffect(() => {
    // console.log("authledjo", auth);
    const getData = async () => {
      let monthInterval = dayjsTZ()
        .startOf("month")
        .subtract(1, "months")
        .valueOf();
      let emplIdRefForEmployee =
        auth?.userRole === "Employee" ? auth?.employeeId : false;
      await requestDataWithParams(
        "clocking",
        monthInterval,
        null,
        emplIdRefForEmployee
      ).then((res) => dispatch(clockings(res || [])));
      requestData("programFields").then((res) => dispatch(programFields(res)));
      requestData("employees").then((res) =>
        dispatch(employees(res, "GET_ACTIVE_EMPLOYEES"))
      );
      requestData("departments").then((res) => dispatch(departments(res)));
      requestData("users").then((res) => dispatch(users(res)));
      requestDataWithParams(
        "employeeRequests",
        dayjsTZ().subtract(2, "years").valueOf()
      ).then((res) => dispatch(employeeRequestsAction(res || [])));
      requestData("earnings").then((res) => dispatch(earnings(res)));
      requestData("deductions").then((res) => dispatch(deductions(res)));
      requestData("prepayments").then((res) => dispatch(prepayments(res)));
      requestData("wages").then((res) => dispatch(wages(res)));
      requestData("interviews").then((res) => dispatch(candidates(res)));

      requestData("tickets").then((res) => {
        const resp = res;
        //check the response from, iterates throught the ticket logs and if we have a status changed to completed it adds the date the ticket gets resolved
        for (let i = 0; i < resp.length; i++) {
          if (resp[i].ticketLogs.length === 0) {
            resp[i].resolvedTicket = null;
          } else {
            for (let j = 0; j < resp[i].ticketLogs.length; j++) {
              for (let k = 0; k < resp[i].ticketLogs[j].length; k++) {
                if (resp[i].ticketLogs[j][k].field === "ticketStatus") {
                  if (resp[i].ticketLogs[j][k].currentData === "Completed") {
                    resp[i].resolvedTicket = dayjsTZ(
                      resp[i].ticketLogs[j][k].changeDate
                    ).format("DD/MM/YYYY");
                  } else {
                    resp[i].resolvedTicket = null;
                  }
                } else {
                  resp[i].resolvedTicket = null;
                }
              }
            }
          }
        }
        if (auth?.userRole === "Admin" || auth?.userRole === "Super Admin") {
          dispatch(problematikat(resp));
        } else {
          dispatch(
            problematikat(
              resp?.filter(
                (item) =>
                  item?.createdBy?.userId === auth?.userAccess[0]?.userSub
              ) || []
            )
          );
        }
      });
    };
    if (!!auth.userRole && loading === false) {
      getData();
    }
  }, [auth]);

  // no need since no idle time
  // useEffect(() => {
  //   Auth.currentCredentials().then((r) => {
  //     try {
  //       API.get("users", `/users`).then((users) => {
  //         setUserDBInfo(users);
  //       });
  //     } catch (error) {
  //       console.log("error", error);
  //     }
  //   });
  // }, []);

  /**
   * * @EneaXharau - Async function taking no parameters
   * * it awaits for redux to fetch data from AWS database
   * * then sets in redux store to update info from database
   */
  const getData = async () => {
    Promise.allSettled([
      requestData("programFields"),
      requestData("employees"),
      requestData("departments"),
      requestData("users"),
      requestData("employeeRequests"),
    ])
      .then(
        ([
          { value: programFieldsRes },
          { value: employeesRes },
          { value: departmentsRes },
          { value: usersRes },
          { value: employeeRequestsRes },
        ]) => {
          dispatch(programFields(programFieldsRes));
          dispatch(employees(employeesRes, "GET_ACTIVE_EMPLOYEES"));
          dispatch(departments(departmentsRes));
          dispatch(users(usersRes));
          dispatch(employeeRequestsAction(employeeRequestsRes));
        }
      )
      .catch((error) => {
        console.error(
          "🚀 ~ file: AuthenticatedRoute.js ~ line 161 ~ getData ~ error",
          error
        );
      });
  };

  /**
   * * @EneaXharau - Async function taking no parameters
   * * it awaits for redux to fetch data from AWS database
   * * then sets in redux store to update info from database
   * * optimized for clockings from @LedjoPilua
   */
  // const getDataClocking = async () => {
  // 	const currentTime = dayjsTZ().format("HH:mm");
  // 	if (auth?.userRole === "Admin") {
  // 		if (currentTime !== "13:30" && currentTime !== "13:29" && currentTime !== "21:30" && currentTime !== "21:31") {
  // 			await requestAllPages("clocking", "clockingId").then((res) => dispatch(clockings(res || [])));
  // 		} else {
  // 			setTimeout(async () => {
  // 				await requestAllPages("clocking", "clockingId").then((res) => dispatch(clockings(res || [])));
  // 			}, 30000);
  // 		}
  // 	}
  // };
  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  useEffect(() => {
    if (!!auth) {
      socketHandler.onmessage = (message) => {
        const { request, body, id = "" } = JSON.parse(message.data);
        switch (request) {
          case "user_clockIn_received":
            {
              if (
                auth?.employeeId !== id &&
                (auth?.userRole === "Admin" ||
                  auth?.userRole === "Super Admin" ||
                  auth?.userRole === "Supervisor")
              )
                dispatch(addClocking(body));
            }
            break;
          case "user_clockOut_received":
            {
              if (
                auth?.employeeId !== id &&
                (auth?.userRole === "Admin" ||
                  auth?.userRole === "Super Admin" ||
                  auth?.userRole === "Supervisor")
              )
                dispatch(editClocking(body));
            }
            break;
            // case "user_added_received":
            // 	{
            // 		if (
            // 			auth?.employeeId !== id &&
            // 			(auth?.userRole === "Admin" ||
            // 				auth?.userRole === "Super Admin" ||
            // 				auth?.userRole === "Supervisor")
            // 		)
            // 			dispatch(addEmployees(body));
            // 	}
            // 	break;
            // case "user_edit_received":
            // 	{
            // 		if (
            // 			auth?.employeeId !== id &&
            // 			(auth?.userRole === "Admin" ||
            // 				auth?.userRole === "Super Admin" ||
            // 				auth?.userRole === "Supervisor")
            // 		)
            // 			dispatch(editEmployee(body));
            // 	}
            // 	break;
            // case "user_leave_request_received":
            // 	{
            // 		if (auth?.userRole === "Admin" || auth?.userRole === "Super Admin")
            // 			getData().then(() => {
            // 				setTabMessage(`(${countRequestNotif}) ${body}`);
            // 				setIcon("icon_njoftimet.svg");
            // 				setTimeout(() => {
            // 					clearTabMessage();
            // 				}, 15000);
            // 			});
            // 	}
            // 	break;
            // case "user_loggedIn_received":
            {
              if (
                auth?.userRole === "Admin" ||
                auth?.userRole === "Super Admin" ||
                auth?.userRole === "Supervisor" ||
                auth?.employeeId === id
              ) {
                dispatch(editUser(body));
              }
            }
            break;
          default:
            break;
        }
        return () => socketHandler.close();
      };
    }
  }, [auth]);

  // /**
  //  * * @EneaXharau - useEffect to update notification count
  //  * * when employee request changes
  //  */
  // useEffect(() => {
  // 	if (!!employeeRequests) {
  // 		//Vendos numrin e kerkesave qe jane Pending te Badge i kerkesave per leje
  // 		setCountRequestNotif(
  // 			employeeRequests?.filter(
  // 				(pr) => pr?.requestStatus === "Pending" && pr?.userSub !== "undefined"
  // 			)?.length
  // 		);
  // 	}
  // }, [employeeRequests]);

  return (
    <div
      className="auth"
      style={
        path === "/login" ||
        path === "/harruatkodin" ||
        path === "/rregjistrohu"
          ? { display: "none" }
          : { height: "100%" }
      }
    >
      {/* <button onClick={sendDontForget}>Dont forget</button> */}
      <Routes>
        <Route path="/" element={<PrivateRoute accessRoute={accessRoute} />}>
          {AuthRouteData?.map((AuthRoute) => (
            <Route
              path={AuthRoute?.path}
              key={AuthRoute?.path}
              element={
                <>
                  {/* {auth?.user !== null && (
                    <IdleTimeContainer {...{ isIdle, setIsIdle }} />
                  )} */}
                  {!AuthRoute.View ? null : (
                    <AuthRoute.View>
                      <AuthRoute.Component />
                    </AuthRoute.View>
                  )}
                </>
              }
            >
              {AuthRoute?.child !== null
                ? AuthRoute?.child?.map((el) => (
                    <Route
                      key={el.path}
                      path={el.path}
                      element={<el.Component />}
                    />
                  ))
                : null}
            </Route>
          ))}{" "}
          <Route path="/ndryshoFjalekalimin" element={<ResetPassword />} />
        </Route>
      </Routes>
    </div>
  );
}
export default AuthenticatedRoute;
