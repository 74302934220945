const colorShade = (hex, percent) => {
  hex = hex.replace("#", "");

  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  if (percent > 1) {
    r = Math.max(0, r - r * (percent / 100));
    g = Math.max(0, g - g * (percent / 100));
    b = Math.max(0, b - b * (percent / 100));
  } else if (percent < 1) {
    r = Math.min(255, Math.floor(r + (255 - r) * percent));
    g = Math.min(255, Math.floor(g + (255 - g) * percent));
    b = Math.min(255, Math.floor(b + (255 - b) * percent));
  }

  let newHex =
    "#" +
    Math.round(r).toString(16).padStart(2, "0") +
    Math.round(g).toString(16).padStart(2, "0") +
    Math.round(b).toString(16).padStart(2, "0");

  return newHex;
};
export default colorShade;
