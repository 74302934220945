import { isNumber } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { currencyformatter } from "../../../Pagat/PaySub/employeeCalc/methods";
import { message, Table, Tooltip } from "antd";
import { SwapIcons } from "../../../../assets/icons";
import { getReportTypes } from "../types";
import { useLocation } from "react-router";
import { useReportsData } from "../reportContext/reportHelpers";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";

function ReportTable({ columnsDef, rowData }) {
	const [processedColumns, setProcessedColumns] = useState(null);
	const [processedData, setProcessedData] = useState(null);
	const [data, setData] = useState([]);
	const [footer, setFooter] = useState({});
	const [footerType, setFooterType] = useState();
	const [sorted, setSorted] = useState(null);
	const { state } = useLocation();
	const { reportData, populateData, retrieveData } = useReportsData();

	const onSort = (column, txt) => {
		const data2 = data;
		if (sorted === null || sorted === "desc") {
			setSorted("asc");
		} else if (sorted === "asc") {
			setSorted("desc");
		}
		data2.sort((a, b) => {
			if (sorted === null || sorted === "desc") {
				if (getReportTypes(txt) === "date") {
					return dayjsTZ(a[txt], "DD/MM/YYYY").valueOf() > dayjsTZ(b[txt], "DD/MM/YYYY").valueOf()
						? 1
						: -1;
				} else return a[txt] > b[txt] ? 1 : -1;
			} else if (sorted === "asc") {
				if (getReportTypes(txt) === "date") {
					return dayjsTZ(a[txt], "DD/MM/YYYY").valueOf() < dayjsTZ(b[txt], "DD/MM/YYYY").valueOf()
						? 1
						: -1;
				} else return a[txt] < b[txt] ? 1 : -1;
			}
		});
		populateData(data2);
		setProcessedData(data2);
	};

	useMemo(() => {
		if (!!data) {
			if (state === "approvals") {
				onSort("", "Emri");
			} else {
				populateData(data);
			}
		}
	}, [data]);

	useEffect(() => {
		if (data?.length > 0) {
			let temp = {};
			let cols = {};
			Object.entries(data[0]).forEach(([key, value]) => {
				// console.log("REPORT TYPES", getReportTypes(key));
				if (getReportTypes(key) === "currency") {
					temp[key] = "currency";
					cols[key] = 0;
				} else if (getReportTypes(key) === "number") {
					temp[key] = "number";
					cols[key] = 0;
				} else {
					temp[key] = "string";
					cols[key] = "";
				}
			});
			// console.log("Temp", temp);
			if (Object.keys(temp).length !== 0) {
				let tempFooter = cols;
				const footerData = data.reduce((acc, curr) => {
					// console.log("acc", acc, curr);
					Object.entries(curr).forEach(([key, value]) => {
						if (temp[key] === "currency") {
							// if (!footer[key]) {
							// 	footer[key] = 0;
							// }
							// console.log("foir", "key", key, "val", value, formatVal(value));
							acc[key] += formatVal(value);
						} else if (temp[key] === "number") {
							acc[key] += formatNum(value);
						}
					});
					return acc;
				}, tempFooter);
				setFooter(footerData);
				setFooterType(temp);
			}
		}
	}, [data]);
	useEffect(() => {
		if (rowData && columnsDef) {
			setProcessedColumns(columnsDef);
			setProcessedData(rowData);
			rowData.forEach((el, idx) => {
				let temp = {};
				columnsDef.forEach((col, idx) => {
					temp[col] = el[idx] || "";
				});
				setData((prev) => [...prev, temp]);
			});
		} else {
			setProcessedColumns(null);
			setProcessedData(null);
			setData([]);
		}
		return () => {
			setProcessedColumns(null);
			setProcessedData(null);
			setData([]);
		};
	}, [rowData, columnsDef]);

	return (
		<>
			{!!processedData && (
				<>
					<br />
					{processedData.length > 0 && (
						<div className="payStub-table2">
							<table>
								<tr>
									{processedColumns?.map((el, idx) => (
										<th
											style={
												getReportTypes(el) === "currency"
													? { textAlign: "right" }
													: { textAlign: "center" }
											}
											onClick={() => onSort(idx, el)}
										>
											<Tooltip
												title={
													sorted === null || sorted === "desc"
														? "Sort Ascending"
														: "Sort Descending"
												}
											>
												{" "}
												{el} <span className="sort-icon-wrapper"> {/* <SwapIcons />{" "} */}</span>
											</Tooltip>
										</th>
									))}
								</tr>
								{data?.length > 0 ? (
									<>
										{data.map((el) => {
											const isHighlighted = el?.["Kategoria e turnit"] === "MUNGESE";
											return (
												<tr
													style={{
														background: isHighlighted ? "#FE4C4A" : null,
														color: isHighlighted ? "#ffffff" : null,
													}}
												>
													{Object.entries(el).map(([key, rd]) => (
														<td
															style={
																getReportTypes(key) === "currency"
																	? { textAlign: "right" }
																	: { textAlign: "center" }
															}
														>
															{getReportTypes(key) === "currency" && typeof rd === "string"
																? rd?.replace("ALL", "")
																: getReportTypes(key) === "currency" && typeof rd !== "string"
																? Number(rd).toFixed(2)
																: rd}
														</td>
													))}
												</tr>
											);
										})}
										{Object.entries(footer).map(([key, rd], idx) => (
											<td
												style={{
													// textAlign: "center",
													// background: "#1D3445",
													//Autumn
													background: "#1D3445",
													color: "#ffffff",
													border: "1px solid white",
													// border: "1px solid black",
													textAlign: "right",
												}}
											>
												{idx > 0 && !!footerType
													? footerType[key] === "currency"
														? currencyformatter.format(rd?.toFixed(2))
														: footerType[key] === "number" && isNumber(rd)
														? rd.toFixed(2)
														: rd
													: rd}
											</td>
										))}
									</>
								) : (
									<strong style={{ display: "flex", justifyContent: "center", color: "red" }}>
										Nuk ka te dhena per te shfaqur
									</strong>
								)}
							</table>
						</div>
					)}
				</>
			)}
		</>
	);
}

export default ReportTable;

const formatVal = (v) => {
	// console.log("type of", typeof v, v);
	if (typeof v === "string") {
		if (v[0] !== "-") {
			return Number(v.trim().replaceAll("ALL", "").replaceAll(" ", "").replaceAll(",", ""));
		} else {
			return -Number(v.trim().replaceAll("-ALL", "").replaceAll(" ", "").replaceAll(",", ""));
		}
	} else {
		return v;
	}
};

const formatNum = (v) => {
	if (typeof v === "string") {
		return Number(v);
	} else return v;
};
