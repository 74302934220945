import "./ClockShiftLog.scss";
import { useState, useEffect, useMemo } from "react";
import { Drawer, Select, Skeleton, message } from "antd";
import { API } from "aws-amplify";
import { List, Input } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { ClockInIcon, ClockOutIcon } from "../../../assets";
import { groupArrays } from "../../../utils/groupBy";
import { SearchOutlined } from "@ant-design/icons";
import { calculateDayShiftHours } from "../../../utils/calculateHours";
import ListOfShiftLogs from "./ListOfShiftLogs";
import ShiftLogButtonsFilter from "./ShiftLogButtonsFilter";
import { useAuth } from "../../../../../authentication/authHelpers";
import {
  requestAllPages,
  requestData,
} from "../../../../../helpers/API/RequestFactory";

function ClockShiftLog({ shiftLog, toggleShift }) {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [processedData, setProcessedData] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [monthQuery, setMonthQuery] = useState([
    dayjsTZ().startOf("month").valueOf(),
    dayjsTZ().endOf("month").valueOf(),
  ]);
  const auth = useAuth();

  const shifts = useMemo(() => {
    if (processedData.length > 0) {
      if (search === null) {
        if (monthQuery !== null) {
          return processedData.map((el) =>
            el.filter(
              (i) =>
                i.clockInDate >= monthQuery[0] &&
                i.clockOutDate <= monthQuery[1]
            )
          );
        } else if (monthQuery === null) {
          return processedData;
        }
      }
      if (search !== null) {
        if (monthQuery !== null) {
          return processedData.map((el) =>
            el

              .filter(
                (item) =>
                  item.group.includes(search) ||
                  dayjsTZ(item.createdAt)
                    .format("dddd")
                    .toLowerCase()
                    .includes(search.replace("ë", "e").toLowerCase())
              )
              .filter(
                (i) =>
                  i.clockInDate >= monthQuery[0] &&
                  i.clockOutDate <= monthQuery[1]
              )
          );
        } else if (monthQuery === null) {
          return processedData.map((el) =>
            el.filter(
              (item) =>
                item.group.includes(search) ||
                dayjsTZ(item.createdAt)
                  .format("dddd")
                  .toLowerCase()
                  .includes(search.replace("ë", "e").toLowerCase())
            )
          );
        }
      }
    }
  }, [search, monthQuery, processedData]);

  const getUserData = async (userID) => {
    setLoading(true);
    await requestData("clocking").then(
      (res) =>
        setUserData(
          res
            .filter(
              (el) =>
                el.employeeId === userID &&
                el.clockOutDate !== null &&
                el.clockInDate !== null
            )
            .sort((a, b) => b.createdAt - a.createdAt)
        ),
      (err) => console.log("Error retrieving shifts", err)
    );
    setLoading(false);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setFilterLoading(true);
    setFilterActive(true);
    setTimeout(() => setFilterLoading(false), 300);
  };

  const filterMonths = (monthInstance) => {
    // console.log("month instance", monthInstance);
    setFilterLoading(true);
    let startMonth = dayjsTZ(monthInstance).startOf("month").valueOf();
    let endMonth = dayjsTZ(monthInstance).endOf("month").valueOf();
    if (monthInstance !== null) {
      setMonthQuery([startMonth, endMonth]);
    } else {
      setMonthQuery(null);
    }

    setTimeout(() => setFilterLoading(false), 300);
  };

  useEffect(() => {
    // console.log("employee", auth);
    if (auth.employeeId !== null && shiftLog) {
      getUserData(auth.employeeId);
    }
  }, [auth, shiftLog]);

  useEffect(() => {
    if (userData.length > 0) {
      let toGroupArr = userData.map((p, idx) => ({
        ...p,
        group: dayjsTZ(p.clockInDate).format("DD/MM/YYYY"),
      }));
      let mes = groupArrays(toGroupArr);
      setProcessedData(
        mes.sort((a, b) => b[0]?.clockInDate - a[0]?.clockInDate)
      );
    }
  }, [userData]);

  return (
    <div className="clock-log-drawer">
      <Drawer
        title="Prezenca në të kaluarën"
        placement="right"
        onClose={() => toggleShift(false)}
        open={shiftLog}
        className="clock-log-drawer"
        destroyOnClose={true}
      >
        {loading ? (
          <div>
            {Array(5)
              .fill(null)
              .map((_, idx) => (
                <Skeleton style={{ margin: "20px" }} key={idx} />
              ))}
          </div>
        ) : (
          <div className="shift-log-container">
            {userData.length === 0 && (
              <h3
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Nuk ka rekorde per te shfaqur!
              </h3>
            )}
            <div className="search-header">
              {" "}
              <Input
                onChange={handleSearch}
                placeholder="Kërko një ditë apo datë të caktuar..."
                prefix={<SearchOutlined />}
              />
            </div>
            <div className="Filter Buttons">
              {" "}
              <ShiftLogButtonsFilter
                defaultFirst={dayjsTZ()}
                filterMonths={filterMonths}
              />{" "}
              <br />
            </div>

            {filterLoading ? (
              Array(5)
                .fill(null)
                .map((_, idx) => (
                  <Skeleton style={{ margin: "20px" }} key={idx} />
                ))
            ) : (
              <li className="list-of-shift-logs">
                <ListOfShiftLogs dataSource={shifts} />
              </li>
            )}
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default ClockShiftLog;
