import { EditIcon, TickIcon, XIcon } from "../../../../../assets/icons";
import RenderDynamicComponents from "../../../../RenderDynamicComponents/RenderDynamicComponents";
import PunonjesitContext from "../../../store/PunonjesitContext";
import "./EditableCard.scss";
import { Button, Card, Form, message, Spin } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import dayjsTZ from "../../../../../utils/dayjs";
import { API } from "aws-amplify";
import { useAuth } from "../../../../../authentication/authHelpers";
import { translateObjectFields } from "../../../../Intervistat/Kandidatet/ShtoKandidat/ShowContactDetails/ShowContactDetails";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const ICON_PROPS = { fill: "#fff", width: "16", height: "16" };
/**
 * @typedef showOn
 *
 * @property {string} key formItemName to match value
 * @property {'Aktiv' | 'Shkarkuar' | 'Larguar'} value formItemName's value that renders everything
 */
/**
 * @typedef employeeKey
 * @property {string} formItemName employee's key to render
 * @property {string} label form item's label
 * @property {string} placeholder form item's placeholder
 * @property {string} type form item's Component type
 * @property {Boolean} required is input required
 * @property {Array<string> | undefined} options Select options
 * @property {Array<Object> | undefined} rules form item's rules
 * @property {string | undefined} format datepicker & timerangepicker formatting
 * @property {string | undefined} onSaveFormat form item's formatting when POSTing to DB
 * @property {function | undefined} disabledDate datepicker & timerangepicker allowed dates
 * @property {Object | undefined} labels string labels on checked and unchecked for labeledcheckbox
 * @property {string | undefined} optionsParentFor formItemName of item (child) whose options get filtered based on this item's selected option
 * @property {string | undefined} optionsOnParent formItemName of item (parent) to inherit options from, on its selected option
 * @property {string | undefined} primaryOptionsFor formItemName of item (child) having both the same options, but (child) filters out current selected option
 * @property {string | undefined} secondaryOptions formItemName of item (parent) to use its options except its selected value
 * @property {Boolean | undefined} alwaysOnDisplay render this form item even if showOn.key hides all form items
 * @property {Boolean | undefined} isParentRenderer defines if its value decides another form item's display
 * @property {string | undefined} renderBy formItemName of item (parent) whose value decides the rendering
 * @property {Boolean | undefined} renderOnParent when to render the form item based on parent's value
 */
/**
 * @typedef props
 *
 * @property {string} title
 * @property {Array<string>} employeeKeys
 * @property {showOn} showOn
 */
/**
 *
 * @param {props} props
 */
const EditableCard = ({ title = "", employeeKeys, showOn = {} }) => {
  const [form] = Form.useForm();
  const auth = useAuth();
  const { employee, setEmployee } = useContext(PunonjesitContext);
  const [fieldsJSON, setFieldsJSON] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  //region BUILD KEYLOGS
  const updatedFields = (originalObject, changedObject) => {
    const excludedFields = [
      "createdAt",
      "googleDriveFolderIds",
      "employeeEffectives",
      "employeeId",
      "keylog",
      "userId",
    ];
    const fieldOptions = Object.keys(changedObject)?.map((key) => {
      const fieldIdx = employeeKeys?.findIndex(
        (el) => el?.formItemName === key
      );
      return { formItemName: key, label: employeeKeys[fieldIdx]?.label };
    });

    let changes = [];
    let constants = {
      activity: "Ndryshuar",
      author:
        auth.userAccess[0]?.given_name + " " + auth.userAccess[0]?.family_name,
      changeDate: dayjsTZ().valueOf(),
    };
    for (const key in changedObject) {
      if (changedObject[key] !== null && !excludedFields.includes(key)) {
        if (Array.isArray(changedObject[key])) {
          if (
            JSON.stringify(changedObject[key]) !==
            JSON.stringify(originalObject[key])
          ) {
            changes.push({
              ...constants,
              field: key,
              newValue: changedObject[key]?.join(", ") || changedObject[key],
              oldValue: originalObject[key]?.join(", ") || originalObject[key],
            });
          }
        } else {
          if (changedObject[key] !== originalObject[key]) {
            changes.push({
              ...constants,
              field: key,
              oldValue: originalObject[key],
              newValue: changedObject[key],
            });
          }
        }
      } else if (
        originalObject[key] !== null &&
        !excludedFields.includes(key)
      ) {
        changes.push({
          ...constants,
          field: key,
          oldValue: originalObject[key],
          newValue: null,
        });
      }
    }

    return translateObjectFields({ fieldOptions, changes });
  };

  // region ON SAVE
  const handleSave = () => {
    setLoading(true);
    setEditMode(false);
    try {
      form.validateFields().then((res) => {
        let toSend = { ...res };

        //format values if onSaveFormat key is defined
        Object.keys(toSend)?.forEach((key) => {
          employeeKeys
            ?.filter((el) => el?.hasOwnProperty("onSaveFormat"))
            .forEach(({ formItemName, onSaveFormat }) => {
              if (formItemName === key) {
                if (Array.isArray(toSend[key])) {
                  toSend[key].forEach((value, index) => {
                    toSend[key][index] = value?.format(onSaveFormat);
                  });
                } else {
                  toSend[key] = parseInt(toSend[key]?.format(onSaveFormat));
                }
              }
            });
        });
        const changes = updatedFields(employee, toSend);

        API.put("employees", `/employees/${employee?.employeeId}`, {
          body: {
            ...toSend,
            keylog: [...(employee?.keylog || []), ...changes],
          },
        })
          .then((res) => {
            setEmployee(res?.employee?.Item);
            setLoading(false);
            message.success("Të dhënat u ndryshuan!");
          })
          .catch((err) => console.log("err: ", err));
      });
    } catch (error) {
      message.error("Validation Error!");
      setEditMode(true);
      setLoading(false);
    }
  };

  //region FILTERS
  const onParentRendererChange = (e) => {
    setFieldsJSON((prev) =>
      prev?.map((el) =>
        el?.renderBy === e.target?.id
          ? {
              ...el,
              visible: !el?.visible,
            }
          : el
      )
    );
  };
  const filterOptionsByParent = (selected, formItemName) => {
    setFieldsJSON((prev) =>
      prev?.map((el) => {
        if (el?.optionsOnParent === formItemName) {
          let options = (
            prev?.find((el) => el?.formItemName === formItemName) || {}
          )?.options?.find((option) => option?.[formItemName] === selected)?.[
            el?.formItemName
          ];
          return { ...el, options };
        } else return el;
      })
    );
  };
  const filterOptionsByPrimary = (selected, formItemName) => {
    setFieldsJSON((prev) =>
      prev?.map((el) => {
        if (el?.secondaryOptions === formItemName) {
          let options = (
            prev?.find((key) => key?.formItemName === formItemName) || {}
          )?.options?.filter((el) => el !== selected);
          return { ...el, options };
        } else return el;
      })
    );
  };

  //region INITIAL FIELDSJSON
  const initialFieldsJSON = useMemo(() => {
    const fieldsArray = [];
    employeeKeys?.map((el) => {
      let newObj = {
        ...el,
        visible: true,
        formattedValue: employee[el.formItemName],
        formValue: employee[el.formItemName],
      };
      if (el.hasOwnProperty("isParentRenderer")) {
        newObj = { ...newObj, onChange: onParentRendererChange };
      }
      if (el.hasOwnProperty("optionsParentFor")) {
        newObj = {
          ...newObj,
          options: newObj.options?.map((option) => option[el?.formItemName]),
          onSelect: (e) => filterOptionsByParent(e, el?.formItemName),
        };
      }
      if (el.hasOwnProperty("optionsOnParent")) {
        newObj = {
          ...newObj,
          options: employeeKeys
            ?.find((key) => key?.formItemName === el["optionsOnParent"])
            ?.options?.find(
              (option) =>
                option[el?.optionsOnParent] === employee[el?.optionsOnParent]
            )?.[el?.formItemName],
        };
      }
      if (el.hasOwnProperty("primaryOptionsFor")) {
        newObj = {
          ...newObj,
          onSelect: (e) => filterOptionsByPrimary(e, el?.formItemName),
        };
      }
      if (el.hasOwnProperty("secondaryOptions")) {
        const findOptions = (arr) =>
          arr?.find((key) => key?.primaryOptionsFor === el?.formItemName)
            ?.options;
        const primaryOptions =
          findOptions(fieldsArray) || findOptions(employeeKeys);

        newObj = {
          ...newObj,
          options: primaryOptions?.filter(
            (option) => option !== employee[el["secondaryOptions"]]
          ),
        };
      }
      if (el.hasOwnProperty("renderBy")) {
        const parentIdx = employeeKeys?.findIndex(
          (key) => key.formItemName === el.renderBy
        );
        const parentKey = employeeKeys[parentIdx];
        newObj = {
          ...newObj,
          visible: employee[parentKey.formItemName] === el.renderOnParent,
        };
      }
      if (el.formItemName === "breakTime") {
        let formValue =
          employee[el?.formItemName]?.length > 0
            ? [
                dayjs(employee[el?.formItemName][0], el?.format),
                dayjs(employee[el?.formItemName][1], el?.format),
              ]
            : [];
        newObj = {
          ...newObj,
          value: formValue,
          formValue,
          formattedValue: employee[el.formItemName]?.join(" - "),
        };
      } else if (el.type === "labeledcheckbox" || el.type === "checkbox") {
        let formattedValue = !!employee[el.formItemName]
          ? el?.labels?.checked
          : el?.labels?.unchecked;
        newObj = {
          ...newObj,
          defaultChecked: !!employee[el.formItemName],
          formattedValue,
          value: formattedValue,
          formValue: !!employee[el.formItemName],
          ...(!newObj.hasOwnProperty("onChange")
            ? {
                onChange: (e) =>
                  form.setFieldValue(e.target.id, e.target.checked),
              }
            : {}),
        };
      } else if (el.type === "datepicker") {
        newObj = {
          ...newObj,
          formattedValue: !!employee[el?.formItemName]
            ? dayjs(employee[el?.formItemName]).format(el?.format)
            : "",
          formValue: !!employee[el?.formItemName]
            ? dayjs(employee[el?.formItemName])
            : null,
        };
      }
      form.setFieldValue(el?.formItemName, newObj?.formValue);
      fieldsArray.push({ ...newObj });
    });
    return fieldsArray;
  }, [employee, employeeKeys]);

  useEffect(() => {
    setFieldsJSON(initialFieldsJSON);
  }, [initialFieldsJSON]);

  //region RETURN
  return (
    <Card
      className="editableCard"
      title={title}
      data-testid="card-title"
      extra={
        !!editMode ? (
          <div className="editableCardIcons">
            <MondayButton
              className="mondayButtonRed"
              onClick={() => setEditMode(false)}
              id="close"
              data-testid="close-btn"
            >
              <XIcon {...ICON_PROPS} />
            </MondayButton>
            <MondayButton
              className="mondayButtonGreen"
              onClick={handleSave}
              id="tick"
              data-testid="save-btn"
            >
              <TickIcon {...ICON_PROPS} />
            </MondayButton>
          </div>
        ) : (
          <div className="editableCardIcons">
            <MondayButton
              className="mondayButtonGrey"
              onClick={() => setEditMode(true)}
              id="edit"
              data-testid="edit-btn"
            >
              <EditIcon {...ICON_PROPS} />
            </MondayButton>
          </div>
        )
      }
    >
      {!!loading ? (
        <Spin
          spinning={true}
          data-testid="saving-spinner"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        />
      ) : !!editMode ? (
        <Form form={form} data-testid="edit-mode">
          {employee[showOn?.key] === showOn?.value
            ? RenderDynamicComponents(
                fieldsJSON?.filter((el) => !!el?.visible),
                { form }
              )
            : RenderDynamicComponents(
                fieldsJSON?.filter((el) => !!el?.alwaysOnDisplay),
                { form }
              )}
        </Form>
      ) : (
        <div className="viewMode" data-testid="view-mode">
          {fieldsJSON
            ?.filter((el) => !!el?.visible)
            ?.map(({ formattedValue, label }, idx) => (
              <div className="viewModeRow" key={idx}>
                <span style={{ fontWeight: 600 }}>{label}:</span>
                <span>{formattedValue}</span>
              </div>
            ))}
        </div>
      )}
    </Card>
  );
};

export default EditableCard;

EditableCard.propTypes = {
  title: PropTypes.string,
  employeeKeys: PropTypes.array.isRequired,
  showOn: PropTypes.object,
};
