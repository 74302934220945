import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Button, Input, Tooltip, DatePicker, message } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import { IconExcel, IconKerko, IconNgargo } from "../../assets/icons";
import AgGridComponent from "../AG-grid/AgGridComponent";
import DetailsModal from "./DetailsModal";
import "./raportet.scss";
import HtmlExelTabel from "../Konfigurimet/dynamic components/HtmlExelTabel";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ExportToPdf from "../Konfigurimet/dynamic components/ExportToPdf";
import { useMediaQuery } from "react-responsive";
import MobileRaportet from "./MobileRaportet";
import { getReportsClockings } from "../Reports/generate/GeneratePage";
import PunonjesitContext from "../punonjesit/store/PunonjesitContext";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";
import FooterCounter from "../commonComponents/FooterCounter/FooterCounter";
import getAgThemePreference from "../../utils/getAgThemePreference";
import colorShade from "../../utils/colorShade";
const { RangePicker } = DatePicker;

const EXPORT_COLS = [
  { en: "dayType", al: "Lloji i lejes" },
  { en: "dayType", al: "Statusi i lejes" },
  { en: "clockInDate", al: "Data e lejes" },
];
const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function Raportet() {
  const { clockings } = useSelector((state) => state.clockings);
  const { employee } = useContext(PunonjesitContext);

  const [specificData, setSpecificData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [filteredData, setFilteredData] = useState({
    active: false,
    data: [],
    timeInterval: [],
  });
  const [visible, setVisible] = useState(false);

  const allLeaves = useMemo(() => {
    return (clockings || [])
      .filter(
        (rqs) =>
          rqs?.dayType === "HolidayRequest" &&
          rqs?.employeeId === employee?.employeeId
      )
      .sort((a, b) => dayjsTZ(b?.clockInDate) - dayjsTZ(a?.clockInDate));
  }, [clockings, employee?.employeeId]);

  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#02686b";
      case "Refuzuar":
        return "#c43428";
      case "Pending":
        return "#FCC94A";
    }
  };

  const excludeWeekends = (startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = new Date(curDate).getDay();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  };

  const columnDefs = [
    {
      headerName: "Nr.",
      field: "fieldId",
      valueGetter: "node.rowIndex + 1",
      flex: 1,
    },
    {
      // headerName: "Lloji i lejes",
      field: "dayType",
      filter: "agTextColumnFilter",
      flex: 3,
      cellRenderer: () => {
        return <>Pushime</>;
      },
    },
    {
      headerName: "Statusi i lejes",
      field: "dayType",
      filter: "agTextColumnFilter",
      flex: 3,
      cellRenderer: (params) => (
        <div
          className="status-column"
          style={{ backgroundColor: getColor("Aprovuar") }}
        >
          Aprovuar
        </div>
      ),
    },
    {
      headerName: "Data e lejes",
      field: "clockInDate",
      filter: "agTextColumnFilter",
      flex: 3,
      valueFormatter: function (params) {
        try {
          return dayjsTZ(params?.value).format("DD/MM/YYYY");
        } catch (err) {
          console.error("employeeWorkStartDate--err");
        }
      },
    },
    // {
    //   headerName: "Data e mbarimit",
    //   field: "requestPeriod",
    //   filter: "agTextColumnFilter",
    //   flex: 3,
    //   valueFormatter: function (params) {
    //     try {
    //       return dayjsTZ(params.value.slice(-1)[0]).format("DD/MM/YYYY");
    //     } catch (err) {
    //       console.error("employeeWorkStartDate--err");
    //     }
    //   },
    // },
    // {
    //   headerName: "Detajet e lejes",
    //   field: "",
    //   filter: "agTextColumnFilter",
    //   flex: 3,
    //   cellRenderer: (params) => (
    //     <div>
    //       <Button
    //         className="request-details-btn"
    //         onClick={() => {
    //           handleClickOpen();
    //           setSpecificData(params.data);
    //         }}
    //       >
    //         Detaje
    //       </Button>
    //     </div>
    //   ),
    // },
    // {
    //   headerName: "Dite pa fundjave",
    //   field: "requestPeriod",
    //   filter: "agTextColumnFilter",
    //   flex: 3,
    //   cellRenderer: (params) => (
    //     <div
    //       style={{ display: "flex", justifyContent: "center", marginRight: 80 }}
    //     >
    //       <span>{`${excludeWeekends(
    //         new Date(dayjsTZ(params.value[0]).format("YYYY/MM/DD")),
    //         new Date(dayjsTZ(params.value.slice(-1)[0]).format("YYYY/MM/DD"))
    //       )}`}</span>
    //     </div>
    //   ),
    // },
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onFilteredDate = async (e) => {
    console.log("onFilteredDate", e);
    if (e?.[0] && e?.[1]) {
      setFilteredData((prev) => ({ ...prev, active: true, timeInterval: e }));
      message.loading("Duke ngarkuar te dhenat...", 0);
      await getReportsClockings(e, employee?.employeeId, "HolidayRequest")
        .then((r) => {
          let toFilter = r
            ?.filter((el) => el?.employeeId === employee?.employeeId)
            .filter(
              (rqs) =>
                rqs?.dayType === "HolidayRequest" || rqs?.dayType === "Sick"
            );
          setFilteredData((prev) => ({ ...prev, data: toFilter }));
        })
        .then(() => {
          message.destroy();
          message.success("Te dhenat u ngarkuan me sukses!", 2);
        })
        .catch((err) => {
          message.destroy();
          message.error("Dicka shkoi keq, ju lutem provoni perseri!", 2);
        });
    } else {
      setFilteredData((prev) => ({ ...prev, active: false, timeInterval: [] }));
    }
  };

  let rowDataGrid = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowDataGrid?.push(node?.data);
  });

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  const rowData = useMemo(() => {
    return filteredData?.active
      ? filteredData?.data?.sort((a, b) => b?.clockInDate - a?.clockInDate) ||
          []
      : allLeaves?.sort((a, b) => b?.clockInDate - a?.clockInDate) || [];
  }, [filteredData, allLeaves]);

  const { style, theme, accentColor } = getAgThemePreference();

  return (
    <>
      {isMobileView ? (
        <div className="globalPageWrapper">
          <div className="punonjesit-ag-grid">
            <AgGridHeader
              gridApi={gridApi}
              gridData={rowData}
              items={ITEMS}
              exportTitle={`Lejet - ${employee?.employeeFirstName} ${employee?.employeeLastName}`}
              children={
                <RangePicker
                  bordered={true}
                  style={{
                    backgroundColor: theme.includes("dark")
                      ? colorShade(accentColor, 40)
                      : null,
                    color: style?.["--ag-foreground-color"],
                  }}
                  allowClear={true}
                  onCalendarChange={onFilteredDate}
                  renderExtraFooter={() =>
                    "Intervali maksimal i të dhënave për këtë raport është 24 muaj."
                  }
                  // disabledDate={(params) =>
                  //   disabledDate(params, filteredData?.timeInterval)
                  // }
                  value={
                    filteredData?.active ? filteredData?.timeInterval : null
                  }
                  // onChange={(e) => {
                  //   onTimeChange(e);
                  //   setShortcut("range");
                  // }}
                  format={"DD/MM/YYYY"}
                />
              }
            />
            <AgGridComponent
              rowData={rowData}
              rowSelection="single"
              onGridReady={onGridReady}
              paginationPageSize={15}
              columnDefs={columnDefs}
            />
            <FooterCounter
              className={"rowFlex"}
              title={"Totali i lejeve (orë)"}
              data={rowData.map((el) =>
                !!el.clockOutDate
                  ? dayjsTZ(el.clockOutDate)
                      .diff(dayjsTZ(el.clockInDate), "hours", true)
                      .toFixed(2)
                  : 0
              )}
            />
          </div>
          <DetailsModal
            {...{
              visible,
              setVisible,
              allLeaves,
              specificData,
            }}
          />
        </div>
      ) : (
        <MobileRaportet rowDataGrid={rowDataGrid} />
      )}
    </>
  );
}

export default Raportet;
function disabledDate(current, ledjo) {
  // Disable dates beyond a 3-month range
  return current && !!ledjo?.[0]
    ? current < dayjsTZ().subtract(3, "months") ||
        current > dayjsTZ().add(3, "months")
    : false;
}
