import "./DrivePermissionForm.scss";
import { Form, Select } from "antd";
import {
  CaretDownFilled,
  CheckOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { useMemo, useState } from "react";

const GOOGLE_DRIVE_ACCESS_ROLES = [
  { key: "1", role: "writer", label: "Writer" },
  { key: "2", role: "reader", label: "Reader" },
  { key: "3", role: "commenter", label: "Commenter" },
  { key: "4", role: "fileOrganizer", label: "File Organizer" },
];

const DrivePermissionForm = ({
  form,
  handleRow,
  employees,
  permissions,
  handleSubmit,
  handleKeylogs,
}) => {
  const { Option } = Select;
  const [isEditMode, setIsEditMode] = useState(false);

  const permissionsEmails = useMemo(
    () => permissions?.map((el) => el?.email || el?.permissionEmail),
    [permissions]
  );

  const onEmailChange = (data) => {
    if (permissionsEmails?.includes(data)) setIsEditMode(true);
    else setIsEditMode(false);
  };

  return (
    <div className="add-permission">
      <Form name="add-permission-form" form={form}>
        <Form.Item
          name="permissionEmail"
          rules={[
            {
              required: true,
              message: "Email është i detyrueshëm!",
            },
          ]}
          style={{
            // width: 200,
            marginBottom: 0,
          }}
        >
          <Select
            showSearch
            variant={false}
            className="dropdown"
            suffixIcon={<CaretDownFilled />}
            placeholder={"Email..."}
            onChange={onEmailChange}
            filterOption={(input, option) =>
              (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
            }
          >
            {employees?.map((el) => (
              <Option key={el?.employeeId} value={el?.employeeEmailWork}>
                {el?.employeeEmailWork}
              </Option>
            ))}
            <Option
              key={"05e77450-701a-11ef-9c27-a3344edda1eb"}
              value={"flextestportal@gmail.com"}
            >
              flextestportal@gmail.com
            </Option>
            {/* <Option key={"sjdfh47hw487uihd"} value={"a.berziu@flex.al"}>
              a.berziu@flex.al
            </Option>
            <Option key={"sjkhfsjdfhwir"} value={"info@flex.al"}>
              info@flex.al
            </Option> */}
          </Select>
        </Form.Item>
        <Form.Item
          name="permissionRole"
          rules={[
            {
              required: true,
              message: "Roli është i detyrueshëm!",
            },
          ]}
          style={{
            // width: 100,
            marginBottom: 0,
          }}
        >
          <Select
            variant={false}
            className="dropdown"
            suffixIcon={<CaretDownFilled />}
            placeholder={"Roli..."}
          >
            {GOOGLE_DRIVE_ACCESS_ROLES?.map((el) => (
              <Option key={el?.key} value={el?.role}>
                {el?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <MondayButton
        {...{
          className: "mondayButtonGreen",
          hasIcon: true,
          Icon: !!isEditMode ? <CheckOutlined /> : <PlusOutlined />,
          onClick() {
            if (handleSubmit && typeof handleSubmit === "function") {
              handleSubmit((keylog) => {
                handleKeylogs(keylog);
              });
            }
            if (handleRow && typeof handleRow === "function") {
              handleRow();
            }
          },
          testid: "add-permission-btn",
        }}
      />
    </div>
  );
};

export default DrivePermissionForm;
