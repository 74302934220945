import { Button, Modal, Input, Tag, message } from "antd";
import { ModalXIcon } from "../../../../assets/icons";
import { InfoIcon } from "./Image";
import { CheckOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

const AddTagModal = ({
  visible,
  setVisible,
  kandidatiSelektuar,
  handleUpdate,
  applicantTagNames,
  setApplicantTagNames,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [input, setInput] = useState("");

  const handleDisable = (e) => {
    const value = e.target.value;
    if (!value) setDisabled(true);
    if (!!value) setDisabled(false);
    setInput(value);
  };

  const handleTagRemove = (listItems, e) => {
    e.preventDefault();
    const filterArray = applicantTagNames?.filter(
      (items) => items !== listItems
    );
    setApplicantTagNames(filterArray);
  };

  const handleTagAdd = () => {
    if (applicantTagNames && applicantTagNames.includes(input)) {
      setDisabled(true);
      message.info("Tagu ekziston!");
    } else {
      setApplicantTagNames((prev) => [...(prev || []), input]);
    }
  };

  useEffect(() => {
    setApplicantTagNames(kandidatiSelektuar?.applicantTag);
  }, [kandidatiSelektuar]);

  return (
    <Modal
      open={visible}
      centered={true}
      onCancel={() => {
        setVisible(!visible);
        setApplicantTagNames(kandidatiSelektuar?.applicantTag);
        setInput("");
      }}
      title="Shtoni një tag"
      className="tag-modal lightHeader"
      data-testid="shtoTagModal"
      closeIcon={<ModalXIcon />}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          testid="closeBtn"
          onClick={() => {
            setVisible(!visible);
            setApplicantTagNames(kandidatiSelektuar?.applicantTag);
            setInput("");
          }}
        >
          Mbyllni faqen
        </MondayButton>,
        <MondayButton
          className="mondayButtonGreen"
          testid="saveBtn"
          onClick={() => {
            handleUpdate();
            setVisible(!visible);
            setInput("");
          }}
        >
          Ruani ndryshimet
        </MondayButton>,
      ]}
    >
      <div className="tag-modal-container">
        <div className="modal-header">
          <img src={InfoIcon} alt="Info Icon" />
          <p>
            Më poshtë ndodhen tags që{" "}
            <span>
              {kandidatiSelektuar.applicantFirstName}{" "}
              {kandidatiSelektuar.applicantLastName}
            </span>{" "}
            aktualisht ka. Për të shtuar më shumë, shkruani më poshtë dhe ruani
            ndryshimet në fund pas përfundimit të veprimeve.
          </p>
        </div>
        <div className="modal-content">
          <div className="input-container">
            <Input
              data-testid="input"
              placeholder="Emërtoni tag"
              value={input}
              onChange={handleDisable}
            />
            <MondayButton
              disabled={disabled}
              className="mondayButtonGrey"
              onClick={handleTagAdd}
              testid="addBtn"
              Icon={<CheckOutlined />}
            />
          </div>
          <div className="tags-container">
            {(applicantTagNames || kandidatiSelektuar?.applicantTag)?.map(
              (listItems, index) => (
                <Tag
                  data-testid="tag"
                  key={index}
                  closable
                  onClose={(e) => handleTagRemove(listItems, e)}
                >
                  #{listItems}
                </Tag>
              )
            )}
          </div>
        </div>
        <div className="content-container"></div>
      </div>
    </Modal>
  );
};

export default AddTagModal;
