import "./auth-scss/OtpLogin.scss";
import CustomOtpInput from "./CustomOtpInput";
import { useAuth } from "../../authentication/authHelpers";
import { message, Statistic } from "antd";
import { useEffect, useState } from "react";
import dayjsTZ from "../../utils/dayjs";

const OtpLogin = () => {
  const { Countdown } = Statistic;
  const { confirmSignIn, sendNewCode, auth2Fa, user, updateUserConfig } =
    useAuth();
  const [alert, setAlert] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);

  const resendCode = async () => {
    if (!!emailFailed) {
      await sendNewCode();
      setAlert(false);
      message.success("Kodi u dërgua me sukses!");
    }
  };
  const handleChange = (value) => {
    if (!alert) {
      confirmSignIn(value);
    }
  };

  const handleBeforeUnload = async (e) => {
    if (!!user?.identityId) {
      await updateUserConfig(
        { auth2Fa: { is2FaActive: auth2Fa?.is2FaActive } },
        user?.identityId
      );
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (!emailFailed) {
      timeout = setTimeout(() => {
        setEmailFailed(true);
      }, 10000);
    }
    return () => clearTimeout(timeout);
  }, [auth2Fa?.codeSentOn]);

  return (
    <div className="otpContainer">
      <div>
        <Countdown
          className={`${!!alert ? "timeoutAlert" : ""}`}
          value={dayjsTZ(auth2Fa?.codeSentOn) + 120 * 1000}
          onFinish={() => setAlert(true)}
          format="mm:ss"
        />
      </div>
      <CustomOtpInput
        length={auth2Fa?.successOTP.length}
        successOTP={auth2Fa?.successOTP}
        timedOut={alert}
        onChange={handleChange}
      />
      {!!alert ? (
        <div className="timeoutAlert">
          Kodi i mëparshëm ka skaduar!
          <span className="resendCodeLink" onClick={resendCode}>
            Dërgo një kod të ri
          </span>
        </div>
      ) : !!emailFailed ? (
        <div className="failedReceivingCode">
          <span>Nuk morët kodin në email?</span>
          <span onClick={resendCode} className="resendCodeLink">
            Ridërgo kodin
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default OtpLogin;
