import { CaretDownFilled } from "@ant-design/icons";
import { Collapse, Skeleton } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { IconRaportet } from "../../assets/icons";
import "./reports.scss";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import { useAuth } from "../../authentication/authHelpers";
const { Panel } = Collapse;

const reportsCategory = [
	{
		name: "Listë Prezencat",
		access: ["Supervisor"],
		children: [
			{
				description: "Info rreth Listë Prezencave",
				key: 1,
				navigation: "supervisorApprovals",
			},
			{
				description: "Info rreth Listë Prezencave",
				key: 2,
				navigation: "clockingTypesReports",
			},
		],
	},
	{
		name: "Liste Prezencat",
		access: ["Admin"],
		children: [
			{
				description: "Info rreth Listë Prezencave",
				key: 1,
				navigation: "approvals",
			},
			{
				description: "Raporti i Llojit të Prezencave",
				key: 2,
				navigation: "clockingTypesReports",
			},
		],
	},

	{
		name: "Employee Requests",
		access: ["Supervisor", "Admin"],

		children: [{ description: "Pushimet vjetore", key: 2, navigation: "requests" }],
	},
	{
		name: "Pagat dhe Taksat",
		access: ["Admin"],

		children: [
			{
				description: "Listëpagesa për në bankë",
				key: 2,
				navigation: "bankForwardPay",
			},
			{
				description: "Orë shtesë/Banka e Orëve/Kompensime",
				key: 2,
				navigation: "prepayments",
			},
			{
				description: "Listëpagesa e Kontributeve dhe TAP",
				key: 2,
				navigation: "detailedTaxes",
			},
		],
	},
	{
		name: "Punonjësit",
		access: ["Supervisor", "Admin"],

		children: [
			{
				description: "Lista e punonjësve",
				key: 2,
				navigation: "employeesList",
			},
			{
				description: "Trendi i punësimeve/largimeve",
				key: 2,
				navigation: "suspensions",
			},
		],
	},
];

function ListReportCategory({ title, reportLogs }) {
	const navigate = useNavigate();
	const { userRole } = useAuth();

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: 25,
			}}
		>
			{reportsCategory
				?.filter(({ access }) => access?.some((e) => e === userRole))
				.map(({ name, children }) => (
					<ListItem {...{ name, children, navigate, reportLogs }} />
				))}
		</div>
	);
}

export default ListReportCategory;

const ListItem = ({ name, children, navigate, reportLogs }) => (
	<div className="report-options">
		<Collapse
			className="reports-collapse"
			defaultActiveKey={["1"]}
			style={{ width: "100%" }}
			expandIconPosition="right"
			expandIcon={({ isActive }) => <CaretDownFilled rotate={isActive ? 0 : 90} />}
		>
			<Panel
				header={
					<div className="header-description">
						<IconRaportet width={18} height={18} />
						&nbsp;&nbsp; <strong>{name}</strong>
					</div>
				}
				key="1"
			>
				{children.map((child) => (
					<ul
						className="record-ul-in-reports"
						style={{
							padding: "0px 15px",
							lineHeight: "20px",
						}}
					>
						<div
							className="div-inside-ul"
							style={{
								display: "flex",
								alignItems: "center",
								columnGap: "4px",
								width: "100%",
								height: "100%",
							}}
						>
							<span
								onClick={() => navigate("/raportet/gjenero", { state: child?.navigation })}
								className="report-title"
								style={{
									textOverflow: "ellipsis",
									display: "block",
									flex: "auto",
									cursor: "pointer",
								}}
							>
								{child?.description}{" "}
							</span>
							<div
								className="report-options"
								style={{
									display: "flex",
									alignItems: "center",
									cursor: "pointer",
								}}
							>
								{getDynamicName(reportLogs, child?.navigation)}
							</div>
						</div>
					</ul>
				))}
			</Panel>
		</Collapse>
	</div>
);

const getDynamicName = (reportLogs, navigation) => {
	let desired = reportLogs?.find((reportLog) => reportLog.reportItem === navigation);
	if (!!desired) {
		return (
			<>
				Gjeneruar herën e fundit nga &nbsp;
				<b>{desired?.createdBy?.name + " " + desired?.createdBy?.lastName}</b>
				<div className="time">
					{dayjsTZ(desired?.createdBy?.time).format("DD/MM/YYYY - HH:mm:ss")}
				</div>
			</>
		);
	}
	if (reportLogs === null) {
		return <span>Duke ngarkuar të dhënat....</span>;
	}
	return <span>Nuk ka asnjë gjenerim për këtë raport</span>;
};
