import React, { useMemo } from "react";
import "./WagesHistory.scss";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { getMonthStatistic } from "./calculations";
import { HollowDotsSpinner } from "react-epic-spinners";

function WagesHistory({ employee }) {
  const { wages } = useSelector((state) => state.wages);

  const wagesHistoryData = useMemo(() => {
    if (!!wages) {
      // console.log("Wages", wages);
      let currentMom = dayjsTZ().startOf("month");
      let lastSixMonths = [];
      [...Array(6)].map((_, idx) => {
        lastSixMonths.push(dayjsTZ(currentMom).subtract(5 - idx, "months"));
      });
      if (!employee) {
        return lastSixMonths.map((month) => {
          let res = getMonthStatistic(wages, month);
          return {
            name: dayjsTZ(month).format("MMMM YYYY"),
            "Shuma Bruto": res?.bruto.toFixed(2),
            "Shuma Neto": res?.neto.toFixed(2),
            "Kosto e Kompanise": res?.totalCost.toFixed(2),
          };
        });
      }
    }
  }, [wages, employee]);

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {!!wages && !!wagesHistoryData?.length > 0 ? (
        <div
          className="statistikat-pagat-dashboard"
          style={{
            width: "100%",
            height: "100%",
            padding: 10,
            borderRadius: "0 0 10px 10px",
          }}
        >
          <ResponsiveContainer height={"100%"} width={"100%"}>
            <AreaChart
              data={wagesHistoryData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {/* <Area type="monotone" dataKey="Shuma Bruto" stackId="1" stroke="#8884d8" fill="#8884d8" /> */}
              {/* <Area type="monotone" dataKey="Shuma Neto" stackId="1" stroke="#82ca9d" fill="#82ca9d" /> */}
              <Area
                type="monotone"
                dataKey="Kosto e Kompanise"
                stackId="1"
                stroke="#ffc658"
                fill="#ffc658"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#232f5d" size={24} />
        </div>
      )}
    </>
  );
}

export default WagesHistory;
