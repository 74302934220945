import { Button, Modal } from "antd";
import React from "react";
import { InfoBluecIcon, InfoOvertime } from "../../../../assets/icons";
import { useAuth } from "../../../../authentication/authHelpers";
import "./InfoOvertimeModal.scss";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useMediaQuery } from "react-responsive";
const InfoOvertimeModal = ({ infoVisible, setVisible, setInfoVisible }) => {
  const { employee } = useAuth();

  const isMobileView = useMediaQuery({
    query: "(max-width: 460px)",
  });

  return (
    <div>
      <Modal
        className="InfoOvertimeModal"
        destroyOnClose={true}
        closable={false}
        width={isMobileView ? "calc(100% - 20px)" : 450}
        open={infoVisible}
        centered
        footer={null}
      >
        <div className="lejeRequestOvertime">
          <InfoBluecIcon
            style={{
              height: 62,
              width: 62,
            }}
          />
          <div className="questionRequest">Kur mund të kërkosh leje?</div>
          <div className="greetRequest">
            Përshëndetje <strong>{`${employee?.employeeFirstName},`}</strong>
            <br />
            për të bërë një kërkesë për orë shtesë ju duhet që ta kërkoni të
            paktën një ditë para.
          </div>
          <div className="footerActionBtn">
            <MondayButton
              onClick={() => setInfoVisible(false)}
              className="mondayButtonRed"
            >
              Mbyll faqen
            </MondayButton>
            <MondayButton
              onClick={() => {
                return setVisible(true), setInfoVisible(false);
              }}
              className="mondayButtonGreen"
            >
              Krijo një kërkesë për leje
            </MondayButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default InfoOvertimeModal;
