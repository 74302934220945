import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";

export const retrieveRelevantWages = (wages, emplId, momentInstance) => {
	if (!!wages && !!emplId && !!momentInstance) {
		return wages.find(
			(el) =>
				dayjsTZ(el?.startPeriod).format("DD/MM/YYYY") ===
					dayjsTZ(momentInstance.start).format("DD/MM/YYYY") && el?.employeeId === emplId
		);
	}
	return null;
};
