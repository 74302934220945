import { message, Tooltip } from "antd";
import { Delete } from "../../../Documentation/View/assets";
import { apiDelete } from "../../../DocumentationConfiguration/utils/api";
import GlobalPopupKonfirm from "../../GlobalPopupKonfirm";

const BulkDeleteAgGrid = ({ gridApi, endpoint, onClick, className, style }) => {
  const handleDelete = () => {
    const selectedNodes = gridApi.getSelectedNodes();
    if (!selectedNodes?.length) {
      message.info({
        content: "Nuk ka asnjë rresht të selektuar!",
        key: "selectedNodes",
      });
      return;
    } else {
      const nodesIds = selectedNodes.map((node) => node.data.employeeId);
      const selectedData = nodesIds.map((node) => ({ employeeId: node }));
      gridApi.applyTransaction({
        remove: selectedData,
      });
      nodesIds?.forEach(async (id) => {
        await apiDelete(endpoint, id)
          .then(() => {})
          .catch((err) => console.log("Error: ", err));
      });
    }
  };

  return (
    <div className={`headerIcon ${className}`} style={style}>
      <GlobalPopupKonfirm
        title={"Jeni të sigurt që doni të fshini të selektuarat?"}
        Button={
          <Tooltip
            placement="top"
            overlayClassName="global-icon-tooltip"
            title={"Fshi të selektuarat"}
          >
            <Delete width={20} height={20} fill={style?.fill || "#323338"} />
          </Tooltip>
        }
        cancelText={"JO"}
        okText={"PO"}
        onConfirm={
          onClick && typeof onClick === "function" ? onClick : handleDelete
        }
      />
    </div>
  );
};

export default BulkDeleteAgGrid;
