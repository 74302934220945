import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import dayjs from "dayjs";

export const processClockings = (clockings, employees) => {
  let floorTime = dayjsTZ().set("hour", 13).set("minute", 31).set("second", 30);

  let res = [];
  if (!!clockings && Array.isArray(clockings) && !!employees) {
    clockings
      .filter(
        (item) =>
          dayjsTZ(item.clockInDate).format("DD/MM/YYYY") ===
          dayjsTZ().format("DD/MM/YYYY")
      )
      .forEach((element) => {
        let employeeFirstName, employeeLastName;
        const test = employees?.find(
          (employee) => employee.employeeId === element?.employeeId
        );
        if (!!test) {
          employeeLastName = test?.employeeLastName || "";
          employeeFirstName = test?.employeeFirstName || "";
        }
        if (!!element?.clockOutDate) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "clockOut",
            color: getColor("clockOut"),
            inOrOut: "out",
            time: element.clockOutDate,
          });
        }
        let minutesDiff = dayjsTZ(element.clockInDate).diff(
          floorTime,
          "minutes",
          true
        );
        if (minutesDiff < 0) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "clockIn",
            color: getColor("clockIn"),
            inOrOut: "in",
            time: element.clockInDate,
          });
        } else if (minutesDiff > 0 && minutesDiff <= 5) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "lateClockIn",
            color: getColor("lateClockIn"),
            inOrOut: "in",
            time: element.clockInDate,
          });
        } else if (minutesDiff > 5) {
          res.push({
            ...element,
            employeeInfo: {
              name: employeeFirstName,
              surname: employeeLastName,
            },
            lateInfo: "lateClockIn",
            color: getColor("extraLateClockIn"),
            inOrOut: "in",
            time: element.clockInDate,
          });
        }
      });
    return res.sort((a, b) => b.time - a.time);
  }
  return res;
};

const getColor = (type) => {
  let colors = {
    clockIn: "#02686b",
    lateClockIn: "#FCC94A",
    extraLateClockIn: "#c43428",
    clockOut: "#E8E8E8",
  };
  return colors[type];
};
