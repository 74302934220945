import { Button, Collapse } from "antd";
import { API } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { assignAccessRights } from "../../constantsFactory/accessRights";
import ReportGenerateModal from "./generateModal/ReportGenerateModal";
import ListReportCategory from "./ListReportCategory";
import "./reports.scss";
const { Panel } = Collapse;

function ReportsMain() {
	const [visible, setGenerateVisib] = useState(false);
	const [reportLogs, setReportLogs] = useState(null);
	const onGenerate = async () => {
		await assignAccessRights("Admin", "us-east-1:a4215f50-9899-4530-af8a-2af8d28064c4");
	};

	const getReportLogs = async () => {
		await API.get("reportsLogs", "/reportsLogs")
			.then((res) => {
				setReportLogs(res);
			})
			.catch((err) => {
				console.log("Something went wrong", err);
			});
	};

	useEffect(() => {
		getReportLogs();
	}, []);

	return (
		<div className="globalPageWrapper">
			<ReportGenerateModal {...{ visible, setGenerateVisib }} />
			<ListReportCategory {...{ reportLogs }} />
		</div>
	);
}

export default ReportsMain;
