import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Divider,
  DatePicker,
  Col,
  Row,
  Button,
  message,
  Tooltip,
} from "antd";
import { API } from "aws-amplify";
import { KolltukuBardh, CalendarIconFest } from "../../../../assets/icons";
import LoaderComponent from "../../../commonComponents/LoadableComp/LoadableComp";
import { useMediaQuery } from "react-responsive";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { useAuth } from "../../../../authentication/authHelpers";
import { notificationUserToAdmin } from "../../../../utils/sendNotification";
import { useSelector } from "react-redux";
import ImportantDetailsPage from "./ImportantDetailsPage";
import { v4 as uuidv4 } from "uuid";
import FestatZyrtareLogs from "./FestatZyrtareLogs/FestatZyrtareLogs";
import { LogsIcon } from "../../../Documentation/View/assets";
import "./FestatZyrtare.scss";

const DATE_FORMAT_LIST = ["DD/MM/YYYY", "DD/MM/YY"];
const HOLIDAYS_FIELD_ID = "a3a610f9-5a60-40ec-8a1d-d94ffbb10054";

function PolitikatFerstatZyrtare() {
  const { userAccess } = useAuth();

  const { programFields } = useSelector((state) => state.programFields);
  const { users } = useSelector((state) => state.usersList);

  const [festat, setFestat] = useState([]);
  const [changes, setChanges] = useState({});
  const [importantDetails, setImportantDetails] = useState({});
  const [updatedFesta, setUpdatedFesta] = useState({});
  const [visible, setVisible] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastname: userAccess[0].family_name,
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 450px)",
  });
  const initialFestat = useMemo(() => {
    return (
      (programFields || []).find((pf) => pf?.fieldId === HOLIDAYS_FIELD_ID) ||
      []
    );
  }, [programFields]);

  const getChanges = (array, idx) => {
    const oldHoliday = initialFestat?.fieldOptions?.[idx];
    const newHoliday = array?.[idx];

    if (oldHoliday?.effectiveDate !== newHoliday?.effectiveDate) {
      setChanges((prev) => ({ ...prev, [newHoliday?.id]: { ...newHoliday } }));
    }
  };

  //region UPDATE EFFECTIVE
  const updateEffectiveDate = (index, value) => {
    let idx = festat?.findIndex?.((el) => el?.id === index);

    if (idx !== -1) {
      let newFestat = [...festat];

      let keylog = {
        id: uuidv4(),
        activity: "Ndryshuar Data Efektive",
        author: username,
        changeDate: Date.now(),
        field: "effectiveDate",
        previousData: initialFestat?.fieldOptions?.[idx].effectiveDate,
        currentData: value,
      };

      newFestat[idx] = {
        ...newFestat[idx],
        effectiveDate: value,
        logs: [...(initialFestat?.fieldOptions?.[idx]?.logs || []), keylog],
      };

      setFestat([...newFestat]);
      getChanges(newFestat, idx);
    }
  };

  //region ONSAVE
  const onSave = async () => {
    message.loading({ key: "onSave", content: "Duke u ruajtur" });

    let notificationBody =
      userAccess[0]?.given_name +
      " " +
      userAccess[0]?.family_name +
      " ndryshoi datën efektive për";

    Object.entries(changes || {}).forEach(([id, obj], idx) => {
      const text = `${idx > 0 ? "," : ""} ${obj?.title} në ${dayjsTZ(
        obj?.effectiveDate
      ).format("DD/MM/YYYY")}`;

      notificationBody = notificationBody + text;
    });

    await API.put("programFields", `/programFields/${HOLIDAYS_FIELD_ID}`, {
      body: { fieldOptions: festat },
    })
      .then(async () => {
        message.success({ key: "onSave", content: "Te dhënat u ruajtën" });
        await notificationUserToAdmin(
          {
            notificationTitle: "Ndryshim i dates efektive",
            createdAt: dayjsTZ().format("DD/MM/YYYY"),
            notificationPriority: true,
            notificationCategory: "Info",
            notificationBody,
            isAnnouncement: false,
            notificationUrl: "/konfigurimet/politikat/festave/zyrtare",
            notificationFirstName: userAccess[0]?.given_name,
            notificationLastName: userAccess[0]?.family_name,
          },
          users?.allUsers?.Items
        );
        setChanges({});
      })
      .catch((err) => {
        console.log(err);
        message.error({ key: "onSave", content: "Ndodhi nje gabim" });
      });
  };

  useEffect(() => {
    if (!!programFields) {
      const getHolidays = programFields?.find(
        (pf) => pf.fieldId === HOLIDAYS_FIELD_ID
      );
      const sortHolidays = getHolidays?.fieldOptions
        ?.map((el) => {
          return {
            ...el,
            sortedDatas: dayjsTZ(el?.date, "DD/MM/YYYY").valueOf(),
          };
        })
        ?.sort((a, b) => dayjsTZ(a?.sortedDatas) - dayjsTZ(b?.sortedDatas));
      setFestat(sortHolidays);
    }
  }, [programFields]);

  return (
    <LoaderComponent loading={festat?.length > 0 ? false : true}>
      <div className="politikat-festave-zyrtare">
        {isDesktopOrLaptop ? (
          <Card
            className="festaCard"
            title={`Kalendari i festave zyrtare për vitin ${dayjsTZ().format(
              "YYYY"
            )}`}
            extra={
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Tooltip title={"Shfaq historikun"}>
                  <LogsIcon onClick={() => setLogsVisible(true)} />
                </Tooltip>
              </div>
            }
          >
            <div>
              <h3>Dita e javës</h3>
              <p>
                {festat?.map(({ id, color, icon, dayOfWeek }) => {
                  return (
                    <div
                      key={id}
                      className={color == "#EA3943" ? "weekend" : "weekday"}
                    >
                      <div className="dayOfWeekIcon">
                        {icon === "Kolltuku.svg" ? (
                          <KolltukuBardh />
                        ) : (
                          <CalendarIconFest />
                        )}
                      </div>
                      <p className="dayOfWeekLabel">{dayOfWeek}</p>
                    </div>
                  );
                })}
              </p>
            </div>
            <div className="dataZyrtare">
              <h3>Data Zyrtare</h3>
              <p>
                {festat?.map(({ id, date }) => {
                  return (
                    <div key={id} className="dateItems">
                      <p className="data">{date}</p>
                    </div>
                  );
                })}
              </p>
            </div>
            <div className="title">
              <h3>Titulli</h3>
              <p>
                {festat?.map(({ id, hasDivider, title }) => {
                  return (
                    <div className="titleDivider" key={id}>
                      <div
                        style={{
                          display: "flex",
                          flexDircetion: "column",
                          gap: "2rem",
                        }}
                      >
                        <p>{title}</p>
                      </div>

                      <span>
                        {hasDivider === true && (
                          <Divider
                            style={{
                              position: "relative",
                              bottom: "35px",
                              left: "200px",
                            }}
                          ></Divider>
                        )}
                      </span>
                    </div>
                  );
                })}
              </p>
            </div>
            <div className="day">
              <h3>Data efektive</h3>
              {festat?.map((el) => {
                return (
                  <div className="selectDate" key={el?.id}>
                    <p>
                      <DatePicker
                        format={DATE_FORMAT_LIST}
                        placeholder="Selekto daten"
                        allowClear={false}
                        defaultValue={
                          el?.effectiveDate !== ""
                            ? dayjsTZ(el?.effectiveDate)
                            : null
                        }
                        onChange={(val) => {
                          updateEffectiveDate(el?.id, dayjsTZ(val).valueOf());
                        }}
                        style={{
                          backgroundColor: "#F1F7FB",
                          width: "200px",
                          color: "red",
                          borderRadius: "10px",
                        }}
                      />
                    </p>
                    <div
                      onClick={() => {
                        setVisible(true);
                        if (updatedFesta?.id !== el?.id) {
                          setImportantDetails(el);
                        } else {
                          setImportantDetails({
                            ...importantDetails,
                            users: updatedFesta?.users || [],
                          });
                        }
                      }}
                      className="shikoMeSHumeBtn"
                    >
                      <Button>Shiko më shumë</Button>
                    </div>
                  </div>
                );
              })}
              <>
                {!!visible ? (
                  <ImportantDetailsPage
                    {...{
                      visible,
                      setVisible,
                      importantDetails,
                      setImportantDetails,
                      initialFestat,
                      setUpdatedFesta,
                    }}
                  />
                ) : null}
              </>
            </div>
            <div className="saveBtn">
              <Button
                className="btn"
                onClick={onSave}
                disabled={!Object.keys(changes || {}).length}
              >
                Ruaj
              </Button>
            </div>
          </Card>
        ) : (
          <Card
            className="festaCard"
            style={{
              width: 300,
              height: "99%",
              marginTop: 10,
              marginBottom: 10,
            }}
            title={`Kalendari i festave zyrtare për vitin ${dayjsTZ().format(
              "YYYY"
            )}`}
          >
            <div>
              <div className="description">
                <div>Dita e javës</div>
                <div>Data Zyrtare</div>{" "}
              </div>
              {festat?.map((day) => {
                return (
                  <Row className="festat-zyrtare-row">
                    <Col span={6}>
                      <div>
                        <p>
                          <div
                            key={day.id}
                            className={
                              day?.color === "#EA3943" ? "weekend" : "weekday"
                            }
                          >
                            <img
                              className="dataIcon"
                              src={
                                day.icon === "Kolltuku.svg"
                                  ? KolltukuBardh
                                  : CalendarIconFest
                              }
                            />
                            <p style={{ width: 10 }}>{day.dayOfWeek}</p>
                          </div>
                        </p>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div className="dataZyrtare">
                        <div key={day.id}>
                          <p className="data">{day.date}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        className="title-festat-zyrtare"
                        style={{ width: 130 }}
                      >
                        {day.title}
                      </div>
                    </Col>
                    <Col span={4}>
                      <div className="day-festat">
                        <div className="selectDate" key={day.id}>
                          <p>
                            {day.effectiveDate && (
                              <DatePicker
                                format={DATE_FORMAT_LIST}
                                placeholder="Selekto daten"
                                style={{
                                  backgroundColor: "#F1F7FB",
                                  width: "120px",
                                  color: "red",
                                  borderRadius: "10px",
                                }}
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Card>
        )}
      </div>
      {logsVisible && (
        <FestatZyrtareLogs
          data={festat}
          isVisible={logsVisible}
          setIsVisible={setLogsVisible}
        />
      )}
    </LoaderComponent>
  );
}

export default PolitikatFerstatZyrtare;