import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers/index";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import loggerMiddleware from "./middlewares/loggerMiddleware";

const composeEnhancers = composeWithDevTools({
  compose,
});

// Creating the Redux Store
export const store = createStore(
  reducers,
  // { devTools: false },
  {},
  composeEnhancers(applyMiddleware(thunk))
);

// using reduxjs/toolkit
// import { configureStore } from "@reduxjs/toolkit";
// import reducers from "./reducers/index";

// // Creating the Redux Store
// export const store = configureStore({
//   reducer: reducers,
// });
