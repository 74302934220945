import { Form, Modal, Typography, Space, message } from "antd";
import { HeaderStepper } from "../../..";
import { InterviewsContext } from "../../data";
import { useState, useContext, useEffect, useMemo } from "react";
import ShowContactDetails from "./ShowContactDetails/ShowContactDetails";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import { ModalXIcon } from "../../../../assets/icons";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { useSelector } from "react-redux";
import dayjsTZ from "../../../../utils/dayjs";
import dayjs from "dayjs";
import CreateCandidate from "./CreateContact";
import { QuestionsMark } from "./Image";
import { driveApi } from "../../../DriveRequest";
import "./CreateCandidateModal.scss";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { candidates as candidatesReducer } from "../../../../store/actions";
import { UserIcon } from "./Image";
import { toSentenceCase } from "../../../../utils";

const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const NewCandidate = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { Text } = Typography;
  const {
    isChanged,
    isEditable,
    setIsEditable,
    intervistat,
    setIsChanged,
    createUserPage,
    temporaryArray,
    setCreateUserPage,
    filesToBeDeleted,
    setTemporaryArray,
    setIntervistat,
    defaultSwalProperties,
    googleDriveFolderId,
    formattedDriveFiles,
    kandidatiSelektuar,
    tempUploadedFiles,
    setKandidatiSelektuar,
    resetGoogleDriveProps,
    showSecondLayoutStatus,
  } = useContext(InterviewsContext);
  const { programFields } = useSelector((state) => state.programFields);
  const { candidates } = useSelector((state) => state.candidates);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const [currentStep, setCurrentStep] = useState(0);
  const [spin, setSpin] = useState(false);

  //region >checkReApplied
  const checkApplicantReApplied = (data, candidates) => {
    let index = (candidates || []).findIndex(
      (el) =>
        el.applicantFirstName === data.applicantFirstName &&
        el.applicantLastName === data.applicantLastName &&
        el.applicantPersonalNumber === data.applicantPersonalNumber
    );
    return index > -1;
  };

  //region >compare obj
  //Function to compare 2 objects and check id the key/value of the second are the same with the first
  const compareObject = (originalObject, changedObject) => {
    for (const key in changedObject) {
      if (!!changedObject[key]) {
        if (Array.isArray(changedObject[key])) {
          if (originalObject[key]?.length === changedObject[key]?.length) {
            for (let i = 0; i < changedObject[key].length; i++) {
              if (typeof changedObject[key][i] === "object") {
                if (
                  !compareObject(originalObject[key][i], changedObject[key][i])
                ) {
                  return false;
                }
              } else if (originalObject[key][i] !== changedObject[key][i]) {
                {
                  return false;
                }
              }
            }
          } else {
            return true;
          }
        } else if (
          Object.getPrototypeOf(changedObject[key]).constructor.name ===
          "Object"
          // typeof changedObject[key] === "object" &&
          // !(changedObject[key] instanceof Date)
        ) {
          if (!compareObject(originalObject[key], changedObject[key])) {
            return false;
          }
        } else if (changedObject[key] instanceof Date) {
          if (changedObject[key].getTime() !== originalObject[key].getTime()) {
            return false;
          }
        } else {
          if (changedObject[key] !== originalObject[key]) {
            return false;
          }
        }
      }
    }
    return true;
  };
  //region >resetValues()
  const resetValues = () => {
    if (!isEditable && !!googleDriveFolderId?.length) {
      try {
        driveRequest.deleteDriveItem(googleDriveFolderId);
      } catch (err) {
        console.log(err);
      }
    } else {
      tempUploadedFiles.map((id) => {
        try {
          driveRequest.deleteDriveItem(id);
        } catch (err) {
          console.log(err);
        }
      });
    }
    setIsEditable(false);
    setCreateUserPage(false);
    setTemporaryArray(null);
    resetGoogleDriveProps();
  };

  //region ON CANCEL
  const handleCancel = () => {
    let localIsChanged = !!isChanged;
    let formValues = { ...form.getFieldsValue() };
    let originalValues = {};
    for (const key in formValues) {
      originalValues = { ...kandidatiSelektuar };
      if (dayjs.isDayjs(formValues[key])) {
        formValues[key] = dayjsTZ(formValues[key]).format(
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        );
        originalValues[key] = dayjsTZ(originalValues[key]).format(
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        );
      }
    }
    const isObjectChanged = compareObject(originalValues, formValues);

    if (!isChanged) {
      setIsChanged(isObjectChanged);
      localIsChanged = !isObjectChanged;
    }

    if (!!localIsChanged) {
      Modal.confirm({
        title: "A jeni të sigurt?",
        icon: <QuestionsMark />,
        content: (
          <Text>
            Ju po anuloni{"  "}
            {!!isEditable ? "ndryshimin" : "shtimin"} e një kandidati në listë.
            <Space className="space-gap">
              Nëse jeni të sigurt për këtë veprim, klikoni
              <span className="text-bold">"Po, anuloje"</span>
            </Space>
          </Text>
        ),
        okText: "Po, anuloje",
        cancelText: "Jo, mos e anulo",
        width: "660px",
        className: "cancelModal",
        centered: true,
        cancelButtonProps: {
          type: "primary",
          style: {
            background: "#c43428",
            borderColor: "#c43428",
            borderRadius: "5px",
          },
        },
        okButtonProps: {
          className: "ant-btn-confirm",
        },
        onOk() {
          resetValues();
        },
        onCancel() {},
      });
    } else {
      resetValues();
    }
  };

  //region ON CONTINUE
  const handleContinue = () => {
    form
      .validateFields()
      .then((res) => {
        setTemporaryArray((prev) => ({ ...prev, ...res }));
        setCurrentStep((prev) => prev + 1);
        setIsChanged(true);
      })
      .catch((err) => console.log("Error validating form: ", err));
  };

  //region ON SUBMIT
  const handleOnSubmit = async () => {
    setSpin(true);
    if (isEditable) {
      let tmpCandidad = Object.assign(kandidatiSelektuar, temporaryArray);

      const { applicantId, userId, ...rest } = tmpCandidad;

      tmpCandidad = { ...tmpCandidad, googleDriveFiles: formattedDriveFiles };

      Swal.fire({
        title: "Ju lutem prisni...",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await API.put(
        "interviews",
        `/interviews/${kandidatiSelektuar?.applicantId}`,
        {
          body: {
            ...rest,
            googleDriveFiles: formattedDriveFiles,
            applicantInterviewDate:
              kandidatiSelektuar?.applicantInterviewDate.format(),
          },
        }
      )
        .then(() => {
          dispatch(
            candidatesReducer(updateCandidatet(candidates, tmpCandidad))
          );
          setIntervistat(updateCandidatet(intervistat, tmpCandidad));
          setSpin(false);
          Swal.mixin({
            ...defaultSwalProperties,
          }).fire({
            icon: "success",
            title: "Kandidati u ndryshua!",
          });
          success(tmpCandidad);
        })
        .catch((err) => {
          setSpin(false);
          console.log("err", { err });
          message.error("Ndodhi nje gabim");
        });
      setIsEditable(false);
      try {
        filesToBeDeleted.forEach((item) => driveRequest.deleteDriveItem(item));
      } catch (err) {
        console.log("Deleting Files Error:", err);
      }
      resetGoogleDriveProps();
    } else {
      const didApplicantReApply = !isEditable
        ? checkApplicantReApplied(temporaryArray, candidates)
        : kandidatiSelektuar?.applicantReApplied;

      let tmpCandidad = {
        ...temporaryArray,
        applicantReApplied: didApplicantReApply,
        googleDriveFiles: formattedDriveFiles,
      };

      const { applicantId, userId, ...rest } = tmpCandidad;

      Swal.fire({
        title: "Ju lutem prisni...",
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await API.post("interviews", "/interviews", {
        body: rest,
      })
        .then((res) => {
          setTemporaryArray(null);
          dispatch(candidatesReducer(res));
          setKandidatiSelektuar({ ...res });
          setSpin(false);
          Swal.mixin({
            ...defaultSwalProperties,
          }).fire({
            icon: "success",
            title: "Kandidati u krijua!",
          });
          success(res);
        })
        .catch((err) => {
          setSpin(false);
          message.error("err");
          console.error(err);
        });
      resetGoogleDriveProps();
    }
  };
  //region ON SUCCESS
  function updateCandidatet(data, newCandidad) {
    return data?.map((el) =>
      el?.applicantId === kandidatiSelektuar?.applicantId
        ? {
            ...newCandidad,
            applicantId: kandidatiSelektuar?.applicantId,
          }
        : el
    );
  }

  //region >success()
  const success = (tmpCandidad) => {
    setCreateUserPage(false);
    Modal.info({
      icon: <UserIcon />,
      title: isEditable ? "Kandidati u Ndryshua" : "Kandidati u Shtua",
      centered: true,
      closable: false,
      content: (
        <div>
          <p>
            Intervista me kandidatin{" "}
            <span>
              {temporaryArray.applicantFirstName}{" "}
              {temporaryArray.applicantLastName}
            </span>{" "}
            sapo u {isEditable ? "ndryshua" : "Krijua"} me sukses. Për të bërë
            ndryshime
            {isEditable ? " të tjera " : " "}ju duhet të gjeni intervistën në
            listën e intervistave dhe të klikoni mbi të.
          </p>
        </div>
      ),
      okText: "Kliko për të parë",
      className: "successModal",

      okType: "link",
      onCancel() {
        if (!isEditable) {
          driveRequest.deleteDriveItem(googleDriveFolderId);
        }
        setIsEditable(false);
        setTemporaryArray(null);
      },
      onOk() {
        setIsEditable(false);
        setTemporaryArray(null);
        setKandidatiSelektuar({ ...tmpCandidad });
      },
    });
  };

  //region STEPS
  const STEPS = useMemo(() => {
    const steps = [];
    const formItemNames = [];
    const fieldOptions = programFields?.find(
      (el) => el.fieldId === CANDIDATE_CONFIGURATION_FIELD_ID
    )?.fieldOptions;
    fieldOptions?.forEach((option) => {
      const formItems = option?.fields?.filter((el) => !el?.deletedField);
      steps.push({
        title: toSentenceCase(option?.name),
        Comp: GeneralInfo,
        formItems,
      });
      formItemNames.push(...(formItems || []));
    });
    steps.push({
      title: "Preview Candidate",
      Comp: ShowContactDetails,
      // disabled: true,
    });
    return [steps, formItemNames];
  }, [programFields]);

  //region SET INITIAL
  useEffect(() => {
    let initialData = {};

    (STEPS?.[1] || []).forEach((field) => {
      const type = (field?.type || "").toLowerCase();
      let value;

      if (type === "tagselect" || type === "select") value = [];
      else value = null;

      initialData[field?.formItemName] = value;
    });

    if (!!isEditable) {
      form.setFieldsValue({
        ...kandidatiSelektuar,
        applicantBirthday: !!kandidatiSelektuar?.applicantBirthday
          ? dayjsTZ(kandidatiSelektuar?.applicantBirthday)
          : null,
        applicantStartDate: !!kandidatiSelektuar?.applicantStartDate
          ? dayjsTZ(kandidatiSelektuar?.applicantStartDate)
          : null,
        // applicantInterviewDate: !!kandidatiSelektuar?.applicantInterviewDate
        //   ? dayjsTZ(kandidatiSelektuar?.applicantInterviewDate)
        //   : null,
      });
    } else {
      form.setFieldsValue(initialData);
    }
  }, [STEPS, kandidatiSelektuar, isEditable]);

  //region RETURN
  return (
    <Modal
      open={createUserPage}
      title={isEditable ? "Ndryshoni një kandidat" : "Shtoni një kandidat"}
      // onCancel={!!googleDriveFolderId?.length !== 0 && showDeleteConfirm} // to be checked cuz we aint got a folderId if only step 1
      className={`createCandidateModal lightHeader ${
        !!showSecondLayoutStatus ? "kantidatInfoModalSideContainer" : ""
      }`}
      centered={true}
      destroyOnClose={true}
      closeIcon={<ModalXIcon />}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        <MondayButton
          className="mondayButtonRed"
          testid="anuloBtn"
          onClick={handleCancel}
          spin={spin}
          // disabled={
          //   !!isEditable
          //     ? compareObject(kandidatiSelektuar, form.getFieldsValue())
          //     : !!googleDriveFolderId?.length === 0
          // }
        >
          {!!isEditable
            ? Object.keys(kandidatiSelektuar).length
              ? "Anuloni ndryshimet"
              : "Anuloni Krijimin"
            : "Anuloni Krijimin"}
        </MondayButton>,
        currentStep === 2 ? (
          <MondayButton
            onClick={handleOnSubmit}
            testid="konfirmoBtn"
            className="mondayButtonGreen"
          >
            {!!Object.keys(kandidatiSelektuar).length
              ? "Konfirmoni ndryshimet"
              : "Konfirmoni krijimin"}
          </MondayButton>
        ) : (
          <MondayButton
            onClick={handleContinue}
            testid="vazhdoBtn"
            className="mondayButtonGreen"
          >
            Vazhdoni më tej...
          </MondayButton>
        ),
      ]}
    >
      <Form form={form}>
        <HeaderStepper
          {...{
            steps: STEPS[0],
            currentStep,
            setCurrentStep,
            form,
            ...(currentStep !== STEPS[0]?.length - 1
              ? { onChange: handleContinue }
              : {}),
          }}
        />
      </Form>
    </Modal>
  );
};

export default NewCandidate;
