import React, { useEffect, useState } from "react";
import "./TimeSheet.scss";
import { Input, DatePicker, Select } from "antd";
import { DeleteFilled, SearchOutlined } from "@ant-design/icons";
import ShiftLogButtonsFilter from "../../dashboard/components/clockInOut/clockShiftLog/ShiftLogButtonsFilter";
import {
  ClearIcon,
  DropDownIcon,
  IconCalendar,
  NextIcon,
} from "../../dashboard/assets";
import { useAuth } from "../../../authentication/authHelpers";
import TimeSheetButtonsFilter from "./TimeSheetButtonsFilter";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjsTZ from "../../../utils/dayjs";

const { Option } = Select;
const { RangePicker } = DatePicker;

function TimeSheetHeader({
  handleSelect,
  handleDepartment,
  filterMonths,
  onCustomDate,
  viewType,
  employeeList,
  departamentFilter,
  searchString,
  departmentId,
  setDepartmentId,
  employeeId,
  setEmployeeId,
}) {
  const auth = useAuth();
  const { departments } = useSelector((state) => state.departments);
  const [selectedEmployee, setSelectedEmployee] = useState([""]);
  const [selectedDepartament, setSelectedDepartament] = useState([]);

  const onSelect = (e) => {
    handleSelect(e);
    setEmployeeId(e);
  };

  const onDepartmentChange = (e) => {
    // console.log("e", e);
    handleDepartment(e);
    setDepartmentId(e);
  };

  const handleChange = (val) => {
    onCustomDate(val);
  };

  useEffect(() => {
    if (viewType === "user" && auth.employeeId !== null) {
      handleSelect(auth.employeeId);
    }
  }, [auth, viewType]);

  useEffect(() => {
    if (searchString !== null && !!departments) {
      let test = employeeList.filter((el) => el.employeeId === searchString);
      let t2 = departments.find(
        (e) => e.departmentId === test[0].employeeDepartmentId
      );
      setSelectedDepartament(t2?.departmentName || []);
    } else if (searchString === null && selectedDepartament.length > 0) {
      setSelectedDepartament([]);
    }
  }, [searchString, departments]);

  useEffect(() => {
    if (departamentFilter !== null && !!departments) {
      let t2 = departments.find((e) => e.departamentName === departmentId);
      setSelectedDepartament(t2.departamentName);
    } else if (departamentFilter === null && searchString === null) {
      setSelectedDepartament([]);
    }
  }, [departamentFilter, searchString, departments]);
  // console.log("Departments", departments);

  return (
    <div className="time-sheet-header-comp">
      {viewType !== "user" && (
        <>
          {" "}
          <div className="search-input">
            <Select
              allowClear
              showSearch
              value={employeeId}
              placeholder="Kërko punonjës"
              optionFilterProp="children"
              disabled={viewType === "user" ? true : false}
              onChange={onSelect}
              clearIcon={<ClearIcon />}
              suffixIcon={<DropDownIcon />}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {employeeList.length > 0 && viewType !== "user" ? (
                employeeList.map((el) => (
                  <Option
                    value={
                      el?.employeeId
                    }>{`${el?.employeeFirstName} ${el?.employeeLastName}`}</Option>
                ))
              ) : (
                <Option value={auth?.employeeId}>
                  {auth?.userAccess[0]?.given_name}
                </Option>
              )}
            </Select>
          </div>
          <div className="department-select">
            {" "}
            <Select
              allowClear
              value={departmentId}
              showSearch
              placeholder="Zgjidh Departamentin"
              optionFilterProp="children"
              // value={selectedDepartament}
              clearIcon={<ClearIcon />}
              suffixIcon={<DropDownIcon />}
              onChange={onDepartmentChange}
              disabled={viewType === "user" ? true : false}>
              {!!departments &&
                departments?.map((el) => (
                  <Option value={el?.departmentName}>
                    {el?.departmentName + ""}
                  </Option>
                ))}
            </Select>
          </div>
        </>
      )}

      <div className="month-filter-btns">
        <TimeSheetButtonsFilter {...{ filterMonths }} />
      </div>
      <div className="filter-date-range">
        <RangePicker
          onChange={handleChange}
          format={"DD/MM/YYYY"}
          placeholder={["Filtro Fillim", "Mbarim"]}
          disabledDate={(current) => {
            var startDate =
              viewType === "user"
                ? dayjsTZ().subtract(12, "months").startOf("month")
                : dayjsTZ().subtract(2, "months").startOf("month"); //Today.
            // var endDate = dayjsTZ().add(10, "days").calendar(); // 10 days in the future from now.
            // It will return false if its before today or after 10 days from now.
            return current < startDate;
          }}
          clearIcon={<ClearIcon />}
          suffixIcon={<IconCalendar />}
          separator={<NextIcon />}
        />
      </div>
    </div>
  );
}

export default TimeSheetHeader;
