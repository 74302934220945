import { Button, Modal, message } from "antd";
import React, { useContext, useState } from "react";
import { DeleteIcon, ImageIcon } from "./Image";
import { API } from "aws-amplify";
import { InterviewsContext } from "../../data";
import { useDispatch, useSelector } from "react-redux";
import AddContactDocUploader from "../ShtoKandidat/AddContactDocUploader/AddContactDocUploader";
import moment from "moment";
import dayjsTZ from "../../../../utils/dayjs";
import { useAuth } from "../../../../authentication/authHelpers";
import { sortBy } from "lodash";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import { RedCloseIcon } from "../../../Documentation/View/assets";
import { NewUploadIcon } from "../../../../assets/icons";
import { candidates as candidatesReducer } from "../../../../store/actions";
import PermissionsDocModal from "./PermissionsDocModal";

const Dokumentet = ({
  uploadedFiles,
  // setUploadedFiles,
  // formattedDriveFiles,
  onPickerSuccess,
  onDelete,
  filesToBeDeleted,
}) => {
  const dispatch = useDispatch();
  const auth = useAuth();

  const { kandidatiSelektuar, driveRequest, setKandidatiSelektuar } =
    useContext(InterviewsContext);

  const [currentFile, setCurrentFile] = useState({});
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  //region ON DELETE
  const handleRemove = ({ name, id }) => {
    Modal.confirm({
      className: "confirmModal",
      icon: <DeleteIcon />,
      title: "Ju po fshini një dokument!",
      centered: true,
      cancelButtonProps: {
        type: "primary",
        style: {
          background: "#c43428",
          borderColor: "#c43428",
          borderRadius: "5px",
        },
      },
      onCancel: () => false,
      okButtonProps: {
        className: "ant-btn-confirm",
      },
      cancelText: "Jo, mos e fshi",
      okText: "Po, fshije",
      content: (
        <>
          <p>
            "Ju po fshini dokumentin me titull <span>{name}</span> Nëse jeni të
            sigurt për këtë veprim, klikoni <span>Po, fshije.</span>"
          </p>
          <img src={ImageIcon} />
        </>
      ),
      onOk: () => removeFile(id),
    });
  };

  //region >delete()
  const removeFile = async (id) => {
    const filteredArray =
      id && uploadedFiles.filter((files) => files.id !== id);
    const keylog = buildKeylog(filteredArray);

    await API.put(
      "interviews",
      `/interviews/${kandidatiSelektuar?.applicantId}`,
      {
        body: {
          googleDriveFiles: filteredArray,
          keylog: [...(kandidatiSelektuar?.keylog || []), keylog],
        },
      }
    )
      .then(() => {
        try {
          onDelete(id);
          driveRequest.deleteDriveItem(id);
        } catch (err) {
          console.log(err);
        }
        const updatedCandidate = {
          ...kandidatiSelektuar,
          googleDriveFiles: filteredArray,
          keylog: [...(kandidatiSelektuar?.keylog || []), keylog],
        };
        dispatch(candidatesReducer(updatedCandidate));
        setKandidatiSelektuar(updatedCandidate);
      })
      .catch((err) => {
        message.error("err: ", err);
        console.log("err: ", err);
      });
  };

  //region ON PICKER SUCCESS
  const customOnPickerSuccess = (params) => {
    const test = kandidatiSelektuar.googleDriveFiles.concat(
      params.uploadedFiles.map((item) => ({
        ...item,
        newDocFile: true,
        uploadedBy: `${auth?.user?.given_name} ${auth?.user?.family_name}`,
        uploadedAt: dayjsTZ().valueOf(),
      }))
    );
    const keylog = buildKeylog(test);

    try {
      API.put("interviews", `/interviews/${kandidatiSelektuar?.applicantId}`, {
        body: {
          googleDriveFiles: test,
          keylog: [...(kandidatiSelektuar?.keylog || []), keylog],
        },
      });
    } catch (err) {
      console.log("Error When Uploading Files", err);
    }
    const updatedCandidate = {
      ...kandidatiSelektuar,
      googleDriveFiles: test,
      keylog: [...(kandidatiSelektuar?.keylog || []), keylog],
    };

    dispatch(candidatesReducer(updatedCandidate));
    setKandidatiSelektuar(updatedCandidate);

    onPickerSuccess(params);
  };

  //region >buildKeylog()
  const buildKeylog = (array) => {
    return {
      activity: "Ndryshuar",
      author: `${auth?.user?.given_name} ${auth?.user?.family_name}`,
      changeDate: dayjsTZ().valueOf(),
      field: "Dokumentet",
      oldValue: kandidatiSelektuar?.googleDriveFiles?.map((el) => el.name),
      newValue: array.map((el) => el.name),
    };
  };

  //region ON DOC CLICK
  const handleDocumentClick = async (file) => {
    setPreviewModalVisible(true);
    setCurrentFile(file);
  };

  return (
    <div className="dokumente-container">
      <div className="content-container">
        <div className="upload-container">
          <div className="upload-kandidati-button">
            <NewUploadIcon width={90} height={90} />
            <div className="upload-kandidati-button-text-container">
              <span className="upload-kandidati-button-text">
                Hidhni dokumentat këtu ose klikoni butonin e mëposhtëm
              </span>
              <AddContactDocUploader
                {...{
                  onPickerSuccess(param) {
                    customOnPickerSuccess(param);
                  },
                  uploadedFiles,
                  parentFolderId: kandidatiSelektuar?.googleDriveFolderId,
                }}
              />
            </div>
          </div>
        </div>
        <div className="uploadedFilesSection">
          {sortBy(uploadedFiles, "lastEditedUtc")
            .reverse()
            ?.map((file) => {
              const { name = "", id } = file;
              const fileExtension = name.split(".").pop();

              return (
                <div className="fileCard" key={id}>
                  <div className="uploadedFile">
                    <div
                      className="uploadFileName"
                      onClick={() => handleDocumentClick(file)}
                    >
                      <span className="uploadedFileIcon">
                        {fileIcons({ type: fileExtension }) ||
                          fileIcons({ type: "image" })}
                      </span>
                      <span className="fileName">{name}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: 20,
                      }}
                    >
                      <Button
                        className="closeIconContainer"
                        type="ghosted"
                        onClick={() => handleRemove({ name, id })}
                        icon={<RedCloseIcon className="closeIcon" />}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <MultipleFilePrevieModal
            {...{
              visible: previewModalVisible,
              setVisible: () => {
                setCurrentFile({});
                setPreviewModalVisible(false);
              },
              setCurrentFile,
              file: currentFile,
              uploadedFiles,
              driveRequest,
            }}
          /> */}
          {!!previewModalVisible ? (
            <PermissionsDocModal
              {...{
                setVisible: () => {
                  setCurrentFile({});
                  setPreviewModalVisible(false);
                },
                currentFile,
                endpoint: "interviews",
                recordId: kandidatiSelektuar?.applicantId,
                currentKeylogs: kandidatiSelektuar?.keylog,
                driveRequest,
                folderId: kandidatiSelektuar?.googleDriveFolderId,
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Dokumentet;
