import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { useState } from "react";
import "./FilterDateButtons.scss";

let AlbaniaLocale = require("moment/locale/sq");
const today = dayjsTZ().format("MMMM");

const FilterDateButtons = ({ filterMonths }) => {
	const [activeFilter, setActiveFilter] = useState(today);

	const ButtonFilter = (idx) => {
		if (
			activeFilter ===
			dayjsTZ()
				.subtract(3 - idx, "months")
				.format("MMMM")
		) {
			setActiveFilter(null);
			filterMonths(null);
		} else {
			setActiveFilter(
				dayjsTZ()
					.subtract(3 - idx, "months")
					.format("MMMM")
			);
			filterMonths(
				dayjsTZ()
					.startOf("month")
					.subtract(3 - idx, "months")
			);
		}
	};

	const getComputedClassname = (idx) => {
		let month = dayjsTZ()
			.subtract(3 - idx, "months")
			.format("MMMM");
		if (month === activeFilter) {
			return "active-filter";
		}
		return "inactive-filter";
	};
	return (
		<div className="date-buttons-container">
			{Array.apply(null, Array(4)).map((_, idx) => (
				<button key={idx} className={getComputedClassname(idx)} onClick={() => ButtonFilter(idx)}>
					{dayjsTZ()
						.subtract(3 - idx, "months")
						.format("MMMM")}
				</button>
			))}
		</div>
	);
};

export default FilterDateButtons;
