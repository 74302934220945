import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { currencyformatter } from "./methods";

export const calcMonthInd = (type, wages, month, employee) => {
	if (!!wages && !!employee) {
		let bruto = 0;
		let neto = 0;
		let temp = wages.find(
			(el) =>
				el.employeeId === employee?.employeeId &&
				dayjsTZ(el?.startPeriod).format("DD/MM/YYYY") ===
					dayjsTZ(month).startOf("month").format("DD/MM/YYYY")
		);

		if (!!temp) {
			if (type === "bruto") return (bruto = currencyformatter.format(temp?.bruto?.totalBruto) || 0);
			else if (type === "neto")
				return (neto = currencyformatter.format(temp?.taxes?.netoPay?.netoPay) || 0);
		} else {
			return "Pa caktuar";
		}
	} else {
		return "Pa përcaktuar";
	}
};
