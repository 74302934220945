import { Card } from "antd";
import LoaderComponent from "../../commonComponents/LoadableComp/LoadableComp";
import "./ProfileCustomCard.scss";
import ProfileCustomInput from "./ProfileCustomInput";
import { useSelector } from "react-redux";
const CustomProfileCard = ({ data }) => {
  const { users } = useSelector((state) => state.usersList);

  return (
    <div style={{ width: "100%", height: "calc(100% - 35px)" }}>
      {data?.name === "KontaktCard" ? (
        <div className="infoCard">
          <ProfileCustomInput
            name="emri"
            label="Emri"
            value={users?.given_name}
          />
          <ProfileCustomInput
            name="mbiemri"
            label="Mbiemri"
            value={users?.family_name}
          />
          <ProfileCustomInput
            name="nrtelefoni"
            label="Nr.Telefoni"
            value={users?.phone_number}
          />
          <ProfileCustomInput name="email" label="Email" value={users?.email} />
        </div>
      ) : (
        <div className="infoCard">
          <ProfileCustomInput
            name="custom:department"
            label="Departamenti"
            value={users?.["department"]}
          />
          <ProfileCustomInput
            name="roliIpunonjesit"
            label="Roli i Punonjësit"
            value={users?.["departmentRole"]}
          />
          <ProfileCustomInput
            name="roliIperdoruesit"
            label="Roli i përdoruesit"
            value={users?.["userRole"]}
          />
        </div>
      )}
    </div>
  );
};

export default CustomProfileCard;
