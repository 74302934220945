import "./SingleNotif.scss";
import { useState, useEffect, useRef } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { Popconfirm, message, Menu, Dropdown, Typography, Tooltip } from "antd";
import {
  DeleteIcon,
  LajmerimeIcon,
  LajmerimeIconWhite,
  LexoMeShum,
} from "../../../../../assets/icons";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

function SingleNotif({
  content,
  deleteNotif,
  markRead,
  hideNotif,
  toggleRead,
  handleCancel,
}) {
  const notifRef = useRef();
  const navigate = useNavigate();
  const [ellipsis, setEllipsis] = useState(true);
  const { Paragraph, Text } = Typography;
  const handleDelete = (notifID, content) => {
    notifRef.current.classList.add("out");
    setTimeout(() => {
      notifRef.current.classList.remove("out");
      setTimeout(() => {
        deleteNotif(notifID, content);
      }, 100);
    }, 1000);
  };
  const handleHide = (notifID, markedHidden, notifContent) => {
    notifRef.current.classList.add("out");
    setTimeout(() => {
      notifRef.current.classList.remove("out");
      setTimeout(() => {
        hideNotif(notifID, markedHidden, notifContent);
      }, 100);
    }, 1000);
  };
  const checkTime = (createdTime) => {
    let toCompare = dayjsTZ().startOf("day").valueOf();
    if (!!createdTime) {
      if (toCompare < createdTime) {
        return true;
      }
    }
    return false;
  };

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  // useEffect(() => {
  //   console.log(content);
  // }, [content]);
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() =>
          toggleRead(
            content?.notificationId,
            content?.notificationMarkedRed,
            content
          )
        }
      >
        <span>
          {content?.notificationMarkedRed === true
            ? "Shëno si të palexuar"
            : "Shëno si të lexuar"}
        </span>
      </Menu.Item>
      <Menu.Item
        onClick={() =>
          handleHide(
            content?.notificationId,
            content?.notificationMarkedHidden,
            content
          )
        }
      >
        <span>
          {content?.notificationMarkedHidden === true ? "Shfaq" : "Fshih"}
        </span>
      </Menu.Item>
    </Menu>
  );
  let div = document.createElement("div");
  div.innerHTML = content?.notificationBody;
  return (
    <div
      className={
        content?.notificationMarkedRed ? "single-notif read" : "single-notif"
      }
      ref={notifRef}
    >
      {isMobileView ? (
        <>
          <div
            className="notif-content"
            onClick={() => markRead(content?.notificationId, content)}
          >
            <span
              className={
                content?.notificationPriority === true
                  ? "notif-icon important"
                  : "notif-icon"
              }
            >
              {content?.notificationMarkedRed ? (
                <span className="original">
                  {" "}
                  <LajmerimeIcon />{" "}
                </span>
              ) : (
                <span className="white">
                  {" "}
                  <LajmerimeIcon />{" "}
                </span>
              )}

              {/* {content?.notificationID} */}
            </span>
            <span
              onClick={() => {
                navigate(`${content.notificationUrl}`);
                handleCancel();
              }}
              className="notif-body"
            >
              <b>{content.notificationTitle}</b>
              &nbsp; {"  ‒ "}&nbsp;
              <Paragraph
                style={{ width: 500 }}
                ellipsis={
                  ellipsis ? (
                    <Tooltip
                      placement="top"
                      overlayClassName="global-icon-tooltip"
                      title={ellipsis ? "Lexo më shumë" : "Lexo më pak"}
                    >
                      <div
                        className="lexo-me-shum-icon"
                        onClick={() => {
                          setEllipsis(!ellipsis);
                        }}
                      >
                        <LexoMeShum width={22} height={22} />
                      </div>
                    </Tooltip>
                  ) : (
                    false
                  )
                }
              >
                {div.innerText}
              </Paragraph>
            </span>
          </div>
          <div className="notif-time">
            <Tooltip
              placement="top"
              overlayClassName="global-icon-tooltip"
              title={dayjsTZ(content?.createdAt).format("DD/MM/YYYY || HH:mm")}
            >
              <span className="relative-time">
                {dayjsTZ(content?.createdAt).fromNow()}
              </span>
            </Tooltip>
          </div>

          <div className="notif-actions">
            <Tooltip
              placement="top"
              overlayClassName="global-icon-tooltip"
              title={ellipsis ? "Lexo më shumë" : "Lexo më pak"}
            >
              <div
                className="lexo-me-shum-icon"
                onClick={() => {
                  setEllipsis(!ellipsis);
                }}
              >
                <LexoMeShum width={22} height={22} />
              </div>
            </Tooltip>
            <Dropdown
              overlayClassName="show-more-dropdown"
              overlay={menu}
              placement="bottomLeft"
            >
              <div className="show-more-icon">
                <MoreOutlined />
              </div>
            </Dropdown>
            <Popconfirm
              overlayClassName="singleNotifPopconfirm"
              title="A jeni të sigurt që doni të fshini këtë njoftim?"
              onConfirm={() => handleDelete(content?.notificationId, content)}
              onCancel={() => message.error("Veprimi U anullua!")}
              placement="left"
              okText="Po, fshi!"
              cancelText="Jo, anullo!"
            >
              {" "}
              <div className="delete-icon">
                <DeleteIcon width={20} height={20} />{" "}
              </div>
            </Popconfirm>
          </div>
        </>
      ) : (
        <>
          <div
            className="notif-content"
            onClick={() => markRead(content?.notificationId, content)}
          >
            <span
              className={
                content?.notificationPriority === true
                  ? "notif-icon important"
                  : "notif-icon"
              }
            >
              {content?.notificationMarkedRed ? (
                <span className="original">
                  {" "}
                  <LajmerimeIcon />{" "}
                </span>
              ) : (
                <span className="white">
                  {" "}
                  <LajmerimeIcon />{" "}
                </span>
              )}

              {/* {content?.notificationID} */}
            </span>
            <span
              onClick={() => {
                navigate(`${content.notificationUrl}`);
                handleCancel();
              }}
              className="notif-body"
            >
              <b>{content.notificationTitle}</b>
              &nbsp; {"  ‒ "}&nbsp;
              {div.innerText}
              <br />
              <span className="relative-time">
                {dayjsTZ(content?.createdAt).fromNow()}
              </span>
            </span>
          </div>
          <div className="notif-actions">
            <Dropdown
              overlayClassName="show-more-dropdown"
              overlay={menu}
              placement="bottomLeft"
            >
              <div className="show-more-icon">
                <MoreOutlined />
              </div>
            </Dropdown>
            <Popconfirm
              title="A jeni të sigurt që doni të fshini këtë njoftim?"
              onConfirm={() => handleDelete(content?.notificationId, content)}
              onCancel={() => message.error("Veprimi U anullua!")}
              placement="left"
              okText="Po, fshi!"
              cancelText="Jo, anullo!"
            >
              {" "}
              <div className="delete-icon">
                <DeleteIcon width={20} height={20} />{" "}
              </div>
            </Popconfirm>
          </div>
        </>
      )}
    </div>
  );
}
export default SingleNotif;
