import React, { useEffect, useMemo, useState, useCallback } from "react";
import LeaveModal from "./LeaveModal";
import "./LeaveRequest.scss";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { Row, Col, Button, Form, Tooltip, Table, Input, message } from "antd";
import { HolidayIcon, DayOffIcon } from "../../../assets";
import { useNavigate } from "react-router-dom";
import AddLeave from "./AddLeave";
import { useSelector } from "react-redux";
import { useAuth } from "../../../../../authentication/authHelpers";
import { HollowDotsSpinner } from "react-epic-spinners";
import { lazyFetch } from "../../../../../utils/lazyFetch";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../../../utils/MobileComponents/MobileHtmlTabel";
import AddLeaveSendEmail from "./AddLeaveSendEmail/AddLeaveSendEmail";
import AddLeaveStatus from "./AddLeaveStatus/AddLeaveStatus";
import { SearchIconInput } from "../../../../../assets/icons";
import LeaveRequestTable from "./LeaveRequestTable";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

export const LeaveRequests = () => {
  let auth = useAuth();
  const { employees } = useSelector((state) => state.employeesList);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const [content, setContent] = useState(null);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [spin, setSpin] = useState({});
  const [form] = Form.useForm();
  const [isAddLeaveEmailOpen, setIsAddLeaveEmailOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [emailObject, setEmailObject] = useState({});
  const [editData, setEditData] = useState(false);

  const rowKey = useCallback((record) => {
    return record.requestId;
  }, []);

  const handleCancelLeaveEmail = () => {
    setIsAddLeaveEmailOpen();
  };

  // const [noEmpId, setNoEmpId] = useState(false);
  // opens the modal with the clicked row state
  const getNotif = async (filterId) => {
    setSpin({ [filterId]: true });
    await API.get("employeeRequests", `/employeeRequests/${filterId}`)
      .then((el) => {
        setContent(el);
        console.log(el)
        setSpin({ filterId: false });
        setIsModalVisible(true);
      })
      .catch((err) => {
        message.error("Something went wrong!");
        setSpin({ filterId: false });
        console.log("Error: ", err);
      });
  };

  // get the employesRequest data from api
  const getEmployeRequest = async () => {
    await lazyFetch("employeeRequests", [
      "requestId",
      "family_name",
      "given_name",
      "userSub",
      "requestType",
      "requestStatus",
      "requestPeriod",
      "createdAt",
      "employeeId",
    ])
      .then(() => {})
      .catch((err) => {
        console.log("Error getting emplyees: ", err);
      });
  };

  const iconStyle = {
    marginTop: 2,
    marginLeft: 2,
    width: 18,
    height: 18,
  };

  //Percakton ikonen ne varesi te llojit te kerkeses
  const getIcon = (reqType, style) => {
    switch (reqType) {
      // case "Leje lindje":
      //   return <MaternityLeaveIcon style={style} />;
      case "Pushime":
        return <HolidayIcon width={18} height={18} style={style} />;
      case "Dite Personale":
        return <DayOffIcon style={style} />;
      default:
        return;
    }
  };
  //Percakton ngjyren ne varssi te statusit te kerkeses
  const getColor = (reqStatus) => {
    switch (reqStatus) {
      case "Aprovuar":
        return "#18AA73";
      case "Refuzuar":
        return "#c43428";
      case "Pending":
        return "#FCC94A";
      default:
        return "#FCC94A";
    }
  };

  const isDisabled = useCallback((x, y) => {
    return x > y?.[0];
  }, []);

  //function to get only the week days
  const excludeWeekends = useCallback((startDate, endDate) => {
    let count = 0;
    let curDate = +startDate;
    while (curDate <= +endDate) {
      const dayOfWeek = dayjsTZ(curDate).day();
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;
      if (!isWeekend) {
        count++;
      }
      curDate = curDate + 24 * 60 * 60 * 1000;
    }
    return count;
  }, []);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  // * @EneaXharau - Added employees dependency
  const handleFilterChange = useCallback((value) => {
    setFilterValue(value);
  }, []);

  //function to populate the form when we double click on the leave start time and end
  const populateFieldsValues = useCallback(
    (value) => {
      let test = employees?.find((item) => item?.userSub === value?.userSub);
      form.setFieldValue(
        "employeeName",
        `${value?.given_name} ${value?.family_name} ${value?.userSub} ${test?.employeeId}`
      );
      form.setFieldValue("startDate", dayjsTZ(value?.requestPeriod[0]));
      form.setFieldValue("endDate", dayjsTZ(value?.requestPeriod.slice(-1)[0]));
      form.setFieldsValue(value);
      setEditData(value);
    },
    [employees, form]
  );

  useEffect(() => {
    getEmployeRequest();
  }, [employees]);

  //gets the values from dispatch and sorts them by created time
  const tableValues = useMemo(() => {
    if (!employeeRequests) return [];
    return employeeRequests?.sort((a, b) => b?.createdAt - a?.createdAt);
  }, [employeeRequests]);

  //filters the values depending on the input field
  const dataSource = useMemo(() => {
    return tableValues?.filter((item) => {
      return (
        item.given_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.family_name.toLowerCase().includes(filterValue.toLowerCase()) ||
        item.requestStatus.toLowerCase().includes(filterValue.toLowerCase()) ||
        dayjsTZ(item.createdAt).format("DD/MM/YYYY").includes(filterValue) ||
        dayjsTZ(item.requestPeriod[0])
          .format("DD/MM/YYYY")
          .includes(filterValue) ||
        dayjsTZ(item?.requestPeriod?.slice(-1)[0])
          .format("DD/MM/YYYY")
          .includes(filterValue)
      );
    });
  }, [tableValues, filterValue]);

  //region DEFCOLS
  const columns = useMemo(() => {
    return [
      {
        title: "Emër i punonjësit",
        dataIndex: "requestId",
        key: "requestId",
        align: "center",
        ellipsis: true,
        render(text, data) {
          return (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (!!data?.employeeId) {
                  navigate(`/punonjesit/specifikat/${data?.employeeId}`);
                }
              }}
            >{`${data.given_name} ${data.family_name}`}</span>
          );
        },
      },
      {
        title: "Data e krijimit",
        dataIndex: "createdAt",
        key: "createdAt",
        align: "center",
        render: (record) => <span>{dayjsTZ(record).format("DD/MM/YYYY")}</span>,
      },
      {
        title: "Statusi",
        dataIndex: "requestStatus",
        key: "requestStatus",
        align: "center",
        width: "140px",
        render(text, data) {
          return (
            <AddLeaveStatus
              onClick={() => getNotif(data?.requestId)}
              status={
                isDisabled(
                  dayjsTZ().startOf("day").valueOf(),
                  data?.requestPeriod?.slice(-1)
                )
                  ? "Përfunduar"
                  : text
              }
              spin={spin[data?.requestId]}
            />
          );
        },
      },
      {
        title: "Data e fillimit",
        dataIndex: "requestPeriod",
        key: "requestPeriod",
        align: "center",
        render: (record, data) => {
          return (
            <span
              style={{
                cursor:
                  auth.userAccess[0].userRole === "Admin"
                    ? !isDisabled(
                        dayjsTZ().startOf("day").valueOf(),
                        data?.requestPeriod?.slice(-1)
                      )
                      ? "pointer"
                      : "not-allowed"
                    : null,
              }}
              onDoubleClick={
                auth.userAccess[0].userRole === "Admin"
                  ? !isDisabled(
                      dayjsTZ().startOf("day").valueOf(),
                      data?.requestPeriod?.slice(-1)
                    )
                    ? () => {
                        setIsAddModalVisible(true);
                        populateFieldsValues(data);
                      }
                    : null
                  : null
              }
            >
              {" "}
              {dayjsTZ(record[0]).format("DD/MM/YYYY")}
            </span>
          );
        },
      },
      {
        title: "Data e mbarimit",
        dataIndex: "requestPeriod",
        key: "requestPeriod",
        align: "center",
        ellipsis: true,
        render: (record, data) => (
          <span
            style={{
              cursor:
                auth.userAccess[0].userRole === "Admin"
                  ? !isDisabled(
                      dayjsTZ().startOf("day").valueOf(),
                      data?.requestPeriod?.slice(-1)
                    )
                    ? "pointer"
                    : "not-allowed"
                  : null,
            }}
            onDoubleClick={
              auth.userAccess[0].userRole === "Admin"
                ? !isDisabled(
                    dayjsTZ().startOf("day").valueOf(),
                    data?.requestPeriod?.slice(-1)
                  )
                  ? () => {
                      setIsAddModalVisible(true);
                      populateFieldsValues(data);
                    }
                  : null
                : null
            }
          >
            {dayjsTZ(record?.slice(-1)[0]).format("DD/MM/YYYY")}
          </span>
        ),
      },

      {
        title: "Totali",
        dataIndex: "requestPeriod",
        key: "requestPeriod",
        align: "center",
        width: "70px",
        ellipsis: true,
        render: (record) => (
          <span>{`${excludeWeekends(
            dayjsTZ(record[0]),
            dayjsTZ(record?.slice(-1)[0])
          )}`}</span>
        ),
      },
    ];
  }, [populateFieldsValues, isDisabled, excludeWeekends, employees, spin]);

  //region RETURN
  return (
    <>
      {!!tableValues && !!employees ? (
        isMobileView ? (
          <div className="wrap">
            <div className="kerkesa-leje-container">
              <LeaveRequestTable
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={rowKey}
              />

              {!!isModalVisible ? (
                <LeaveModal
                  id={content?.requestId}
                  key={content?.requestId}
                  content={content}
                  isModalVisible={isModalVisible}
                  getEmployeRequest={getEmployeRequest}
                  setIsModalVisible={setIsModalVisible}
                  getIcon={getIcon}
                  getColor={getColor}
                />
              ) : null}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "50px",
              }}
            >
              <Input
                className="searchInput"
                placeholder="  Kërkoni Punonjës..."
                onChange={(e) => handleFilterChange(e.target.value)}
                prefix={<SearchIconInput />}
              />
              <MondayButton
                className="mondayButtonGreen add-request"
                onClick={() => setIsAddModalVisible(true)}
              >
                Krijo një kërkesë për leje
              </MondayButton>
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MondayButton
                className="mondayButtonGreen add-request-mobile"
                onClick={() => setIsAddModalVisible(true)}
              >
                Krijo një kërkesë për leje
              </MondayButton>
            </div>
            <MobileHtmlTabel
              header={["Emri", "Periudha", "Lloj lejes", "Krijuar", "Ditet"]}
              tableData={tableValues.map?.(
                ({
                  family_name,
                  given_name,
                  requestPeriod,
                  requestStatus,
                  createdAt,
                }) => ({
                  family_name: `${given_name} ${family_name}`,
                  requestType: `${dayjsTZ(requestPeriod[0]).format(
                    "DD/MM/YYYY"
                  )} - ${dayjsTZ(requestPeriod?.slice(-1)[0]).format(
                    "DD/MM/YYYY"
                  )}`,
                  requestStatus,
                  createdAt: dayjsTZ(createdAt).format("DD/MM/YYYY"),
                  weekEnd: `${excludeWeekends(
                    new Date(dayjsTZ(requestPeriod[0]).format("YYYY/MM/DD")),
                    new Date(
                      dayjsTZ(requestPeriod?.slice(-1)[0]).format("YYYY/MM/DD")
                    )
                  )}`,
                })
              )}
              isHeader={false}
              isLink={false}
            />
          </>
        )
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <HollowDotsSpinner color="#18AA73" size={24} />
        </div>
      )}
      {!!isAddModalVisible && (
        <AddLeave
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
          // setTableData={setTableData}
          tableData={tableValues}
          form={form}
          setIsAddLeaveEmailOpen={setIsAddLeaveEmailOpen}
          setEmailObject={setEmailObject}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      {isAddLeaveEmailOpen && (
        <AddLeaveSendEmail
          isAddLeaveEmailOpen={isAddLeaveEmailOpen}
          handleCancelLeaveEmail={handleCancelLeaveEmail}
          emailObject={emailObject}
          setEmailObject={setEmailObject}
        />
      )}
    </>
  );
};
