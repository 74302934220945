import React, { useEffect, useMemo, useState } from "react";
import { Card, Button, Badge, Form, message } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { EditIcon, MenagementIcon, XIcon } from "../../../../../assets/icons";
import LoadableComp from "../../../../commonComponents/LoadableComp/LoadableComp";
import { API } from "aws-amplify";
import { RichTextEditor } from "../../../../commonComponents";
import { htmlParser } from "../../../../../utils";
import "./KompaniaRolet.scss";
import GeneralViewModal from "../../../../commonComponents/GeneralViewModal/GeneralViewModal";
import { useSelector } from "react-redux";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

function KompaniaRolet() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { departmentId } = useParams();

  const { users } = useSelector((state) => state.usersList);
  const { departments } = useSelector((state) => state.departments);
  const { employees } = useSelector((state) => state.employeesList);

  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);
  // state with title of role return strin
  const [roleName, setRoleName] = useState("");
  const [loading, setLoading] = useState(true);
  const [showViewEdit, setShowViewEditCard] = useState(false);
  const [toBeEdited, setToBeEdited] = useState(null);
  const [savedData, setSavedData] = useState({
    description: null,
  });
  //get data from state location and store in state
  const tableData = useMemo(() => {
    if (!departments || !employees) {
      setLoading(true);
      return {};
    } else if (
      (Array.isArray(departments) && !departments.length) ||
      (Array.isArray(employees) && !employees.length)
    ) {
      setLoading(false);
      return {};
    } else {
      setLoading(false);
      let department =
        departments?.find((el) => el?.departmentId === departmentId) || {};
      department.departmentEmployees = employees?.filter(
        ({ employeeDepartmentName, employeeStatus }) =>
          employeeDepartmentName === department?.departmentName &&
          employeeStatus === "Aktiv"
      );
      return department;
    }
  }, [departmentId, departments, employees]);

  // change the state of the data  with the new value
  const handleChange = (objField, e) => {
    let test = objField;
    setSavedData((prev) => ({ ...prev, [test]: e }));
  };

  // opens the modal with the clicked state data
  const openModal = (role) => {
    setContent(
      tableData?.departmentEmployees?.filter(
        (el) => el.employeeRoleName === role
      )
    );
    setRoleName(role);
    setVisible(true);
  };

  //open the edit card view
  const handleShowEditView = (id) => {
    setShowViewEditCard(!showViewEdit);
    setToBeEdited(id);
  };

  // close the edit card view
  const handleHideEditView = (id) => {
    setShowViewEditCard(false);
    if (id === toBeEdited) {
      setToBeEdited(null);
      setSavedData({ description: null });
    }
  };
  // update description function
  const updateItems = async (idObj) => {
    let deps = tableData?.departmentRoles?.map((el) => {
      if (el.id === idObj) {
        return {
          ...el,
          description: savedData.description,
        };
      }
      return { ...el };
    });

    if (!!departmentId) {
      await API.put("departments", `/departments/${departmentId}`, {
        body: { departmentRoles: deps },
      }).then(() => {
        navigate(`/konfigurimet/kompania/rolet/${departmentId}`);
        setToBeEdited(null);
        setSavedData({ description: null });
      });
    } else {
      message.error("Something went wrong!");
      console.log("No departmentId: ", departmentId);
    }
  };

  return (
    <LoadableComp loading={loading}>
      <div className="departmentRolesContainer">
        <div className="departmentRoles">
          {tableData?.departmentRoles?.length > 0 ? (
            (tableData?.departmentRoles || []).map((role) => (
              <div className="roleCard">
                <Form form={form}>
                  <Card
                    key={role.employeeId}
                    title={
                      <div className="cardHeaderTitle">
                        <div className="name">
                          <MenagementIcon />
                          {role.name}
                        </div>
                        {toBeEdited === role?.id ? (
                          <div
                            className={`icon ${
                              toBeEdited === role?.id && "editMode"
                            }`}
                            onClick={() => handleHideEditView(role.id)}
                          >
                            <XIcon width={15} height={15} />
                          </div>
                        ) : (
                          <div
                            className="icon"
                            onClick={() => handleShowEditView(role.id)}
                          >
                            <EditIcon width={15} height={15} />
                          </div>
                        )}
                      </div>
                    }
                    bordered={false}
                  >
                    <div className="supervisor">
                      <span>
                        Supervizori: {tableData?.departmentManagerName}
                      </span>
                    </div>
                    <h1>Përshkrimi i pozicionit:</h1>
                    {toBeEdited === role?.id ? (
                      <div className="modeContainer">
                        <RichTextEditor
                          value={
                            !savedData.description
                              ? role.description
                              : savedData.description
                          }
                          className="richTextEditor"
                          onChange={(e) => handleChange("description", e)}
                        />
                        <MondayButton
                          disabled={
                            savedData.description !== null ? false : true
                          }
                          className="mondayButtonGreen"
                          onClick={() => updateItems(role.id)}
                        >
                          Ruaj Ndryshimet
                        </MondayButton>
                      </div>
                    ) : (
                      <div className="modeContainer">
                        <div className="description" style={{ height: 170 }}>
                          {!!role.description ? (
                            htmlParser(role.description)
                          ) : (
                            <div className="fallbackText">
                              Nuk ka përshkrim për këtë pozicion.
                            </div>
                          )}
                        </div>
                        <h3>
                          <Badge status="default" />
                          &nbsp;
                          {
                            tableData?.departmentEmployees?.filter(
                              (el) => el?.employeeRoleName === role?.name
                            )?.length
                          }
                          &nbsp;punonjës aktivë
                        </h3>
                        <MondayButton
                          key={role.employeeId}
                          onClick={() => openModal(role.name)}
                          className="mondayButtonGreen"
                        >
                          Shiko punonjësit
                        </MondayButton>
                      </div>
                    )}
                  </Card>
                </Form>
              </div>
            ))
          ) : (
            <div className="fallbackText">
              Nuk ka asnjë punonjës për këtë rol
            </div>
          )}
        </div>
        <div className="departmentRolesFooter">
          <Link to="/konfigurimet/kompania/pozicionet">
            <MondayButton className="mondayButtonGreen">
              Kthehu pas
            </MondayButton>
          </Link>
        </div>
      </div>
      <GeneralViewModal
        {...{
          visible,
          setVisible,
          tableDefs,
        }}
        title={`Lista e punonjësve për rolin ${roleName}`}
        dataList={users?.allUsers?.Items}
        subDataList={content}
        pagination={7}
      />
    </LoadableComp>
  );
}

export default KompaniaRolet;

const tableDefs = [
  {
    header: "Punonjësi",
    key: "concatKeys",
    keys: ["employeeFirstName", "employeeLastName"],
    badge: true,
  },
  { header: "Data e punësimit", key: "employeeHireDate" },
];
