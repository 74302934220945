import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import { PagatIcon } from "../../../assets/icons";
import { dayCalculateFactory } from "../../../components/Pagat/PaySub/employeeCalc/dayCalculateFactory";
import { requestAllPages, requestData } from "../../../helpers/API/RequestFactory";
import { clockings } from "../../../store/actions";
import { last } from "lodash";
/**
 * Retrieves all the valid, (not Ongoin shifts)
 * for specific employee and sorts them according to clockIn timestamp (/ms)
 * @param {String} employeeId the identifier for employee
 * @returns {Array} Array of Objects if successful & nonEmpty or empty Array if error occured
 */
export const getValidShifts = async (employeeId) => {
	return await requestData("clocking").then(
		(success) =>
			success
				.filter((el) => el.employeeId === employeeId && el.clockOutDate !== null)
				.sort((a, b) => a.clockInDate - b.clockInDate),
		(err) => console.error("error", err)
	);
};

// Without Null Filter
export const getShifts = async (employeeId) => {
	return await requestData("clocking").then(
		(success) =>
			success
				.filter((el) => el.employeeId === employeeId)
				.sort((a, b) => a.createdAt - b.createdAt),
		(err) => console.error("error", err)
	);
	// return await API.get("clocking", "/clocking").then(
	// 	(success) =>
	// 		success
	// 			.filter((el) => el.employeeId === employeeId)
	// 			.sort((a, b) => a.createdAt - b.createdAt),
	// 	(err) => console.error("error", err)
	// );
};

/**
 * From all shifts, sorted for a specific Employee ID, it returns the last ongoing shift
 * @param {Array} Array of objects filteredShifts
 * @returns {Object} with shift values for clockOutDate === null
 */

export const getLastOnGoingShift = (filteredShifts) => {
	if (filteredShifts.length > 0 && filteredShifts !== null) {
		if (filteredShifts[filteredShifts.length - 1].clockOutDate === null) {
			return filteredShifts[filteredShifts.length - 1];
		} else {
			return null;
		}
	}
};

/**
 * Starts the shift for specific user with clockOutDate = null
 * @param {Object} shift initial Shift Values
 * @param {moment} value of clockIn
 * @returns {Promise} with sucess/error status
 */
export const startClockIn = async (shift, val) => {
  if ((await validateNotPrevClockIn(val, shift.employeeId)) !== false) {
    let fixedTime = dayjsTZ(val);
    fixedTime = fixedTime
      .set("hour", fixedTime.get("hour"))
      .set("minute", fixedTime.get("minute"))
      .set("second", "00");

    return await API.post("clocking", `/clocking`, {
      body: { ...shift, clockInDate: dayjsTZ(fixedTime).valueOf() },
    }).then(
      (res) => console.log("Clock In", res),
      (err) => console.log("error", err)
    );
  } else {
    return Promise.reject("Perplasje oraresh!");
  }
};

/**
 * Ends the specific shift, which ID attribute is given on call,
 * And updates the clockOutDate with current timestamp in milliseconds
 * @param {String} lastShift the ID of the shift which will be updated/finished
 * @returns {Promise} with sucess/error status
 */
export const endShift = async (lastShift, shift, time) => {
	console.log("lastShift", lastShift, "shift", shift, "time", time);

	let fixedTime = dayjsTZ(time);
	fixedTime = fixedTime
    .set("hour", fixedTime.get("hour"))
    .set("minute", fixedTime.get("minute"))
    .set("second", "00");

	let amounts = await dayCalculateFactory({
		...shift,
		clockInDate: lastShift?.clockInDate,
		clockOutDate: dayjsTZ(fixedTime).valueOf(),
	});
	return await API.put("clocking", `/clocking/${lastShift?.clockingId}`, {
		body: {
			...shift,
			clockingLogs: lastShift?.clockingLogs || [],
			clockOutDate: dayjsTZ(fixedTime).valueOf(),
			totalities: amounts || {},
		},
	}).then(
		(res) => console.log("Success:", res),
		(err) => console.error("Error:", err)
	);
};

export const validateNotPrevClockIn = async (timeInstance, emplId) => {
	let val = dayjsTZ(timeInstance).valueOf();
	let shifts = await getShifts(emplId);

	if (shifts.length > 0) {
		let error = shifts.filter((el) => el.clockOutDate > val);
		if (error.length > 0) {
			message.error("Turni juaj nuk mund te procesohet.");
			return false;
		} else {
			return true;
		}
	}
};

export const endDoubleShift = async (lastShift, shift, time) => {
	// message.success("Double shift");
	let endOfFirst = dayjsTZ(lastShift?.clockInDate);

	endOfFirst = endOfFirst
    .set("hour", "23")
    .set("minute", "59")
    .set("second", "00");

	// Custom For shifts that rely on after midnight
	let startOfSecond = dayjsTZ(lastShift?.clockInDate);
	startOfSecond = startOfSecond
    .set("hour", "00")
    .set("minute", "00")
    .set("second", "00");

	let endOfShift = dayjsTZ(lastShift?.clockInDate);
	endOfShift = endOfShift
    .set("hour", dayjsTZ(time).get("hour"))
    .set("minute", dayjsTZ(time).get("minute") + 1)
    .set("second", dayjsTZ(time).get("second"));

	let amounts = await dayCalculateFactory({
		...shift,
		clockInDate: dayjsTZ(lastShift?.clockInDate).valueOf(),
		clockOutDate: dayjsTZ(endOfFirst).valueOf(),
	});
	// console.log("lastShift", lastShift, "shift", shift, "time", time);
	return await API.put("clocking", `/clocking/${lastShift?.clockingId}`, {
		body: {
			...shift,
			clockOutDate: dayjsTZ(endOfFirst).valueOf(),
			totalities: amounts || {},
			slicedShift: true,
		},
	})
		.then(async () => {
			let amounts2 = await dayCalculateFactory({
				...shift,
				clockInDate: dayjsTZ(startOfSecond).valueOf(),
				clockOutDate: dayjsTZ(endOfShift).valueOf(),
			});
			return await API.post("clocking", `/clocking`, {
				body: {
					...lastShift,
					clockInNote: "",
					totalities: amounts2 || {},
					clockInDate: dayjsTZ(startOfSecond).valueOf(),
					clockOutDate: dayjsTZ(endOfShift).valueOf(),
					clockingCategory: "midnightReset",
				},
			});
		})
		.then(() => message.success("U krijua me sukses!"));
};
