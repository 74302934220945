import {
  Badge,
  ColorPicker,
  Form,
  Input,
  message,
  Select,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import { API } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { driveApi } from "../../DriveRequest";
import GoogleDriveUpload from "./GoogleDriveUpload";
import { CloseIcon } from "../icons";
import "./Profile.scss";
import { useAuth } from "../../../authentication/authHelpers";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";
import { toSentenceCase } from "../../../utils";
import rgbToHex from "../../../utils/rbgToHex";

const Profile = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { updateAuthCtx } = useAuth();

  const { activeEmployees } = useSelector((state) => state.employeesList);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { users } = useSelector((state) => state.usersList);
  const driveRequest = driveApi({ accessToken });

  const [userDBInfo, setUserDBInfo] = useState({});
  const [userPhoto, setUserPhoto] = useState("");
  const [fileId, setFileId] = useState("");
  const [spinning, setSpinning] = useState(true);
  const [saving, setSaving] = useState(false);

  let ls = JSON.parse(sessionStorage.getItem("agThemePreference"));
  console.log(ls);

  const employeeObj = useMemo(() => {
    if (!!Object.keys(users || {}).length && !!activeEmployees) {
      return (
        (activeEmployees || []).find(
          (el) => el?.userSub === (users || {}).userSub
        ) || {}
      );
    }
  }, [users, activeEmployees]);

  const onPickerSuccess = async (params) => {
    const photoId = params?.uploadedFiles?.[0]?.id || "";
    if (!!photoId) {
      setFileId(photoId);
      await driveRequest
        .getImageSrc(photoId)
        .then((image) => setUserPhoto(image?.src));
    }
  };

  //region >validatePin()
  const validateNewPin = (newVal) => {
    let notSafe = [
      "123456",
      "password",
      "12345678",
      "qwerty",
      "123456789",
      "1234567890",
      "12345",
      "1234",
      "1111",
      "0000",
    ];
    if (notSafe.includes(newVal)) {
      message.warning({ key: "notSafe", content: "This pin is not safe!" });
    }
  };
  //region ON SAVE
  const onSave = async () => {
    setSaving(true);
    form
      .validateFields()
      .then(async (res) => {
        const { metaColor } = res?.accentColor || {};
        let accentColor = res?.accentColor;

        if (!!metaColor) {
          const { r, g, b } = metaColor || {};
          accentColor = rgbToHex(r, g, b);
        }

        const { theme, mode } = res;
        sessionStorage.setItem(
          "agThemePreference",
          JSON.stringify({ theme, mode, accentColor })
        );

        let promises = [];
        if (!userPhoto) {
          promises.push(
            driveRequest.deleteDriveItem(employeeObj?.googleDriveFileId)
          );
          promises.push(
            API.put("employees", `/employees/${employeeObj?.employeeId}`, {
              body: { googleDriveFileId: "" },
            })
          );
        } else if (!!fileId) {
          console.log("here", employeeObj?.employeeId);
          promises.push(
            API.put("employees", `/employees/${employeeObj?.employeeId}`, {
              body: { googleDriveFileId: fileId },
            })
          );
        }
        await Promise.allSettled(promises);

        await API.put("users", `/users/${userDBInfo?.identityId}`, {
          body: {
            pinNumber: res?.pinNumber,
            idleTimeout: res?.idleTimeout,
            auth2Fa: {
              ...userDBInfo?.auth2Fa,
              is2FaActive: res?.is2FaActive,
            },
          },
        })
          .then(() => {
            message.success({
              key: "success",
              content: "U ndryshua me sukses!",
            });
            updateAuthCtx((prev) => ({
              ...prev,
              auth2Fa: {
                ...(prev.auth2Fa || {}),
                is2FaActive: res?.is2FaActive,
              },
            }));
            setSaving(false);
          })
          .catch(() => {
            setSaving(false);
            message.error({ key: "error", content: "Ndodhi nje gabim!" });
          });
      })
      .catch((err) => {
        setSaving(false);
        console.log("Error validating fields: ", err);
      });
  };

  //region >getImgDrive()
  const getImageFromDrive = (fileId) => {
    if (!!fileId) {
      driveRequest
        .getImageSrc(fileId)
        .then((image) => {
          setUserPhoto(image?.src);
        })
        .catch((err) => console.log(err))
        .finally(() => setSpinning(false));
    } else {
      setInterval(() => {
        setSpinning(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!!users) {
      // let timeout;
      // if (users?.idleTimeout >= 3600000) {
      //   timeout =
      //     users?.idleTimeout / 3600000 +
      //     ` hour${users?.idleTimeout >= 7200000 && "s"}`;
      // }
      // if (users?.idleTimeout < 3600000) {
      //   timeout = users?.idleTimeout / 60000 + " minutes";
      // }

      const defaultTheme = {
        theme: "alpine",
        mode: "light",
        accentColor: "#1d3445",
      };

      Object.entries(ls || defaultTheme).forEach(([key, value]) => {
        form.setFieldValue(key, value);
      });

      form.setFieldValue("pinNumber", users?.pinNumber);
      form.setFieldValue("idleTimeout", users?.idleTimeout);
      form.setFieldValue("is2FaActive", users?.auth2Fa?.is2FaActive);

      setUserDBInfo(users);
      getImageFromDrive(employeeObj?.googleDriveFileId);
    }
  }, [users, employeeObj]);
  const rules = [
    {
      required: true,
      message: "Kjo fushë nevojitet!",
    },
  ];

  //region RETURN
  return (
    <>
      <div style={{ width: "100%", height: "calc(100% - 35px)" }}>
        <div className="userPersonalInfo">
          <Spin spinning={spinning}>
            {!!userPhoto && (
              <div className="profile-img-div">
                <img className="profile-img" src={userPhoto || ""} />
                <div
                  className="remove-profile-photo"
                  onClick={() => {
                    setUserPhoto("");
                  }}
                >
                  <CloseIcon />
                </div>
              </div>
            )}
            {!spinning && !userPhoto && (
              <div className="userLettersName">
                <Avatar
                  name={users?.given_name + " " + users?.family_name}
                  size="150"
                  round={true}
                />
              </div>
            )}
          </Spin>
          <div className="userActions">
            <h2>{users?.given_name + " " + users?.family_name}</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            ></div>
            <GoogleDriveUpload
              employeeObj={employeeObj}
              accessToken={accessToken}
              // currentEmpl={currentEmpl}
              onPickerSuccess={onPickerSuccess}
              adminUpload={false}
              uploadImages={true}
            />
          </div>
          {/* !!! time to make this shit work  */}
          <Form form={form}>
            <div className="profileFormItem">
              <span>Pin Number:</span>
              <Form.Item rules={rules} name={"pinNumber"}>
                <Input.Password
                  type="password"
                  className="pasw-prof-input"
                  onChange={(e) => validateNewPin(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="profileFormItem">
              <span>Idle Time:</span>
              <Form.Item rules={rules} name={"idleTimeout"}>
                <Select>
                  {idleOptions?.map(({ value, label }) => (
                    <Option value={value}>{label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="profileFormItem">
              <span>AG Grid theme:</span>
              <div className="agThemeDropdowns">
                <Tooltip placement="top" title="Main theme">
                  <Form.Item rules={rules} name={"theme"}>
                    <Select>
                      {themeOptions?.map((theme) => (
                        <Option value={theme}>{toSentenceCase(theme)}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Tooltip>
                <Tooltip placement="top" title="Theme mode">
                  <Form.Item rules={rules} name={"mode"}>
                    <Select>
                      {modeOptions?.map((mode) => (
                        <Option value={mode}>{toSentenceCase(mode)}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Tooltip>
                <Tooltip placement="top" title="Accent color">
                  <Form.Item rules={rules} name={"accentColor"}>
                    <ColorPicker />
                  </Form.Item>
                </Tooltip>
                <p>Tip: Light accents suit dark themes better :&#41;</p>
              </div>
            </div>
            <div className="profileFormItem">
              <span>Enable 2FA</span>
              <Form.Item name={"is2FaActive"}>
                <Switch />
              </Form.Item>
            </div>
            <MondayButton
              className="mondayButtonGreen saveBtn"
              onClick={onSave}
              spin={saving}
            >
              Ruaj Ndryshimet
            </MondayButton>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Profile;

const idleOptions = [
  { value: 300000, label: "5 minutes" },
  { value: 600000, label: "10 minutes" },
  { value: 3600000, label: "1 hour" },
  { value: 7200000, label: "2 hours" },
  { value: 14400000, label: "4 hours" },
];
const themeOptions = ["quartz", "balham", "material", "alpine"];
const modeOptions = ["light", "dark"];
