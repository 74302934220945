import { Badge, Popconfirm } from "antd";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  DocumentationWarningIcon,
  GoBack,
  HeaderUser,
  IconDetyrat,
  IconDil,
  Iconkonfigurimet,
  IconNojftimet,
  LogoFlex,
  IntervistatIcon,
  KonfigurimetIcon,
} from "../../../assets/icons";
import HamburgerMenu from "../../../utils/MobileComponents/HamburgerMenu";
import DynamicHeaderTitle from "../DynamicHeaderTitle";
import { useAuth } from "../../../authentication/authHelpers";

function MobileHeader({
  location,
  handleLogOut,
  setRequestsActive,
  requestsActive,
  pendingRequest,
  setNotifsActive,
  notifsActive,
  count,
  userPhoto,
  problemsActive,
  setProblemsActive,
  problematikatCount,
  setInterviewActive,
  interviewActive,
  candidatesCount,
}) {
  const auth = useAuth();

  const [isConfig, isKryefaqja] = useMemo(() => {
    return [
      location.pathname.startsWith("/konfigurimet"),
      location.pathname.startsWith("/kryefaqja"),
    ];
  }, [location.pathname]);

  const renderPfp = useMemo(
    () =>
      userPhoto?.length > 0 && userPhoto !== null ? (
        <img
          src={userPhoto}
          alt="Flex"
          width={32}
          height={32}
          style={{ borderRadius: 5 }}
        />
      ) : (
        <HeaderUser />
      ),
    [userPhoto]
  );

  return (
    <div
      className="header-app"
      style={{
        width: "100%",
        background: isConfig ? "#b31312" : "#090a0f",
      }}
    >
      <HamburgerMenu data={auth} />
      {isConfig ? <DynamicHeaderTitle /> : null}

      <div className="icons">
        {isKryefaqja ? (
          <Link to="/konfigurimet/kompania/profili">{renderPfp}</Link>
        ) : null}

        {isConfig ? (
          <Link to="/kryefaqja" style={{ margin: 0, padding: 0 }}>
            <GoBack fill="#fff" />
          </Link>
        ) : null}

        {!isKryefaqja && !isConfig ? (
          <>
            <Link to="/konfigurimet/kompania/profili">{renderPfp}</Link>
            <Link to="/kryefaqja" style={{ margin: 0, padding: 0 }}>
              <GoBack fill="#fff" />
            </Link>
          </>
        ) : null}

        {!isConfig ? (
          <>
            {auth?.userAccess[0]?.userRole === "Admin" ||
            auth?.userAccess[0]?.userRole === "Super Admin" ? (
              <div onClick={() => setRequestsActive(!requestsActive)}>
                <Badge
                  className="notification-badge"
                  count={pendingRequest?.length}
                >
                  <IconDetyrat />
                </Badge>
              </div>
            ) : null}{" "}
            {auth?.userAccess[0]?.userRole === "Admin" ||
            auth?.userAccess[0]?.userRole === "Super Admin" ? (
              <div onClick={() => setInterviewActive(!interviewActive)}>
                <Badge className="notification-badge" count={candidatesCount}>
                  <IntervistatIcon fill="white" />
                </Badge>
              </div>
            ) : null}
            {auth?.userAccess[0]?.userRole === "Admin" ||
            auth?.userAccess[0]?.userRole === "Super Admin" ? (
              <div onClick={() => setProblemsActive(!problemsActive)}>
                <Badge
                  className="notification-badge"
                  count={problematikatCount}
                >
                  <DocumentationWarningIcon fill={"white"} />
                </Badge>
              </div>
            ) : null}
            <div onClick={() => setNotifsActive(!notifsActive)}>
              <Badge className="notification-badge" count={count?.length}>
                <IconNojftimet />
              </Badge>
            </div>
          </>
        ) : null}

        <Popconfirm
          placement="left"
          title={"A jeni të sigurt që dëshironi të dilni nga programi?"}
          onConfirm={handleLogOut}
          okText="Po"
          cancelText="Jo"
          overlayClassName="popup-logout-modal"
          icon={<LogoFlex width={45} height={45} />}
        >
          <IconDil />
        </Popconfirm>
      </div>
    </div>
  );
}

export default MobileHeader;
