import { Button } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import React from "react";
import MobileHtmlTabel from "../../../utils/MobileComponents/MobileHtmlTabel";

function MobilePunojsitView({ setVisible, activeEmployees }) {
	return (
		<div className="base-mobile-div">
			<div className="custom-header-div">
				{" "}
				<Button className="btn-header" onClick={() => setVisible(true)}>
					Shto Punonjës
				</Button>
			</div>
			<MobileHtmlTabel
				header={["Emri", "Mbiemri", "Departamenti", "Roli", "Data Punësimit"]}
				tableData={activeEmployees?.map?.(
					({
						employeeFirstName,
						employeeLastName,
						employeeDepartmentName,
						employeeRoleName,
						employeeHireDate,
						employeeId,
					}) => ({
						employeeFirstName,
						employeeLastName,
						statusHeaderKey: employeeDepartmentName,
						employeeRoleName,
						employeeHireDate: dayjsTZ(employeeHireDate).format("DD/MM/YYYY"),
						employeeId,
					})
				)}
				isLink={true}
				isHeader={true}
			/>
		</div>
	);
}

export default MobilePunojsitView;
