import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Modal, Select, Tooltip, DatePicker } from "antd";
import { IconFilter, WarningIcon } from "../../../../assets/icons/index";
import { CaretDownFilled } from "@ant-design/icons";
import "./FilterAgGrid.scss";
import { ClearIcon, IconCalendar, NextIcon } from "../../../dashboard/assets";
import moment from "moment";
import { onDateFilter } from "../../../Documentation/utils/onFilter";
import MondayButton from "../../MondayButton/MondayButton";
import PropTypes from "prop-types";

/**
 * @typedef filterKey
 * @property {string} key AgGrid's key to match when filtering
 * @property {string} label dropdown's label & placeholder
 * @property {'string' | 'date-range'} type datatype of column to be filtered (may affect what filter component to render)
 */

/**
 * @typedef props
 * @property {Object} gridApi
 * @property {function} clearFilters provide clearFilters function to reset all filters
 * @property {Array} tableData AgGrid's data to use the filter on
 * @property {Array<Object>} filterKeys AgGrid's data to use the filter on
 * @property {Boolean} filterActive state that specifies outer filters that affect tableData
 * @property {Object<Array>} replaceable provide this new set of keys if AgGrid's data for that key is manipulated
 */
/**
 *
 * @param {props} props
 * @returns
 */

const FilterAgGrid = ({
  gridApi,
  clearFilters = () => {},
  tableData,
  filterKeys,
  filterActive,
  replaceable,
  className,
  style,
}) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [options, setOptions] = useState(null);
  const [open, setOpen] = useState(false);

  const setFilterModel = async ({
    e,
    key,
    type,
    filterType,
    dateFrom,
    dateTo,
  }) => {
    const filterObj = {
      filterType,
      dateFrom,
      dateTo,
      type,
    };
    await gridApi.getColumnFilterInstance(key).then((comp) => {
      if (filterType !== "date") filterObj.filter = `${e}`;
      comp.setModel(filterObj);
    });
    gridApi.onFilterChanged();
  };

  const generalFilter = (e) => {
    console.log(e);
    tableData?.forEach((el) => {
      for (const { key } of filterKeys) {
        if (!!replaceable && !!replaceable[key]?.length) {
          for (const replace of replaceable[key]) {
            for (const [_, value] of Object.entries(replace)) {
              if (value === e) {
                setFilterModel({ e, key, type: "contains" });
                break;
              }
            }
          }
          if (el?.[key] === e) {
            setFilterModel({ e, key, type: "contains" });
          }
        } else {
          if (el?.[key] === e) {
            setFilterModel({ e, key, type: "contains" });
          }
        }
        if (Array.isArray(e)) {
          setFilterModel({
            key,
            filterType: "date",
            type: "inRange",
            dateFrom: e[0].format("YYYY-MM-DD"),
            dateTo: e[1].format("YYYY-MM-DD"),
          });
        }
      }
    });
  };

  function closeFilter() {
    clearFilters();
    setOpen(false);
  }

  useEffect(() => {
    let options = {};
    filterKeys?.forEach(({ key, type }) => {
      if (type === "string") {
        options[key] = [];
        tableData?.forEach((el) => {
          if (!!replaceable && !!replaceable[key]?.length) {
            replaceable[key]?.forEach((replace) => {
              for (const [_, value] of Object.entries(replace)) {
                if (!!value && options[key]?.indexOf(value) === -1)
                  options[key].push(value);
              }
            });
          } else {
            if (!!el?.[key] && options[key]?.indexOf(el?.[key]) === -1)
              options[key].push(el?.[key]);
          }
        });
      }
    });
    setOptions(options);
  }, [tableData, filterKeys]);

  return (
    <>
      <div className={`headerIcon ${className}`} style={style}>
        <Tooltip
          placement="top"
          overlayClassName="global-icon-tooltip"
          title={"Filter i avancuar"}
          style={{ opacity: 1.0 }}
        >
          <IconFilter
            width={18}
            height={18}
            fill={style?.fill || "#323338"}
            onClick={() => setOpen(true)}
          />{" "}
        </Tooltip>
      </div>
      <Modal
        className="global-filter-modal darkHeader"
        data-testid="filter-ag-grid"
        title={
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <span>Kërkim i Avancuar</span>
            {typeof filterActive == "boolean" && !filterActive && (
              <Tooltip title={"Po kërkon vetëm punonjësit aktivë!"}>
                <WarningIcon fill="#fff" width={14} height={14} />
              </Tooltip>
            )}
          </div>
        }
        destroyOnClose
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        // destroyOnClose={true}
        footer={[
          <MondayButton onClick={closeFilter} className="mondayButtonRed">
            Mbyll
          </MondayButton>,
          <MondayButton
            onClick={() => setOpen(false)}
            className="mondayButtonGreen"
          >
            Kërko
          </MondayButton>,
        ]}
      >
        <Row gutter={10} style={{ width: "100%" }}>
          {filterKeys?.map(({ key, label, type }) => (
            <Col className="coln-2" span={6}>
              <h3>{label}</h3>
              {type === "string" ? (
                <Select
                  showSearch
                  variant={false}
                  className="filter-dropdown"
                  data-testid="filter-dropdown"
                  suffixIcon={<CaretDownFilled />}
                  placeholder={label}
                  onChange={generalFilter}
                >
                  {options?.[key]?.map((el) => (
                    <Option key={el} value={el} data-testid={`option-${el}`}>
                      {el}
                    </Option>
                  ))}
                </Select>
              ) : type === "date-range" ? (
                <RangePicker
                  onChange={generalFilter}
                  format={"DD/MM/YYYY"}
                  allowClear={{ clearIcon: <ClearIcon /> }}
                  suffixIcon={<IconCalendar />}
                  separator={<NextIcon />}
                />
              ) : null}
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default FilterAgGrid;

FilterAgGrid.propTypes = {
  gridApi: PropTypes.object.isRequired,
  clearFilters: PropTypes.func,
  tableData: PropTypes.array.isRequired,
  filterKeys: PropTypes.object.isRequired,
  filterActive: PropTypes.bool,
  replaceable: PropTypes.object,
};
