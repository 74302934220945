import React, {
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useCallback,
} from "react";
import "./AddEmployeeModal/AddEmployeeModal.scss";
import "./punojsit scss/punonjesit.scss";
import {
  IconPunonjesit,
  PunojsitAcitvIcon,
  PunojsitLarguarIcon,
  ShkarkuarIcon,
} from "../../assets/icons";
import { Card, Select, Form } from "antd";
import AgGridComponent from "../AG-grid/AgGridComponent";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { useNavigate } from "react-router-dom";
import { GeneralInformationsContext } from "./AddEmployeeModal/context";
import { useSelector } from "react-redux";
import { useAuth } from "../../authentication/authHelpers";
import { useLocation } from "react-router";
import { uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MobilePunojsitView from "./components/MobilePunojsitView";
import AddEmployeModal from "./components/AddEmployeModal";
import CustomAgPaginator from "./EmployeeView/CustomAgPaginator/CustomAgPaginator";
import AgGridHeader from "../commonComponents/AgGridHeader/AgGridHeader";
import MondayButton from "../commonComponents/MondayButton/MondayButton";
import CancelAddEmployeeModalPopover from "./AddEmployeeModal/Popover";
import getAgThemePreference from "../../utils/getAgThemePreference";
import colorShade from "../../utils/colorShade";

// * @EneaXharau -  Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

const { theme, accentColor, style } = getAgThemePreference();

const EXPORT_COLS = [
  { en: "employeeFirstName", al: "Emri" },
  { en: "employeeLastName", al: "Mbiemri" },
  { en: "employeeDepartmentName", al: "Departmenti" },
  { en: "employeeRoleName", al: "Roli" },
  { en: "employeeEmailWork", al: "Emaili punës" },
  { en: "employeeHireDate", al: "Data Punësimit" },
];
const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: [
        { key: "employeeFullName", label: "Punonjesi", type: "string" },
        {
          key: "employeeDepartmentName",
          label: "Departamenti",
          type: "string",
        },
        { key: "employeeRoleName", label: "Roli", type: "string" },
      ],
    },
    delete: { endpoint: "employees" },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
  dropdowns: [
    {
      keyName: "employeeDepartmentName",
      placeholder: "Të gjithë",
      icon: (
        <IconPunonjesit width={15} fill={style?.["--ag-foreground-color"]} />
      ),
      style: { color: style?.["--ag-foreground-color"] },
      
    },
    {
      keyName: "employeeTeam",
      placeholder: "Filtro Branch",
      icon: (
        <IconPunonjesit width={15} fill={style?.["--ag-foreground-color"]} />
      ),
      style: { color: style?.["--ag-foreground-color"] },
    },
  ],
  switch: {},
};

const Punonjesit = forwardRef(() => {
  const dispatch = useDispatch();
  // columns displayed in the grid
  //region COL DEFS
  const columnDefs = [
    {
      headerName: "ID e punonjesit",
      field: "employeeId",
      hide: true,
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      // filter: "agTextColumnFilter",
      // valueGetter: "node.rowIndex + 1",
      // resizable: true,
      // flex: 1.2,
    },
    {
      headerName: "Nr.",
      // field: "employeeId",
      checkboxSelection: true,
      // headerCheckboxSelection: true,
      filter: "agTextColumnFilter",
      valueGetter: "node.rowIndex + 1",
      resizable: true,
      flex: 1.2,
    },
    {
      headerName: "Punonjësi",
      field: "employeeFullName",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
      valueGetter: function (params) {
        return (
          params?.data?.employeeFirstName + " " + params?.data?.employeeLastName
        );
      },
      cellRenderer: function (params) {
        try {
          return (
            <div
              className="navigate-row"
              onClick={() => {
                navigate(`/punonjesit/specifikat/${params?.data?.employeeId}`);
              }}
              style={
                theme.includes("dark")
                  ? { color: "#FFF" }
                  : { color: colorShade(accentColor, 0.25) }
              }
            >
              {params?.data?.employeeFirstName +
                " " +
                params?.data?.employeeLastName}
            </div>
          );
        } catch (err) {
          if (params.value) {
            return params.value;
          } else {
            return null;
          }
        }
      },
    },
    {
      headerName: "Departamenti",
      field: "employeeDepartmentName",
      filter: "agTextColumnFilter",
      flex: 3,
      resizable: true,
    },
    {
      headerName: "Dega",
      field: "employeeTeam",
      filter: "agTextColumnFilter",
      flex: 3,
      resizable: true,
    },
    {
      headerName: "Roli",
      field: "employeeRoleName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
    },
    {
      headerName: "Email punës",
      field: "employeeEmailWork",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
    },
    {
      headerName: "Data Punësimit",
      field: "employeeHireDate",
      filter: "agTextColumnFilter",
      resizable: true,
      flex: 2,
      cellRenderer: function (params) {
        if (params?.value !== undefined) {
          return dayjsTZ(params?.value).format("DD/MM/YYYY");
        } else {
          return <div>Pa përcaktuar</div>;
        }
      },
    },
    {
      headerName: "Lloj punsimit",
      field: "employeeTypeOfEmployment",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },
    {
      headerName: "createdAt",
      field: "createdAt",
      flex: 2.5,
      sort: "desc",
      filterParams: { apply: true, newRowsAction: "keep" },
      resizable: true,
      hide: true,
    },
    {
      headerName: "Emri",
      field: "employeeFirstName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },
    {
      headerName: "Mbiemri",
      field: "employeeLastName",
      filter: "agTextColumnFilter",
      flex: 2.5,
      resizable: true,
      hide: true,
    },

    { headerName: "Status", field: "employeeStatus", hide: true },
  ];
  //region STATES
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAuth();
  const [form] = Form.useForm();
  // const { Option } = Select;
  // get  programFields , employees , users from redoux
  const { programFields } = useSelector((state) => state.programFields);
  const { employees } = useSelector((state) => state.employeesList);
  // const { activeEmployees } = useSelector((state) => state.employeesList);
  const { users } = useSelector((state) => state.usersList);
  //* states to show employeeModal
  const [visible, setVisible] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  //selected value from department dropdown
  // const [selectedBranch, setSelectedBranch] = useState(null);
  // store the searched value return string
  // const [searchInput, setSearchInput] = useState(null);
  //store card statuses return array
  // store the state from location return string
  // const [locationState, setLocationState] = useState(location?.state);
  //* state for switching only to activ employees
  const [showOnlyActivEmployees, setShowOnlyActivEmployees] = useState(false);
  //* state that will show only the active employees when we open punonjesit for the first time
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [findEmployees, setFindEmployees] = useState({
    active: {},
    larguar: {},
    shkarkuar: {},
  });

  //* find status field in programfields tabel

  const cardStatus = useMemo(() => {
    const filterData = programFields?.find(
      (data) => data?.fieldId === "ea2e2537-0b6a-4292-aca8-62eafecbfad1"
    );
    let values = filterData?.fieldOptions.filter(
      (item) => item.name !== "Logs"
    );
    return { ...filterData, fieldOptions: values };
  }, [programFields]);

  //* make the grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    // setGridApi(params.api);
  };

  /*
   * * @EneaXharau - Data fetching from database
   * * for better communication in sockets
   */
  const getEmployees = async () => {
    await API.get("employees", "/employees").then((r) => {
      const data = r.map((el) => ({
        ...el,
        employeeFullName: `${el?.employeeFirstName} ${el?.employeeLastName}`,
      }));
      setTableData(data);
      setActiveEmployees(data?.filter((e) => e?.employeeStatus === "Aktiv"));
    });
  };
  // const deleteItems = () => {
  //   const selectedNodes = gridApi.getSelectedNodes();
  //   let selectedEmployeeId = selectedNodes.map((node) => node.data.employeeId);
  //   let selectedData = selectedNodes.map((node) => node.data);
  //   gridApi.applyTransaction({
  //     remove: selectedData,
  //   });

  //   API.del("employees", `/employees/${selectedEmployeeId[0]}`);
  // };

  let rowData = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowData?.push(node?.data);
  });

  //! ag grid global search
  // const onFilterTextChange = (e) => {
  //   gridApi.setGridOption("quickFilterText", e.target.value);
  //   setSearchInput(e.target.value);
  // };

  // //! clear THE AG GRID  filter
  const clearFilters = () => {
    // setSearchInput(null);
    // setLocationState(null);
  };

  //* dropdown header select  filter
  // const onSelectChange = async (e, field) => {
  //   if (e !== undefined) {
  //     await gridApi.getColumnFilterInstance(field).then((comp) => {
  //       comp.setModel({
  //         type: "contains",
  //         filter: e,
  //       });
  //     });
  //     gridApi.onFilterChanged();
  //   } else {
  //     gridApi.destroyFilter(field);
  //   }
  // };

  const departamentFilterState = async (value) => {
    const locationStates = ["Kohe te plote", "Kohe te pjesshme"];
    const filterInstance = locationStates.includes(value)
      ? "employeeTypeOfEmployment"
      : "employeeDepartmentName";

    await gridApi?.getColumnFilterInstance(filterInstance)?.then((comp) => {
      comp?.setModel({
        type: "equals",
        filter: value,
      });
    });
    gridApi?.onFilterChanged();
  };

  // //* print fille
  // const componentRef = useRef("Perdoruesit-print");
  // console.log(componentRef.current);
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  //* check for duplicate employe department name , dropdown advanced filter
  const duplicateCheck = [];

  tableData &&
    tableData
      ?.map((data) => {
        if (duplicateCheck.includes(data.employeeDepartmentName)) return null;
        duplicateCheck.push(data.employeeDepartmentName);
        return data;
      })
      ?.filter((e) => e);

  // * @EneaXharau - Changed this from const -> useCallback for better performance
  const filterering = (typeName) => {
    return uniqBy(
      cardStatus?.fieldOptions?.map(({ name, color }) => ({
        name,
        color,
        length: tableData?.filter(
          ({ employeeStatus }) => employeeStatus === name
        ).length,
      })),
      "name"
    )
      ?.filter((x) => !!x?.name)
      ?.filter((e) => e?.name === typeName)[0];
  };

  const statusIcon = (status, fill) => {
    switch (status) {
      case "Aktiv":
        return (
          <PunojsitAcitvIcon width={90} height={90} fill={fill || "#1D3445"} />
        );
      case "Larguar":
        return (
          <PunojsitLarguarIcon
            width={90}
            height={90}
            fill={fill || "#1D3445"}
          />
        );
      case "Shkarkuar":
        return (
          <ShkarkuarIcon width={90} height={90} fill={fill || "#1D3445"} />
        );
      default:
        return (
          <PunojsitAcitvIcon width={90} height={90} fill={fill || "#1D3445"} />
        );
    }
  };

  const emplStatusFilter = async (e, field) => {
    await gridApi.getColumnFilterInstance("employeeStatus").then((comp) => {
      comp.setModel({
        type: "contains",
        filter: e,
      });
    });
    gridApi.onFilterChanged();
  };

  const onSwitch = () => {
    setShowOnlyActivEmployees(!showOnlyActivEmployees);
    return !showOnlyActivEmployees ? getEmployees() : emplStatusFilter("Aktiv");
  };

  const sortedEmployees = (data = []) =>
    data?.sort(
      (a, b) =>
        dayjsTZ(a?.employeeHireDate).valueOf() -
        dayjsTZ(b?.employeeHireDate).valueOf()
    );

  const getRowId = useCallback((param) => {
    return param?.data.employeeId;
  }, []);

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body, id } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "user_added_received":
  // 				{
  // 					if (
  // 						userData?.employeeId !== id &&
  // 						(userData?.userRole === "Admin" ||
  // 							userData?.userRole === "Super Admin" ||
  // 							userData?.userRole === "Supervisor")
  // 					)
  // 						dispatch(addEmployees(body));
  // 				}
  // 				break;
  // 			case "user_edit_received":
  // 				{
  // 					if (
  // 						userData?.employeeId !== id &&
  // 						(userData?.userRole === "Admin" ||
  // 							userData?.userRole === "Super Admin" ||
  // 							userData?.userRole === "Supervisor")
  // 					)
  // 						dispatch(editEmployee(body));
  // 				}
  // 				break;
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);

  // * @EneaXharau - useEffects moved before return, for esLint reasons
  useEffect(() => {
    if (!!location.state) {
      departamentFilterState(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    setFindEmployees({
      active: filterering("Aktiv"),
      larguar: filterering("Larguar"),
      shkarkuar: filterering("Shkarkuar"),
    });
  }, [employees, tableData, cardStatus]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  const themeStyle = useMemo(() => {
    if (theme.includes("dark")) {
      return {
        backgroundColor: colorShade(accentColor, 40),
        color: "#f1f7fb",
        fill: "#f1f7fb",
      };
    } else {
      return {};
    }
  }, []);

  //region RETURN
  return (
    <>
      <LoadableComp loading={tableData ? false : true}>
        <div className="globalPageWrapper">
          {isMobileView ? (
            <div className="punonjesitPage">
              <div className="punonjesit-cards">
                <Card
                  className="card-puno-prog"
                  onClick={() => emplStatusFilter(findEmployees?.active?.name)}
                  style={themeStyle}
                >
                  <div>
                    {statusIcon(findEmployees?.active?.name, themeStyle?.fill)}
                    <div
                      className="statusi-per-punojsit"
                      style={{ color: findEmployees?.active?.color }}
                    >
                      {findEmployees?.active?.length}
                      <br></br>
                      {findEmployees?.active?.name}
                    </div>
                  </div>
                </Card>
                {showOnlyActivEmployees && (
                  <>
                    <Card
                      className="card-puno-prog"
                      onClick={() =>
                        emplStatusFilter(findEmployees?.larguar?.name)
                      }
                      style={themeStyle}
                    >
                      <div>
                        {statusIcon(
                          findEmployees?.larguar?.name,
                          themeStyle?.fill
                        )}
                        <div
                          className="statusi-per-punojsit"
                          style={{ color: findEmployees?.larguar?.color }}
                        >
                          {findEmployees?.larguar?.length}
                          <br></br>
                          {findEmployees?.larguar?.name}
                        </div>
                      </div>
                    </Card>
                    <Card
                      className="card-puno-prog"
                      onClick={() =>
                        emplStatusFilter(findEmployees?.shkarkuar?.name)
                      }
                      style={themeStyle}
                    >
                      <div>
                        {statusIcon(
                          findEmployees?.shkarkuar?.name,
                          themeStyle?.fill
                        )}
                        <div
                          className="statusi-per-punojsit"
                          style={{ color: findEmployees?.shkarkuar?.color }}
                        >
                          {findEmployees?.shkarkuar?.length}
                          <br></br>
                          {findEmployees?.shkarkuar?.name}
                        </div>
                      </div>
                    </Card>
                  </>
                )}
                <Card
                  className="add-punojsi-card card-puno-prog"
                  style={{ ...themeStyle, cursor: "default" }}
                >
                  <IconPunonjesit
                    className="add-icon"
                    fill="#1D3445"
                    width={73}
                    height={73}
                  />
                  <p style={{ fontSize: 14, whiteSpace: "350px" }}>
                    Këtu ti mund të krijosh <br></br>një profil të ri punonjësi.
                  </p>
                  <MondayButton
                    className="mondayButtonGreen"
                    onClick={() => setVisible(true)}
                  >
                    Shto Punonjës
                  </MondayButton>
                </Card>
              </div>
              <div className="punonjesit-ag-grid">
                <AgGridHeader
                  gridApi={gridApi}
                  gridData={tableData}
                  items={{
                    ...ITEMS,
                    search: { handleClean: clearFilters },
                    switch: {
                      checked: showOnlyActivEmployees,
                      onSwitch: onSwitch,
                      onChecked: "Shfaq vetëm punonjësit aktiv",
                      onUnChecked: "Shiko të	gjithë punonjësit",
                    },
                    icons: {
                      ...ITEMS.icons,
                      filter: {
                        ...ITEMS.icons.filter,
                        filterActive: showOnlyActivEmployees,
                      },
                    },
                  }}
                  exportTitle={"Lista e Punonjësve"}
                />

                {/* <div
                  className={theme}
                  style={{
                    ...style,
                    height: "100%",
                    width: "100%",
                  }}
                > */}
                <AgGridComponent
                  gridApi={gridApi}
                  rowData={
                    showOnlyActivEmployees
                      ? sortedEmployees(tableData)
                      : sortedEmployees(activeEmployees)
                  }
                  rowSelection="single"
                  onGridReady={onGridReady}
                  paginationPageSize={theme.includes("alpine") ? 11 : 19}
                  getRowId={getRowId}
                  columnDefs={columnDefs}
                  // onSelectionChanged={handleSelectionChanged}
                  suppressServerSideInfiniteScroll={"full"}
                  enableCellChangeFlash={true}
                  rowModelType={"serverSide"}
                />
                <CustomAgPaginator gridApi={gridApi} />
              </div>
            </div>
          ) : (
            // </div>
            <MobilePunojsitView
              setVisible={setVisible}
              activeEmployees={activeEmployees}
            />
          )}
        </div>
        <GeneralInformationsContext.Provider value={{ files, setFiles }}>
          <AddEmployeModal
            {...{
              setVisible,
              visible,
              tableData,
              setTableData,
              userData,
              setFiles,
              files,
              // socketHandler,
              socketHandler: () => {},
              users,
              form,
              setActiveEmployees,
              setPopoverVisible,
              setCurrentStep,
              currentStep,
            }}
          />
          <CancelAddEmployeeModalPopover
            {...{
              form,
              visible: popoverVisible,
              setVisible: setPopoverVisible,
              modalVisible: visible,
              setModalVisible: setVisible,
              setCurrentStep,
              currentStep,
              title:
                "Ti nuk ke përfunduar, a je i sigurt që dëshiron të largohesh nga krijimi i një punonjësi?",
            }}
          />
        </GeneralInformationsContext.Provider>
      </LoadableComp>
    </>
  );
});

export default Punonjesit;
