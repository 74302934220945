import { Switch } from "antd";

const SwitchAgGrid = ({
  checked,
  onSwitch,
  onChecked,
  onUnChecked,
  className,
  style,
}) => {
  return (
    <div
      className={`headerSwitch ${className}`}
      data-testid="headerSwitch"
      onClick={onSwitch}
      style={{ ...style, backgroundColor: "transparent" }}
    >
      <Switch checked={!checked} />
      <span className="span" style={{ ...style, backgroundColor: "transparent" }}>
        {checked ? onChecked : onUnChecked}
      </span>
    </div>
  );
};

export default SwitchAgGrid;
