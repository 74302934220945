import { Radio, Select } from "antd";
import GPickerButton from "../punonjesit/AddEmployeeModal/GPickerButton";
import InputComponent from "../InputComponent/InputComponent";
import { compareIncluding } from "../Documentation/utils/compareIncluding";
import FormTextEditor from "../FormTextEditor/FormTextEditor";

const RenderDynamicComponents = (compJSON = [], optionalProps = {}) => {
  const { Option, OptGroup } = Select;

  const { form, accessToken, disabled = false } = optionalProps;

  return compJSON.map((input, i) => {
    const {
      options,
      type,
      form: inputForm,
      accessToken: inputAccessToken,
      optionGroups,
      optionType,
      customOptions,
      CustomField,
    } = input;

    const InputOption = compareIncluding(type, "radio")
      ? compareIncluding(optionType, "Button")
        ? Radio.Button
        : Radio
      : Option;

    const WhichComponent = compareIncluding(type, "placesautocomplete")
      ? GPickerButton
      : compareIncluding(type, "textEditor")
      ? FormTextEditor
      : InputComponent;
    // console.log("options: ", options);
    return (
      <WhichComponent
        {...{
          ...input,
          form: inputForm || form,
          accessToken: inputAccessToken || accessToken,
          options:
            !customOptions &&
            options?.length &&
            options?.map((item, i) => (
              <InputOption key={i} value={item}>
                {item}
              </InputOption>
            )),
          optionGroups: optionGroups?.map(({ label, options }, index) => (
            <OptGroup key={label + index} label={label}>
              {options?.map((item, i) => (
                <Option key={item + i + label} value={item} data-testid={item}>
                  {item}
                </Option>
              ))}
            </OptGroup>
          )),
          key: i,
          ...(!!disabled ? { disabled: true } : {}),
        }}
      />
    );
  });
};

export default RenderDynamicComponents;
