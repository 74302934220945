import bcrypt from "bcryptjs";

export const generateToken = (key, value) => {
  const salt = bcrypt.genSaltSync(10);
  const hash = bcrypt.hashSync(`${value}`, salt);

  localStorage.setItem(key, hash);
};

export const compareToken = (key, value) => {
  let isSame;

  const prevToken = localStorage.getItem(key);

  //compare value (sessionId) with stored token
  // if there is no token saved, 2FA will be required
  if (!!prevToken) {
    isSame = bcrypt.compareSync(`${value}`, prevToken);
  }

  return isSame;
};
