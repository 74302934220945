import { FlexLogo } from "../../assets/icons";
import "./AuthInterface.scss";

const AuthInterface = ({ title, children }) => {
  return (
    <div className="authWrapper">
      <div className="authHeader">
        <img src={FlexLogo} alt="Flex" width={160} height={100} />
      </div>
      <div className="authContent">
        <div className="backgroundImg" />
        <div className="dynamicInterface">
          <div className="authTitle">{title || "Futu në adresën tënde!"}</div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthInterface;
