import moment from "moment";
import dayjsTZ from "../../../utils/dayjs";
import React, { useEffect, useState } from "react";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { Button, Tooltip } from "antd";
import { ExcelIcon } from "../../../assets/icons";
import { htmlParser } from "../../../utils";
import { formatChanges } from "../../punonjesit/EmployeeView/components/ActivityModal/formatChanges";

const KeylogsExcelExporter = ({ keylogs, headers, filename }) => {
  const [keylogsArray, setKeylogsArray] = useState([]);
  const [uniqueKeys, setUniqueKeys] = useState(false);

  const exportKeylogs = () => {
    const test = [];
    keylogs.forEach((keylog) => {
      let newKeylog = {};
      for (const [key, value] of Object.entries(keylog)) {
        let field = headers.find((item) => item.key === key);
        let keyText = `${!!field ? field.value : key}:`;

        let valueText = ` ${
          !!value
            ? Array.isArray(value)
              ? value.join(", ")
              : field.key === "changeDate"
              ? dayjsTZ(value).format("DD/MM/YYYY HH:mm")
              : typeof value === "string" || value instanceof String
              ? !!htmlParser(value)?.props?.children
                ? htmlParser(value)?.props.children
                : formatChanges(value)
              : formatChanges(value)
            : ""
        }`;
        newKeylog[keyText] = valueText;
      }
      test.push(newKeylog);
    });
    setKeylogsArray(test);
  };

  useEffect(() => {
    exportKeylogs();
  }, [keylogs]);

  useEffect(() => {
    let test = new Set();
    keylogsArray.forEach((keylog) => {
      let values = Object.keys(keylog);
      values?.forEach((item) => test.add(item));
    });
    setUniqueKeys(test);
  }, [keylogsArray]);

  return (<>
    {keylogs?.length !== 0 && (
      <>
        <ReactHtmlTableToExcel
          id="keylogs-table-xls-button"
          className="exportKandidateBtn"
          table={"tableKeylogs"}
          filename={filename}
          sheet={"Keylogs"}
          buttonText={
            <Tooltip
              placement="top"
              overlayClassName="global-icon-tooltip"
              title={`Eksporto ${filename} në eksel`}
            >
              <Button
                icon={<ExcelIcon width={18} height={20} />}
                className="exportKandidateBtn"
                style={{ backgroundColor: "#02686b" }}
              />
            </Tooltip>
          }
        />
        {uniqueKeys && (
          <table style={{ display: "none" }} id="tableKeylogs">
            <thead>
              <tr>
                {[...uniqueKeys]?.map((item) => {
                  return <th>{item.replace(/applicant/gi, "").replace(/application/gi, "")}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {keylogsArray?.map((item) => {
                return (
                  <tr>
                    {[...uniqueKeys]?.map((key) => {
                      return <td>{item[key]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </>
    )}
  </>);
};

export default KeylogsExcelExporter;
