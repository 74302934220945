import { Select } from "antd";
import moment from "moment";
import { useContext, useMemo } from "react";
import { Chart } from "react-google-charts";
import { InterviewsContext } from "../../../../Intervistat/data";
import dayjsTZ from "../../../../../utils/dayjs";

const { Option } = Select;

const ChartComponent = ({
  topTitle,
  selectOptions,
  years,
  props,
  idx,
  activeFilter,
  setActiveFilter,
  hasData,
}) => {
  const { setActiveKey } = useContext(InterviewsContext);

  return (
    <div className="charts" key={idx}>
      <>
        <div className="headerChart">
          {/* <span className="topTitle">{topTitle}</span> */}
          {/* <div className="selectContent"> */}
          <Select
            className="selectOptions"
            defaultValue={
              activeFilter[topTitle]?.month ??
              selectOptions?.[selectOptions?.length - 1]?.name
            }
            onChange={(value) => {
              setActiveFilter((prev) => ({
                ...prev,
                [topTitle]: {
                  ...prev[topTitle],
                  month: value,
                  year: prev?.[topTitle]?.year || dayjsTZ().year(),
                },
              }));
            }}
          >
            {selectOptions?.map?.((option, i) => (
              <Option
                key={i}
                value={
                  typeof option?.value === "string"
                    ? option?.value
                    : dayjsTZ(option?.value).month()
                }
              >
                {option?.name}
              </Option>
            ))}
          </Select>
          <Select
            className="selectYear"
            defaultValue={
              activeFilter[topTitle]?.year ?? years?.[years?.length - 1]
            }
            onChange={(value) => {
              setActiveFilter((prev) => ({
                ...prev,
                [topTitle]: {
                  ...prev[topTitle],
                  month: prev?.[topTitle]?.month || "All",
                  year: Number(value),
                },
              }));
            }}
          >
            {years?.map?.((option, i) => (
              <Option key={i} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </div>
        {!!hasData ? (
          <Chart
            {...{
              ...props,
              chartEvents: [
                {
                  eventName: "select",
                  callback: ({ chartWrapper }) => {
                    setActiveKey("2");
                  },
                },
              ],
            }}
          />
        ) : (
          <div className="noData">
            <span>Asnjë të dhënë!</span>
          </div>
        )}
      </>
    </div>
  );
};

const proccessMap = (dynamicMap) => {
  const defaultData = {
    x: 0,
    y: 0,
    h: 1,
    w: 1,
    minW: 1,
    minH: 1,
    static: false,
  };
  let save = [];
  const pozitions = {
    0: { x: 0, y: 0, w: 2, minW: 2 },
    1: { x: 2, y: 0 },
    2: { x: 3, y: 0 },
    3: { x: 0, y: 1 },
    4: { x: 1, y: 1 },
    5: { x: 2, y: 1, w: 2, minW: 2 },
  };

  (dynamicMap || []).forEach((chart, idx) => {
    let toPush = {
      i: `${idx + 1}`,
      data: {
        name: chart?.topTitle,
        emri: chart?.topTitle,
        src: () => ChartComponent({ ...chart, idx }),
        parent: false,
        // data: dynamicMap,
        hidden: false,
      },
      ...defaultData,
      ...pozitions[idx],
    };
    save.push(toPush);
  });

  return save;
};

export const draggableInterviewsCharts = (dynamicMap, viewType) => {
  let processedMap = proccessMap(dynamicMap);
  const ls = JSON.parse(localStorage.getItem(viewType));

  if (ls !== null) {
    let toReturn = [];
    ls.forEach((el) => {
      let idx = processedMap.findIndex((l) => l.i === el.i);

      if (idx !== -1) {
        let item = {
          // ...processedMap[idx], hidden: el.hidden, data: dynamicMap
          ...processedMap[idx],
          data: {
            ...processedMap[idx].data,
            hidden: el?.data?.hidden,
            blur: el?.data?.blur,
          },
          x: el.x,
          y: el.y,
          h: el.h,
          w: el.w,
          minW: el.minW,
          minH: el.minH,
        };
        toReturn.push(item);
      }
    });

    return toReturn;
  } else if (ls === null) {
    console.log(`updated: ${viewType}`);
    localStorage.setItem(viewType, JSON.stringify(processedMap));

    return proccessMap(dynamicMap);
  }
};
