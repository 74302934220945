import { Alert, Checkbox, DatePicker, Input, Modal } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import "./GenerateModal.scss";
import { Steps, Button, message, Select, Form } from "antd";
// import { ClearIcon } from "../../dashboard/assets";
import moment from "moment";
import dayjsTZ from "../../../utils/dayjs";
import PayStub from "../PaySub/PaySub";
import { paySubFactory } from "../PaySub/employeeCalc/employeeSubFactory";
import { useReactToPrint } from "react-to-print";
import { FulfillingSquareSpinner } from "react-epic-spinners";
import { useAuth } from "../../../authentication/authHelpers";
// import { notificationUserToAdmin } from "../../../utils/sendNotification";
import { HeaderStepper } from "../../../components";
import GeneralInformations from "../../punonjesit/AddEmployeeModal/GeneralInformations";
import { API } from "aws-amplify";
import { requestData } from "../../../helpers/API/RequestFactory";
import { notificationUserToAdmin } from "../../../utils/sendNotification";
import { RichTextEditor } from "../../commonComponents";
import QuillTextEditor from "../../commonComponents/QuillTextEditor/QuillTextEditor";
import { useLocation } from "react-router";
import MondayButton from "../../commonComponents/MondayButton/MondayButton";

const { Step } = Steps;
const { Option } = Select;

function GenerateModal({ generateVisib, setGenerateVisib, onSave, clockings }) {
  // console.log("generateModalClockings", clockings);
  const [current, setCurrent] = useState(0);
  const { eligibleEmployeesClockIn } = useSelector(
    (state) => state.employeesList
  );
  // const { clockings } = useSelector((state) => state.clockings);
  const { programFields } = useSelector((state) => state.programFields);
  const { state } = useLocation();
  const [toGenerate, setToGenerate] = useState({
    timeframe: {
      start: dayjsTZ(state?.data?.muaji).startOf("month"),
      end: dayjsTZ(state?.data?.muaji).endOf("month"),
    },
    employeeId: null,
    note: null,
  });
  const [enableNote, setEnableNote] = useState(false);
  const [note, setNote] = useState("");
  const [payStubObj, setPayStubObj] = useState(null);
  const [form] = Form.useForm();
  const [existUnapproved, setExistUnapproved] = useState(false);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { prepayments } = useSelector((state) => state.prepayments);
  const [printTimesheet, setPrintTimesheet] = useState(false);
  const userDataAuth = useAuth();
  const richTextEditorRef = useRef();

  //
  const { users } = useSelector((state) => state.usersList);
  const auth = useAuth();

  //

  const onReset = () => {
    setGenerateVisib(false);
    setPayStubObj(null);
    setExistUnapproved(false);
    setCurrent(0);
    setToGenerate({
      timeframe: {
        start: dayjsTZ().startOf("month"),
        end: dayjsTZ().endOf("month"),
      },
      employeeId: null,
    });
  };

  const next = () => {
    setCurrent(current + 1);
    if (current === steps.length - 2) {
      if (
        !!toGenerate?.timeframe &&
        !!toGenerate?.employeeId &&
        !!prepayments &&
        !!programFields
      ) {
        /**
         * DO NOT CHANGE
         */
        const { paystubNote } = form.getFieldsValue("paystubNote");

        const appliedEmployee = eligibleEmployeesClockIn.find(
          (el) => el.employeeId === toGenerate.employeeId
        );
        const appliedClockings = clockings.filter(
          (c) => c.employeeId === toGenerate.employeeId
        );
        const approvedClockings = appliedClockings.filter(
          (el) => el.approved === true
        );
        // console.log("Applied Clockings", appliedClockings, "Approved Clockgins", approvedClockings);
        if (
          appliedClockings.filter(
            (el) =>
              dayjsTZ(el?.clockInDate) >=
                dayjsTZ(toGenerate?.timeframe?.start).valueOf() &&
              dayjsTZ(el?.clockOutDate) <=
                dayjsTZ(toGenerate?.timeframe?.end).valueOf()
          ).length !==
          approvedClockings.filter(
            (el) =>
              dayjsTZ(el?.clockInDate) >=
                dayjsTZ(toGenerate?.timeframe?.start).valueOf() &&
              dayjsTZ(el?.clockOutDate) <=
                dayjsTZ(toGenerate?.timeframe?.end).valueOf()
          ).length
        ) {
          setExistUnapproved(true);
        }

        const payObj = new paySubFactory({
          type: "allEarnings",
          employee: appliedEmployee,
          clockings: approvedClockings,
          employeeRequests: employeeRequests,
          momentInstance: toGenerate.timeframe,
          programFields: programFields,
          prepayments: prepayments,
          note: paystubNote === "<p><br></p>" ? null : paystubNote,
        });
        console.log({ payObj });
        setPayStubObj(payObj);
        // notificationUserToAdmin(
        // 	{
        // 		notificationTitle: "Gjenerim Page",
        // 		createdAt: dayjsTZ().valueOf(),
        // 		notificationPriority: false,
        // 		notificationCategory: "Critical",
        // 		notificationBody: `${userDataAuth?.userAccess[0]?.given_name}  ${userDataAuth?.userAccess[0]?.family_name} inspektoi pagen per ${appliedEmployee?.employeeFirstName} ${appliedEmployee?.employeeLastName}`,
        // 		isAnnouncement: false,
        // 		notificationUrl: "/pagat/llogaritja",
        // 		notificationFirstName: userDataAuth?.userAccess[0]?.given_name,
        // 		notificationLastName: userDataAuth?.userAccess[0]?.family_name,
        // 	},
        // 	users?.allUsers?.Items
        // );
      }
    }
  };

  const prev = () => {
    if (payStubObj !== null) {
      setPayStubObj(null);
      setExistUnapproved(false);
    }
    setCurrent(current - 1);
  };

  const getDocTitle = () => {
    let em = eligibleEmployeesClockIn?.find(
      (el) => el.employeeId === toGenerate?.employeeId
    );
    if (!!em) {
      let fullName = em?.employeeFirstName + " " + em?.employeeLastName;
      let date = dayjsTZ(toGenerate.timeframe.start).format("MMMM_YYYY");
      return `${fullName}_${date}`;
    }
  };

  const componentRef = useRef("payStub-print");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${getDocTitle()}`,
  });

  const PickEmployee = () => (
    <div
      className="pick-employee"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "7px",
        height: "200px",
      }}
    >
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <strong style={{ color: "red" }}>*</strong>
        Zgjidh Punonjesin:
      </span>{" "}
      <Select
        className="pick-empl"
        showSearch
        value={toGenerate?.employeeId}
        placeholder="Zgjidh Punonjesin"
        style={{ width: 220 }}
        onChange={(val) =>
          setToGenerate((prev) => ({ ...prev, employeeId: val }))
        }
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {eligibleEmployeesClockIn?.map((empl, idx) => (
          <Option value={empl?.employeeId}>
            {empl?.employeeFirstName + " " + empl?.employeeLastName}
          </Option>
        ))}
      </Select>
    </div>
  );

  const handleNoteEnable = () => {
    if (enableNote === true) {
      setEnableNote(!enableNote);
      form.setFieldsValue("paystubNote", null);
    } else {
      setEnableNote(!enableNote);
    }
  };

  const PickTime = () => (
    <div className="pick-month">
      <div>
        <span>
          <strong style={{ color: "red" }}>*</strong>
          Zgjidh Muajin:
        </span>{" "}
        <DatePicker
          width={300}
          // defaultValue={toGenerate.timeframe.start}
          defaultValue={dayjsTZ(state?.data?.muaji).startOf("month")}
          // className="month-shift-picker"
          picker="month"
          allowClear={false}
          format={"MMMM YYYY"}
          onChange={(val) =>
            setToGenerate((prev) => ({
              ...prev,
              timeframe: {
                start: val.startOf("month"),
                end: val.endOf("month"),
              },
            }))
          }
        />{" "}
      </div>
      <span>
        <Checkbox
          checked={printTimesheet}
          onChange={(e) => {
            setPrintTimesheet(e.target.checked);
          }}
        >
          Printo Orarin?
        </Checkbox>
      </span>
    </div>
  );

  const PickNote = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          // width: 500,
        }}
      >
        <>
          <br />
          <Form form={form}>
            <Form.Item name={"paystubNote"}>
              <QuillTextEditor />
            </Form.Item>
          </Form>
        </>
      </div>
    );
  };

  const Generate = () =>
    payStubObj !== null ? (
      <PayStub {...{ componentRef, payStubObj, printTimesheet }} />
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          height: "100%",
        }}
      >
        <FulfillingSquareSpinner color="#02686b" size={100} />
      </div>
    );

  const steps = [
    {
      title: "Zgjidh punonjësin",
      Comp: PickEmployee,
      disabled: true,
    },
    {
      title: "Zgjidh periudhën",
      Comp: PickTime,
      disabled: true,
    },
    {
      title: "Zgjidh shenimin",
      Comp: PickNote,
      disabled: true,
    },
    {
      title: "Gjenero",
      Comp: Generate,
      disabled: true,
    },
  ];

  return (
    <div>
      {" "}
      <Modal
        destroyOnClose={true}
        maskClosable={false}
        width={current > steps.length - 1 ? "100%" : " 863px"}
        className="generate-modal darkHeader"
        title="Gjenero Pagen"
        open={generateVisib}
        onOk={() => setGenerateVisib(false)}
        onCancel={onReset}
        centered={true}
        footer={[
          <>
            {" "}
            <MondayButton className="mondayButtonRed" onClick={onReset}>
              Mbyll Faqen
            </MondayButton>
            {current === 0 && (
              <MondayButton
                className="mondayButtonGreen"
                onClick={() => next()}
                disabled={!!toGenerate.employeeId ? false : true}
                style={{
                  cursor: !!toGenerate.employeeId ? "pointer" : "not-allowed",
                }}
              >
                Vazhdo me tej
              </MondayButton>
            )}
            {(current === 1 || current === 2) && (
              <div className="finished-gen">
                <MondayButton
                  className="mondayButtonGrey"
                  style={{ margin: "0 8px" }}
                  onClick={() => prev()}
                >
                  Kthehu mbrapa
                </MondayButton>

                <MondayButton
                  className="mondayButtonGreen"
                  onClick={() => next()}
                  style={{
                    cursor:
                      !!toGenerate.employeeId &&
                      !!toGenerate?.timeframe?.start &&
                      !!toGenerate?.timeframe?.end
                        ? "pointer"
                        : "not-allowed",
                  }}
                >
                  Vazhdo me tej
                </MondayButton>
              </div>
            )}{" "}
            {current === steps.length - 1 && (
              <div className="finished-gen">
                {" "}
                <MondayButton
                  className="mondayButtonGrey"
                  style={{ margin: "0 8px" }}
                  onClick={() => prev()}
                >
                  Kthehu mbrapa
                </MondayButton>
                <MondayButton
                  className="mondayButtonGreen"
                  disabled={payStubObj?.valid === false ? true : false}
                  onClick={() => handlePrint()}
                >
                  Printo
                </MondayButton>
              </div>
            )}
          </>,
        ]}
      >
        <div className="generate-modal-container">
          {existUnapproved && (
            <>
              <Alert
                message="Kujdes!"
                description="Për punonjësin dhe periudhën e zgjedhur, ka turne të pa aprovuara qe nuk janë përfshirë në llogaritje."
                type="warning"
                showIcon
                closable
              />
              <br />
            </>
          )}
          <HeaderStepper
            currentStep={current}
            setCurrentStep={setCurrent}
            steps={steps}
          />
        </div>
      </Modal>
    </div>
  );
}

export default GenerateModal;
