import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { themeQuartz, themeMaterial, themeAlpine } from '@ag-grid-community/theming';
import "./ag-grid-component.scss";
import getAgThemePreference from "../../utils/getAgThemePreference";

function AgGridComponent({
	onGridReady,
	columnDefs,
	paginationPageSize,
	onCellValueChanged,
	rowData,
	onRowValueChanged,
	onSelectionChanged,
	rowSelection,
	suppressRowClickSelection,
	overlayNoRowsTemplate,
	footer,
    rowGroupPanelShow = 'always',
	onPaginationChanged,
	overlayLoadingTemplate,
	getRowId,
	onFirstDataRendered,
	gridApi,
    statusBar,
    rowHeight,
    headerHeight,
	customDefaultColDef = {}
}) {
    const defaultColDef = {
        sortable: true,
        flex: 1,
        filter: true,
        resizable: true,
        // editable: { edit },
    };
    const { theme, style } = useMemo(() => {
        return getAgThemePreference();
    }, []);

	return (
    <div className={theme} style={{ ...style, width: "100%", height: "100%" }}>
        <div className="ag-grid-component">
            <AgGridReact
                gridApi={gridApi}
                rowData={rowData}
                rowSelection={rowSelection}
                editType={"fullRow"}
                onCellValueChanged={onCellValueChanged}
                onRowValueChanged={onRowValueChanged}
                columnDefs={columnDefs}
                statusBar={statusBar}
                defaultColDef={Object.assign(defaultColDef, customDefaultColDef)}
                onGridReady={onGridReady}
                pagination={true}
                // gridOptions={{
                //     theme: themeAlpine.withParams({
                //         accentColor: 'yellow'
                //     })
                // }}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                // singleClickEdit={true}
                paginationPageSize={paginationPageSize}
                groupSelectsChildren={true}
                rowGroupPanelShow={rowGroupPanelShow}
                onSelectionChanged={onSelectionChanged}
                enterNavigatesVertically={true}
                enterNavigatesVerticallyAfterEdit={true}
                suppressRowClickSelection={suppressRowClickSelection}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
                onPaginationChanged={onPaginationChanged}
                overlayLoadingTemplate={overlayLoadingTemplate}
                onFirstDataRendered={onFirstDataRendered}
                getRowId={getRowId}
                animateRows={true}
                selection={{
                    mode: "cell",
                    enableMultiSelectWithClick: true
                }} />
        </div>
        {footer}
    </div>);
}

export default AgGridComponent;
