import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  IconNgargo,
  IconFilter,
  IconExcel,
  DeleteIcon,
} from "../../../../assets/icons";
import { Tooltip } from "antd";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
// import totalValue from './totalValue';
import ShiftModal from "./EmployeeShifts/ShiftModal";
import { getMonthsOfYear, getNextMonthsOfYear } from "./utils/calculations";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { calcMonthInd } from "../../../Pagat/PaySub/employeeCalc/calculateMonthlyInd";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import HtmlExelTabel from "../../../Konfigurimet/dynamic components/HtmlExelTabel";
import ExportToPdf from "../../../Konfigurimet/dynamic components/ExportToPdf";
import "./PaymentGrid.scss";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";
import MobilePaymentGrid from "./MobilePaymentGrid";
import PunonjesitContext from "../../store/PunonjesitContext";
import AgGridHeader from "../../../commonComponents/AgGridHeader/AgGridHeader";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import getAgThemePreference from "../../../../utils/getAgThemePreference";
import colorShade from "../../../../utils/colorShade";
import FooterCounter from "../../../commonComponents/FooterCounter/FooterCounter";

const EXPORT_COLS = [
  { en: "muaji", al: "Muaji" },
  { en: "neto", al: "Shuma Neto" },
  { en: "bruto", al: "Shuma Bruto" },
];

const ITEMS = {
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function PaymentGrid() {
  const { wages } = useSelector((state) => state.wages);
  const { employee } = useContext(PunonjesitContext);

  const [rowData, setRowData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [agGridRow, setAgGridRow] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [nextGen, setNextGen] = useState({ next: 0, prev: 0 });

  const { theme, accentColor } = getAgThemePreference();

  const columnDefs = [
    {
      headerName: "Nr",
      field: "nr",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      headerName: "Muaji",
      field: "muaji",
      cellRenderer: ({ data }) => (
        <div style={{ cursor: "pointer" }}>
          <div variant="outlined">
            <span
              style={{
                color: theme.includes("dark")
                  ? "#FFF"
                  : colorShade(accentColor, 0.25),
                fontWeight: 600,
              }}
              onClick={() => handleMonthModal(data)}
            >
              {dayjsTZ(data.muaji).format("MMMM YYYY")}
            </span>
          </div>
        </div>
      ),
    },
    {
      headerName: "Shuma Neto",
      field: "neto",

      // cellRenderer: ({ data }) => (
      // 	<div style={{ cursor: "pointer" }}>
      // 		<div variant="outlined">
      // 			<span style={{ color: "green", fontWeight: 600 }}>
      // 				{calcMonthInd("neto", wages, data.muaji, location?.state)}
      // 			</span>
      // 		</div>
      // 	</div>
      // ),
    },
    {
      headerName: "Shuma Bruto",
      field: "bruto",
      // cellRenderer: ({ data }) => (
      // 	<div style={{ cursor: "pointer" }}>
      // 		<div variant="outlined">
      // 			<span style={{ color: "green", fontWeight: 600 }}>
      // 				{calcMonthInd("bruto", wages, data.muaji, location?.state)}
      // 			</span>
      // 		</div>
      // 	</div>
      // ),
    },
  ];

  let rowDataGrid = [];
  gridApi?.forEachNodeAfterFilter?.((node) => {
    rowDataGrid?.push(node?.data);
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleMonthModal = (data) => {
    setSelectedData([data]);
    setIsModalVisible(true);
  };

  const initializeMonths = () => {
    let temp = getMonthsOfYear()?.map((el) => ({
      ...el,
      neto: calcMonthInd("neto", wages, el.muaji, employee),
      bruto: calcMonthInd("bruto", wages, el.muaji, employee),
    }));

    setRowData(temp);
  };

  const onNextGen = (type) => {
    if (!!type) {
      let temp = getNextMonthsOfYear(type, nextGen[type] + 1)?.map((el) => ({
        ...el,
        neto: calcMonthInd("neto", wages, el.muaji, employee),
        bruto: calcMonthInd("bruto", wages, el.muaji, employee),
      }));

      setRowData(temp);
      setNextGen((prev) => ({ ...prev, [type]: prev[type] + 1 }));
    } else {
      initializeMonths();
      setNextGen({ next: 0, prev: 0 });
    }
  };

  useEffect(() => {
    if (!!wages) {
      initializeMonths();
    }
  }, [wages]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  console.log(rowData);

  const headerIcons = useMemo(() => {
    return (
      <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
        <AgGridHeader
          gridApi={gridApi}
          gridData={rowData}
          items={ITEMS}
          exportTitle={`Paga - ${employee?.employeeFirstName}.${employee?.employeeLastName}`}
          children={[
            <MondayButton
              className="mondayButtonGreen"
              data-testid="on-next-btn"
              onClick={() => onNextGen("next")}
            >
              Shfaq Muajin Pasardhës
            </MondayButton>,
            <MondayButton
              className="mondayButtonGreen"
              data-testid="on-prev-btn"
              onClick={() => onNextGen("prev")}
            >
              Shfaq Muajin Paraardhës
            </MondayButton>,
            <MondayButton
              className="mondayButtonRed"
              onClick={() => onNextGen(null)}
              data-testid="pastro-btn"
            >
              Pastro
            </MondayButton>,
          ]}
        />
      </div>
    );
  }, [employee, nextGen, rowData]);

  return isMobileView ? (
    <div className="payment-grid">
      <div className="punonjesit-ag-grid">
        {headerIcons}
        <AgGridComponent
          rowData={rowData}
          rowSelection="single"
          onGridReady={onGridReady}
          paginationPageSize={12}
          columnDefs={columnDefs}
          suppressRowClickSelection={true}
          dataTestId="payment-ag-grid"
        />
        <FooterCounter
          title="Shuma totale bruto (neto)"
          className={"rowFlex"}
          data={rowData?.map((el) => el?.bruto)}
          derivedData={rowData?.map((el) => el?.neto)}
        />
      </div>

      {isModalVisible ? (
        <ShiftModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          {...{ selectedData, setSelectedData, gridApi }}
        />
      ) : null}
    </div>
  ) : (
    <>
      {headerIcons}
      <MobilePaymentGrid rowData={rowData} dataTestId="payment-ag-grid" />
    </>
  );
}
export default PaymentGrid;
