import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import { currencyformatter } from "../../../Pagat/PaySub/employeeCalc/methods";
import { getTranslation } from "../../generate/translations";

export const prepayments = (data, filter, columns, manualColumns) => {
	let result = {
		columnsDef: [],
		allColumns: [],
		rowData: [],
		selectedColumns: [],
		totalities: {
			days: 0,
			amount: 0,
			hours: 0,
			deductions: 0,
			additions: 0,
			finalTotal: 0,
		},
	};
	// console.log("Data", data, filter);
	if (!!data && !!filter) {
		// result.columnsDef.push(
		// 	"EMER",
		// 	"MBIEMER",
		// 	"SHTIMET",
		// 	"ZBRITJET",
		// 	"PERIUDHA",
		// 	"DEPARTAMENTI",
		// 	"TOTALI"
		// );
		const preFilter = data
			.filter((item) => {
				let times = item?.period || [];
				return times.some(
					(el) =>
						dayjsTZ(el).isSameOrAfter(filter?.timeInterval[0]) &&
						dayjsTZ(el).isSameOrBefore(filter?.timeInterval[1])
				);
			})
			.map((m) => {
				result.totalities.amount +=
					m.earnings?.totalities?.amount - m.deductions?.totalities?.amount;
				result.totalities.deductions += m.deductions?.totalities?.amount;
				result.totalities.additions += m.earnings?.totalities?.amount;

				return {
					...m,
					...m?.employeeDetails,
					additions: currencyformatter
						.format(m.earnings?.totalities?.amount || 0)
						.replace("ALL", ""),
					timeframe: dayjsTZ(m?.period[0]).format("MMMM YYYY"),
					deductions: currencyformatter
						.format(m.deductions?.totalities?.amount || 0)
						.replace("ALL", ""),
					total: currencyformatter.format(result.totalities.amount),
				};
			});
		// .map((m) => {
		// 	result.totalities.amount +=
		// 		m.earnings?.totalities?.amount - m.deductions?.totalities?.amount;
		// 	result.rowData.push([
		// 		m?.employeeDetails?.employeeFirstName || "",
		// 		m?.employeeDetails?.employeeLastName || "",
		// 		` ${currencyformatter.format(m.earnings?.totalities?.amount || 0).replace("ALL", "")} (${
		// 			m?.earnings?.totalities?.hours
		// 		} ore)`,
		// 		` ${m.deductions?.totalities?.amount} (${m?.deductions?.totalities?.hours} ore)`,
		// 		`${dayjsTZ(m?.period[0]).format("MMMM YYYY")}`,
		// 		m?.employeeDetails?.employeeDepartmentName,
		// 		m.earnings?.totalities?.amount - m.deductions?.totalities?.amount,
		// 	]);
		// });
		result.selectedColumns = manualColumns || columns.columnDefs;
		result.allColumns = columns.allAppliedColumnDefs;
		if (preFilter.length > 0) {
			if (!!manualColumns && Array.isArray(manualColumns)) {
				manualColumns.forEach((e) => {
					result.columnsDef.push(getTranslation(e));
				});
				preFilter.forEach((emplRecord) => {
					let toAdd = [];
					manualColumns.forEach((c) => toAdd.push(emplRecord[c]));
					result.rowData.push(toAdd);
				});
			} else {
				columns?.columnDefs.forEach((e) => {
					result.columnsDef.push(getTranslation(e));
				});
				preFilter.forEach((emplRecord) => {
					console.log("preFilter", preFilter);
					let toAdd = [];
					columns?.columnDefs?.forEach((c) => toAdd.push(emplRecord[c]));
					result.rowData.push(toAdd);
				});
			}
		}

		return result;
	}
	return result;
};
