import dayjsTZ from "../../utils/dayjs";
import ProblemStatus from "../dashboard/AdminDashboard/AdminCards/Problems/ProblemStatus/ProblemStatus";
import { htmlParser } from "../../utils";

const DATE_FORMAT = "DD/MM/YYYY";

export const problematikatPageData = () => [
  {
    headerName: "Nr.",
    field: "ticketId",
    valueGetter: "node.rowIndex + 1",
  },
  {
    headerName: "Emër Mbiemër",
    field: "createdBy",
    valueGetter: ({ data }) => {
      return `${data.createdBy.employeeFirstName} ${data.createdBy.employeeLastname}`;
    },
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Përshkrimi",
    field: "ticketDescription",
    cellRenderer: ({ value }) => {
      return Array.isArray(htmlParser(value))
        ? htmlParser(value)[0]
        : htmlParser(value);
    },
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Kategoria",
    field: "ticketCategory",
    filter: "agTextColumnFilter",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Statusi",
    field: "ticketStatus",
    filter: "agTextColumnFilter",
    cellRenderer: ({ value }) => {
      return <ProblemStatus status={value} />;
    },

    cellClass: "centeredCell",
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Data e Krijimit",
    field: "createdAt",
    filter: "agTextColumnFilter",
    cellClass: "centeredCell",
    valueGetter: ({ data }) => {
      return dayjsTZ(data?.createdAt).format(DATE_FORMAT);
    },
    resizable: true,
    sortable: true,
  },
  {
    headerName: "Data e Zgjidhjes",
    field: "resolvedTicket",
    filter: "agTextColumnFilter",
    cellRenderer: (params) => (!!params.value ? params?.value : ""),
    resizable: true,
    sortable: true,
  },
];
