import { Form, Popover } from "antd";

const LabeledInput = ({
  label,
  formItemName,
  initialValue,
  noFormItem = false,
  className = "",
  style = {},
  required = false,
  rules = [],
  children,
  popoverVisible = false,
  popoverTitle = "",
  popoverContent,
  popoverPlacement,
  popoverClassName = "",
  popoverStyle = {},
  valuePropName,
  testId,
  ...rest
}) => (
  <div
    className={`labeledInputContainer ${className}`.trim()}
    style={{ ...style }}
    data-testid={testId}
  >
    <div className="labeledInputLabel">
      {required && !!label && <span style={{ color: "red" }}>*</span>}
      {label}
    </div>
    {!noFormItem ? (
      popoverVisible ? (
        <Popover
          open={popoverVisible}
          title={popoverTitle}
          content={popoverContent}
          placement={popoverPlacement}
          overlayClassName={popoverClassName}
          overlayStyle={popoverStyle}
        >
          <Form.Item
            initialValue={initialValue}
            name={formItemName}
            valuePropName={valuePropName}
            rules={[
              required && {
                required: true,
                message: `*${!!label ? label : "Kjo fushë"} nevojitet!`,
              },
              ...rules,
            ].filter((item) => !!item)}
            {...rest}
          >
            {children}
          </Form.Item>
        </Popover>
      ) : (
        <Form.Item
          initialValue={initialValue}
          name={formItemName}
          rules={[
            ...rules,
            required && {
              required: true,
              message: `*${!!label ? label : "Kjo fushë"} nevojitet!`,
            },
          ].filter((item) => !!item)}
          valuePropName={valuePropName}
          {...rest}
        >
          {children}
        </Form.Item>
      )
    ) : (
      children
    )}
  </div>
);

export default LabeledInput;
