import { ImportantIcon, InfoIcon, UnreadIcon, WarningIcon } from "./";
const filterData = [
  {
    id: 104,
    title: "Të palexuarat",
    Icon: UnreadIcon,
    color: "#959ca7a1",
    color2: "#959CA7",
  },
  {
    id: 102,
    title: "Kritike",
    Icon: WarningIcon,
    color2: "#c43428",
    color: "#ea394298",
  },
  {
    id: 101,
    title: "Të rëndësishme",
    Icon: ImportantIcon,
    color: "#FCC94A98",
    color2: "#FCC94A",
  },
  {
    id: 103,
    title: "Info",
    Icon: InfoIcon,
    color2: "#02686b",
    color: "#90d1cb",
  },
];

export default filterData;
