import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
export const getAppliedShifts = (shiftsArr) => {
	if (Array.isArray(shiftsArr)) {
		let sorted =
			shiftsArr.sort((a, b) => {
				let tempStart = dayjsTZ(dayjsTZ(a?.shiftStartTime).format("HH:mm"), "HH:mm").valueOf();
				let tempEnd = dayjsTZ(dayjsTZ(b?.shiftStartTime).format("HH:mm"), "HH:mm").valueOf();
				return tempStart - tempEnd;
			}) || [];

		let shiftsText = "";
		sorted.forEach((shift, idx) => {
			shiftsText += `${dayjsTZ(shift?.shiftStartTime).format("HH:mm")} - ${dayjsTZ(
				shift?.shiftEndTime
			).format("HH:mm")} ${idx !== sorted?.length - 1 ? "|" : ""} `;
		});
		return shiftsText;
	}
	return "Aktualisht nuk ka turn të konfiguruar për ju";
};
