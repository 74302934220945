import React from "react";
import { useState, useEffect } from "react";
import DragContainer from "../../dashboard/AdminDashboard/Draggable/DragContainer";
import LoaderComponent from "../../commonComponents/LoadableComp/LoadableComp";
import { useSelector } from "react-redux";

const UserProfile = () => {
  const [loading, setLoading] = useState(true);
  const { users } = useSelector((state) => state.usersList);

  useEffect(() => {
    if (!!users) {
      setLoading(false);
    }
  }, [users]);

  return (
    <>
      <LoaderComponent loading={loading}>
        <div
          className="user-profile"
          style={{
            width: "100%",
            height: "100%",
            display: "grid",
          }}
        >
          <DragContainer view={"profili"} dynamicMap={users} />
        </div>
      </LoaderComponent>
    </>
  );
};

export default UserProfile;
