import MobileHtmlTabel from "../../utils/MobileComponents/MobileHtmlTabel";
import { categories } from "./Announcements";
import { htmlParser } from "../../utils";
import AnnouncementReplies from "./AnnouncementReplies/AnnouncementReplies";

const MobileViewAnnouncements = ({ tableData }) => {
  return (
    <div className="base-mobile-div">
      <MobileHtmlTabel
        header={[
          "Titulli",
          "Kategoria",
          "Përmbajtja",
          "Url",
          "Krijuar Nga",
          "Përgjigjet",
        ]}
        tableData={tableData?.map?.(
          ({
            announcementTitle,
            announcementCategory,
            announcementBody,
            announcementUrl,
            createdAt,
            createdBy,
            usersResponses,
            announcementId,
          }) => ({
            announcementTitle,
            announcementCategory: categories[announcementCategory],
            announcementBody: <>{htmlParser(announcementBody)}</>,
            announcementUrl,
            createdAt: createdAt?.createdAt,
            createdBy,
            userResponses: (
              <AnnouncementReplies
                userResponses={usersResponses || {}}
                id={announcementId}
                title={announcementTitle}
              />
            ),
          })
        )}
        isLink={true}
        isHeader={true}
      />
    </div>
  );
};

export default MobileViewAnnouncements;
