import { useState, useEffect } from "react";
// import HorizontalCard from "../dashboard/components/horizontalCard/HorizontalCard";
import TimeSheet from "./timesheet/TimeSheet";
import LoadableComp from "../commonComponents/LoadableComp/LoadableComp";
import { API } from "aws-amplify";
import { Alert, Button, message } from "antd";
import "./listPrezenca.scss";
import FullCard from "../commonComponents/FullCard/FullCard";
import { useAuth } from "../../authentication/authHelpers";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjsTZ from "../../utils/dayjs";
import MoreView from "./MoreView/MoreView";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
} from "../../helpers/API/RequestFactory";

function ListPrezenca() {
  const [loading, setLoading] = useState(true);
  const { employees } = useSelector((state) => state.employeesList);
  const [data, setData] = useState([]);
  const auth = useAuth();
  const [shiftsConfig, setShiftsConfig] = useState(null);

  const getClocking = async () => {
    // console.log("");
    let emplIdRefForEmployee =
      auth?.userRole === "Employee" ? auth?.employeeId : false;
    let threeMonthsAgo = !emplIdRefForEmployee
      ? dayjsTZ().subtract(1, "months").startOf("month").valueOf()
      : dayjsTZ().subtract(8, "months").startOf("month").valueOf();
    await requestDataWithParams(
      "clocking",
      threeMonthsAgo,
      null,
      emplIdRefForEmployee
    )
      .then((res) =>
        setData(
          res
            .map((el) => ({
              ...el,
              employeeDepartmentId: employees.filter(
                (s) => s.employeeId === el.employeeId
              )[0]?.employeeDepartmentId,
              employeeInfo: employees.find(
                (s) => s.employeeId === el.employeeId
              ),
              employeeDepartmentName: employees.filter(
                (s) => s.employeeId === el.employeeId
              )[0]?.employeeDepartmentName,

              // clockOutDate: !!el?.clockOutDate ? el.clockOutDate : dayjsTZ(),
              // onGoing: !!el?.clockOutDate ? false : true,
            }))
            .filter((r) => r.clockOutDate !== null)
        )
      )
      .catch((err) => {
        console.log(err);
        message.error("Ndodhi nje problem!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!!employees) {
      getClocking();
    }
  }, [employees]);

  useEffect(() => {
    API.get("shifts", "/shifts").then((res) => setShiftsConfig(res));
  }, []);

  return (
    <LoadableComp loading={loading}>
      <div className="globalPageWrapper">
        {/* <MoreView /> */}
        {!!employees && data.length > 0 ? (
          <FullCard
            contentChild={
              <TimeSheet {...{ data, employees, auth, shiftsConfig }} />
            }
          />
        ) : (
          <div
            style={{
              display: "flex",
              width: 500,
              justifyContent: "center",
              padding: "20px",
              margin: "20px",
            }}
          >
            <Alert description="Nuk ka te dhena" />
          </div>
        )}
      </div>
    </LoadableComp>
  );
}

export default ListPrezenca;
