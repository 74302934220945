import { Tooltip } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs";
import React from "react";
import { ApprovedIcon, UnapprovedIcon } from "../../../assets/icons";

function MobileViewTimeSheet({ toVisualize, totalHours }) {
  return (
    <div className="mobileViewTimeSheet">
      <div style={{ display: "flex", gap: 30 }}>
        <Tooltip
          placement="top"
          title={
            !toVisualize?.some((el) => el?.approved === false)
              ? "Aprovuar"
              : "I Pa Aprovuar"
          }
        >
          <span
            className="approved-ind-icons"
            style={{
              backgroundColor:
                toVisualize?.length > 0 &&
                toVisualize?.some((el) => el?.approved === false)
                  ? "#c43428"
                  : "#02686b",
            }}
          >
            {" "}
            {toVisualize?.length > 0 &&
            !toVisualize?.some((el) => el?.approved === false) ? (
              <ApprovedIcon />
            ) : (
              <UnapprovedIcon />
            )}{" "}
          </span>
        </Tooltip>
        <span className="clock-out-ind">
          {" "}
          {toVisualize?.length > 0 &&
            dayjsTZ(toVisualize[toVisualize.length - 1]?.clockOutDate).format(
              "HH:mm"
            )}
        </span>
      </div>
      <span className="total-hours">Totali: {totalHours || 0} orë</span>{" "}
    </div>
  );
}

export default MobileViewTimeSheet;
