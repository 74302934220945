import React, { useEffect, useState } from "react";
import "./ShiftLogCard.scss";
import moment from "moment-timezone";
import dayjs from "dayjs";
import dayjsTZ from "../../../../utils/dayjs";
import { htmlParser } from "../../../../utils/htmlParser";
import { Tooltip } from "antd";

function CreatedShiftCard({ content, users }) {
  const [display, setDisplay] = useState(null);
  const [user, setUser] = useState(null);
console.log(content)
  useEffect(() => {
    if (content !== null && users !== null) {
      let adminShift = content.clockingLogs[0]?.newShiftByAdmin
        ? content.clockingLogs[0]
        : null;
      // console.log("Admin Shift", adminShift);
      if (!!adminShift) {
        let userInfo = users.find((el) => el.userSub === adminShift.username);
        if (!!userInfo) setUser(userInfo);
      }
    }
  }, [users, content]);

  console.log(content?.clockingLogs[0]?.ipResponse?.IPv4);

  return content.clockingLogs.length > 0 ? (
    <div className="shift-log-card-wrapper">
      <div className="header">
        <span className="header-title">
          {content?.clockingCategory === "user"
            ? "Punonjesi"
            : `${user?.given_name + " " + user?.family_name} (${
                user?.userRole
              })`}{" "}
          krijoi këtë turn:
          <Tooltip
            title={`${dayjsTZ(content?.clockingLogs[0]?.createdAt).format(
              "HH:mm:ss |  DD/MM/YYYY"
            )}`}
          >
            <span style={{ marginLeft: "5px" }}>
              {" "}
              {" " + dayjsTZ(content?.clockingLogs[0]?.createdAt).fromNow()}
            </span>
          </Tooltip>
          .{" "}
          {!!content?.clockingLogs[0]?.ipResponse && (
            <Tooltip
              title={htmlParser(`
                    <a href="https://maps.google.com/?q=${
                      content?.clockingLogs[0]?.ipResponse?.latitude
                    },${
                content?.clockingLogs[0]?.ipResponse?.longitude
              }" target="_blank">
                          <span>
                        Clock-in: (${
                          content?.clockingLogs[0]?.ipResponse?.IPv4
                        }) ${content?.clockingLogs[0]?.ipResponse?.city}, ${
                content?.clockingLogs[0]?.ipResponse?.country_name
              } 
                  </span>
                        </a>
                  ${
                    content?.clockingLogs[1]
                      ? `<a href="https://maps.google.com/?q=${content?.clockingLogs[1]?.ipResponse?.latitude},${content?.clockingLogs[1]?.ipResponse?.longitude}" target="_blank">
                          <span>
                            Clock-out: (${content?.clockingLogs[1]?.ipResponse?.IPv4}) ${content?.clockingLogs[1]?.ipResponse?.city}, ${content?.clockingLogs[1]?.ipResponse?.country_name}
                          </span>
                        </a>`
                      : ""
                  }`)}
            >
              <span style={{ marginLeft: "5px" }}>
                {content?.clockingLogs[0]?.ipResponse?.IPv4}
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      <div className="content">
        <div className="content-shifts">
          <div className="original-shift">
            <span style={{ display: "flex", justifyContent: "center" }}>
              Turni
            </span>
            <span
              className="shift-format"
              style={{ backgroundColor: "#02686b" }}
            >
              {dayjsTZ(content?.clockInDate).format("HH:mm")} -{" "}
              {dayjsTZ(content.clockOutDate).format("HH:mm DD/MM/YYYY") !==
              "Invalid Date"
                ? dayjsTZ(content.clockOutDate).format("HH:mm | DD/MM/YYYY")
                : "--:--"}
            </span>
          </div>
          {/* <div className="new-shift">
                <span style={{ display: "flex", justifyContent: "center" }}>Turni i ri </span>
                <span className="shift-format" style={{ backgroundColor: "#02686b" }}></span>
              </div> */}
        </div>
        <div className="content-note">
          <span className="sticky-title">Shënim</span>
          <span className="note-text">
            {/* {content?.clockingLogs[0]?.note} */}
            {htmlParser(content?.clockingLogs[0]?.note)}
          </span>
        </div>
      </div>
    </div>
  ) : null;
}

export default CreatedShiftCard;
