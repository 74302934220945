import Raportet from "../../Raportet/Raportet";
import { EmployeePayment } from "../EmployeeView/Payment/EmployeePayment";
import EmployeesView from "../EmployeeView/Personal/EmployeesView";
import DocumentationView from "../../Documentation/View/DocumentationView.jsx";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tabs, Tooltip, Breadcrumb } from "antd";
import "./Tabs.scss";
import ClockIns from "../../Raportet/ClockIns";
import { DocumentationWarningIcon } from "../../../assets/icons";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../../utils/dayjs.js";
import { useSelector } from "react-redux";
import PunonjesitContext from "../store/PunonjesitContext.js";
import { useAuth } from "../../../authentication/authHelpers.js";
import LoaderComponent from "../../commonComponents/LoadableComp/LoadableComp.jsx";
import { uniqBy } from "lodash";

const DynamicPunojsitView = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { employeeId } = useParams();
  const externalTab = useLocation()?.state?.tab || "";
  const externalKey = useLocation()?.state?.key || "";

  const { employees } = useSelector((state) => state.employeesList);
  const { accessToken } = useSelector((state) => state.accessToken);

  const [expiringDocs, setExpiringDocs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState(null);
  const [documentation, setDocumentation] = useState([]);
  const [tab, setTab] = useState(externalTab || "Personale");
  const [activeKey, setActiveKey] = useState(externalKey || "1");
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  const [categoryConfiguration, setCategoryConfiguration] = useState({});

  const rowData = useMemo(() => {
    return employees?.find((emp) => emp?.employeeId === employeeId);
  }, [employeeId, employees]);

  //* maps all statuses with statusName, color and shows them at documentationView
  const populatedStatuses = useMemo(() => {
    if (!!Object.keys(categoryConfiguration)?.length) {
      return uniqBy(
        categoryConfiguration?.documentationsAvailable?.flatMap(
          ({ docStatuses = [] }) => docStatuses
        ),
        "statusName"
      ).map((el) => ({
        ...el,
        value: documentation.filter(
          ({ docStatus }) => docStatus === el?.statusName
        ).length,
      }));
    } else return [];
  }, [categoryConfiguration, documentation]);

  const onChange = (e) => {
    const dataTab = items?.find((el) => el?.key === e)?.label;
    setTab(dataTab);
    setActiveKey(e);
  };

  const getEmployeeDocs = () => {
    API.get("documentation", `/documentation`).then((r) => {
      let docs = r?.filter((el) => el?.recordId === employeeId);
      const expiring = docs?.find(({ expirationDate }) => {
        if (!!expirationDate) {
          return (
            dayjsTZ(expirationDate).format("DD/MM/YYYY") >
            dayjsTZ().format("DD/MM/YYYY")
          );
        }
      });
      setExpiringDocs(!!expiring);
      setDocumentation(docs);
      !docs?.length && setTemplateModalVisible(true);
    });
  };

  const dokumentacioneLabel = useMemo(() => {
    return (
      <span style={{ display: "flex", gap: 5 }}>
        {!!expiringDocs ? (
          <Tooltip
            title="Ky punonjës ka dokumente të cilat kanë skaduar."
            mouseEnterDelay={0.2}
            mouseLeaveDelay={0}
          >
            <DocumentationWarningIcon width={25} height={25} />
          </Tooltip>
        ) : (
          ""
        )}
        Dokumentacione
      </span>
    );
  }, [expiringDocs]);

  useEffect(() => {
    if (!!rowData) {
      setEmployee(rowData);
      getEmployeeDocs();
      setLoading(false);
    }
  }, [rowData]);

  const items = [
    {
      key: "1",
      label: "Personale",
      children: <EmployeesView />,
    },
    {
      key: "2",
      label: "Paga",
      children: <EmployeePayment />,
    },
    {
      key: "3",
      label: dokumentacioneLabel,
      children: <DocumentationView />,
    },
    {
      key: "4",
      label: "Lejet",
      children: <Raportet />,
    },
    {
      key: "5",
      label: "Oraret",
      children: <ClockIns />,
    },
  ];

  return (
    <PunonjesitContext.Provider
      value={{
        auth,
        loading,
        employee,
        activeKey,
        setLoading,
        accessToken,
        setEmployee,
        documentation,
        getEmployeeDocs,
        setDocumentation,
        populatedStatuses,
        templateModalVisible,
        categoryConfiguration,
        setTemplateModalVisible,
        setCategoryConfiguration,
      }}
    >
      <LoaderComponent loading={loading}>
        <div style={{ padding: 7 }}>
          <Breadcrumb>
            <Breadcrumb.Item
              onClick={() => {
                navigate("/punonjesit");
              }}
            >
              Punonjesit
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {`${employee?.employeeFirstName} ${employee?.employeeLastName}`}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{tab}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="dynamic-punojsit-tabs">
          <Tabs
            className="punojsit-tab"
            {...{
              onChange,
              activeKey,
              items,
            }}
          />
        </div>
      </LoaderComponent>
    </PunonjesitContext.Provider>
  );
};

export default DynamicPunojsitView;
