import { FileTextFilled, UploadIcon } from "../../../../assets";
import { useEffect, useState } from "react";
import driveApi from "../../../../../../DriveRequest/driveApi";
import { openInNewTab } from "../../../../../utils/openInNewTab";
import { CloseIcon } from "../../../../../../../assets/icons";
import { fileIcons } from "../../../../documentationViewData";
import sortBy from "lodash/sortBy";
import dayjsTZ from "../../../../../../../utils/dayjs";
import FilePreviewModal from "../../../FilePreviewModal/FilePreviewModal";
import "./DocUploader.scss";
import { useSelector } from "react-redux";
import { GPicker } from "../../../../../../GPicker";
import MondayButton from "../../../../../../commonComponents/MondayButton/MondayButton";
import { Form } from "antd";
import { useAuth } from "../../../../../../../authentication/authHelpers";
import DrivePermissionList from "../../../../../../DrivePermissionList/DrivePermissionList";
import { apiRoutes } from "../../../../../../../apiRoutes";
import { apiPut } from "../../../../../../DocumentationConfiguration/utils/api";

const DATE_FORMAT = "DD/MM/YYYY hh:mm a";
const ACCESS_RIGHTS = ["Admin", "Supervisor"];

/**
 *
 * @param {Array} uploadedFiles Array of the uploaded files.
 * @param {Function} onDelete On Delete button click callback function.
 * @param {Function} onPickerSuccess On file upload callback function.
 * @param {Function} accessToken API access token.
 * @param {Function} folderId Id of the target folder.
 */

const DocUploader = ({
  docType,
  onDelete,
  doc = {},
  folderId,
  disabled = false,
  uploadedFiles = [],
  onPickerSuccess = () => {},
}) => {
  const [form] = Form.useForm();
  const { userRole } = useAuth();
  const { accessToken } = useSelector((state) => state.accessToken);

  const driveRequest = driveApi({ accessToken });

  const [keylogs, setKeylogs] = useState([]);
  const [currentFile, setCurrentFile] = useState({});
  const [previewModalVisible, setPreviewModalVisible] = useState(false);

  const saveKeylogs = async () => {
    if (!!keylogs?.length) {
      await apiPut(apiRoutes.documentation, doc?.docId, {
        keylog: [...(doc?.keylog || []), ...keylogs],
      })
        .then(() => setKeylogs([]))
        .catch((err) => {
          console.log("err:", err);
        });
    }
  };
  useEffect(() => {
    saveKeylogs();
  }, [keylogs?.length]);

  return (
    <div className="docUploader">
      <div className="uploadSection">
        <FileTextFilled className="uploaderIcon" />
        <span className="uploaderTitle">Ju lutemi ngarkoni skedarin tuaj.</span>
        <GPicker
          token={accessToken}
          onAuthenticate={() => {}}
          createPicker={(google, oauthToken) => {
            const uploadView = new google.picker.DocsUploadView()
              .setIncludeFolders(false)
              .setParent(folderId);

            const picker = new window.google.picker.PickerBuilder()
              .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
              .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
              .enableFeature(google.picker.Feature.MINE_ONLY)
              .enableFeature(google.picker.Feature.NAV_HIDDEN)
              .addView(uploadView)
              .setOAuthToken(oauthToken)
              .setDeveloperKey("AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo")
              .setCallback(async (data) => {
                if (
                  data.action === google.picker.Action.PICKED &&
                  !!data.docs.length
                ) {
                  onPickerSuccess({
                    uploadedFiles: data.docs,
                  });
                }
              });
            picker.build().setVisible(true);
          }}
        >
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              Icon: <UploadIcon />,
              disabled: disabled || !folderId || !accessToken,
            }}
          >
            Zgjidhni Skedarin
          </MondayButton>
        </GPicker>
      </div>
      <div className="uploadedFilesSection">
        {sortBy(uploadedFiles, "lastEditedUtc")
          .reverse()
          ?.map(
            ({
              url,
              name = "",
              id,
              uploadedBy = "",
              uploadedAt,
              type,
              mimeType,
            }) => {
              const fileExtension = name.split(".").pop();

              return (
                <div className="fileCard" key={id}>
                  <div className="uploadedFile">
                    <div style={{ display: "flex" }}>
                      <span className="filesIcon">
                        {fileIcons({ type: fileExtension }) ||
                          fileIcons({ type: "image" })}
                      </span>
                      <span
                        className="fileName"
                        onClick={() => {
                          if (
                            type !== "photo" &&
                            mimeType !== "application/pdf"
                          )
                            openInNewTab(url);
                          else {
                            setPreviewModalVisible(true);
                            driveRequest.getImageSrc(id).then((file) => {
                              setCurrentFile({ ...file, url, mimeType });
                            });
                          }
                        }}
                      >
                        {name}
                      </span>
                    </div>
                    {!disabled && (
                      <span
                        className="closeIconContainer"
                        onClick={() => onDelete(id)}
                      >
                        <CloseIcon className="closeIcon" />
                      </span>
                    )}
                  </div>
                  <div className="fileAuthor">
                    <span>{uploadedBy}</span>
                    <span>{dayjsTZ(uploadedAt).format(DATE_FORMAT)}</span>
                  </div>
                </div>
              );
            }
          )}
      </div>
      {!!ACCESS_RIGHTS.includes(userRole) && (
        <DrivePermissionList {...{ form, folderId, setKeylogs, docType }} />
      )}

      {!!previewModalVisible ? (
        <FilePreviewModal
          {...{
            setVisible: () => {
              setCurrentFile({});
              setPreviewModalVisible(false);
            },
            file: currentFile,
          }}
        />
      ) : null}
    </div>
  );
};

export default DocUploader;
