import React, { useState, useContext } from "react";
import ActivityModal from "../components/ActivityModal/ActivityModal";
// import "./personal.scss";
import { Row, Col } from "antd";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import NameCard from "../components/NameCard";
import User from "./User/User";
import UserActionCards from "./User/UserActionCards";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import EmployeeWageCard from "./Wage/EmployeeWageCard";
import UserWageHistory from "../../../dashboard/UserDashboard/UserWageHistory";
import { useSelector } from "react-redux";
import { ComponentCard } from "../../../dashboard/AdminDashboard/Draggable/ComponentCard";
import PunonjesitContext from "../../store/PunonjesitContext";
import EditableCard from "./EditableCard/EditableCard";
import { personalNumber } from "../RegEx/RegExpressions";
import "./EmployeesView.scss";

// * @EneaXharau - Websocket handler and listener
// const socketHandler = new WebSocket(getSocketUrl("/dashboard/sockets"));

function EmployeesView() {
  const { employee, auth } = useContext(PunonjesitContext);
  const { programFields } = useSelector((state) => state.programFields);
  const { departments } = useSelector((state) => state.departments);
  const { employees } = useSelector((state) => state.employeesList);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [showPersonal, setShowPersonal] = useState(false);
  // const [showContact, setShowContact] = useState(false);
  // const [details, setDetails] = useState(false);
  // const [changes, setChanges] = useState([]);

  // will reuse this everywhere later
  // const handleChange = (objField, e, label) => {
  //   let test = objField;
  //   let changedObj = {
  //     activity: "Ndryshuar",
  //     author:
  //       auth?.user?.given_name +
  //       " " +
  //       auth?.user?.family_name,
  //     changeDate: moment().valueOf(),
  //     field: label,
  //     oldValue:
  //       typeof employee[`${test}`] == "boolean"
  //         ? `${employee[`${test}`]}`
  //         : employee[`${test}`],
  //     newValue: typeof e == "boolean" ? `${e}` : e,
  //   };
  //   setChanges([
  //     ...changes?.filter((el) => el.field !== changedObj.field),
  //     changedObj,
  //   ]);
  //   setFormData((prev) => ({ ...prev, [test]: e }));
  // };

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // * @EneaXharau - useEffect to run when component re-renders
  // * @EneaXharau - Added socket emitter
  // useEffect(() => {
  //   socketHandler.onmessage = (msg) => {
  //     const { request, body, id } = JSON.parse(msg.data);
  //     switch (request) {
  //       case "user_edit_received":
  //         {
  //           auth?.employeeId !== id && setEmployeesData(body);
  //         }
  //         break;
  //       default:
  //         break;
  //     }
  //   };
  // }, []);

  // * @EneaXharau - Dynamically change style={{height: 100%}} to help center loading component
  //region RETURN

  return (
    <div className="globalPageWrapper">
      <div className="employeesPersonaleView">
        <LoadableComp loading={!!employee ? false : true}>
          <Row className="editableCards">
            <NameCard />
            {personalCards({ programFields, employees, departments })?.map(
              (el) => (
                <EditableCard
                  title={el?.title}
                  employeeKeys={el?.fieldsJSON}
                  showOn={el?.showOn}
                />
              )
            )}
          </Row>
          <Row className="userActionCards">
            <UserActionCards
              headerName={"Statistika Pagat (deri sot)"}
              contentChild={
                <ComponentCard
                  blur={true}
                  content={<EmployeeWageCard {...{ employee }} />}
                  shadow={false}
                />
              }
            />
            <UserActionCards
              contentChild={
                <ComponentCard
                  blur={true}
                  blurDuration={30}
                  content={<UserWageHistory employee={employee?.employeeId} />}
                  shadow={false}
                />
              }
              headerName={"Historiku Pagat"}
            />
            <UserActionCards
              className="wageHistory"
              headerName={"Llogaria e punonjesit"}
              contentChild={<User {...{ employee }} />}
            />
          </Row>
          {!!isModalVisible ? (
            <ActivityModal
              setIsModalVisible={setIsModalVisible}
              keylog={employee?.keylog}
            />
          ) : null}
        </LoadableComp>
      </div>
    </div>
  );
  return (
    <div style={!!employee ? {} : { height: "100vh" }}>
      <LoadableComp loading={!!employee ? false : true}>
        <div>
          <Row
            className="employe-view-row"
            gutter={16}
            style={{ width: "100%", paddingRight: 20 }}
          >
            <Col span={6} style={{ paddingRight: 10, maxWidth: "100%" }}>
              <NameCard height={"300px"} />
            </Col>
            {personalCards({ programFields, employees, departments })?.map(
              (el) => (
                <Col span={6} style={{ paddingRight: 10, maxWidth: "100%" }}>
                  <EditableCard
                    title={el?.title}
                    employeeKeys={el?.fieldsJSON}
                    showOn={el?.showOn}
                  />
                </Col>
              )
            )}
            {/* <Col span={6}>
              <div>
                {showPersonal === false ? (
                  <DataCardInfoPersonal
                    handleClick={() => setShowPersonal(true)}
                  />
                ) : (
                  <PersonalInformation
                    handleHidePersonal={() => setShowPersonal(false)}
                    handleChange={handleChange}
                    changes={changes}
                    setChanges={setChanges}
                    socketHandler={() => {}}
                    // socketHandler ={socketHandler}
                  />
                )}
              </div>
            </Col> */}
            {/* <Col span={6}>
              <div>
                {showContact === false ? (
                  <ContactInfoCard handleClick={() => setShowContact(true)} />
                ) : (
                  <ContactInformation
                    handleHideContact={() => setShowContact(false)}
                    handleChange={handleChange}
                    changes={changes}
                    setChanges={setChanges}
                    // socketHandler={socketHandler}
                    socketHandler={() => {}}
                  />
                )}
              </div>
            </Col> */}
            {/* <Col
              span={6}
              style={{ paddingRight: 20, width: "100%", height: "100%" }}
            >
              <div className="position-item">
                {details === false ? (
                  <DetailsView
                    title={"Detaje"}
                    nameOfClass={"info-card"}
                    handleClick={() => setDetails(true)}
                  />
                ) : (
                  <Details
                    handleDetailsClose={() => setDetails(false)}
                    handleChange={handleChange}
                    changes={changes}
                    setChanges={setChanges}
                    // socketHandler={socketHandler}
                    socketHandler={() => {}}
                  />
                )}
              </div>
            </Col>{" "} */}
          </Row>
        </div>
        <Row
          className="responsive-second-row-employe"
          style={{ marginBottom: 30, width: "100%", height: "100%" }}
        >
          <Col
            span={6}
            style={{ paddingRight: 20, width: "100%", height: "100%" }}
          >
            <div
              className="position-item"
              style={{
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              <UserActionCards
                headerName={"Statistika Pagat (deri sot)"}
                contentChild={
                  <ComponentCard
                    blur={true}
                    content={<EmployeeWageCard {...{ employee }} />}
                    shadow={false}
                  />
                }
              />
            </div>
          </Col>
          <Col
            span={6}
            style={{
              paddingRight: 10,
              marginLeft: -10,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              className="position-item"
              style={{
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              <UserActionCards
                contentChild={
                  <ComponentCard
                    blur={true}
                    blurDuration={30}
                    content={
                      <UserWageHistory employee={employee?.employeeId} />
                    }
                    shadow={false}
                  />
                }
                headerName={"Historiku Pagat"}
              />
            </div>
          </Col>
          <Col style={{ marginLeft: 0, width: 770, height: "100%" }}>
            <UserActionCards
              className="wageHistory"
              headerName={"Llogaria e punonjesit"}
              contentChild={<User {...{ employee }} />}
            />{" "}
          </Col>
          {!!isModalVisible ? (
            <ActivityModal
              setIsModalVisible={setIsModalVisible}
              keylog={employee?.keylog}
            />
          ) : null}
        </Row>
      </LoadableComp>
    </div>
  );
}

export default EmployeesView;

//region ONLY LETTERS RULES
const onlyLettersRules = (field) => [
  {
    validator: (rule, value) => {
      if (
        value &&
        value?.split("")?.some?.((item) => {
          if (isNaN(item)) {
            return false;
          } else {
            return true;
          }
        })
      ) {
        return Promise.reject("Kjo fushë duhet të përmbajë vetëm shkronja");
      }
      return Promise.resolve();
    },
  },
];

//! birthday validation it does not allow you to select birthdate over 67 years and under 18 years
const disabledDate = (current) => {
  const youngerThan18 = dayjsTZ().subtract(18, "years").valueOf();
  const olderThan67 = dayjsTZ().subtract(67, "years").valueOf();

  return current > dayjsTZ(youngerThan18) || current < dayjsTZ(olderThan67);
};

//region BUILD FIELDS JSON
const personalCards = ({ programFields, employees, departments: deps }) => {
  const CONTRACT_LENGTHS = "01djhsgdfh-fuihwef-uifyuis39a7289d9";
  let contractLengths;
  const TEAMS = "2e9f7112-1775-4b69-b55e-3134333bfdf4";
  let teams;
  const GENDERS = "eb5833c8-06b5-47a2-bc68-7beb9570880c";
  let genders;
  const STATUSES = "ed8e0430-8509-11ec-bf83-199a45b1539d";
  let statuses;
  const EMPLOYMENT_TYPES = "f418a4ca-6c0c-479a-9c04-1e244cb581da";
  let employmentTypes;
  let departments = [];
  let supervisors;

  if (!!programFields?.length) {
    programFields?.forEach(({ fieldId = "", fieldOptions = [] }) => {
      switch (fieldId) {
        case CONTRACT_LENGTHS:
          contractLengths = fieldOptions?.map((el) => el.name);
          break;
        case TEAMS:
          teams = fieldOptions;
          break;
        case GENDERS:
          genders = fieldOptions;
          break;
        case STATUSES:
          statuses = fieldOptions;
          break;
        case EMPLOYMENT_TYPES:
          employmentTypes = fieldOptions;
          break;
      }
    });
  }
  if (!!deps?.length) {
    deps?.forEach((dep) => {
      const { departmentRoles, departmentName, ...rest } = dep;
      departments.push({
        ...rest,
        employeeDepartmentName: departmentName,
        employeeRoleName: departmentRoles?.map((el) => el?.name),
      });
    });
  }
  if (!!employees?.length) {
    supervisors = employees
      .filter(
        (el) =>
          el.employeeDepartmentName === "Administration" &&
          el.employeeRoleName === "Administrator"
      )
      .map((admin) => admin.employeeFirstName + ` ` + admin.employeeLastName);
  }
  return [
    {
      title: "Informacione personale",
      fieldsJSON: [
        {
          formItemName: "employeeFirstName",
          label: "Emri",
          placeholder: "Vendos emrin e punonjësit",
          type: "input",
          rules: onlyLettersRules("emrin"),
          required: true,
        },
        {
          formItemName: "employeeFathersName",
          label: "Atësia",
          placeholder: "Vendos atësinë e punonjësit",
          type: "input",
        },
        {
          formItemName: "employeeLastName",
          label: "Mbiemri",
          placeholder: "Vendos mbiemrin e punonjësit",
          type: "input",
          rules: onlyLettersRules("mbiemrin"),
          required: true,
        },
        {
          formItemName: "employeePersonalNumber",
          label: "Numri personal",
          placeholder: " --- ",
          type: "input",
          rules: [
            {
              required: true,
              message: "Ju lutem vendosni numrin e punonjesit!",
            },
            {
              pattern: personalNumber,
              message: "Kjo fushe duhet te permbaje vetem numra",
            },
          ],
          required: true,
        },
        {
          formItemName: "employeeGender",
          label: "Gjinia",
          type: "select",
          options: genders,
        },
        {
          formItemName: "employeeDayOfBirth",
          label: "Ditëlindja",
          placeholder: "Ditëlindja e punonjësit",
          type: "datepicker",
          format: "DD/MM/YYYY",
          onSaveFormat: "x", // will return timestamp
          disabledDate: disabledDate,
        },
        {
          formItemName: "breakTime",
          label: "Orari i pushimit",
          placeholder: "Orari i pushimit",
          type: "timerangepicker",
          format: "HH:mm",
          onSaveFormat: "HH:mm",
          disabledDate: disabledDate,
        },
        {
          formItemName: "isBreakTimePaid",
          label: "Pushim i paguar",
          type: "labeledcheckbox",
          labels: {
            checked: "I paguar",
            unchecked: "I pa paguar",
          },
        },
      ],
    },
    {
      title: "Informacione kontakti",
      fieldsJSON: [
        {
          formItemName: "employeeEmergencyContact",
          label: "Numri i emergjencës",
          placeholder: "",
          type: "phone",
        },
        {
          formItemName: "employeePhoneNumber",
          label: "Numri i celularit",
          placeholder: "",
          type: "phone",
        },
        {
          formItemName: "employeeEmailWork",
          label: "Email i punës",
          placeholder: "Shkruaj emailin e punës",
          type: "input",
        },
        {
          formItemName: "employeePersonalEmail",
          label: "Email personal",
          placeholder: "Shkruaj emailin personal",
          type: "input",
        },
        {
          formItemName: "employeeAddress",
          label: "Adresa",
          placeholder: "Shkruaj adresën e punonjësit",
          type: "textarea",
        },
      ],
    },
    {
      title: "Detaje",
      showOn: { key: "employeeStatus", value: "Aktiv" },
      fieldsJSON: [
        {
          formItemName: "employeeTeam",
          label: "Team",
          placeholder: "Zgjidh team",
          type: "select",
          options: teams,
          required: true,
          // rules: baseRules,
        },
        {
          formItemName: "employeeDepartmentName",
          label: "Departamenti",
          placeholder: "Zgjidh departamentin",
          type: "select",
          options: departments,
          optionsParentFor: "employeeRoleName",
          required: true,
          // rules: baseRules,
        },
        {
          formItemName: "employeeRoleName",
          label: "Pozicioni",
          placeholder: "Zgjidh pozicionin",
          type: "select",
          options: [],
          optionsOnParent: "employeeDepartmentName",
          primaryOptionsFor: "employeeOtherPosition",
          required: true,
          // rules: baseRules,
        },
        {
          formItemName: "employeeOtherPosition",
          label: "Pozicion tjetër",
          placeholder: "Zgjidh pozicionin dytësor",
          type: "select",
          options: [],
          secondaryOptions: "employeeRoleName",
        },
        {
          formItemName: "employeeStatus",
          label: "Statusi i punonjësit",
          placeholder: "Zgjidh statusin",
          type: "select",
          options: statuses,
          required: true,
          alwaysOnDisplay: true,
          // rules: baseRules,
        },
        {
          formItemName: "employeeHireDate",
          label: "Data e punësimit",
          placeholder: "Zgjidh datën e punësimit",
          type: "datepicker",
          format: "DD/MM/YYYY",
          onSaveFormat: "x",
          required: true,
          // rules: baseRules,
        },
        {
          formItemName: "employeeTypeOfEmployment",
          label: "Lloji i punësimit",
          placeholder: "Zgjidh llojin e punësimit",
          type: "select",
          options: employmentTypes,
          required: true,
          // rules: baseRules,
        },
        {
          formItemName: "employeeContractDuration",
          label: "Periudha e kontratës",
          placeholder: "Periudha e kontratës",
          type: "select",
          options: contractLengths,
          required: true,
          // rules: baseRules,
        },
        {
          formItemName: "employeeIsSupervisor",
          label: "Është supervizor",
          type: "checkbox",
          isParentRenderer: true,
          labels: {
            checked: "Po",
            unchecked: "Jo",
          },
        },
        {
          formItemName: "employeeSupervisor",
          label: "Supervizori",
          placeholder: "Zgjidh supervizorin",
          type: "select",
          options: supervisors,
          required: true,
          renderBy: "employeeIsSupervisor",
          renderOnParent: false,
          // rules: baseRules,
        },
        {
          formItemName: "employeeDateOfReEmployment",
          label: "Data e ripunësimit",
          placeholder: "Zgjidh datën e ripunësimit",
          type: "datepicker",
          format: "DD/MM/YYYY",
          onSaveFormat: "x",

          // disabledDate: (curr) =>
          //   curr < startDate || curr > moment().add(1, "week").valueOf(),
        },
      ],
    },
  ];
};
