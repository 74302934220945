import { Form, Input, InputNumber, message } from "antd";
import { useEffect, useState, useRef } from "react";

const CustomOtpInput = ({
  length,
  className,
  onChange,
  placeholder,
  successOTP,
  timedOut,
}) => {
  const refs = useRef([]);
  const [otp, setOtp] = useState([]);
  const [status, setStatus] = useState("");

  const submitOTP = (callback, time) => {
    let timeout;
    const later = () => {
      timeout = null;
      callback();
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, time);
  };

  const handleInput = (key, idx) => {
    const num = parseInt(key);
    if (typeof num === "number") {
      const newOtp = [...otp];
      newOtp[idx] = num;
      setOtp(newOtp);
      handleFocus(idx + 1);
      if (
        idx === length - 1 &&
        otp.filter((el) => typeof el === "number").length === length - 1
      ) {
        handleFinish(newOtp);
      }
    }
  };
  const handleFinish = (newOtp) => {
    const input = newOtp?.join("");
    if (successOTP === input && !timedOut) {
      setStatus("success");
      message.success({
        content: "Kodi eshte i sakte!",
        key: "login",
      });
      refs.current.forEach((ref) => {
        ref.blur();
      });
      submitOTP(() => {
        onChange(input);
      }, 500);
    } else {
      setStatus("fail");
      message.error({
        content: "Kodi i vendosur nuk eshte i sakte!",
        key: "login",
      });
    }
  };
  const handleFocus = (index) => {
    if (typeof index === "number") {
      refs.current?.[index]?.focus();
    }
  };
  const handleKeyPress = (e, idx) => {
    const codes = ["Numpad", "Digit"];
    if (codes.some((code) => e?.code?.startsWith(code))) {
      e.preventDefault();
      handleInput(e.key, idx);
    } else if (e.key === "Backspace") {
      e.preventDefault();
      if (idx === length - 1) {
        setStatus("");
      }
      const newOtp = [...otp];
      newOtp[idx] = null;
      setOtp(newOtp);
      handleFocus(idx - 1);
    } else if (e.key === "ArrowLeft" && idx > 0) {
      handleFocus(idx - 1);
    } else if (e.key === "ArrowRight" && idx < length - 1) {
      handleFocus(idx + 1);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text/plain");
    const numericRegex = /^[0-9]*$/;

    if (numericRegex.test(pastedData) && pastedData.length === length) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);
      handleFinish(newOtp);
      handleFocus(length - 1);
    }
  };

  useEffect(() => {
    setOtp(new Array(length).fill(null))
    if(!timedOut){
      setStatus("")
    }
  }, [successOTP, timedOut])

  return (
    <div className={`otpInput ${!!className ? className : ""} ${status}`}>
      {otp?.map((num, idx) => (
        <div className={"otpInputNum"} onClick={() => handleFocus(idx)}>
          <InputNumber
            key={idx}
            controls={false}
            maxLength={1}
            changeOnWheel={false}
            defaultValue={num}
            value={num}
            ref={(el) => (refs.current[idx] = el)}
            onKeyDown={(e) => handleKeyPress(e, idx)}
            keyboard={true}
            onPaste={handlePaste}
            placeholder={placeholder}
          />
        </div>
      ))}
    </div>
  );
};

export default CustomOtpInput;
