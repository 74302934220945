import React from "react";

export default () => (
  <svg
    width="60"
    height="45"
    viewBox="0 0 60 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.3636 19.6875H40.9091C40.1364 19.6875 39.4886 19.418 38.9659 18.8789C38.4432 18.3398 38.1818 17.6719 38.1818 16.875C38.1818 16.0781 38.4432 15.4102 38.9659 14.8711C39.4886 14.332 40.1364 14.0625 40.9091 14.0625H46.3636V8.4375C46.3636 7.64062 46.625 6.97266 47.1477 6.43359C47.6705 5.89453 48.3182 5.625 49.0909 5.625C49.8636 5.625 50.5114 5.89453 51.0341 6.43359C51.5568 6.97266 51.8182 7.64062 51.8182 8.4375V14.0625H57.2727C58.0455 14.0625 58.6932 14.332 59.2159 14.8711C59.7386 15.4102 60 16.0781 60 16.875C60 17.6719 59.7386 18.3398 59.2159 18.8789C58.6932 19.418 58.0455 19.6875 57.2727 19.6875H51.8182V25.3125C51.8182 26.1094 51.5568 26.7773 51.0341 27.3164C50.5114 27.8555 49.8636 28.125 49.0909 28.125C48.3182 28.125 47.6705 27.8555 47.1477 27.3164C46.625 26.7773 46.3636 26.1094 46.3636 25.3125V19.6875ZM21.8182 22.5C18.8182 22.5 16.25 21.3984 14.1136 19.1953C11.9773 16.9922 10.9091 14.3438 10.9091 11.25C10.9091 8.15625 11.9773 5.50781 14.1136 3.30469C16.25 1.10156 18.8182 0 21.8182 0C24.8182 0 27.3864 1.10156 29.5227 3.30469C31.6591 5.50781 32.7273 8.15625 32.7273 11.25C32.7273 14.3438 31.6591 16.9922 29.5227 19.1953C27.3864 21.3984 24.8182 22.5 21.8182 22.5ZM0 39.375V37.125C0 35.5312 0.397727 34.0664 1.19318 32.7305C1.98864 31.3945 3.04545 30.375 4.36364 29.6719C7.18182 28.2188 10.0455 27.1289 12.9545 26.4023C15.8636 25.6758 18.8182 25.3125 21.8182 25.3125C24.8182 25.3125 27.7727 25.6758 30.6818 26.4023C33.5909 27.1289 36.4545 28.2188 39.2727 29.6719C40.5909 30.375 41.6477 31.3945 42.4432 32.7305C43.2386 34.0664 43.6364 35.5312 43.6364 37.125V39.375C43.6364 40.9219 43.1023 42.2461 42.0341 43.3477C40.9659 44.4492 39.6818 45 38.1818 45H5.45455C3.95455 45 2.67045 44.4492 1.60227 43.3477C0.534091 42.2461 0 40.9219 0 39.375Z"
      fill="#20AC9D"
    />
  </svg>
);
