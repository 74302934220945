import { message } from "antd";
import { groupBy } from "lodash";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs"
import _ from "lodash";

/**
 * A function that calculates all hours of employee and matches employeeId, with Employee Name
 * @param {Array} emplArr | Array of objects with Employees
 * @param {Array} clockingsArr | Array of objects with Employees
 * @param {Moment} monthInstance | Moment Instance used for Moment actions
 */
export const calculateMonthHoursAllEmp = (emplArr, clockingsArr, monthInstance) => {
  let toReturn = [];
  if (Array.isArray(emplArr) && Array.isArray(clockingsArr)) {
    let startMonth = dayjsTZ(monthInstance).startOf("month").valueOf();
    let endMonth = dayjsTZ(monthInstance).endOf("month").valueOf();
    // console.log("Clockings", clockingsArr, emplArr);
    try {
      let result = clockingsArr
        .filter(
          (el) =>
            el.clockInDate > startMonth &&
            el.clockOutDate < endMonth &&
            !!el.clockOutDate &&
            !!el.clockInDate
        )
        .map((c, id) => {
          let r = emplArr.find((emp) => emp.employeeId === c.employeeId);
          c.employeeFirstName = r ? r.employeeFirstName : null;
          c.employeeLastName = r ? r.employeeLastName : null;
          c.hoursWorked = dayjsTZ(c.clockOutDate).diff(c.clockInDate, "hour", true).toFixed(2);
          return c;
        });
      let result2 = result.length > 0 ? _.groupBy(result, "employeeId") : {};

      Object.entries(result2).forEach(([key, value]) => {
        let hours = 0;
        value.forEach((el) => {
          hours = parseFloat(hours) + parseFloat(el.hoursWorked);
        });
        toReturn.push({
          employeeFirstName: value[0].employeeFirstName,
          employeeLastName: value[0].employeeLastName,
          employeeId: value[0].employeeId,
          totalHours: parseFloat(hours),
        });
      });
      // console.log("result", toReturn);
      return toReturn.sort((a, b) => b.totalHours - a.totalHours);
    } catch (err) {
      message.error("Nodhi nje gabim ne llogaritjen e te dhenave!");
      throw new Error(err);
      // return toReturn;
    }
  }
};

const joinEmployeeWithDepartment = (tableData, department) => {
  const modified = tableData.map((employe) => {
    const res = department.find((depart) => depart.departmentId === employe.employeeDepartmentId);
    employe.employeeDepartmentName = res ? res.departmentName : null;
    return employe;
  });
  // console.log("Objekti i modifikuar", modified);
  return modified;
};
