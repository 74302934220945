import { CaretDownFilled } from "@ant-design/icons";
import { Select } from "antd";
import { useMemo } from "react";
import { ClearIcon } from "../../../dashboard/assets";

/**
 * @property {Object} gridApi
 * @property {Array<Object>} gridData
 * @property {string} keyName key that exists in AgGrid's columnDefs to use on filter
 * @property {string} placeholder
 * @property {string} icon
 */

/**
 *
 * @param {props} props
 * @returns
 */

const DropdownAgGrid = ({
  gridApi,
  gridData,
  keyName,
  placeholder,
  icon,
  className,
  style,
  value,
  setValue,
}) => {
  const { Option } = Select;

  const onChange = async (e) => {
    if (e !== undefined) {
      await gridApi.getColumnFilterInstance(keyName).then((comp) => {
        comp.setModel({
          type: "contains",
          filter: e,
        });
      });
      gridApi.onFilterChanged();
    } else {
      gridApi.destroyFilter(keyName);
    }
    setValue((prev) => ({ ...prev, [keyName]: e }));
  };

  const options = useMemo(() => {
    const items = [];
    if (!gridData?.length) {
      return items;
    }
    const data = gridData?.map((row) => row?.[keyName])?.filter(Boolean);
    data?.forEach((el) => {
      if (!items?.includes(el)) {
        items.push(el);
      }
    });
    return items;
  }, [gridData]);

  return (
    <div
      className={`headerDropdown ${className}`}
      data-testid="headerDropdown"
      style={style}
    >
      <span className="icon">{icon}</span>
      <Select
        suffixIcon={
          <CaretDownFilled style={{ color: style?.fill || "#323338" }} />
        }
        placeholder={placeholder}
        onChange={onChange}
        style={style}
        value={value}
      >
        {options?.map((option, idx) => (
          <Option value={option} key={idx}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default DropdownAgGrid;
