import defaultDayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import weekday from "dayjs/plugin/weekday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/sq";

defaultDayjs.locale("sq");
defaultDayjs.extend(utc);
defaultDayjs.extend(timezone);
defaultDayjs.extend(customParseFormat);
defaultDayjs.extend(duration);
defaultDayjs.extend(relativeTime);
defaultDayjs.extend(isSameOrBefore);
defaultDayjs.extend(isBetween);
defaultDayjs.extend(isSameOrAfter);
defaultDayjs.extend(weekday);
defaultDayjs.extend(localizedFormat);

const guessedTimeZone = defaultDayjs.tz.guess();
// defaultDayjs.tz.setDefault("America/New_York");

if (guessedTimeZone.startsWith("America/")) {
  defaultDayjs.tz.setDefault("Europe/Tirane");
}

const dayjsTZ = (...args) => {
  return defaultDayjs(...args).tz();
};

const timezonedUnix = (value) => {
  return defaultDayjs.unix(value).tz();
};

dayjsTZ.unix = timezonedUnix;

export default dayjsTZ;
