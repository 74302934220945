import React, { useState, useEffect } from "react";
import { Row, Button, Skeleton } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import Cardkonfig from "../../dynamic components/Card";
import "./sistemi_llojet_kerkesave.scss";
import { DeleteIcon } from "../../../../assets/icons";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { API } from "aws-amplify";
import StatusiKerkesave from "./StatusiKerkesave";
import LlojKerkesave from "./LlojKerkesave";
import { htmlParser } from "../../../../utils/htmlParser";
import StatusModal from "./components/StatusModal";
import KerkeseModal from "./components/KerkeseModal";
import GlobalPopupKonfirm from "../../../commonComponents/GlobalPopupKonfirm";
import { useMediaQuery } from "react-responsive";
import MobileLlojetKerkesave from "./components/MobileLlojetKerkesave";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../../authentication/authHelpers";
import LlojetKerkesaveLogs from "./components/LlojetKerkesaveLogs";
import AgGridHeader from "../../../commonComponents/AgGridHeader/AgGridHeader";
import { LogsIcon } from "../../../Documentation/View/assets";

function SistemiLlojetKerkesave() {
  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [fields, setFields] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false);
  const [dataLista, setDataLista] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [logsModal, setLogsModal] = useState(false);
  let auth = useAuth();

  const username = {
    userId: auth.userAccess[0].userSub,
    employeeFirstName: auth.userAccess[0].given_name,
    employeeLastname: auth.userAccess[0].family_name,
  };

  const handleRequestDelete = (id) => {
    let newArray = tableData.filter((r) => r.statusId !== id);

    let item = tableData.find((r) => r.statusId === id);

    let logs = data.fieldOptions.find((item) => item.name === "Logs");
    let newLogsValue = {
      id: uuidv4(),
      activity: "U Hoq",
      author: username,
      changeDate: Date.now(),
      currentData: item,
    };
    if (logs) {
      logs?.value?.push(newLogsValue);
    } else {
      logs = {
        id: uuidv4(),
        name: "Logs",
        value: [newLogsValue],
      };
    }
    API.put(
      "programFields",
      "/programFields/e480aa5f-a671-4781-a8bb-89876006cc90",
      {
        body: {
          fieldOptions: [...newArray, logs],
        },
      }
    );
    setTableData(newArray);
    setData({ ...data, fieldOptions: [...newArray, logs] });
  };

  const columnDefs = [
    {
      headerName: "Titulli i kerkeses",
      field: "statusTitle",
      filter: "agTextColumnFilter",
      flex: 4,
      cellRenderer: (params) => (
        <div className="request-title-2">
          <p onClick={() => handelshowRequest(params.node.data)}>
            {params.node.data.statusTitle}
          </p>
        </div>
      ),
    },
    {
      headerName: "Krijuar nga",
      field: "statusCreator",
      filter: "agTextColumnFilter",
      flex: 3,
    },
    {
      headerName: "Krijuar më",
      field: "statusCreationDate",
      filter: "agTextColumnFilter",
      flex: 4,
      cellRenderer: (params) => {
        try {
          return dayjsTZ(params.value).format("DD/MM/YYYY - HH:MM");
        } catch (err) {
          console.error("err");
        }
      },
    },
    {
      headerName: "Pershkrimi",
      field: "statusDescription",
      filter: "agTextColumnFilter",
      flex: 4,
      cellRenderer: ({ value }) => htmlParser(value),
    },
    {
      headerName: "Statuset",
      field: "statusName",
      filter: "agTextColumnFilter",
      flex: 3,
      cellRenderer: (params) => (
        <div cursor="pointer" className="column-paisjet-orarit-lista">
          <Button
            className="lista-paisjet-btn"
            onClick={() => handelshowLista(params.node.data)}
          >
            Statuset
          </Button>
        </div>
      ),
    },
    {
      headerName: "Fshi",
      field: "",
      filter: "agTextColumnFilter",
      flex: 1,
      cellRenderer: (params) => (
        <div className="iconat-paisjet">
          {" "}
          <GlobalPopupKonfirm
            Button={
              <div variant="outlined" cursor="pointer" className="icon-delete">
                <DeleteIcon width={17} height={17} />
              </div>
            }
            title={"Jeni i sigurt qe doni te fshini këtë lloj kërkesë?"}
            onConfirm={() => handleRequestDelete(params.node.data.statusId)}
            okText={"Po"}
            cancelText={"Jo"}
          />
        </div>
      ),
    },
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
    let allColIds = params.api.getColumns().map((column) => column.colId);
    params.api.autoSizeAllColumns(allColIds);
  };

  const handelshowLista = (row) => {
    setIsModalVisible(true);
    setDataLista(row);
  };

  const handelshowRequest = (row) => {
    setIsRequestModalVisible(true);
    setRequestData(row);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fieldsData = await API.get(
        "programFields",
        "/programFields/e480aa5f-a671-4781-a8bb-89876006cc90"
      );
      if (Object.keys(fieldsData).length > 0) {
        setTableData(
          fieldsData.fieldOptions.filter((item) => item.name !== "Logs")
        );
        setData(fieldsData);
      }
    };
    fetchData();
  }, []);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });
  console.log(data);
  return (
    <div className="sistemi-llojet-kerkesave">
      <Row className="sistemi-llojet-kerkesave-row">
        <Cardkonfig
          contentChild={
            <LlojKerkesave
              tableData={tableData}
              setTableData={setTableData}
              fields={fields}
              setFields={setFields}
              gridApi={gridApi}
              data={data}
              setData={setData}
            />
          }
          width="100%"
          title="Shtimi i llojit të kërkesës"
        />
        <StatusiKerkesave fields={fields} setFields={setFields} />
      </Row>
      {isMobileView ? (
        <div className="punonjesit-ag-grid">
          <AgGridHeader
            items={{
              icons: {
                modals: [
                  {
                    onClick: () => setLogsModal(true),
                    icon: <LogsIcon width={20} height={20} fill="#323338" />,
                  },
                ],
              },
            }}
          />
          <div
            className="ag-theme-alpine"
            style={{ height: "100%", width: "100%" }}
          >
            {tableData.length > 0 ? (
              <AgGridComponent
                // resizable={true}
                rowData={tableData}
                rowSelection="single"
                onGridReady={onGridReady}
                paginationPageSize={15}
                columnDefs={columnDefs}
                // defaultColDef={{ flex: 1 }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </div>
      ) : (
        <MobileLlojetKerkesave tableData={tableData} />
      )}
      {isModalVisible ? (
        <StatusModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          {...{ dataLista }}
        />
      ) : null}
      {isRequestModalVisible ? (
        <KerkeseModal
          isRequestModalVisible={isRequestModalVisible}
          setIsRequestModalVisible={setIsRequestModalVisible}
          {...{ requestData }}
          tableData={tableData}
          setTableData={setTableData}
        />
      ) : null}
      {logsModal && (
        <LlojetKerkesaveLogs
          isVisible={logsModal}
          setIsVisible={setLogsModal}
          data={data}
        />
      )}
    </div>
  );
}

export default SistemiLlojetKerkesave;
