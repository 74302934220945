import "./ShowContactDetailsSecondModal.scss";
import React, { useEffect, useState } from "react";
import { Typography, Button, Modal, Space, message, Tag } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import QuillRenderer from "../Components/QuillRenderer";
import moment from "moment";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { ModalXIcon } from "../../../../../assets/icons";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

const ShowContactDetailsSecondModal = ({
  showSecondLayoutStatus,
  setShowSecondLayoutStatus,
  temporaryArray,
}) => {
  return (
    <Modal
      mask={false}
      open={showSecondLayoutStatus}
      title="Avantazhet dhe disavantazhet"
      onCancel={() => {
        setShowSecondLayoutStatus(false);
      }}
      data-testid="contactDetailsSecondModal"
      destroyOnClose={true}
      centered={true}
      closeIcon={<ModalXIcon />}
      className="showContactDetailsSecondModal lightHeader"
      wrapClassName="showContactDetailsSecondModalWrap"
      footer={[
        <MondayButton
          className="mondayButtonRed"
          onClick={() => {
            setShowSecondLayoutStatus(!showSecondLayoutStatus);
          }}
        >
          Mbyllni Faqen
        </MondayButton>,
      ]}
    >
      <div className="container">
        <div className="container-content">
          <div className="content-first-container">
            <p className="quill-header">Avantazhet</p>
            <div className="quill-container">
              {Array.isArray(temporaryArray?.applicantAdvantages) ? (
                <div className="tag-container">
                  {temporaryArray?.applicantAdvantages?.map(
                    (listItems, index) => (
                      <Tag key={index}>#{listItems}</Tag>
                    )
                  )}
                </div>
              ) : (
                <QuillRenderer value={temporaryArray?.applicantAdvantages} />
              )}
            </div>
          </div>
          <div className="divider"></div>
          <div className="content-second-container">
            <p className="quill-header">Disavantazhet</p>
            <div className="quill-container">
              {Array.isArray(temporaryArray?.applicantDisadvantages) ? (
                <div className="tag-container">
                  {temporaryArray?.applicantDisadvantages?.map(
                    (listItems, index) => (
                      <Tag key={index}>#{listItems}</Tag>
                    )
                  )}
                </div>
              ) : (
                <QuillRenderer value={temporaryArray?.applicantDisadvantages} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShowContactDetailsSecondModal;
