import { message } from "antd";
import { API } from "aws-amplify";
import { REGULAR_USER } from "./regularUser";
// export const REGULAR_USER = {
// 	userRole: "Employee",
// 	accessConfig: {
// 		routeConfig: [
// 			{
// 				exact: false,
// 				path: "/kryefaqja",
// 				title: "Kryefaqja",
// 				auth: true,
// 				write: true,
// 			},
// 			{
// 				exact: false,
// 				path: "/prezencat",
// 				title: "Prezencat",
// 				auth: true,
// 				write: true,
// 			},
// 			{
// 				exact: false,
// 				path: "/konfigurimet",
// 				title: "Konfigurimet",
// 				auth: true,
// 				write: true,
// 			},
// 			{
// 				exact: true,
// 				path: "/konfigurimet/kompania/profili",
// 				title: "Kompania profili",
// 				auth: true,
// 				write: true,
// 			},
// 			{
// 				exact: false,
// 				path: "/konfigurimet/preferencat",
// 				title: "Preferencat",
// 				auth: true,
// 				write: true,
// 			},
// 		],
// 		sidebarConfig: [
// 			[
// 				{ title: "Kryefaqja", id: 1, to: "/kryefaqja" },
// 				{ title: "Listë Prezenca", id: 3, to: "/prezencat" },
// 				{
// 					title: "Konfigurimet",
// 					auth: true,
// 					id: 55,
// 					to: "/konfigurimet",
// 				},
// 			],
// 		],
// 		settingsConfig: [
// 			{
// 				title: "Profili",
// 				auth: true,
// 				SubmenuKey: "sub0",
// 				icon: "ProfileIcon",
// 				to: "profile",
// 				submenu: [],
// 				parent: false,
// 			},
// 			{
// 				title: "Preferencat",
// 				SubmenuKey: "sub6",
// 				icon: "PerdoruesitKofigIcon",
// 				auth: true,
// 				submenu: [],
// 				parent: false,
// 				to: "preferencat",
// 			},
// 		],
// 	},
// };
export const ADMIN_USER = {
	userRole: "Admin",
	accessConfig: {
		routeConfig: [
			{
				exact: false,
				path: "/kryefaqja",
				title: "Kryefaqja",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/punonjesit",
				title: "Punonjesit",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/departamentet",
				title: "Departamentet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/lajmerimet",
				title: "Lajmerimet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/intervistat",
				title: "Intervistat",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/dokumentacione",
				title: "Dokumentacione",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/punonjesit/specifikat",
				title: "Specifikat Punonjesit",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/pagat",
				title: "Pagat",
				auth: true,
				write: true,
			},
			{
				exact: true,
				path: "/pagat/llogaritja",
				title: "Llogaritja Pagave",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/prezencat",
				title: "Prezencat",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/raportet",
				title: "Raportet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/raportet/gjenero",
				title: "Gjenerim Raportesh",
				auth: true,
				write: true,
			},

			{
				exact: false,
				path: "/aprovimet",
				title: "Aprovimet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet",
				title: "Konfigurimet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/kompania/info/rreth/komapnise",
				title: "Informacione Rreth Kompanise",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/kompania/pozicionet",
				title: "Kompania pozicionet",
				auth: true,
				write: true,
			},
			{
				exact: true,
				path: "/konfigurimet/kompania/rolet/:departmentId",
				title: "Kompania rolet",
				auth: true,
				write: true,
			},
			{
				exact: true,
				path: "/konfigurimet/kompania/profili",
				title: "Kompania profili",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/sistemi/pajisjet/orarit",
				title: "Sistemi paisjet e orarit",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/sistemi/oraret/punes",
				title: "Sistemi oraret e punës",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/sistemi/turnet",
				title: "Sistemi turnet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/sistemi/statuset/punojesve",
				title: "Sistemi statuset e punojsve",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/sistemi/arsyet/largimit",
				title: "Sistemi arsyet e largmimit",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/sistemi/llojet/kerkesave",
				title: "Sistemi llojet e kërkesave",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/sistemi/dokumentacionet",
				title: "Sistemi dokumentacionet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/politikat/mungesave",
				title: "Politikat e mungesave",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/politikat/ditet/pushimit",
				title: "Politikat e ditëve pushim",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/politikat/festave/zyrtare",
				title: "Politikat e festave zyrtare",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/politikat/menaxhimi/orve",
				title: "Politikat menaxhimi i orve",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/politikat/oreve/jashte/orarit",
				title: "Politikat e orve jashtë orarit",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/politikat/rumbullakimit",
				title: "Politikat e rrumbullakimit",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/pagat/fitimet",
				title: "Pagat fitimet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/pagat/zbritje",
				title: "Pagat zbritjet",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/pagat/pagesat",
				title: "Mënyrat e pagesës",
				auth: true,
				write: true,
			},
			{
				exact: true,
				path: "/konfigurimet/pagat/kalkulimi",
				title: "Pagat Eksportet Listes",
				auth: true,
				write: true,
			},
			//Access Rights UI :::: Limited Access!
			{
				exact: false,
				path: "/konfigurimet/perdoruesit/:identityId",
				title: "Rolet e siguris",
				auth: true,
				write: true,
			},
			{
				exact: false,
				path: "/konfigurimet/perdoruesit",
				title: "Siguria e përdorusit",
				auth: true,
				write: true,
			},
		],
		sidebarConfig: [
			[
				{ title: "Kryefaqja", id: 1, auth: true, to: "/kryefaqja" },
				{ title: "Punonjësit", id: 2, auth: true, to: "/punonjesit" },
				{ title: "Listë Prezencat", id: 3, auth: true, to: "/prezencat" },
				{ title: "Aprovimet", id: 9, auth: true, to: "/aprovimet" },
				{ title: "Pagat", id: 4, auth: true, to: "/pagat" },
				{
					title: "Raportet",
					id: 5,
					auth: true,
					to: "/raportet",
				},
				{ title: "Dokumentacione", id: 6, auth: true, to: "/dokumentacione" },
				{ title: "Departamentet", id: 8, auth: true, to: "/departamentet" },
				{
					title: "Konfigurimet",
					auth: true,
					id: 55,
					to: "/konfigurimet",
				},
				{
					title: "Intervistat",
					auth: true,
					id: 11,
					to: "/intervistat",
				},
			],
		],
		settingsConfig: [
			{
				title: "Profili",
				auth: true,
				SubmenuKey: "sub0",
				icon: "ProfileIcon",
				to: "profile",
				submenu: [],
				parent: false,
			},
			{
				title: "Kompania",
				auth: true,
				SubmenuKey: "sub1",
				submenu: [
					{
						id: 1,
						auth: true,
						to: "kompania/info/rreth/komapnise",
						title: "Informacione rreth kompanisë",
					},
					{
						key: 2,
						auth: true,
						to: "kompania/pozicionet",
						title: "Pozicionet",
					},
				],
			},
			{
				title: "Sistemi",
				SubmenuKey: "sub2",
				auth: true,
				submenu: [
					{
						key: "3",
						auth: true,
						to: "sistemi/pajisjet/orarit",
						title: "Pajisjet e orarit",
					},
					{
						key: "4",
						auth: true,
						to: "sistemi/turnet",
						title: "Turnet",
					},
					{
						key: 7,
						auth: true,
						to: "sistemi/arsyet/largimit",
						title: "Arsyet e largimeve",
					},
					{
						key: 8,
						auth: true,
						to: "sistemi/statuset/punojesve",
						title: "Statuset e punojësve",
					},
					{
						key: 9,
						auth: true,
						to: "sistemi/llojet/kerkesave",
						title: "Llojet e kërkesave",
					},
					// {
					// 	key: 10,
					// 	auth: true,
					// 	to: "sistemi/dokumentacionet",
					// 	title: "Konfigurimi i Dokumentacioneve",
					// },
					{
						key: 10,
						title: "Konfigurimi i Adresave IP",
						to: "sistemi/adresatIp",
						auth: true,
					},
				],
			},
			{
				title: "Politikat",
				SubmenuKey: "sub3",
				auth: true,
				submenu: [
					{
						key: 12,
						auth: true,
						to: "politikat/ditet/pushimit",
						title: "Politikat për ditët e pushimit",
					},
					{
						key: 13,
						auth: true,
						to: "politikat/festave/zyrtare",
						title: "Politikat e festave zyrtare",
					},
					{
						key: 15,
						auth: true,
						to: "politikat/oreve/jashte/orarit",
						title: "Politikat e orëve jashtë orarit",
					},
				],
			},
			{
				title: "Pagat",
				SubmenuKey: "sub4",
				auth: true,
				submenu: [
					{
						key: 17,
						to: "pagat/fitimet",
						title: "Fitimet",
						auth: true,
					},
					{
						key: 18,
						to: "pagat/zbritje",
						auth: true,
						title: "Zbritje",
					},
					{
						key: 19,
						auth: true,
						to: "pagat/pagesat",
						title: "Mënyrat e pagesës",
					},
					{
						key: "20",
						auth: true,
						to: "pagat/kalkulimi",
						title: "Pagat Eksportet Listes",
					},
				],
			},
			{
				title: "Përdoruesit",
				SubmenuKey: "sub5",
				auth: true,
				icon: "SiguriaIcon",
				submenu: [],
				parent: false,
				to: "perdoruesit",
			},
			{
				title: "Konfigurimi i Kandidateve",
				SubmenuKey: "sub6",
				icon: "PerdoruesitKofigIcon",
				auth: true,
				submenu: [],
				parent: false,
				to: "KonfigurimiKandidateve",
			},
			{
				title: "Preferencat",
				SubmenuKey: "sub7",
				icon: "PerdoruesitKofigIcon",
				auth: true,
				submenu: [],
				parent: false,
				to: "preferencat",
			},

		],
	},
};
export const assignAccessRights = async (userType, identitiyId) => {
	await API.get("userGroups", "/userGroups")
		.then(async (res) => {
			if (userType === "Admin") {
				let applied = res.find((item) => item.userRole === "Admin");
				return await API.put("users", `/users/${identitiyId}`, {
					body: {
						userRole: "Admin",
						accessConfig: applied.accessConfig,
					},
				})
					.then((success) => {
						message.success("Admini ka te drejta default.");
						console.log(success);
					})
					.catch((err) => {
						message.error("Ndodhi nje gabim!");
						console.log("Error Instance", err);
					});
			} else {
				let applied = res.find((item) => item.userRole === "Employee");
				return await API.put("users", `/users/${identitiyId}`, {
					body: {
						userRole: "Employee",
						accessConfig: applied.accessConfig,
					},
				})
					.then((success) => {
						message.success("User ka te drejta default.");
						console.log(success);
					})
					.catch((err) => {
						message.error("Ndodhi nje gabim!");
						console.log("Error Instance", err);
					});
			}
		})
		.catch((err) => {
			console.error(err);
			message.error("Ndodhi nje gabim ne access rights!");
		});
};
