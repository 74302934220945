import colorShade from "./colorShade";

const getStyles = (color, mode, theme) => {
  const styles = {
    "--ag-header-background-color": !!mode ? colorShade(color, 70) : color,
    "--ag-odd-row-background-color": !!mode
      ? colorShade(color, 70)
      : colorShade(color, 0.9),
    [`--ag${theme !== "quartz" ? `-${[theme]}` : ""}-active-color`]: color,
    "--ag-tooltip-background-color": color,
    "--ag-selected-row-background-color": !!mode
      ? colorShade(color, 0.3)
      : colorShade(color, 0.65),
    "--ag-checkbox-background-color": "#fbfbfb",
    "--ag-checkbox-checked-color": color,
    "--ag-background-color": !!mode ? colorShade(color, 50) : "#fbfbfb",
    "--ag-foreground-color": !!mode ? "#fbfbfb" : "#111",
  };

  return styles;
};

const getAgThemePreference = () => {
  const ls = JSON.parse(sessionStorage.getItem("agThemePreference"));

  if (!!ls) {
    let { theme, mode, accentColor } = ls;
    mode = mode === "dark" ? "-dark" : "";

    const style = getStyles(accentColor, mode, theme);

    return { theme: `ag-theme-${theme}${mode}`, style, accentColor };
  } else {
    const style = getStyles("#54afff", "-dark", "alpine");
    sessionStorage.setItem(
      "agThemePreference",
      JSON.stringify({ theme: "alpine", mode: "dark", accentColor: "#54afff" })
    );

    return { theme: "ag-theme-alpine-dark", style, accentColor: "#54afff" };
  }
};
export default getAgThemePreference;
