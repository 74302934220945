import React, { useState, useContext, useEffect } from "react";
import { Row, Col, message, Button } from "antd";
import { API } from "aws-amplify";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";
import { useAuth } from "../../../../../../authentication/authHelpers";
import { NotifContext } from "../../../../components/notifications/context/notifContext";
import { LajmerimeIcon } from "../../../../../../assets/icons";
import { htmlParser } from "../../../../../../utils";
import GlobalPopupKonfirm from "../../../../../commonComponents/GlobalPopupKonfirm";
import { HollowDotsSpinner } from "react-epic-spinners";
import { requestAllPages } from "../../../../../../helpers/API/RequestFactory";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";

function Pervjetore() {
  const { dispatch, notificationsArr } = useContext(NotifContext);
  //state with all notifications return array
  const [tableData, setTableData] = useState(null);
  // state with selected notification return object
  const [content, setContent] = useState(null);
  // hook with authenticated user data
  let auth = useAuth();

  // get notifications form API
  const getNotification = async () => {
    await API.get("notifications", "/notifications").then((r) => {
      console.log("resii", r);
      setTableData(r);
    });
  };

  // opens the clicked notification
  const getNotif = (emplId) => {
    setContent(tableData?.filter((el) => el.notificationId === emplId)[0]);
  };

  useEffect(() => {
    getNotification();
  }, []);

  // load the notification
  async function loadNotifications() {
    await API.get("notifications", "/notifications").then(
      (notifs) =>
        dispatch({
          type: "LOAD_NOTIFS",
          payload: notifs
            .map((el) => {
              let test = [];
              Object.entries(el?.userDetails).forEach((ent) => {
                if (ent[0] === auth?.userAccess[0]?.identityId) {
                  test.push({ identityId: ent[0], ...ent[1] });
                }
              });
              let userD = {};
              if (test.length > 0) return { ...el, ...test[0] };
            })
            .filter((u) => u.identityId === auth?.userAccess[0]?.identityId),
        }),
      (err) => console.log("error loading notifs", err)
    );
  }

  // delete the notification
  const deleteItems = async () => {
    await API.del(
      "notifications",
      `/notifications/${content.notificationId}`
    ).then(() => {
      setTableData((prev) =>
        prev.filter((item) => item.notificationId !== content.notificationId)
      );
      loadNotifications();
    });
    message.success("Njoftimi u fshi me sukses !", 10);
    setContent(null);
  };

  // filter the notification by date
  const lajmerimeFilter = tableData?.sort(
    (a, b) => b?.createdAt - a?.createdAt
  );

  /**
   * * @EneaXharau - Added independent loading functionality, visual loader based on validation below
   */
  return (
    <>
      {!!tableData ? (
        <div>
          <Row className="lajmerime-njoftime">
            <Col span={12}>
              <Row>
                <Col flex="auto">
                  <div
                    className="notification-container"
                    style={{ width: "100%" }}
                  >
                    {tableData?.length > 0 ? (
                      lajmerimeFilter
                        ?.filter((lajm) => lajm.isAnnouncement === false)
                        ?.map((Data) => (
                          <div
                            className={
                              Data?.notificationId === content?.notificationId
                                ? "notification-open"
                                : "notification"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => getNotif(Data.notificationId)}
                            key={Data?.notificationId}
                          >
                            <Row style={{ width: "100%" }}>
                              <Col span={13}>
                                <LajmerimeIcon
                                  className={
                                    Data.notificationPriority !== true
                                      ? "normal"
                                      : "priority"
                                  }
                                />{" "}
                                &nbsp;&nbsp;
                                {Data?.notificationTitle}
                              </Col>
                              <Col span={5}>
                                {dayjsTZ(Data?.createdAt).format("DD/MM/YYYY")}
                              </Col>
                              <Col span={6}>
                                {Data?.notificationFirstName}&nbsp;&nbsp;
                                {Data?.notificationLastName}
                              </Col>
                            </Row>
                          </div>
                        ))
                    ) : (
                      <div className="nuk-ka-lajmerim">
                        Nuk ka asnje lajmërim
                      </div>
                    )}
                  </div>
                </Col>
                <Col flex="5px" className="line-col">
                  <div className="line"></div>
                </Col>
              </Row>
            </Col>
            {!content ? null : (
              <Col span={12}>
                <Row>
                  <Col flex="auto">
                    <div className="notification-open-container">
                      <div className="notification-content">
                        <div
                          className="notification-header"
                          key={content?.notificationId}
                        >
                          <h3>
                            {" "}
                            {content !== null && content?.notificationTitle}
                          </h3>
                          {content?.notificationFirstName}&nbsp;
                          {content?.notificationLastName}
                          &nbsp;{" "}
                          {content !== null &&
                            dayjsTZ(content?.createdAt).format("DD/MM/YYYY")}
                        </div>
                        <div className="content-container">
                          {htmlParser(content?.notificationBody)}
                        </div>
                      </div>
                    </div>
                    <GlobalPopupKonfirm
                      title={"jeni të sigurt që do të fshini këtë njoftim?"}
                      Button={
                        <MondayButton
                          className="mondayButtonRed"
                          style={{ marginLeft: 100 }}
                        >
                          Fshij Lajmerimin
                        </MondayButton>
                      }
                      cancelText={"JO"}
                      okText={"PO"}
                      onConfirm={deleteItems}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      ) : (
        <HollowDotsSpinner
          color="#232f5d"
          size={24}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </>
  );
}

export default Pervjetore;
