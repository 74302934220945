import React, { useEffect, useState } from "react";
import { Button } from "antd";
import GPicker from "../../GPicker/GPicker";
import LabeledInput from "../../Documentation/LabeledInput/LabeledInput";
import "./GPickerButton.scss";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { compareIncluding } from "../../Documentation/utils/compareIncluding";
import { generateDocumentViewLink } from "../../Documentation/utils/generateDocumentViewLink";
import { CloseOutlined } from "@ant-design/icons";

const GPickerButton = ({
  uploaderId,
  onPickerSuccess = () => {},
  parentFolderId,
  fileNames = [],
  required = false,
  onDelete,
  buttonText = "",
}) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);

  const filteredFileNames = fileNames?.find((item) =>
    compareIncluding(item.uploaderId, uploaderId)
  )?.files;

  const onFileNameClick = async ({ id, name }) => {
    let imageSrc = await accessToken.getImageSrc(id);
    const value = generateDocumentViewLink({
      fileName: name,
      src: imageSrc.src,
    });

    window
      .open(value)
      .document.write(
        `<iframe width='100%' height='100%' src=${value}></iframe>`
      );
  };

  const [folderId, setFolderId] = useState([]);

  //! gets parentFolderId
  useEffect(() => {
    if (!!hotCredentials?.length) {
      const parentFolder = hotCredentials.find(
        (el) => el?.credentialId === "5"
      );
      setFolderId(parentFolder.credentialValue);
    }
  }, [hotCredentials]);

  return (
    <LabeledInput noFormItem required={required}>
      <GPicker
        token={accessToken}
        onAuthenticate={() => {}}
        createPicker={(google, oauthToken) => {
          const uploadView = new google.picker.DocsUploadView()
            .setIncludeFolders(false)
            .setParent(folderId);
          const picker = new window.google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .enableFeature(google.picker.Feature.MINE_ONLY)
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .addView(uploadView)
            .setOAuthToken(oauthToken)
            .setDeveloperKey("AIzaSyAJUmvYoi6q-fDWjHmjByP7LRp4ApAYLSY")
            .setCallback(async (data) => {
              if (
                data.action === google.picker.Action.PICKED &&
                !!data.docs.length
              ) {
                onPickerSuccess({
                  uploaderId: uploaderId,
                  uploadedFiles: data.docs,
                });
              }
            });
          picker.build().setVisible(true);
        }}
      >
        <Button className="uploadButton">{buttonText}</Button>
      </GPicker>

      <div className="uploadedFileNamesContainer">
        {uploaderId ? (
          !!filteredFileNames ? (
            filteredFileNames.map(({ name, id }, i) => (
              <div className="fileNameContainer" key={i}>
                {!!onDelete && (
                  <CloseOutlined
                    className="deleteIcon"
                    onClick={() => onDelete({ uploaderId, id })}
                  />
                )}
                <span
                  onClick={() => onFileNameClick({ name, id })}
                  className="fileName"
                >
                  {name}
                </span>
              </div>
            ))
          ) : (
            <span style={{ color: "#7B7B7B" }}>
              {" "}
              {!parentFolderId ? "" : "Asnjë skedar nuk u ngarkua"}
            </span>
          )
        ) : (
          !!fileNames.length && "Ngarkuar"
        )}
      </div>
    </LabeledInput>
  );
};

export default GPickerButton;
