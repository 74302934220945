import { Col, Row, Tooltip } from "antd";
import React from "react";
import { Photo } from "../../../../../../assets/images";
import { getRandomColor } from "../../../../../../utils";
import NextBirthdayCalendar from "./NextBirthdayCalendar";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../../utils/dayjs";
import Avatar from "react-avatar";

function NextDitlindje({ nextRenderdBirthdays }) {
  return (
    <Row style={{ width: "100%", display: "flex" }}>
      <Col flex="450" style={{ marginRight: 20 }}>
        <div className="calendar-container">
          <NextBirthdayCalendar nextRenderdBirthdays={nextRenderdBirthdays} />
          <div className="calendar-footer">
            <div className="footer-card">
              {nextRenderdBirthdays?.map(
                ({
                  employeeDayOfBirth,
                  employeeId,
                  employeeLastName,
                  employeeFirstName,
                }) => (
                  <div className="card-calendar-foter">
                    <Tooltip
                      className="calendar-tooltip"
                      overlayClassName="calendar-tooltip"
                      placement="top"
                      title={
                        <div className="toptil-calendar-content">
                          <div key={employeeId}>
                            {employeeFirstName} {employeeLastName}
                          </div>
                        </div>
                      }
                    >
                      <Avatar
                        name={employeeFirstName + " " + employeeLastName}
                        size="30"
                        round={true}
                      />
                      <div
                        className="data"
                        style={{ backgroundColor: getRandomColor() }}
                      >
                        {dayjsTZ(employeeDayOfBirth).format("DD")}{" "}
                      </div>
                    </Tooltip>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </Col>
      <Col flex="10px">
        <div className="line"></div>
      </Col>
    </Row>
  );
}

export default NextDitlindje;
