import "./AccessConfig.scss";
import { Switch } from "antd";
import MondayButton from "../../../../../commonComponents/MondayButton/MondayButton";
import { useState } from "react";

const AccessConfig = ({ title, configs, property, handleSave, saving }) => {
  const [newConfigs, setNewConfigs] = useState(configs);

  const onSwitch = (auth, idx) => {
    setNewConfigs((prev) =>
      (prev || []).map((el, i) => (i === idx ? { ...el, auth } : el))
    );
  };

  return (
    <div
      className="accessConfigWrapper"
      style={!!saving[property] ? { pointerEvents: "none" } : {}}
    >
      {!!saving[property] ? <div className="overlay" /> : null}
      <div className="accessConfigHeader">
        <h1>{title}</h1>
        <MondayButton
          className="mondayButtonGreen"
          onClick={() =>
            handleSave(
              property,
              property === "sidebarConfig" ? [newConfigs] : newConfigs
            )
          }
        >
          Ruaj Ndryshimet
        </MondayButton>
      </div>
      <div className="accessConfigContent">
        {(newConfigs || []).map((el, idx) => (
          <div
            className="configWrapper"
            onClick={() => onSwitch(!el?.auth, idx)}
          >
            <span>{el?.title}</span>
            <Switch checked={el?.auth} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccessConfig;
