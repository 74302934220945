import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import AgGridComponent from "../../../AG-grid/AgGridComponent";
import { Row, Col, Input, Button, Tooltip } from "antd";
import {
  IconKerko,
  IconNdrysho,
  IconExcel,
  IconNgargo,
} from "../../../../assets/icons";
import "./PolitikatJashtOrarit.scss";
import PolitikatJashtOraritModal from "./PolitikatJashtOraritModal";
import { API } from "aws-amplify";
import FilterPolitikatJashtOrarit from "./FilterPolitikatJashtOrarit";
import LoadableComp from "../../../commonComponents/LoadableComp/LoadableComp";
import { htmlParser } from "../../../../utils";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { useReactToPrint } from "react-to-print";
import HtmlExelTabel from "../../dynamic components/HtmlExelTabel";
import ExportToPdf from "../../dynamic components/ExportToPdf";
import { getSocketUrl } from "../../../../utils/websocketConfig";
import { programFields as programFieldsDispatch } from "../../../../store/actions";
import { requestData } from "../../../../helpers/API/RequestFactory";
import { useMediaQuery } from "react-responsive";
import MobilePolitikatJashtOrarit from "./MobilePolitikatJashtOrarit";
import PaisjetOraritLogs from "../../SistemiSettings/SistemiPaisjetOrarit/PaisjetOraritLogs";
import AgGridHeader from "../../../commonComponents/AgGridHeader/AgGridHeader";
import { LogsIcon } from "../../../Documentation/View/assets";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";

/**
 * * @EneaXharau - Websocket connection
 */
// const socketHandler = new WebSocket(
//   getSocketUrl("/settings/policies/days/out")
// );

const FILTER_KEYS = [
  { key: "name", label: "Emri i politikës", type: "string" },
  {
    key: "description",
    label: "Përshkrimi i politikës",
    type: "string",
  },
  { key: "formula", label: "Shifra", type: "string" },
];
const EXPORT_COLS = [
  { en: "name", al: "Emri i politikës" },
  { en: "description", al: "Përshkrimi i politikës" },
  { en: "formula", al: "Shifra" },
];

const ITEMS = {
  search: {},
  icons: {
    filter: {
      filterKeys: FILTER_KEYS,
    },
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function SistemiPaisjetOrarit() {
  const columnDefs = [
    {
      headerName: "Nr.",
      field: "fieldId",
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      valueGetter: "node.rowIndex + 1",
      flex: 1,
    },
    {
      headerName: "Emri i politikes",
      field: "name",
      filter: "agTextColumnFilter",
      flex: 3,
    },
    {
      headerName: "Pershkrimi i politikes ",
      field: "description",
      filter: "agTextColumnFilter",
      flex: 5,
      cellRenderer: ({ value }) => htmlParser(value),
    },
    {
      headerName: "Shifra",
      field: "formula",
      filter: "agTextColumnFilter",
      flex: 5,
    },
    {
      headerName: "",
      field: "",
      filter: "agTextColumnFilter",
      flex: 2,
      cellRenderer: (params) => (
        <div className="iconat-paisjet">
          <div variant="outlined" cursor="pointer" className="icon-edit">
            <IconNdrysho
              onClick={() => handleUpdate(params.data)}
              width={14}
              height={14}
            />
          </div>
          {/* <GlobalPopupKonfirm
						Button={
							<div variant='outlined' cursor='pointer' className='icon-delete'>
								<DeleteIcon
									width={17}
									height={17}
									// onClick={() => handleRequestDelete(params.node.data.id)}
								/>
							</div>
						}
						title={"Jeni të sigurt që do të fshini këtë rekord?"}
						onConfirm={() => handleRequestDelete(params.node.data.id)}
						okText={"Po"}
						cancelText={"Jo"}
					/> */}
        </div>
      ),
    },
  ];
  const initialValue = {
    name: "",
    description: "",
    formula: "",
  };

  const [gridApi, setGridApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [visible, setVisible] = useState(false);
  const { programFields } = useSelector((state) => state.programFields);
  const [request, setRequests] = useState([]);
  const [dataLista, setDataLista] = useState(initialValue);
  const [agGridSearchInput, setAgGridSearchInput] = useState(null);
  const [error, setError] = useState(true);
  const [logsVisible, setLogsVisible] = useState(false);
  const dispatch = useDispatch();

  // * @EneaXharau - Changed const variable to useMemo to optimize performance
  const filterData = useMemo(() => {
    return programFields?.find(
      (data) => data?.fieldId === "3d077179-a545-43ec-98be-7e1bf71b09aa"
    );
  }, [programFields]);

  /**
   * * @EneaXharau - Async function taking no parameters
   * * it awaits for redux to fetch data from AWS database
   * * then sets in state which also re-renders component to display new info
   */
  const refreshRedux = async () => {
    await requestData("programFields").then((res) =>
      dispatch(programFieldsDispatch(res))
    );
  };

  // const filterData = programFields?.find(
  //   (data) => data?.fieldId === "3d077179-a545-43ec-98be-7e1bf71b09aa"
  // );

  //open the modal to create new record
  const handleClickOpen = () => {
    setVisible(true);
    setDataLista(initialValue);
    setError(true);
  };

  //get the data from programfields
  const fetchData = () => {
    const fieldsData = filterData;
    setTableData(fieldsData?.fieldOptions);
    setRequests(fieldsData);
  };

  //execute when the page renders
  useEffect(() => {
    fetchData();
  }, [filterData]);

  console.log("tableData", tableData);

  //delete the cliked row
  const handleRequestDelete = (id) => {
    let newArray = tableData.filter((r) => r.id !== id);
    API.put(
      "programFields",
      "/programFields/3d077179-a545-43ec-98be-7e1bf71b09aa",
      {
        body: {
          fieldOptions: newArray,
        },
      }
    ).then(() => {
      /**
       * * @EneaXharau - Added socket emitter
       * * .send() takes -> { request: String, body: any} -> stringified
       */
      // if (socketHandler.readyState === socketHandler.OPEN) {
      // 	socketHandler.send(
      // 		JSON.stringify({
      // 			request: "policy_out_removed",
      // 			body: "A policy was removed",
      // 		})
      // 	);
      // }
      setTableData(newArray);
      let field = request.fieldOptions.filter((el) => el.id !== id);
      setRequests((prev) => ({ ...prev, fieldOptions: field }));
    });
  };

  //open the update modal
  const handleUpdate = (oldData) => {
    setVisible(true);
    setDataLista(oldData);
    setError(false);
  };

  //makes the grid read
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  // clear all the filters from ag grid
  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
    setAgGridSearchInput(null);
  };

  // global ag grid search
  const onFilterTextChange = (e) => {
    gridApi.setGridOption("quickFilterText", e.target.value);
    setAgGridSearchInput(e.target.value);
  };

  // dropdon filter check to not repeat same value 2 times
  const duplicateCheck = [];
  const descriptionduplicateCheck = [];
  const formuladuplicateCheck = [];

  tableData &&
    tableData
      ?.map((data) => {
        if (duplicateCheck.includes(data.name)) return null;
        duplicateCheck.push(data.name);
        return data;
      })
      .filter((e) => e);

  tableData &&
    tableData
      ?.map((data) => {
        if (descriptionduplicateCheck.includes(data.description)) return null;
        descriptionduplicateCheck.push(data.description);
        return data;
      })
      .filter((e) => e);

  tableData &&
    tableData
      ?.map((data) => {
        if (formuladuplicateCheck.includes(data.formula)) return null;
        formuladuplicateCheck.push(data.formula);
        return data;
      })
      .filter((e) => e);

  // print the data from the html tabel
  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  /**
   * * @EneaXharau - useEffect to listen to socket emits from backend
   * * and run a function with a setTimeout to cancel the delay of emitting
   * * according to its emit request.
   * * .onmessage is a variable that needs to be a function (arrow preferably)
   * * .onmessage takes a parameter from backend which is is a stringified object
   */
  // useEffect(() => {
  // 	socketHandler.onmessage = (msg) => {
  // 		const { request, body } = JSON.parse(msg.data);
  // 		switch (request) {
  // 			case "policy_out_removed_received": {
  // 				refreshRedux();
  // 				break;
  // 			}
  // 			case "policy_out_added_received": {
  // 				refreshRedux();
  // 				break;
  // 			}
  // 			case "policy_out_updated_received": {
  // 				refreshRedux();
  // 				break;
  // 			}
  // 			default:
  // 				break;
  // 		}
  // 	};
  // 	return () => socketHandler.close();
  // }, []);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  return (
    <LoadableComp loading={!!tableData ? false : true}>
      {isMobileView ? (
        <div className="globalPageWrapper">
          <div className="punonjesit-ag-grid">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: 10,
              }}
            >
              <AgGridHeader
                gridApi={gridApi}
                gridData={tableData}
                items={{
                  ...ITEMS,
                  icons: {
                    ...ITEMS.icons,
                    modals: [
                      {
                        onClick: () => setLogsVisible(true),
                        icon: (
                          <LogsIcon width={20} height={20} fill="#323338" />
                        ),
                      },
                    ],
                  },
                }}
                children={
                  <MondayButton
                    className="mondayButtonGreen"
                    onClick={handleClickOpen}
                  >
                    Shto një ditë pushimi
                  </MondayButton>
                }
              />
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: 780, width: "100%" }}
            >
              <AgGridComponent
                rowData={tableData}
                rowSelection="single"
                onGridReady={onGridReady}
                paginationPageSize={15}
                columnDefs={columnDefs}
              />
            </div>
          </div>
        </div>
      ) : (
        <MobilePolitikatJashtOrarit
          tableData={tableData}
          setVisible={setVisible}
        />
      )}
      <PolitikatJashtOraritModal
        {...{
          visible,
          setVisible,
          request,
          setTableData,
          setRequests,
          tableData,
          dataLista,
          setError,
          error,
          // socketHandler,
          socketHandler: () => {},
        }}
      />
      {logsVisible && (
        <PaisjetOraritLogs
          data={tableData}
          isVisible={logsVisible}
          setIsVisible={setLogsVisible}
          logsName={"Pushimet"}
        />
      )}
    </LoadableComp>
  );
}

export default SistemiPaisjetOrarit;
