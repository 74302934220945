import { useEffect, useState } from "react";
import "./GeneralInfo.scss";
import { useSelector } from "react-redux";
import RenderDynamicComponents from "../../../../RenderDynamicComponents/RenderDynamicComponents";
import { Form } from "antd";
import dayjsTZ from "../../../../../utils/dayjs";
import CalendarInput from "../DateTime/CalendarInput";
import PropTypes from "prop-types";

/**
 * @typedef props
 * @property {Object} form antd form instance
 * @property {Number} step step number
 * @property {Array<Object>} formItems array of form items to render on current step
 */

const CANDIDATE_CONFIGURATION_FIELD_ID = "a086b42f-cd92-4e6c-8e91-cfe78d66f07f";

const GRID = ["input", "select", "datepicker", "phone", "radio"];
const TEXTAREA = ["customquill", "tagselect", "quill"];

const GeneralInfo = ({ form, step, formItems: proppedInitial }) => {
  const { departments } = useSelector((state) => state.departments);

  const [formItems, setFormItems] = useState(proppedInitial);

  const selectedDepartment = Form.useWatch("applicantDepartment", form);

  //Ketu filtrojme te candidatet per te gjetur se cilet prej tyre e kane intervisten ne diten e perzgjedhur
  // const selectedDateCandidateArray = useMemo(() => {
  //   return candidates.filter(
  //     (items) =>
  //       moment(items?.applicantInterviewDate).format("YYYY-MM-DD") ===
  //         moment(kandidatiSelektuar?.applicantInterviewDate).format(
  //           "YYYY-MM-DD"
  //         ) && kandidatiSelektuar?.applicantId !== items.applicantId
  //   );
  // }, [candidates, kandidatiSelektuar]);
  // console.log(selectedDateCandidateArray);

  // const disabledTimesArray = selectedDateCandidateArray.map((obj) =>
  //   moment(obj["applicantInterviewDate"]).format("HH:mm ")
  // );

  //birthday disabled date
  const disabledDate = (current) => {
    return current && current.unix() > dayjsTZ().unix() - 568025136;
  };

  const setInitialFormItems = () => {
    let fields = formItems;
    let departmentOptions = [];
    departments?.forEach(({ departmentName }) => {
      departmentOptions.push(departmentName);
    });
    fields?.forEach((item, idx) => {
      if (
        item.formItemName === "applicantInterviewDate" ||
        item.formItemName === "applicantDisqualificationReason"
      ) {
        fields.splice(idx, 1);
      }
      if (item.formItemName === "applicantDepartment") {
        let options = [];
        departments?.forEach(({ departmentName }) => {
          options.push(departmentName);
        });
        item.options = options;
      }
      if (item.formItemName === "applicantBirthday") {
        item.disabledDate = (e) => disabledDate(e);
      }
      item.rules = (item.rules || []).map((rule) => {
        if (
          rule.hasOwnProperty("pattern") &&
          typeof rule?.pattern === "string"
        ) {
          const { pattern, ...rest } = rule;

          return {
            ...rest,
            async validator(_, value) {
              const rg = new RegExp(pattern);

              if (!rg.test(value)) {
                return Promise.reject(rest?.message);
              }

              return Promise.resolve();
            },
          };
        } else {
          return rule;
        }
      });
    });
    setFormItems(fields);
  };

  const setRoles = () => {
    let options = [];
    departments
      ?.find(({ departmentName }) => departmentName === selectedDepartment)
      ?.departmentRoles?.forEach(({ name }) => {
        options.push(name);
      });
    setFormItems((prev) =>
      prev?.map((el) => {
        if (el.formItemName === "applicantRoles") {
          if (!!options.length) {
            return {
              ...el,
              options,
              disabled: false,
              placeholder: "Selektoni Roli...",
              mode: "multiple",
            };
          } else {
            return {
              ...el,
              disabled: true,
              placeholder: "Zgjidh departamentin fillimisht",
            };
          }
        } else {
          return el;
        }
      })
    );
  };

  useEffect(() => {
    if (!!selectedDepartment) {
      setRoles();
    } else {
      setInitialFormItems();
    }
  }, [proppedInitial, selectedDepartment, step]);

  //region RETURN
  return (
    <>
      <div className="generalInfo" data-testid={`generalInfo-${step}`}>
        <div className="personalInfo">
          <div className="gridInfos">
            {RenderDynamicComponents(
              formItems?.filter((el) => GRID.includes(el.type)),
              { form }
            )}
          </div>
          <div className="textareaInfos">
            {RenderDynamicComponents(
              formItems?.filter((el) => TEXTAREA.includes(el.type)),
              { form }
            )}
          </div>
        </div>
        <div className="sidebarInfos">
          {/*
          if we wanna 
          {RenderDynamicComponents(
            formItems?.filter((el) => SIDEBAR.includes(el.type)),
            { form }
          )} */}
          <CalendarInput form={form} />
        </div>
      </div>
    </>
  );
};

export default GeneralInfo;

GeneralInfo.propTypes = {
  form: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  formItems: PropTypes.array.isRequired,
};
