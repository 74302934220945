import "./AgGridHeader.scss";
import {
  AgGridToExcel,
  AgGridToPDF,
  AgGridToPrint,
  SearchAgGrid,
  BulkDeleteAgGrid,
  SwitchAgGrid,
  FilterAgGrid,
  DropdownAgGrid,
} from "./components";
import MondayButton from "../MondayButton/MondayButton";
import { RefreshIcon } from "../../../assets/icons";
import { LogsIcon } from "../../Documentation/View/assets";
import PropTypes from "prop-types";
import colorShade from "../../../utils/colorShade";
import getAgThemePreference from "../../../utils/getAgThemePreference";
import { useMemo, useState } from "react";

/**
 * @typedef tableCol
 * @property {string} en key that exists in AgGrid's columnDefs
 * @property {string} al albanian translated key that will replace 'en' at table headers on export
 */
/**
 * @typedef excel/pdf/print
 * @property {Array<Object>} tableCols provide which keys from AgGrid to export
 */
/**
 * @typedef icon
 * @property {Object} filter provide to add FilterAgGrid component to table
 * @property {Object} delete provide to add icon for AgGrid multiple rows deletion
 * @property {Object} excel provide to add icon of Excel export for AgGrid
 * @property {Object} pdf provide to add icon of PDF export for AgGrid
 * @property {Object} print provide to add icon of print export for AgGrid
 */
/**
 * @typedef item
 * @property {Object} search provide empty object of key search to add search component
 * @property {Object} switch provide empty object of key switch to add switch component
 * @property {Object} icons provide icons object to add to add specific components to header
 * @property {Array<Object>} dropdowns provide dropdowns array to add filtering dropdowns
 */
/**
 * @typedef props
 *
 *
 * @property {Object} gridApi Ag Grid API
 * @property {Array<Object>} gridData Ag Grid data
 * @property {Object<Object>} items elements of the Header
 * @property {string} exportTitle the filename to be used on export functions
 * @property {Array<ReactNodes>} children extra components from the parent to render alongside
 * @property {function} modifyRows provide this function to manipulate rowData before exporting/filtering
 */
/**
 *
 * @param {props} props
 */
const getInitalState = (items = {}) => {
  let stateObj = {};
  Object.entries(items).map(([key, props]) => {
    if (key === "dropdowns") {
      props?.forEach((dropdown) => {
        stateObj[dropdown?.keyName] = null;
      });
    }
  });
  return stateObj;
};

const AgGridHeader = ({
  gridApi,
  gridData,
  items,
  exportTitle,
  children,
  modifyRows,
}) => {
  const { theme, accentColor, style } = getAgThemePreference();

  const [values, setValues] = useState(getInitalState(items?.dropdowns));

  const handleClean = (callback) => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
    setValues({});

    !!callback && callback();
  };

  const themeStyle = useMemo(() => {
    return {
      backgroundColor: theme.includes("dark")
        ? colorShade(accentColor, 40)
        : null,
      color: style?.["--ag-foreground-color"],
      fill: style?.["--ag-foreground-color"],
    };
  }, []);

  return (
    <div
      className="agGridHeader"
      data-testid="agGridHeader"
      style={{
        background: theme?.includes("dark")
          ? colorShade(accentColor, 70)
          : "#fff",
      }}
    >
      {Object.entries(items)?.map(([key = "", props = {}]) => {
        if (key.toLowerCase() === "search") {
          return (
            <SearchAgGrid
              {...{
                gridApi,
                handleClean: () => handleClean(props?.handleClean),
                style: themeStyle,
              }}
            />
          );
        } else if (key.toLowerCase() === "dropdowns") {
          return props?.map((prop) => (
            <DropdownAgGrid
              {...prop}
              gridData={gridData}
              gridApi={gridApi}
              style={themeStyle}
              value={values?.[prop?.keyName]}
              setValue={setValues}
            />
          ));
        } else if (key.toLowerCase() === "switch") {
          return <SwitchAgGrid {...props} style={themeStyle} />;
        } else if (key.toLowerCase() === "icons") {
          const exportProps = {
            gridApi,
            title: exportTitle,
            modifyRows,
          };
          return (
            <div className="headerIcons">
              {Object.entries(props)?.map(([iconKey = "", iProps = {}]) => {
                const iconProps = { ...iProps, style: themeStyle };
                if (iconKey.toLowerCase() === "filter") {
                  return (
                    <FilterAgGrid
                      gridApi={gridApi}
                      clearFilters={handleClean}
                      tableData={gridData}
                      {...iconProps}
                      style={themeStyle}
                    />
                  );
                }
                if (iconKey.toLowerCase() === "excel") {
                  return <AgGridToExcel {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "pdf") {
                  return <AgGridToPDF {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "print") {
                  return <AgGridToPrint {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "delete") {
                  return <BulkDeleteAgGrid {...exportProps} {...iconProps} />;
                }
                if (iconKey.toLowerCase() === "refresh") {
                  return (
                    <span className="headerIcon" {...iconProps}>
                      <RefreshIcon width={20} height={20} />
                    </span>
                  );
                }
                if (iconKey.toLowerCase() === "modals") {
                  return (props[iconKey] || []).map((el) => (
                    <span className="headerIcon" {...el} style={themeStyle}>
                      {el?.icon || (
                        <LogsIcon
                          width={20}
                          height={20}
                          fill={themeStyle?.fill || "#323338"}
                        />
                      )}
                    </span>
                  ));
                }
              })}
            </div>
          );
        } else return null;
      })}
      {!!children ? children : null}
    </div>
  );
};

export default AgGridHeader;

AgGridHeader.propTypes = {
  gridApi: PropTypes.object.isRequired,
  gridData: PropTypes.array.isRequired,
  items: PropTypes.object.isRequired,
  exportTitle: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  modifyRows: PropTypes.func,
};
