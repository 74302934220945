import ProfileCustomCard from "../../../../Konfigurimet/Profile/ProfileCustomCard";
import Profile from "../../../../Konfigurimet/Profile/Profile";

const proccessMap = (dynamicMap) => {
  const defaultData = {
    x: 0,
    y: 0,
    h: 1,
    w: 1,
    minW: 1,
    minH: 1,
    static: false,
  };

  let save = [
    {
      i: "1",
      data: {
        name: "ProfiliCard",
        emri: "Profili",
        src: Profile,
        parent: false,
        // data: cardData[0],
        data: dynamicMap,
        hidden: false,
      },
      ...defaultData,
      w: 4,
      minW: 4,
      h: 2,
      minH: 2,
      x: 0,
      y: 0,
    },
    {
      i: "2",
      data: {
        name: "KontaktCard",
        emri: "Kontakt",
        src: ProfileCustomCard,
        parent: false,
        // data: cardData[0],
        data: dynamicMap,
        hidden: false,
      },
      ...defaultData,
      w: 6,
      minW: 6,
      h: 2,
      minH: 2,
      x: 4,
      y: 0,
    },
    {
      i: "3",
      data: {
        name: "PerdoruesiCard",
        emri: "Roli",
        src: ProfileCustomCard,
        parent: false,
        // data: cardData[0],
        data: dynamicMap,
        hidden: false,
      },
      ...defaultData,
      w: 6,
      minW: 6,
      h: 2,
      minH: 2,
      x: 10,
      y: 0,
    },
  ];
  return save;
};

export const profili = (dynamicMap) => {
  let processedMap = proccessMap(dynamicMap);
  const ls = JSON.parse(localStorage.getItem("draggableProfili"));
  if (ls !== null) {
    let toReturn = [];
    let toLocalStorage = [];
    ls.forEach((el) => {
      let idx = processedMap.findIndex((l, x) => l.i === el.i);

      if (idx !== -1) {
        let item = {
          // ...processedMap[idx], hidden: el.hidden, data: dynamicMap
          ...processedMap[idx],
          data: {
            ...processedMap[idx].data,
            hidden: el?.data?.hidden,
            blur: el?.data?.blur,
          },
          x: el.x,
          y: el.y,
          h: el.h,
          w: el.w,
          minW: el.minW,
          minH: el.minH,
        };
        toReturn.push(item);
        toLocalStorage.push({
          ...item,
          data: { ...(item?.data || {}), data: {} },
        });
      }
    });

    const updateLs = ls.some((el) => !!Object.keys(el?.data?.data));
    if (!!updateLs) {
      console.log("updated: draggableProfili");

      localStorage.setItem("draggableProfili", JSON.stringify(toLocalStorage));
    }

    return toReturn;
  } else if (ls === null) {
    let toLocalStorage = [];
    (processedMap || []).forEach((el) => {
      const item = { ...el, data: { ...(el?.data || {}), data: {} } };
      toLocalStorage.push(item);
    });

    console.log("updated: draggableProfili");
    localStorage.removeItem("draggableSettingsProfili");
    localStorage.setItem(
      "draggableProfili",
      JSON.stringify(proccessMap(toLocalStorage))
    );
    return proccessMap(dynamicMap);
  }
};
