import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../authentication/authHelpers";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
import { HappyFaceIcon, SearchIconInput } from "../../../../../assets/icons";
import "./ProblemsCard.scss";
import ProblemsModal from "./PromblemsModal/ProblemsModal";
import { Input, Table } from "antd";
import ProblemStatus from "./ProblemStatus/ProblemStatus";
import { API } from "aws-amplify";
import moment from "moment";
import dayjsTZ from "../../../../../utils/dayjs";
import { htmlParser } from "../../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import MobileHtmlTabel from "../../../../../utils/MobileComponents/MobileHtmlTabel";

const ACCESS_RIGHTS = ["Admin", "Super Admin"];

function ProblemsCard() {
  const { problematikat } = useSelector((state) => state.problematikat);
  const { userAccess } = useAuth();

  const username = {
    userId: userAccess[0].userSub,
    employeeFirstName: userAccess[0].given_name,
    employeeLastName: userAccess[0].family_name,
  };

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [columns, setColums] = useState();
  const [filterValue, setFilterValue] = useState("");

  const handleFilterChange = (value) => {
    setFilterValue(value);
  };

  const onClick = () => {
    setIsAddModalVisible(true);
  };

  const handleRowClick = (data) => {
    setEditData(data);
    setIsAddModalVisible(true);
  };

  //#region COLUMN DEFS
  useEffect(() => {
    if (ACCESS_RIGHTS.includes(userAccess[0].userRole)) {
      const columns = [
        {
          title: "Emër Mbiemër",
          dataIndex: "createdBy",
          key: "createdBy",
          align: "center",
          ellipsis: true,
        },
        {
          title: "Përshkrimi",
          dataIndex: "ticketDescription",
          key: "ticketDescription",
          align: "center",
          ellipsis: true,
          render(text) {
            return (
              <span className="problemsDescriptionRow">
                {Array.isArray(htmlParser(text))
                  ? htmlParser(text)[0]
                  : htmlParser(text)}
              </span>
            );
          },
        },
        {
          title: "Kategoria",
          dataIndex: "ticketCategory",
          key: "ticketCategory",
          align: "center",
        },
        {
          title: "Statusi",
          dataIndex: "ticketStatus",
          key: "ticketStatus",
          align: "center",
          width: "180px",

          render: (text) => <ProblemStatus status={text} />,
        },
        {
          title: "Data e Krijimit",
          dataIndex: "createdAt",
          key: "createdAt",
          align: "center",
          ellipsis: true,

          render: (record) => (
            <span>{dayjsTZ(record).format("DD/MM/YYYY")}</span>
          ),
        },

        {
          title: "Data e Rregullimit",
          dataIndex: "resolvedTicket",
          key: "resolvedTicket",
          align: "center",
          ellipsis: true,
        },
      ];
      setColums(columns);
    } else {
      const columns = [
        {
          title: "Përshkrimi",
          dataIndex: "ticketDescription",
          key: "ticketDescription",
          align: "center",
          ellipsis: true,
          render(text) {
            return (
              <span className="problemsDescriptionRow">
                {Array.isArray(htmlParser(text))
                  ? htmlParser(text)[0]
                  : htmlParser(text)}
              </span>
            );
          },
        },
        {
          title: "Kategoria",
          dataIndex: "ticketCategory",
          key: "ticketCategory",
          align: "center",
        },
        {
          title: "Statusi",
          dataIndex: "ticketStatus",
          key: "ticketStatus",
          align: "center",
          width: "180px",
          render: (text) => <ProblemStatus status={text} />,
        },
        {
          title: "Data e Krijimit",
          dataIndex: "createdAt",
          key: "createdAt",
          align: "center",
          ellipsis: true,

          render: (record) => (
            <span>{dayjsTZ(record).format("DD/MM/YYYY")}</span>
          ),
        },
        {
          title: "Data e Rregullimit",
          dataIndex: "resolvedTicket",
          key: "resolvedTicket",
          align: "center",
          ellipsis: true,
        },
      ];
      setColums(columns);
    }
  }, []);
  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  //#region RETURN
  return (
    <div className="problemCardWrapper">
      {problematikat?.length === 0 || !problematikat ? (
        // <>
        <div className="noProblemsCardContainer">
          <div className="noProblemFirstRow">
            <HappyFaceIcon />
            <span className="noProblemCardTextTitle">Asnjë problematikë!</span>
            <span className="noProblemCardText">
              Përshëndetje{" "}
              <b>{`${username.employeeFirstName} ${username.employeeLastName}`}</b>
              , aktualisht ju nuk keni asnjë problematikë në pritje. Klikoni
              butonin më poshtë për të krijuar një.
            </span>
          </div>
          <MondayButton
            onClick={onClick}
            className="mondayButtonGreen"
            hasIcon={false}
          >
            Krijoni
          </MondayButton>
        </div>
      ) : (
        <div className="tableContainerWraper">
          {isMobileView ? (
            <div className="tableContainer">
              <Table
                columns={columns}
                dataSource={problematikat
                  ?.filter((item) => {
                    return (
                      item.createdBy
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      item.ticketStatus
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      item.ticketDescription
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      item.ticketCategory
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      dayjsTZ(item.createdAt)
                        .format("DD/MM/YYYY")
                        .includes(filterValue)
                    );
                  })
                  ?.sort(
                    (a, b) =>
                      dayjsTZ(a?.createdAt).format("DD/MM/YYYY") -
                      dayjsTZ(b?.createdAt).format("DD/MM/YYYY")
                  )}
                pagination={false}
                rowKey={(record) => record.ticketId}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
              />
            </div>
          ) : (
            <div style={{ height: "100%", overflow: "scroll" }}>
              <MobileHtmlTabel
                header={["Emër Mbiemër", "Kategoria", "Statusi", "Krijuar"]}
                tableData={problematikat
                  ?.filter((item) => {
                    return (
                      item.createdBy
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      item.ticketStatus
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      item.ticketDescription
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      item.ticketCategory
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()) ||
                      dayjsTZ(item.createdAt)
                        .format("DD/MM/YYYY")
                        .includes(filterValue)
                    );
                  })
                  ?.sort(
                    (a, b) =>
                      dayjsTZ(a?.createdAt).format("DD/MM/YYYY") -
                      dayjsTZ(b?.createdAt).format("DD/MM/YYYY")
                  )
                  .map?.(
                    ({
                      createdBy,
                      ticketStatus,
                      ticketCategory,
                      createdAt,
                    }) => ({
                      family_name: createdBy,
                      ticketCategory,
                      ticketStatus,
                      createdAt: dayjsTZ(createdAt).format("DD/MM/YYYY"),
                    })
                  )}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "15px",
              paddingTop: "10px",
            }}
          >
            <Input
              prefix={<SearchIconInput />}
              className="searchInput"
              placeholder="Kërkoni Problematikën..."
              onChange={(e) => handleFilterChange(e.target.value)}
            />

            <MondayButton
              onClick={onClick}
              className="mondayButtonGreen"
              style={{ alignSelf: "flex-end" }}
            >
              Krijoni
            </MondayButton>
          </div>
        </div>
      )}
      {!!isAddModalVisible && (
        <ProblemsModal
          isAddModalVisible={isAddModalVisible}
          setIsAddModalVisible={setIsAddModalVisible}
          username={username}
          editData={editData}
          setEditData={setEditData}
        />
      )}
    </div>
  );
}

export default ProblemsCard;
