export const apiRoutes = {
  deductions: "deductions",
  earnings: "earnings",
  notifications: "notifications",
  notes: "notes",
  programFields: "program-fields",
  documentation: "documentation",
  filterTables: "filter-tables",
  docConfiguration: "doc-configuration",
  userGroups: "user-groups",
  users: "users",
  clocking: "clocking",
  shifts: "shifts",
  departments: "departments",
  jobs: "jobs",
  jobHistory: "job-history",
  employees: "employees",
  interviews: "interviews",
};

// Freeze the object to prevent accidental modifications
Object.freeze(apiRoutes);
