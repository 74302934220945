import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import React from "react";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

function MobilePaymentGrid({ rowData, dataTestId }) {
  return (
    <div data-testid={dataTestId}>
      <MobileHtmlTabel
        tableData={rowData?.map?.(({ muaji, neto, bruto }) => ({
          muaji: dayjsTZ(muaji).format("MMMM - YYYY"),
          neto,
          bruto,
        }))}
        header={["Muaji", "Shuma neto", "Shuma bruto"]}
        isHeader={false}
        isLink={false}
      />
    </div>
  );
}

export default MobilePaymentGrid;
