import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IconVeprime,
  IconExcel,
  IconNdrysho,
  IconNgargo,
  // IconFilter,
  IconKerko,
  DeleteIcon,
  FilterIconButton,
  IconFilter,
} from "../../../../../assets/icons";
import { DeleteFilled, EyeFilled } from "@ant-design/icons";
import {
  // Select,
  Dropdown,
  Menu,
  Input,
  Button,
  // DatePicker,
  message,
  Tooltip,
  Popconfirm,
} from "antd";
import AgGridComponent from "../../../../AG-grid/AgGridComponent";
import "./ShiftGrid.scss";
import InnerModal from "./InnerModal";
import FilteringModal from "./FilteringModal";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { API } from "aws-amplify";
import { checkWhetherEdited } from "./utils/validations";
import { weekFiltering } from "./utils/filtering";
import EditShiftModal from "./EditShiftModal/EditShiftModal";
// import { transferKeyToUpperCase } from "@aws-amplify/core";
import LoadableComp from "../../../../commonComponents/LoadableComp/LoadableComp";
import { approveShifts, deleteClockIns } from "./utils/apiHelpers";
import NewShiftModal from "../../../../Pagat/approvals/newShiftModal/NewShiftModal";
import { disapproveShifts } from "./utils/apiHelpers";
import { useLocation } from "react-router-dom";
import "../../../../../components/Pagat/approvals/Approvals.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  requestAllPages,
  requestData,
  requestDataWithParams,
  requestMultipleTables,
} from "../../../../../helpers/API/RequestFactory";
import { clockings } from "../../../../../store/actions";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import HtmlExelTabel from "../../../../Konfigurimet/dynamic components/HtmlExelTabel";
import { useReactToPrint } from "react-to-print";
import ExportToPdf from "../../../../Konfigurimet/dynamic components/ExportToPdf";
import Swal from "sweetalert2";
import ChartSummary from "../../../../dashboard/components/charts/Chart";
import { DynamicName } from "../../../../ListPrezenca/timesheet/TimeSheet";
import MobileHtmlTabel from "../../../../../utils/MobileComponents/MobileHtmlTabel";
import { useMediaQuery } from "react-responsive";
import MobileShiftGrid from "./MobileShiftGrid";
import { useAuth } from "../../../../../authentication/authHelpers";
import AgGridHeader from "../../../../commonComponents/AgGridHeader/AgGridHeader";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";

// const { RangePicker } = DatePicker;
// const { Option } = Select;

const EXPORT_COLS = [
  { en: "approved", al: "Statusi" },
  { en: "data", al: "Data" },
  { en: "fillim_turni", al: "Fillimi i turnit" },
  { en: "mbarim_turni", al: "Mbarimi i turnit" },
  { en: "oret_totale", al: "Orët Totale" },
];

const ITEMS = {
  search: {},
  icons: {
    excel: { tableCols: EXPORT_COLS },
    pdf: { tableCols: EXPORT_COLS },
    print: { tableCols: EXPORT_COLS },
  },
};

function ShiftGrid({ selected, employee }) {
  const columnDefs = [
    {
      headerName: "Nr",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      field: "nr",
      minWidth: 90,
      maxWidth: 90,
      valueGetter: ({ data }) => data.nr + 1,
      filter: "agNumberColumnFilter",
      filterParams: {
        readOnly: true,
      },
    },
    {
      headerName: "Statusi",
      field: "approved",
      filter: "agTextColumnFilter",
      filterParams: {
        readOnly: true,
      },
      cellRenderer: ({ data }) => (
        <div style={{ cursor: "pointer" }} onClick={() => onEditShift(data)}>
          <div variant="outlined" cursor="pointer">
            {data?.approved === true ? (
              <div
                style={{
                  background: "#02686b",
                  // width: "80px",
                  marginTop: "8px",
                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Aprovuar
              </div>
            ) : data?.approved === false ? (
              <div
                style={{
                  background: "#c43428",
                  // width: "80px",
                  marginTop: "8px",
                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Pa Aprovuar
              </div>
            ) : (
              <div
                style={{
                  background: "grey",
                  marginTop: "8px",
                  cursor: "pointer",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                Pa Përcaktuar
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      headerName: "Data",
      field: "data",
      filter: "agNumberColumnFilter",
      cellRenderer: (params) => {
        try {
          return dayjsTZ(params.value).format("DD/MM/YYYY");
        } catch (err) {
          // console.log("createdAt--err");
        }
      },
      filterParams: {
        inRangeInclusive: true,
      },
    },
    {
      headerName: "Fillimi i turnit",
      field: "fillim_turni",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => (
        <div>
          <div variant="outlined" cursor="pointer">
            {dayjsTZ(data.fillim_turni).format("HH:mm")}{" "}
            {checkWhetherEdited(clockins, data.clockingId, "editedClockIn") ===
            true ? (
              <EyeFilled
                style={styleEyeIcon}
                onClick={() => showModal(data, "clockIn")}
              />
            ) : null}
          </div>
        </div>
      ),
      filterParams: {
        readOnly: true,
      },
    },
    {
      headerName: "Mbarimi i turnit",
      field: "mbarim_turni",
      filter: "agNumberColumnFilter",
      cellRenderer: ({ data }) => (
        <div>
          <div variant="outlined" cursor="pointer">
            {dayjsTZ(data.mbarim_turni).format("HH:mm") !== "Invalid Date" ? (
              dayjsTZ(data.mbarim_turni).format("HH:mm")
            ) : (
              <h4 style={{ color: "#c43428" }}>Pa percaktuar</h4>
            )}{" "}
            {checkWhetherEdited(clockins, data.clockingId, "editedClockOut") ===
            true ? (
              <EyeFilled
                style={styleEyeIcon}
                onClick={() => showModal(data, "clockOut")}
              />
            ) : null}
          </div>
        </div>
      ),
      filterParams: {
        readOnly: true,
      },
    },
    {
      headerName: "Orët Totale",
      field: "oret_totale",
      filter: "agNumberColumnFilter",
      filterParams: {
        readOnly: true,
      },
      cellRenderer: ({ data }) => (
        <div>
          <strong>{isNaN(data.oret_totale) ? "0.00" : data.oret_totale}</strong>
        </div>
      ),
    },
  ];
  const auth = useAuth();
  const [gridApi, setGridApi] = useState(null);
  // const [selectedRows, setSelectedRows] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedShift, setEditedShift] = useState(null);
  const [clockins, setClockins] = useState([]);
  const [loading, setLoading] = useState(true);
  let { state } = useLocation();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [aggridData, setAggridData] = useState([]);
  const [editShiftModalVisibility, setEditShiftModalVisibility] =
    useState(false);
  const [visible, setVisible] = useState(false);
  const [editShiftModalData, setEditShiftModalData] = useState([]);
  const [newShiftVisib, setNewShiftVisib] = useState(false);
  const [approvedEnable, setApprovedEnable] = useState(false);
  const { users } = useSelector((state) => state.usersList);

  const dispatch = useDispatch();
  const reload = async () => {
    const getData = async () => {
      await requestData("clocking").then((res) => {
        dispatch(clockings(res || []));
      });
    };
    await getData();
  };

  const approveMenu = (
    <Menu width={200} height={200}>
      <Menu.Item key="1" height={30} onClick={() => onApprove("approve")}>
        Aprovo
      </Menu.Item>
      <Menu.Item key="2" height={30} onClick={() => onApprove("disapprove")}>
        Hiqe Aprovimin
      </Menu.Item>
    </Menu>
  );
  //DATE FUNCTIONS EXTERNAL FILTERS
  // var dateType = "";
  // const asDate = (dateAsString) => {
  //   var splitFields = dateAsString.split("/");
  //   return new Date(
  //     Number.parseInt(splitFields[2]),
  //     Number.parseInt(splitFields[1]) - 1,
  //     Number.parseInt(splitFields[0])
  //   );
  // };

  const onDelete = async () => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    await deleteClockIns(selectedData).then(() => {
      reload();
    });
    setTimeout(() => {
      getShiftData();
    }, 1400);
    gridApi.deselectAll();
  };

  const handlePassword = (u) => {
    let users1 = u;
    Swal.fire({
      title: "Vendos kodin personal!",
      input: "password",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Konfirmo",
      cancelButtonText: "Anulo",
      confirmButtonColor: "#02686b",
      cancelButtonColor: "#c43428",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("usersa", users1);
        if (result.value === users?.pinNumber) {
          onDelete();
          message.success("Kodi i vendosur eshte i sakte!");
        } else {
          message.error("Kodi i vendosur eshte gabim!");
          handlePassword(u);
        }
      }
    });
  };

  /////////////
  const onSaveEditSuccess = (clockingId, editedShift, employeeId, adminLog) => {
    // console.log("edited shift", editedShift);
    setAggridData(
      aggridData.map((x) => {
        if (x.clockingId !== clockingId) {
          return x;
        }
        return {
          ...x,
          fillim_turni: editedShift.clockInDate,
          mbarim_turni: editedShift.clockOutDate,
          approved: editedShift.approved,
          editedByAdmin: editedShift.editedByAdmin,
          oret_totale: dayjsTZ(editedShift.clockOutDate)
            .diff(dayjsTZ(editedShift.clockInDate), "hours", true)
            .toFixed(2),
        };
      })
    );
    // console.log("saved", clockingId, editedShift, employeeId, adminLog);
  };
  const showModal = (val, type) => {
    setIsModalVisible(true);
    setEditedShift([val, type]);
  };
  const handleSearch = (e) => {
    gridApi.setGridOption("quickFilterText", e.target.value);
  };
  const onApprove = async (type) => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    const isThereUnfinished = selectedData?.find(
      (el) => el?.clockOutDate === null
    );

    if (!isThereUnfinished) {
      if (type === "approve") {
        if (selectedData.filter((el) => el.approved === true).length > 0) {
          message.error("Ju nuk mund te zgjidhni dy statuse te ndryshme!");
          gridApi.deselectAll();
        } else if (
          selectedData.filter((el) => el.approved === true).length === 0 &&
          selectedData.length > 0
        ) {
          let adminlog = {
            approved: true,
            createdAt: dayjsTZ().valueOf(),
            approvedBy:
              auth?.userAccess[0]?.given_name +
              " " +
              auth?.userAccess[0]?.family_name,
          };
          // console.log("selected data", selectedData);
          await approveShifts(selectedData, adminlog);
          reload();
          setTimeout(() => {
            getShiftData();
          }, 1400);
          gridApi.deselectAll();
        }
      }
      if (type === "disapprove") {
        if (selectedData.filter((el) => el.approved === false).length > 0) {
          message.error("Ju nuk mund te zgjidhni dy statuse te ndryshme");
          gridApi.deselectAll();
        } else if (
          selectedData.filter((el) => el.approved === false).length === 0 &&
          selectedData.length > 0
        ) {
          let adminlog = {
            approved: false,
            createdAt: dayjsTZ().valueOf(),
            approvedBy:
              auth?.userAccess[0]?.given_name +
              " " +
              auth?.userAccess[0]?.family_name,
          };
          // console.log("selected data", selectedData);
          disapproveShifts(selectedData);
          reload();
          setTimeout(() => {
            getShiftData();
          }, 1400);
          gridApi.deselectAll();
        }
      }
    } else {
      message.error("Ju nuk mund te zgjidhni turne te pambyllura!");
    }
  };
  const onWeekFilter = async (filterType) => {
    let filterArr = weekFiltering(dayjsTZ(), filterType);

    let start = filterArr[0];
    let end = filterArr[1];
    await gridApi.getColumnFilterInstance("fillim_turni").then((comp) => {
      comp.setModel({
        type: "inRange",
        filter: start,
        filterTo: end,
      });
    });
    gridApi.onFilterChanged();
  };

  const onEditShift = (e) => {
    console.log("edit shift modal data", e, "employee", selectedEmployee);
    setEditShiftModalData([{ ...e, employee_details: selectedEmployee }]);
    setEditShiftModalVisibility(true);
  };
  //Data filterrrrr
  const handleDateRange = async (e) => {
    if (e !== null) {
      let start = e[0].startOf("day").valueOf();
      let end = e[1].endOf("day").valueOf();
      await gridApi.getColumnFilterInstance("fillim_turni").then((comp) => {
        comp.setModel({
          type: "inRange",
          filter: start,
          filterTo: end,
        });
      });
      // console.log("Dater", "start0", start, "end", end);
      gridApi.onFilterChanged();
    } else if (e === null) {
      gridApi.setFilterModel(null);
      gridApi.onFilterChanged(null);
      gridApi.setGridOption("quickFilterText", null);
    }
  };

  // make the grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
  };
  const clearFilters = () => {
    gridApi.setFilterModel(null);
    gridApi.onFilterChanged(null);
    gridApi.setGridOption("quickFilterText", null);
  };
  // editing selected row data
  const onBtStartEditing = () => {
    const selectData = gridApi.getSelectedNodes();
    // let selectedData = selectData.map((node) => node.data.employeeId);
    let selecData = selectData.map((node) => node.rowIndex);
    gridApi.startEditingCell({
      rowIndex: selecData,
      colKey: "employeeLastName",
    });
  };
  // export to exel
  const onBtExport = () => {
    gridApi.exportDataAsExcel();
  };
  const onRefresh = () => {
    setTimeout(() => {
      getShiftData();
    }, 3000);
  };

  const handleSelectionChanged = () => {
    let selectedNodes = gridApi.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    if (selectedData.length > 0) {
      setApprovedEnable(true);
    } else {
      setApprovedEnable(false);
    }
  };
  const getShiftData = async () => {
    if (!!selected[0]?.muaji) {
      return await requestDataWithParams(
        "clocking",
        dayjsTZ(selected[0]?.muaji)
          .subtract(1, "month")
          .startOf("month")
          .valueOf()
      ).then((res) => {
        setAggridData(
          res
            .filter(
              (f) =>
                f.employeeId === selectedEmployee.employeeId &&
                f.clockInDate >
                  dayjsTZ(selected[0].muaji).startOf("month").valueOf() &&
                f.clockInDate <
                  dayjsTZ(selected[0].muaji).endOf("month").valueOf()
            )
            .sort((a, b) => a.clockInDate - b.clockInDate)
            .map((el, idx) => ({
              ...el,
              nr: idx,
              data: el.clockInDate,
              fillim_turni: el.clockInDate,
              mbarim_turni: el.clockOutDate,
              clockingId: el.clockingId,
              oret_totale: dayjsTZ(el?.clockOutDate)
                .diff(dayjsTZ(el?.clockInDate), "hours", true)
                .toFixed(2),
            }))
        );
        setLoading(false);
        setClockins(res);
      });
    } else {
      return await requestData("clocking").then((res) => {
        setAggridData(
          res
            .filter((f) => f.employeeId === selectedEmployee.employeeId)
            .sort((a, b) => b.clockInDate - a.clockInDate)
            .map((el, idx) => ({
              ...el,
              nr: idx,
              data: el.clockInDate,
              fillim_turni: el.clockInDate,
              mbarim_turni: el.clockOutDate,
              clockingId: el.clockingId,
              oret_totale: dayjsTZ(el?.clockOutDate)
                .diff(dayjsTZ(el?.clockInDate), "hours", true)
                .toFixed(2),
            }))
        );
        setLoading(false);
        setClockins(res);
      });
    }
  };
  useEffect(() => {
    if (selected.length > 0 && selectedEmployee !== null) {
      // console.log("Selected employee", selectedEmployee);
      getShiftData();
    }
  }, [selected, selectedEmployee]);
  useEffect(() => {
    console.log("state", state, "employee", employee);
    if (state !== null && !employee) {
      setSelectedEmployee(state);
      // console.log("TO SELECT", state);
    }
    if (!!employee) {
      setSelectedEmployee(employee);
    }
    console.log(employee);
  }, [state, employee]);

  const componentRef = useRef("Print-Punojsit");
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const rowDataGrid = useMemo(() => {
    let temp = [];
    gridApi?.forEachNodeAfterFilter?.((node) => {
      temp?.push(node?.data);
    });
    console.log(temp);
    return temp;
  }, [gridApi]);

  const isMobileView = useMediaQuery({
    query: "(min-width: 460px)",
  });

  console.log(rowDataGrid);
  return (
    // <LoadableComp loading={loading}>
    <>
      {isMobileView ? (
        <div className="employee-shifts">
          <div className="ag-grid-shifts">
            <AgGridHeader
              gridApi={gridApi}
              gridData={aggridData}
              items={{
                ...ITEMS,
                icons: {
                  ...ITEMS.icons,
                  delete: { onClick: handlePassword },
                },
              }}
              exportTitle={`Orari - ${selectedEmployee?.employeeFirstName}.${selectedEmployee?.employeeLastName}`}
              children={[
                <div className="headerIcon">
                  <IconFilter
                    fill="#323338"
                    className="headerFilter"
                    onClick={() => setVisible(true)}
                    width={20}
                    height={20}
                  />
                </div>,
                <Dropdown.Button
                  onClick={() => onApprove("approve")}
                  className="approve-buttons"
                  menu={approveMenu}
                  disabled={approvedEnable ? false : true}
                >
                  Aprovo
                </Dropdown.Button>,
                <MondayButton
                  className="mondayButtonGreen"
                  disabled={
                    selectedEmployee?.employeeStatus === "Aktiv" ? false : true
                  }
                  onClick={() => setNewShiftVisib(true)}
                  style={{
                    cursor:
                      selectedEmployee?.employeeStatus === "Aktiv"
                        ? null
                        : "not-allowed",
                    zIndex: 34343434343,
                  }}
                >
                  Krijo nje hyrje
                </MondayButton>,
              ]}
            />

            {/* <div className="refresh-data">
            <button className="refresh-data-btn" onClick={() => getShiftData()}>
			Rifresko
            </button>
		</div> */}

            <div
              className="ag-theme-alpine"
              style={
                loading ? { height: "100%" } : { height: "100%", width: "100%" }
              }
            >
              {
                <LoadableComp loading={loading}>
                  <div className="shiftGridContent">
                    <AgGridComponent
                      rowData={aggridData}
                      rowSelection={"multiple"}
                      onGridReady={onGridReady}
                      paginationPageSize={20}
                      columnDefs={columnDefs}
                      suppressRowClickSelection={true}
                      onSelectionChanged={handleSelectionChanged}
                      overlayNoRowsTemplate={
                        '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Nuk ka rekorde per kete muaj</span>'
                      }
                      // onRowValueChanged={onRowValueChanged}
                      // onCellValueChanged={onCellValueChanged}
                      // onSelectionChanged={handleSelectionChanged}
                      // suppressServerSideInfiniteScroll={"full"}
                      // enableCellChangeFlash={true}
                      // rowModelType={"serverSide"}
                      // paginationPageSize={16}
                    />{" "}
                    <ChartSummary
                      dataSrc={clockins}
                      dataTitle="Employee hours"
                      employeeChart={selectedEmployee?.employeeId}
                    />
                  </div>
                </LoadableComp>
              }
            </div>
          </div>

          <HtmlExelTabel
            tableData={rowDataGrid?.map?.(
              ({
                approved,
                data,
                fillim_turni,
                mbarim_turni,
                oret_totale,
              }) => ({
                approved: approved ? "Aprovuar" : "Pa Aprovuar",
                data: dayjsTZ(data).format("DD/MM/YYYY"),
                fillim_turni: dayjsTZ(fillim_turni).format("HH:mm"),
                mbarim_turni: dayjsTZ(mbarim_turni).format("HH:mm"),
                oret_totale,
              })
            )}
            header={[
              "Statusi",
              "Data",
              "Fillimi i turnit",
              "Mbarimi i turnit",
              "Orët totale",
            ]}
            id="Employess-view-payment-modal"
            componentRef={componentRef}
          />
          <InnerModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            {...{ editedShift, clockins }}
          />
          <EditShiftModal
            {...{
              editShiftModalVisibility,
              setEditShiftModalVisibility,
              editShiftModalData,
              onRefresh,
              onSaveEditSuccess,
            }}
          />
        </div>
      ) : (
        <MobileShiftGrid
          aggridData={aggridData}
          setNewShiftVisib={setNewShiftVisib}
        />
      )}
      <NewShiftModal
        {...{
          employees: [],
          newShiftVisib,
          setNewShiftVisib,
          editShiftModalData,
          onRefresh,
          selfType: true,
          selectedEmployee,
        }}
      />
      {visible ? (
        <FilteringModal
          clearFilters={clearFilters}
          dateFilter={handleDateRange}
          onWeekFilter={onWeekFilter}
          visible={visible}
          setVisible={setVisible}
          // open={visible}
          // setVisible={setVisible}
          // firstWeekFilter={onWeekFilter("firstWeek")}
          //   secondWeekFilter={secondWeekFilter}
          //   thirdWeekFilter={thirdWeekFilter}
          //   fourthWeekFilter={fourthWeekFilter}
        />
      ) : null}
    </>
  );
}

const styleEyeIcon = {
  color: "#fff",
  padding: 3,
  borderRadius: 3,
  backgroundColor: "#02686b",
};
export default ShiftGrid;
