import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import {
  DragIcon,
  HiddenEye,
  IconEditWhite,
  LogoFlex,
  RefreshIcon,
  SavePreferences,
  UnapprovedIcon,
} from "../../../../assets/icons";
import { Popover, Switch, Tooltip, message } from "antd";
import { Admin } from "./dataFactory/AdminDashboard";
import { getUserSaved } from "./dataFactory/UserDashboard";
import HiddenDrawer from "./HiddenDrawer/HiddenDrawer";
import "./Draggable.scss";
import { settingsPozicionet } from "./dataFactory/SettingsDepartment";
import { settingsFitimet } from "./dataFactory/SettingsFitimet";
import { settingsZbritjet } from "./dataFactory/SettingsZbritjet";
import { departments } from "./dataFactory/Departments";
import { profili } from "./dataFactory/ProfiliDnD";
import { draggableInterviewsCharts } from "./dataFactory/InterviewsCharts";
import { settingsInfoRrethKompanis } from "./dataFactory/InfoRrethKompanis";
import useForceUpdate from "use-force-update";
import LoaderComponent from "../../../commonComponents/LoadableComp/LoadableComp";
import SortableItem from "./dragAndDrop/SortableItem";
import { Supervisor } from "./dataFactory/SupervisorDashboard";
import DashbordLayout from "../../DashboardLayout/DashboardLayout";
import { EditFilled, EditOutlined } from "@ant-design/icons";
import { motion } from "motion/react";
import { useMediaQuery } from "react-responsive";

// const SortableItem = SortableElement((props) => {
// 	const { data: data, value: item, btns: buttons, id: id, handleDelete, handleBlur } = props;

// 	// console.log(item);
// 	return (
// 		<div className="sortable-item-style">
// 			{/* <br /> */}
// 			<div className="content-style">
// 				{" "}
// 				<div
// 					style={{
// 						height: "35px",
// 						display: "flex",
// 						justifyContent: "space-between",
// 						background: "#1D3445",
// 						borderRadius: "5px 5px 0 0",
// 						paddingTop: "5px",
// 					}}
// 				>
// 					<span
// 						style={{
// 							font: "normal normal 400 16px/24px Open Sans",
// 							color: "white",
// 							marginLeft: "20px",
// 						}}
// 					>
// 						{item?.emri}
// 					</span>{" "}
// 					<Popover
// 						placement="rightTop"
// 						title={"Ndryshime"}
// 						overlayClassName="antd-more-drag"
// 						content={[<DragPopOver {...{ handleDelete, item, handleBlur }} />]}
// 						trigger="hover"
// 					>
// 						<span
// 							style={{
// 								cursor: "pointer",
// 								marginRight: "5px",
// 								marginTop: "5px",
// 							}}
// 						>
// 							<DragIcon />{" "}
// 						</span>{" "}
// 					</Popover>
// 				</div>
// 				<item.src data={item.data} content={<item.content />} blur={item?.blur} />
// 			</div>

// 			<br />
// 		</div>
// 	);
// });

// const SortableList = SortableContainer((props) => {
//   const { items, ...restProps } = props;
//   return (
//     <div className="sortable-list-container">
//       {items.map((item, index) => (
//         <SortableItem
//           key={`item-${item.id}`}
//           index={index}
//           value={item}
//           {...restProps}
//           data={item}
//         />
//       ))}
//     </div>
//   );
// });

const getView = (viewType, dynamicMap) => {
  const types = {
    Admin,
    profili,
    Supervisor,
    departments,
    settingsFitimet,
    settingsZbritjet,
    settingsPozicionet,
    settingsInfoRrethKompanis,
    draggableInterviewsCharts,
  };
  return !!types[viewType]
    ? types[viewType](dynamicMap, viewType)
    : getUserSaved();
};

function mergeValues(initial, newEntries) {
  const initialDictionary = initial.reduce(
    (acc, val) => ({ ...acc, [val?.i]: { ...val } }),
    {}
  );
  const finalState = [];

  for (const entry of newEntries) {
    const existingEntry = initialDictionary?.[entry?.i];
    if (existingEntry) {
      finalState.push({
        ...entry,
        h: existingEntry?.h,
        w: existingEntry?.w,
        x: existingEntry?.x,
        y: existingEntry?.y,
      });
    } else {
      finalState.push(entry);
    }
  }

  return finalState;
}

//region DRAG CONTAINER
function DragContainer({ view, dynamicMap, floatingHeader = false }) {
  const [data, setData] = useState(
    [] // view === "Admin"  ? getAdminSaved() : getUserSaved()
  );

  const [hiddenDrawerVisib, setHiddenDrawer] = useState(false);
  // const [drawerContent, setDrawerContent] = useState(null);
  const [toRender, setToRender] = useState([]);

  const [draggedAlr, setDraggedAlr] = useState(false);
  const [saveInd, setSaveInd] = useState(false);
  const [loading, setLoading] = useState({ val: false, interval: 0 });

  const [change, setChanged] = useState(false);

  // const [editedLayout, setEditedLayout] = useState([]);

  const editedLayout = useRef([]);

  const forceUpdate = useForceUpdate();

  const onSaveChanges = () => {
    setSaveInd(false);
    setChanged(false);

    const valuesToSave = mergeValues(editedLayout?.current, data);

    if (view === "Admin") {
      localStorage.setItem("draggableOrderAdmin", JSON.stringify(valuesToSave));
    } else if (view === "settingsPozicionet") {
      localStorage.setItem(
        "draggableSetingsPozicionet",
        JSON.stringify(valuesToSave)
      );
    } else if (view === "settingsFitimet") {
      localStorage.setItem(
        "draggableSetingsFitimet",
        JSON.stringify(valuesToSave)
      );
    } else if (view === "settingsZbritjet") {
      localStorage.setItem(
        "draggableSetingsZbritjet",
        JSON.stringify(valuesToSave)
      );
    } else if (view === "settingsInfoRrethKompanis") {
      localStorage.setItem(
        "draggableSetingsInfoRrethKompanis",
        JSON.stringify(valuesToSave)
      );
    } else if (view === "departments") {
      localStorage.setItem(
        "draggableOrderDepartments",
        JSON.stringify(valuesToSave)
      );
    } else if (view === "profili") {
      localStorage.setItem(
        "draggableProfili",
        JSON.stringify(valuesToSave)
      );
    } else if (view === "draggableInterviewsCharts") {
      localStorage.setItem(
        "draggableInterviewsCharts",
        JSON.stringify(valuesToSave)
      );
    } else if (view === "Supervisor") {
      localStorage.setItem(
        "draggableOrderSuperVisor",
        JSON.stringify(valuesToSave)
      );
    } else {
      localStorage.setItem("draggableOrderUser", JSON.stringify(valuesToSave));
    }
  };

  const handleClick = useCallback(() => {
    setLoading((prev) => ({ ...prev, val: true }));
    setTimeout(() => {
      setLoading((prev) => ({ ...prev, val: false }));
    }, 500);
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSaveInd(true);
    setDraggedAlr(true);
    document.body.style.cursor = "default";
    let tempOld = data.findIndex((el) => el.id === toRender[oldIndex].id);
    setData(arrayMove(data, tempOld, newIndex));
    setToRender(arrayMove(toRender, oldIndex, newIndex));
    // localStorage.setItem("draggableOrderAdmin", JSON.stringify(arrayMove(data, oldIndex, newIndex)));
  };

  const handleDelete = (i) => {
    setDraggedAlr(false);
    setSaveInd(true);
    const valuesToSave = mergeValues(editedLayout?.current, data);

    let temp = valuesToSave.map((el) => {
      if (el.i === i) {
        return { ...el, data: { ...el.data, hidden: true } };
      }
      return el;
    });
    setData(temp);
  };

  const handleBlur = (i) => {
    setSaveInd(true);
    setDraggedAlr(false);
    const valuesToSave = mergeValues(editedLayout?.current, data);

    let temp = valuesToSave.map((el) => {
      if (el.i === i) {
        return {
          ...el,
          data: { ...el.data, blur: !!el?.data?.blur ? !el?.data?.blur : true },
        };
      }
      return el;
    });
    setData(temp);
  };

  const handleCancel = () => {
    setToRender([]);
    setData(getView(view, dynamicMap));
    setChanged(false);
    setSaveInd(false);
  };

  const handleEditChange = () => {
    setChanged(true);
    setSaveInd(true);
  };

  const onUnhide = (i) => {
    setSaveInd(true);
    setDraggedAlr(false);
    let temp = data.map((el) => {
      if (el.i === i) {
        return { ...el, data: { ...el.data, hidden: false } };
      }
      return el;
    });
    setData(temp);
  };

  useEffect(() => {
    if (data?.length > 0 && draggedAlr === false) {
      setToRender(data.filter((el) => el?.data?.hidden === false));
    }
  }, [data, draggedAlr]);

  useEffect(() => {
    setData(getView(view, dynamicMap));
  }, [dynamicMap, view]);

  const isMobileView = useMediaQuery({
    query: "(max-width: 460px)",
  });

  const floating = useMemo(
    () => (isMobileView ? false : floatingHeader),
    [floatingHeader, isMobileView]
  );

  //region RETURN
  return (
    <LoaderComponent loading={loading.val}>
      <div className="drag-container">
        <motion.header
          className={`drag-container-header ${floating && "floatingHeader"}`}
          style={floating && saveInd ? { right: 0 } : {}}
          whileHover={
            floating ? { right: "0px", transition: { duration: 1 } } : {}
          }
        >
          <span
            className="refresh-btn"
            onClick={handleClick}
            style={{ fill: "white" }}
          >
            <RefreshIcon />
          </span>
          {saveInd && (
            <Tooltip
              placement="top"
              overlayClassName="global-icon-tooltip"
              title={"Ruaj kartat"}
            >
              <span
                className="save-btn"
                onClick={() => {
                  onSaveChanges();
                  message.success({ content: "U Ruajt me sukses!" });
                }}
              >
                <SavePreferences />
              </span>
            </Tooltip>
          )}
          {saveInd && (
            <Tooltip
              placement="topLeft"
              overlayClassName="global-icon-tooltip"
              title={"Anulloni Ndryshimet"}
            >
              <span
                className="save-btn"
                onClick={() => {
                  handleCancel();
                }}
              >
                <UnapprovedIcon height={19} width={19} />
              </span>
            </Tooltip>
          )}
          {!change && (
            <Tooltip
              placement="topLeft"
              overlayClassName="global-icon-tooltip"
              title={"Ndryshoni pozicionin e kartave"}
            >
              <span
                className="save-btn "
                onClick={() => {
                  handleEditChange();
                }}
              >
                <IconEditWhite />
              </span>
            </Tooltip>
          )}
          <Tooltip
            placement="topRight"
            overlayClassName="global-icon-tooltip"
            title={"Kartat e fshehura"}
          >
            <span
              className="hide-btn"
              onClick={() => {
                setHiddenDrawer(true);
              }}
            >
              <HiddenEye />
            </span>
          </Tooltip>
        </motion.header>

        <DashbordLayout
          layout={toRender}
          handleBlur={handleBlur}
          handleDelete={handleDelete}
          change={change}
          editedLayout={editedLayout}
          view={view}
        />

        {/* <SortableList
          pressDelay={300}
          axis="xy"
          items={toRender}
          onSortEnd={onSortEnd}
          onSortStart={() => (document.body.style.cursor = "move")}
          setData={setData}
          transitionDuration={900}
          disableAutoscroll={true}
          handleDelete={handleDelete}
          handleBlur={handleBlur}
        /> */}

        <HiddenDrawer
          {...{
            setHiddenDrawer,
            hiddenDrawerVisib,
            drawerContent: data,
            onUnhide,
          }}
        />
      </div>
    </LoaderComponent>
  );
}

export default DragContainer;

const DragPopOver = ({ handleDelete, item, handleBlur }) => {
  return (
    <div className="drag-pop-over">
      <span className="action-label" onClick={() => handleDelete(item?.id)}>
        Fshih
      </span>
      <span className="action-label" onClick={() => handleBlur(item?.id)}>
        Konfidenciale
      </span>
    </div>
  );
};
