import { PlusCircleFilled, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { background } from "../../../../../assets/images";
import "./Actions.scss";
import Additionals from "./Additionals";
import Deductions from "./Deductions";
import Summary from "./Summary";
import moment from "moment-timezone";
import dayjsTZ from "../../../../../utils/dayjs";
import { getSummary } from "./calculations";
import {
  addPrepayment,
  editPrepayment,
  onDeletePrepayment,
  validatePrevious,
} from "./api";
import { useAuth } from "../../../../../authentication/authHelpers";
import { API } from "aws-amplify";
import { requestMultipleTables } from "../../../../../helpers/API/RequestFactory";
import {
  earnings as earningsReducer,
  deductions as deductionsReducer,
  prepayments,
} from "../../../../../store/actions";
import { multiply } from "lodash";
import { paySubFactory } from "../../../PaySub/employeeCalc/employeeSubFactory";
import CustomWarnings from "../../warnings/CustomWarnings";
import ShiftModal from "../../../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import { PlusIcon } from "../../../../../assets/icons";
import HoursPreview from "./HoursPreview";
import MondayButton from "../../../../commonComponents/MondayButton/MondayButton";
const { Option } = Select;

function Actions({ employeeId, employeeInfo, timeperiod, momentInstance }) {
  const auth = useAuth();
  const dispatch = useDispatch();

  const { earnings } = useSelector((state) => state.earnings);
  const { deductions } = useSelector((state) => state.deductions);
  const { clockings } = useSelector((state) => state.clockings);
  const { employeeRequests } = useSelector((state) => state.employeeRequests);
  const { programFields } = useSelector((state) => state.programFields);

  const [currentMode, setCurrentMode] = useState("additions");
  const [deductionsData, setDeductionsData] = useState([]);
  const [additionsData, setAdditionsData] = useState([]);
  const [shiftModalVis, setShiftModalVis] = useState(false);
  const [earningsAddi, setEarningsAddit] = useState([]);
  const [hasPrev, setHasPrev] = useState({
    has: null,
    data: null,
    editMode: false,
  });
  const [loading, setLoading] = useState(false);
  const [realTime, setRealTime] = useState(null);
  const [spin, setSpin] = useState(false);

  console.log(employeeInfo);
  console.log(employeeId);
  console.log(timeperiod);
  console.log("1", momentInstance);

  const [newType, setNewType] = useState(null);
  const [rowObj, setRowObj] = useState({
    type: null,
    hours: null,
    days: null,
    note: "",
    rate: 1,
    amount: null,
  });
  const resetFields = () => {
    setRowObj({
      type: null,
      hours: null,
      days: null,
      note: "",
      rate: 1,
      amount: null,
    });
  };

  const summaryData = useMemo(
    () => getSummary(deductionsData, additionsData),
    [deductionsData, additionsData]
  );

  const typeDropDownData = useMemo(() => {
    if (!!earnings && !!deductions) {
      if (currentMode === "additions") {
        setRowObj((prev) => ({ ...prev, type: null }));
        setEarningsAddit(earnings.map(({ earningName }) => earningName) || []);
      } else if (currentMode === "deductions") {
        setRowObj((prev) => ({ ...prev, type: null }));
        setEarningsAddit(
          deductions.map(({ deductionName }) => deductionName) || []
        );
      }
    }
  }, [currentMode, earnings, deductions]);

  const onChange = (key, val) => {
    console.log("key", key, "val", val);
    const perHour = employeeInfo?.employeeSalaryPerHour || 1;

    if (key === "hours") {
      setRowObj((prev) => ({
        ...prev,
        [key]: val,
        days: val / 8,
        amount: multiply(val, perHour),
      }));
    }
    if (key === "amount") {
      setRowObj((prev) => ({ ...prev, [key]: val, hours: 0, days: 0 }));
    } else if (key === "days") {
      setRowObj((prev) => ({
        ...prev,
        [key]: val,
        hours: multiply(8, val),
        amount: multiply(multiply(8, val), perHour),
      }));
    } else {
      setRowObj((prev) => ({ ...prev, [key]: val }));
    }
  };

  const onAdd = () => {
    let { type, hours, days, note, rate } = rowObj;
    if (!!currentMode && !!type && hours >= 0 && days >= 0 && !!rate) {
      if (currentMode === "additions") {
        setAdditionsData((prev) => [...prev, rowObj]);
        resetFields();
      } else if (currentMode === "deductions") {
        setDeductionsData((prev) => [...prev, rowObj]);
        resetFields();
      }
    } else {
      message.error("Gabim, plotesoni te gjitha fushat!");
    }
  };

  const onFinalize = async () => {
    if (deductionsData.length > 0 || additionsData.length > 0) {
      setSpin(true);
      if (hasPrev?.editMode === false) {
        // message.success("U ruajten ndryshimet");
        // console.log("final", employeeId, auth);
        const earnings = {
          data: additionsData,
          totalities: summaryData?.additions?.totalities,
        };
        const deductable = {
          data: deductionsData,
          totalities: summaryData?.deductions?.totalities,
        };
        await addPrepayment(
          employeeId,
          earnings,
          deductable,
          timeperiod,
          auth?.user?.username,
          auth?.userAccess[0]
        )
          .then(async () => {
            message.success("Veprimet u kryen me sukses");
            reloadDedAdd();
            setTimeout(() => {
              checkPrev();
            }, 1600);
          })
          .catch((err) => {
            message.error("Ndodhi nje gabim!", console.error(err));
          });
      } else if (hasPrev?.editMode === true) {
        const earnings = {
          data: additionsData,
          totalities: summaryData?.additions?.totalities,
        };
        const deductable = {
          data: deductionsData,
          totalities: summaryData?.deductions?.totalities,
        };
        await editPrepayment(
          hasPrev?.data?.prepaymentId,
          earnings,
          deductable,
          auth?.user?.username,
          auth?.userAccess[0]
        )
          .then(async () => {
            message.success("Ndryshimet u ruajten me sukses!");
            await reloadDedAdd();
          })
          .catch((err) => {
            message.error("Ndodhi nje gabim!", console.error(err));
          });
      }
    } else {
      setSpin(false);
      message.error("Ju nuk keni bere asnje ndryshim!");
    }
  };

  const payStubObj = useMemo(() => {
    // console.log("ledjo Info", employeeInfo, clockings, employeeRequests);
    if (
      !!employeeInfo &&
      !!clockings &&
      !!employeeRequests &&
      !!programFields
    ) {
      const earnings = {
        data: additionsData,
        totalities: summaryData?.additions?.totalities,
      };
      const deductable = {
        data: deductionsData,
        totalities: summaryData?.deductions?.totalities,
      };
      // Mock Prepayment
      const preTemp = {
        employeeId: employeeInfo?.employeeId,
        earnings: earnings,
        deductions: deductable,
        issuedAt: dayjsTZ().valueOf(),
        period: [...timeperiod],
        createdBy: { username: "generating", name: "generating | ignore" },
      };
      const test13 = new paySubFactory({
        type: "allEarnings",
        employee: employeeInfo || null,
        clockings: clockings.filter((el) => el?.approved === true),
        employeeRequests: employeeRequests,
        momentInstance: momentInstance,
        prepayments: [preTemp],
        programFields: programFields,
      });
      setRealTime(test13);
      return test13;
    }
    return null;
  }, [
    timeperiod,
    employeeInfo,
    clockings,
    employeeRequests,
    deductionsData,
    additionsData,
  ]);

  const reloadDedAdd = async () => {
    const getData = async () => {
      await requestMultipleTables([
        "earnings",
        "deductions",
        "prepayments",
      ]).then((res) => {
        dispatch(earningsReducer(res[0]));
        dispatch(deductionsReducer(res[1]));
        dispatch(prepayments(res[2]));
      });
    };
    await getData();
  };

  const checkPrev = async () => {
    setDeductionsData([]);
    setAdditionsData([]);
    const result = await validatePrevious(employeeId, timeperiod).then(
      (res) => res
    );
    // console.log("Check Prev", result);
    if (!!result) {
      setHasPrev({ has: true, data: result, editMode: false, new: false });
    } else {
      setHasPrev({ has: false, data: null, editMode: false, new: false });
      // setDeductionsData([]);
      // setAdditionsData([]);
    }
    setLoading(false);
  };

  const onEditMode = () => {
    setHasPrev((prev) => ({ ...prev, has: false, editMode: true, new: false }));
    const { deductions, earnings } = hasPrev?.data;
    console.log("hasPrev", hasPrev);
    setDeductionsData(deductions?.data);
    setAdditionsData(earnings?.data);
  };

  const deleteAndClear = async () => {
    await onDeletePrepayment(hasPrev?.data);
    await reloadDedAdd();
    setHasPrev({ has: false, data: null, editMode: false, new: true });
    setDeductionsData([]);
    setAdditionsData([]);
  };

  // useMemo(() => {
  // 	setDeductionsData([]);
  // 	setAdditionsData([]);
  // }, [momentInstance]);

  useEffect(() => {
    if (!!employeeId && !!timeperiod) {
      setLoading(true);
      checkPrev();
    }
  }, [employeeId, timeperiod, momentInstance]);

  console.log("payStubObj", payStubObj);

  return (
    <>
      {loading === false ? (
        <div className="actions-ded-add">
          <HoursPreview payObj={payStubObj} />
          {hasPrev.has === false && (
            <>
              {!!payStubObj && (
                <>
                  {" "}
                  <br />
                  <CustomWarnings {...{ payStubObj }} />{" "}
                </>
              )}
              <div className="actions-wrapper">
                <div className="action-btns">
                  <div className="first">
                    <span
                      onClick={() => setCurrentMode("additions")}
                      className="action-btn"
                      style={
                        currentMode === "additions"
                          ? { background: "#02686b", color: "white" }
                          : null
                      }
                    >
                      Fitime
                    </span>
                    <span
                      className="action-btn"
                      onClick={() => setCurrentMode("deductions")}
                      style={
                        currentMode === "deductions"
                          ? { background: "#c43428 ", color: "white" }
                          : null
                      }
                    >
                      Zbritje
                    </span>
                  </div>
                  <div className="finalize">
                    <Popconfirm
                      title="A jeni te sigurt qe deshironi te shtoni ndryshimet ne paycheck?"
                      onConfirm={onFinalize}
                      okText="Po, finalizo!"
                      cancelText="Jo, anulo."
                      rootClassName="finalizoPaycheckPopconfirm"
                    >
                      <MondayButton className="mondayButtonGreen" spin={spin}>
                        Finalizo Ndryshimet
                      </MondayButton>{" "}
                    </Popconfirm>
                    <Popconfirm
                      title="A jeni te sigurt qe deshironi te fshini?"
                      onConfirm={() => deleteAndClear()}
                      disabled={!!hasPrev.data ? false : true}
                      okText="Po, fshi!"
                      cancelText="Jo, anulo."
                    >
                      <MondayButton
                        className="mondayButtonRed"
                        disabled={!!hasPrev.data ? false : true}
                      >
                        Fshi
                      </MondayButton>
                    </Popconfirm>
                    <MondayButton
                      className="mondayButtonGreen"
                      onClick={() => setShiftModalVis(true)}
                    >
                      Ndrysho Turnet
                    </MondayButton>
                  </div>
                </div>
                <br />
                <div className="add-row">
                  <span className="add-element">
                    <label>Lloji:</label>
                    <Select
                      showSearch
                      placeholder="Zgjidh "
                      optionFilterProp="children"
                      style={{ width: 200 }}
                      allowClear
                      value={rowObj?.type}
                      defaultValue={rowObj?.type}
                      onChange={(val) => onChange("type", val)}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space
                            align="center"
                            style={{ padding: "0 8px 4px" }}
                          >
                            <Input
                              placeholder="Shto Opsion"
                              value={newType}
                              onChange={(e) => setNewType(e.target.value)}
                            />
                            <Typography.Link
                              onClick={() => {
                                setEarningsAddit((prev) => [...prev, newType]);
                                setNewType(null);
                              }}
                              style={{ whiteSpace: "nowrap" }}
                            >
                              <PlusOutlined />
                            </Typography.Link>
                          </Space>
                        </>
                      )}
                    >
                      {earningsAddi?.map((el) => (
                        <Option value={el}>{el}</Option>
                      ))}
                    </Select>
                  </span>
                  <span className="add-element">
                    <label>Shuma:</label>
                    <InputNumber
                      min={0}
                      style={{ width: "150px" }}
                      placeholder="Zgjidh Shumen"
                      value={rowObj?.amount}
                      defaultValue={rowObj?.amount}
                      onChange={(val) => onChange("amount", val)}
                    />
                  </span>
                  <span className="add-element">
                    <label>Orët:</label>
                    <InputNumber
                      min={0}
                      style={{ width: "150px" }}
                      placeholder="Zgjidh Oret"
                      value={rowObj?.hours}
                      defaultValue={rowObj?.hours}
                      onChange={(val) => onChange("hours", val)}
                    />
                  </span>
                  <span className="add-element">
                    <label>Ditët (1=8 orë):</label>
                    <InputNumber
                      disabled={false}
                      min={0}
                      style={{ width: "150px" }}
                      placeholder="Zgjidh Ditet"
                      value={rowObj?.days}
                      defaultValue={rowObj?.days}
                      onChange={(val) => onChange("days", val)}
                    />
                  </span>
                  <span className="add-element">
                    <label>Shënimi</label>
                    <Input
                      style={{ width: "200px" }}
                      placeholder="Vendos Shenimin"
                      value={rowObj?.note}
                      defaultValue={rowObj?.note}
                      onChange={(val) => onChange("note", val.target.value)}
                    />
                  </span>
                  {/* <InputNumber
					min={0}
					placeholder="Koeficenti"
					value={rowObj?.rate}
					defaultValue={rowObj?.rate}
					onChange={(val) => onChange("rate", val)}
				/> */}
                  <span className="add-element">
                    <label>Koeficienti</label>
                    <Select
                      showSearch
                      placeholder="Zgjidh Koeficentin "
                      optionFilterProp="children"
                      style={{ width: 130 }}
                      value={rowObj?.rate}
                      defaultValue={rowObj?.rate}
                      onChange={(val) => onChange("rate", val)}
                      // onSearch={onSearch}
                    >
                      <Option value={0.8}>0.8</Option>
                      <Option value={1}>1</Option>
                      <Option value={1.2}>1.2</Option>
                      <Option value={1.5}>1.5</Option>
                    </Select>
                  </span>
                  {/* <Button> Shto </Button> */}
                  <span className="add-element">
                    {/* <label>Shto</label> */}
                    <span className="add-icon" onClick={onAdd}>
                      {" "}
                      <PlusIcon />
                    </span>
                  </span>
                </div>
              </div>

              <div className="deductions-table">
                <Additionals {...{ additionsData, setAdditionsData }} />
              </div>
              <div className="additions-table">
                <Deductions {...{ deductionsData, setDeductionsData }} />
              </div>
              <div className="summary-table">
                <Summary {...{ summaryData }} />
              </div>
              <ShiftModal
                isModalVisible={shiftModalVis}
                setIsModalVisible={setShiftModalVis}
                employee={employeeInfo}
                selectedData={[
                  { muaji: dayjsTZ(momentInstance.start).valueOf() },
                ]}
                setSelectedData={() => {}}
              />
            </>
          )}
          {hasPrev.has === true && (
            <div style={{ width: 1230 }}>
              {!hasPrev?.new ? (
                <>
                  <br />
                  <Alert
                    message="KUJDES!"
                    description={
                      <span>
                        Për këtë punonjës në këtë periudhë është gjeneruar një
                        listë me fitimet ose/dhe zbritjet. A dëshironi të
                        vazhdoni? <Button onClick={onEditMode}>Po</Button>
                      </span>
                    }
                    type="warning"
                    showIcon
                    closable
                  />
                </>
              ) : (
                <Alert
                  message="Krijim i ri"
                  description="Ju jeni duke krijuar llogaritje të reja për pagën!"
                  type="success"
                  showIcon
                />
              )}
            </div>
          )}
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      )}
    </>
  );
}

export default Actions;
