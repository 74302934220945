import { useEffect, useMemo } from "react";
import "./CandidatesConfigKanban.scss";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Swal from "sweetalert2";
import { API } from "aws-amplify";
import { apiPut } from "../../../DocumentationConfiguration/utils/api";
import { useSelector } from "react-redux";
import { Badge, message, Modal, Space, Tooltip, Typography } from "antd";
import { Delete, EditIcon, XIcon } from "../../../../assets/icons";
import { toSentenceCase } from "../../../../utils";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
import { QuestionsMark } from "../../../Intervistat/Kandidatet/ShtoKandidat/Image";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { programFields as programFieldsReducer } from "../../../../store/actions";

const ICON_PROPS = { fill: "#fff", width: "16", height: "16" };

const CandidatesConfigKanban = ({
  endpoint,
  setRowData,
  setChanges = () => {},
  onItemClick = () => {},
  data,
  programField = {},
  setDeletedFields = () => {},
  groupBy = "step",
  editColumn = () => {},
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const { programFields } = useSelector((state) => state.programFields);
  // const upd = async () => {
  //   await apiPut("programFields", programField?.fieldId, {
  //     fieldOptions: [
  //       {
  //         step: 1,
  //         name: "general",
  //         fields: [
  //           {
  //             createdAt: 1727962310761,
  //             formItemName: "applicantFirstName",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Emër",
  //             placeholder: "Shkruani Emër...",
  //             type: "input",
  //             required: true,
  //             fieldId: "8f42d563-2c54-4c65-8977-bbd8346f51ce",
  //           },
  //           {
  //             createdAt: 1727962353619,
  //             formItemName: "applicantLastName",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Mbiemër",
  //             placeholder: "Shkruani Mbiemër...",
  //             type: "input",
  //             required: true,
  //             fieldId: "5f845559-14ef-4aef-9c6c-184911145ad9",
  //           },
  //           {
  //             createdAt: 1727962498263,
  //             formItemName: "applicantDepartment",
  //             createdBy: "Flex Solutions",
  //             options: [
  //               "IT & Software Development",
  //               "Architecture & Engineering",
  //               "Administration",
  //               "Internal Staff",
  //               "Human Resources",
  //               "Project Monitoring & Coordinating",
  //               "Finance & Accounting",
  //               "Sales Management",
  //             ],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Departmenti",
  //             placeholder: "Selektoni Departmenti...",
  //             type: "select",
  //             required: true,
  //             fieldId: "bfc2a984-01db-45b9-8afd-a07267dfef68",
  //           },
  //           {
  //             createdAt: 1727962639176,
  //             formItemName: "applicantRoles",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Roli",
  //             placeholder: "Selektoni Roli...",
  //             type: "select",
  //             required: true,
  //             fieldId: "e6f6a563-5fae-4b88-a68a-8e7932be561a",
  //           },
  //           {
  //             createdAt: 1727964369304,
  //             formItemName: "applicantStatus",
  //             createdBy: "Flex Solutions",
  //             options: ["Skualifikuar", "Listuar për intervistë"],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Statusi",
  //             placeholder: "Selektoni Statusi...",
  //             type: "select",
  //             required: true,
  //             fieldId: "4e2b8d51-f84e-4dbc-a9c5-6c61e0876017",
  //           },
  //           {
  //             createdAt: 1727962614573,
  //             formItemName: "applicantPersonalNumber",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Nr.Personal",
  //             placeholder: "Shkruani Nr.Personal...",
  //             type: "input",
  //             required: true,
  //             fieldId: "3637f3b3-4818-4906-8a31-f65c9a2bebc5",
  //           },
  //           {
  //             createdAt: 1727962595352,
  //             formItemName: "applicantBirthday",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Datëlindja",
  //             placeholder: "Shkruani Datëlindja...",
  //             type: "datepicker",
  //             required: false,
  //             fieldId: "5f01fd54-94c3-4f54-b85b-e4d4bce2f1ee",
  //           },
  //           {
  //             createdAt: 1727964449959,
  //             formItemName: "applicantAddress",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "general",
  //             rules: [],
  //             label: "Adresa e banimit",
  //             placeholder: "Shkruani Adresa e banimit...",
  //             type: "input",
  //             required: false,
  //             fieldId: "449965a9-853b-46d7-b400-de342d97b7cf",
  //           },
  //         ],
  //       },
  //       {
  //         step: 2,
  //         name: "questions",
  //         fields: [
  //           {
  //             createdAt: 1727962668545,
  //             formItemName: "applicantTraining",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "questions",
  //             rules: [],
  //             label: "Trajnime dhe kualifikime të tjera",
  //             placeholder: "Shkruani Trajnime dhe kualifikime të tjera...",
  //             type: "customquill",
  //             required: false,
  //             fieldId: "296b2b1a-1c26-4b26-a151-5ad0802fb24a",
  //           },
  //           {
  //             createdAt: 1727964402010,
  //             formItemName: "applicantTeam",
  //             createdBy: "Flex Solutions",
  //             options: ["tirana", "prishtina"],
  //             deletedField: false,
  //             step: "questions",
  //             rules: [],
  //             label: "Skuadra",
  //             placeholder: "Selektoni Skuadra...",
  //             type: "select",
  //             required: true,
  //             fieldId: "fa566e4c-1964-41cc-bb08-8c3745e14e33",
  //           },
  //           {
  //             createdAt: 1727964431122,
  //             formItemName: "applicantPhoneNumber",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: false,
  //             step: "questions",
  //             rules: [],
  //             label: "Nr.Celulari",
  //             placeholder: "Shkruani Nr.Celulari...",
  //             type: "phone",
  //             required: true,
  //             fieldId: "6a512b7a-b907-4955-ab8a-44e1c826f10b",
  //           },
  //           {
  //             createdAt: 1727964499917,
  //             formItemName: "applicantPlatform",
  //             createdBy: "Flex Solutions",
  //             options: ["insta", "dua pune"],
  //             deletedField: false,
  //             step: "questions",
  //             rules: [],
  //             label: "Platforma",
  //             placeholder: "Selektoni Platforma...",
  //             type: "select",
  //             required: true,
  //             fieldId: "1efdad72-e71a-4e86-8940-a9c373601fe6",
  //           },
  //           {
  //             createdAt: 1727964530754,
  //             formItemName: "applicantDiplomaLevel",
  //             createdBy: "Flex Solutions",
  //             options: ["9vjecare", "uni"],
  //             deletedField: false,
  //             step: "questions",
  //             rules: [],
  //             label: "Niveli i diplomës",
  //             placeholder: "Selektoni Niveli i diplomës...",
  //             type: "select",
  //             required: true,
  //             fieldId: "b6594679-631d-4bf7-89ec-f3f1757b4b71",
  //           },
  //           {
  //             createdAt: 1728639677920,
  //             formItemName: "testt",
  //             createdBy: "Flex Solutions",
  //             options: [],
  //             deletedField: true,
  //             step: "questions",
  //             rules: [
  //               {
  //                 type: "email",
  //                 message: "Ju lutem shkruani email-in e sakte",
  //                 required: true,
  //               },
  //             ],
  //             label: "test",
  //             placeholder: "Shkruani test...",
  //             type: "input",
  //             required: false,
  //             fieldId: "7f0f2d41-0890-4998-9fd5-d5cfb437b79e",
  //           },
  //         ],
  //       },
  //     ],
  //   });
  // };

  // useEffect(() => {
  //   upd();
  // }, []);

  //region >updateProgramFields()
  const updateProgramFields = (newFieldOptions) => {
    const newProgramFields = [...(programFields || [])];
    const index = newProgramFields.findIndex(
      ({ fieldId }) => fieldId === programField?.fieldId
    );

    if (index > -1) {
      newProgramFields[index] = {
        ...newProgramFields[index],
        fieldOptions: newFieldOptions,
      };
      dispatch(programFieldsReducer(newProgramFields));
    }
  };

  //region DRAG HANDLER
  const dragEndHandler = async (value) => {
    const { source, destination } = value;
    if (
      source.index === destination.index &&
      source.droppableId === destination.droppableId
    ) {
      return;
    } else {
      const sourceCol = source.droppableId.split("-")[1];
      const destinationCol = destination.droppableId.split("-")[1];

      // selected item
      let draggedRow = data?.find((col) => col?.name === sourceCol)?.["fields"][
        source.index
      ];

      draggedRow[groupBy] = destinationCol;

      //delete item from source
      const newSrcColData = data
        ?.find((col) => col?.name === sourceCol)
        ?.["fields"].filter((_, i) => i !== source.index);

      let srcColToSend = newSrcColData;
      let destColToSend;

      if (sourceCol === destinationCol) {
        //add to source with new index
        srcColToSend = newSrcColData.toSpliced(
          destination.index,
          0,
          draggedRow
        );
      } else {
        //add to destination with new index
        destColToSend = data
          ?.find((col) => col?.name === destinationCol)
          ?.["fields"]?.toSpliced(destination.index, 0, draggedRow);
      }

      let toSend = [];
      data?.forEach((col) => {
        if (col?.name === sourceCol) {
          toSend.push({ ...col, fields: srcColToSend });
        } else if (col?.name === destinationCol) {
          toSend.push({ ...col, fields: destColToSend });
        } else {
          toSend.push({ ...col });
        }
      });
      const swalProps = {
        toast: true,
        timer: 3000,
        showConfirmButton: false,
        position: "bottom-end",
      };
      const oldRowData = [...data];
      setRowData(toSend);
      try {
        Swal.fire({
          title: "Ju lutem prisni...",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        await apiPut(endpoint, programField?.fieldId, {
          fieldOptions: toSend,
        }).then(() => {
          updateProgramFields(toSend)
          Swal.close();
          Swal.mixin({
            ...swalProps,
          }).fire({
            icon: "success",
            title: `${draggedRow?.label} u zhvendos me sukses`,
          });
        });
      } catch (err) {
        message.error("Error ne perditesimin e konfigurimit te kandidateve!");
        setRowData(oldRowData);
        Swal.mixin({
          ...swalProps,
        }).fire({
          icon: "error",
          title: "Perditesimi nuk u krye!",
        });
        console.error("Error:", err);
      }
    }
  };
  // region ON ITEM DELETE
  const onItemDelete = (e, item) => {
    e.stopPropagation();

    setRowData((prev) =>
      prev.map((col) =>
        col?.name === item?.step
          ? {
              ...col,
              fields: col?.fields?.filter(
                (field) => field?.fieldId !== item?.fieldId
              ),
            }
          : col
      )
    );
    setDeletedFields((prev) =>
      prev.map((col) =>
        col?.name === item?.step
          ? {
              ...col,
              fields: col?.fields?.map((el) =>
                el?.fieldId === item?.fieldId
                  ? { ...el, deletedField: true }
                  : el
              ),
            }
          : col
      )
    );
    setChanges(true);
  };

  //region ON COLUMN DELETE
  const onColumnDelete = async (item) => {
    Modal.confirm({
      title: "A jeni të sigurt?",
      icon: <QuestionsMark />,
      content: (
        <Text>
          Ju po fshini një step të konfigurimit të kandidatëve,{" "}
          <span className="text-bold">së bashku me form items përkatës!</span>
          <Space className="space-gap">
            Nëse jeni të sigurt për këtë veprim, klikoni
            <span className="text-bold">"Po, fshije"</span>
          </Space>
        </Text>
      ),
      okText: "Po, fshije",
      cancelText: "Jo, mos e fshi",
      width: "660px",
      className: "cancelModal",
      centered: true,
      cancelButtonProps: {
        type: "primary",
        style: {
          background: "#c43428",
          borderColor: "#c43428",
          borderRadius: "5px",
        },
      },
      okButtonProps: {
        className: "ant-btn-confirm",
      },
      onOk() {
        const oldFieldOptions = data || [];
        const index = oldFieldOptions.findIndex(
          (el) => el?.name === item?.name
        );
        if (index !== -1) {
          let newFieldOptions = [...oldFieldOptions];
          newFieldOptions.splice(index, 1);

          for (let i = index; i < newFieldOptions.length; i++) {
            const field = newFieldOptions[i];
            newFieldOptions[i] = { ...field, step: field?.step - 1 };
          }
          setRowData(newFieldOptions);
          setChanges(true);
        }
      },
      onCancel: () => false,
    });
  };

  //region RETURN
  return (
    <DragDropContext
      onDragStart={() => {}}
      onDragEnd={(val) => dragEndHandler(val)}
    >
      <div className="kanbanContainer">
        {data?.map((el, idx) => (
          <Droppable droppableId={`droppable-${el?.name}`} key={idx}>
            {(provided, snapshot) => (
              <div
                className="kanbanColumn"
                {...provided?.droppableProps}
                ref={provided?.innerRef}
              >
                <div className="kanbanColHeader">
                  {toSentenceCase(el?.name)}
                  <div className="kanbanEditColBtns">
                    <MondayButton
                      className="mondayButtonRed"
                      onClick={() => onColumnDelete(el)}
                      Icon={<Delete {...ICON_PROPS} />}
                      data-testid="close-btn"
                    />
                    <MondayButton
                      className="mondayButtonGrey"
                      onClick={() =>
                        editColumn({ name: el?.name, step: el?.step })
                      }
                      data-testid="edit-btn"
                      Icon={<EditIcon {...ICON_PROPS} />}
                    />
                  </div>
                </div>
                <div className="kanbanColContent">
                  {el?.fields?.map((item, i) => (
                    <Draggable
                      draggableId={`draggable-${el?.name}-${i}`}
                      index={i}
                      key={i}
                    >
                      {(provided, snapshot) => (
                        <Tooltip
                          title={"Click to edit"}
                          placement="top"
                          mouseEnterDelay={0.5}
                        >
                          <div
                            className="kanbanItem"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            onClick={() => onItemClick(item)}
                          >
                            <div
                              className="deleteItem"
                              onClick={(e) => onItemDelete(e, item)}
                            >
                              <Delete width={22} height={22} fill="#c43428" />
                            </div>
                            <div className="itemLabels">
                              <span className="itemLabel">
                                {item?.label.length > 25
                                  ? item?.label.slice(0, 25) + "..."
                                  : item?.label}
                              </span>
                              <span className="itemFormName">
                                {`(${item?.formItemName})`}
                              </span>
                            </div>
                            {!!item?.required ? (
                              <Badge.Ribbon text={"required"} color="red" />
                            ) : null}
                          </div>
                        </Tooltip>
                      )}
                    </Draggable>
                  ))}
                </div>
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default CandidatesConfigKanban;

CandidatesConfigKanban.propTypes = {
  endpoint: PropTypes.string.isRequired,
  setRowData: PropTypes.func.isRequired,
  setChanges: PropTypes.func,
  onItemClick: PropTypes.func,
  data: PropTypes.array.isRequired,
  programField: PropTypes.object,
  setDeletedFields: PropTypes.func,
  groupBy: PropTypes.string,
  editColumn: PropTypes.object,
};
