import React, { useEffect, useMemo, useState } from "react";
import "./Permbledhje.scss";
import { charts } from "./charts";
import dayjsTZ from "../../../utils/dayjs";
import { useSelector } from "react-redux";
import LoaderComponent from "../../commonComponents/LoadableComp/LoadableComp";
import DragContainer from "../../dashboard/AdminDashboard/Draggable/DragContainer";

const Permbledhje = () => {
  const { candidates } = useSelector((state) => state.candidates);
  const { programFields } = useSelector((state) => state.programFields);

  const newInputs = useMemo(() => {
    if (!!programFields?.length) {
      return programFields?.find(
        (el) => el?.fieldName === "Konfigurimi i Kandidateve"
      );
    } else return {};
  }, [programFields]);

  const [chartData, setChartData] = useState({});
  const [activeFilter, setActiveFilter] = useState({});

  useEffect(() => {
    const charts = [
      "Intervista te planifikuara",
      "Aplikimet",
      "Pranuar/Skualifikuar",
      "Team",
      "Platformat",
      "Ditët në provë",
      "Untitled",
    ];
    if (candidates?.length > 0) {
      const updatedChartData = {};
      console.log({ candidates });

      charts?.forEach?.((chart) => {
        updatedChartData[chart] = {
          candidates: candidates?.filter?.((kandidati) => {
            const { applicantInterviewDate = {} } = kandidati;
            const timestamp = dayjsTZ(applicantInterviewDate).valueOf();
            const year = dayjsTZ(timestamp).year();
            return year === dayjsTZ().year();
          }),
          monthToFilter: "All",
          year: dayjsTZ().year(),
        };
      });

      setChartData((prevState) => ({
        ...prevState,
        ...updatedChartData,
      }));
    }
  }, [candidates]);

  useEffect(() => {
    if (Object.keys(activeFilter).length > 0) {
      const updatedChart = {};

      Object.keys(activeFilter)?.forEach?.((key) => {
        const { month, year } = activeFilter?.[key];
        const filteredcandidates = candidates?.filter?.((kandidati) => {
          const { applicantInterviewDate = {} } = kandidati;
          const timestamp = dayjsTZ(applicantInterviewDate).valueOf();
          const monthToFilter = dayjsTZ(timestamp).month();
          const yearToFilter = dayjsTZ(timestamp).year();
          return (
            (month === "All" || monthToFilter === Number(month)) &&
            yearToFilter === year
          );
        });
        updatedChart[key] = {
          ...chartData[key],
          candidates: filteredcandidates,
          monthToFilter: month,
          year,
        };
      });
      setChartData((prevState) => ({
        ...prevState,
        ...updatedChart,
      }));
    }
  }, [activeFilter]);

  const interviewsCharts = useMemo(() => {
    if (!Object.keys(chartData)?.length || !newInputs?.fieldOptions?.length) {
      return [];
    } else {
      return charts(chartData, newInputs?.fieldOptions).map((chart) => ({
        ...chart,
        activeFilter,
        setActiveFilter,
        hasData: !!chartData[chart?.topTitle]?.candidates?.length,
      }));
    }
  }, [chartData, newInputs?.fieldOptions]);

  return (
    <LoaderComponent loading={!Object.keys(chartData)?.length}>
      <div className="hiringCharts">
        {!!newInputs?.fieldOptions?.length ? (
          <DragContainer
            view={"draggableInterviewsCharts"}
            dynamicMap={interviewsCharts}
            floatingHeader={true}
          />
        ) : null}
      </div>
    </LoaderComponent>
  );
};

export default Permbledhje;
