import {
  Alert,
  Button,
  DatePicker,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Select,
} from "antd";
import { API } from "aws-amplify";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import "./EditEmployeeWage.scss";
import { BreedingRhombusSpinner } from "react-epic-spinners";
import {
  AddRowIcon,
  IconaMbyll,
  IconNdrysho,
  LogoFlex,
  RedWarning,
  WarningRed,
} from "../../../../assets/icons";
import { PlusCircleFilled } from "@ant-design/icons";
import Additionals from "./deductAdd/Additionals";
import Deductions from "./deductAdd/Deductions";
import Actions from "./deductAdd/Actions";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import PersonalPaycheckPreview from "../generate/PersonalPaycheckPreview";
import ShiftModal from "../../../punonjesit/EmployeeView/Payment/EmployeeShifts/ShiftModal";
import MondayButton from "../../../commonComponents/MondayButton/MondayButton";
const { Option } = Select;

function EditEmployeeWage({ editable, setVisib, onSave, toEdit }) {
  const employeeInfo = useMemo(() => editable?.toEdit || null, [editable]);
  const [employeeWageTypes, setWageTypes] = useState(null);
  const { programFields } = useSelector((state) => state.programFields);
  const { currentPrepayments } = useSelector(
    (state) => state.currentPrepayments
  );
  const [dayjsInstance, setDayjsInstance] = useState({
    start: dayjsTZ(editable?.toEdit?.time).startOf("month"),
    end: dayjsTZ(editable?.toEdit?.time).endOf("month"),
  });
  const [loading, setLoading] = useState(false);
  const [edited, setEdited] = useState({}); // {}
  const [realTimePreview, setRealTimePreview] = useState({});

  const onChangeEdit = (editField, val) => {
    // console.log("EditField", editField, "val", val);
    let temp = edited || {};
    temp[editField] = val;
    setEdited(temp);
  };

  const onCloseCleanUp = () => {
    setVisib((prev) => ({ ...prev, visibility: false }));
    setEdited({});
  };
  console.log("Editable", editable);

  const onSaveChanges = async () => {
    const isEmpty = Object.keys(edited).length === 0;
    if (!!employeeInfo?.employeeId && !isEmpty) {
      setLoading(true);
      await API.put("employees", `/employees/${employeeInfo?.employeeId}`, {
        body: {
          ...edited,
        },
      })
        .then(() => {
          message.success("Te dhënat u ndryshuan me sukses!");
          onSave(employeeInfo?.employeeId, edited);
          onCloseCleanUp();
        })
        .catch((err) => {
          message.error("Ndodhi një gabim në ndryshimin e të dhënave!");
          console.error(err);
        })
        .finally(() => setLoading(false));
    } else {
      message.info("Ju nuk keni bërë asnjë ndryshim.");
    }
  };

  const onLedjo = async () => {
    await API.del("wages", "/wages/05e5a3d0-cb0a-11ec-84ef-61cf65802456").then(
      (res) => message.success("ledjo")
    );
  };

  const onMonthChange = (val) => {
    setDayjsInstance({
      start: val.startOf("month"),
      end: val.endOf("month"),
    });
  };

  const getActionsData = (data) => {
    const first = data[0];
    const second = data[1];
    if (!!data) {
      // console.log("test17", first, second);
      setRealTimePreview(first);
    }
  };

  useEffect(() => {
    if (!!programFields) {
      let progFieldWages = programFields?.find(
        (data) => data?.fieldId === "01d6ec94-5ddf-4f48-9fdc-86a39a7289d9"
      );
      if (!!progFieldWages) {
        setWageTypes(progFieldWages?.fieldOptions);
        // console.log("prog", progFieldWages.fieldOptions);
      }
    }
  }, [programFields]);

  return (
    <Modal
      destroyOnClose={true}
      width={"1292px"}
      maskClosable={false}
      className="edit-employee-wage darkHeader"
      title={`Ndrysho të dhënat e pagës - ${
        employeeInfo?.employeeFirstName + " " + employeeInfo?.employeeLastName
      } `}
      open={editable?.visibility}
      onOk={() => setVisib((prev) => ({ ...prev, visibility: false }))}
      onCancel={onCloseCleanUp}
      centered={true}
      footer={[
        <MondayButton className="mondayButtonRed" onClick={onCloseCleanUp}>
          Mbyll Faqen
        </MondayButton>,
        // <span style={{ color: "red" }}>Bruto: {realTimePreview?.bruto?.totalBruto}</span>,

        // <Popconfirm
        // 	placement="left"
        // 	title={"A jeni të sigurt që dëshironi të ruani ndryshimet?"}
        // 	onConfirm={onSaveChanges}
        // 	okText="Po"
        // 	cancelText="Jo"
        // 	overlayClassName="popup-edit-wage"
        // 	icon={<LogoFlex width={45} height={45} />}
        // >
        // 	<button className="start-btn">Ruaj Ndryshimet</button>
        // </Popconfirm>,
      ]}
    >
      <div className="edit-wage-div">
        {/* <Button onClick={onLedjo}>LEDJO</Button> */}
        <div className="edit-wage-header">
          <div
            className="warning-header"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span
              className="warning-content"
              style={{ display: "flex", justifyContent: "center", gap: 10 }}
            >
              <RedWarning /> Të dhënat që ndryshohen do të pasqyrohen në
              gjenerimin e ardhshëm të pagesës!
            </span>
          </div>
          {/* <Button onClick={onLedjo}>CLICK</Button> */}
          <div className="edit-wage-row">
            {" "}
            <div className="edit-wage-paymentType">
              <label>Lloji i pageses: </label>
              <Select
                placeholder="Zgjidh një opsion"
                optionFilterProp="children"
                disabled={true}
                onChange={(val) => onChangeEdit("employeePayrollType", val)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                defaultValue={employeeInfo?.employeePayrollType || false}
                style={{ width: 254 }}
              >
                {employeeWageTypes?.map((el, idx) => (
                  <Option value={el?.name} key={`emplWageType${idx}`}>
                    {el?.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="edit-wage-hourlyPay">
              <label>Pagesa për Orë (ALL): </label>
              <InputNumber
                disabled={true}
                style={{ width: "254px" }}
                // value={edited?.employeeSalaryPerHour || 0}
                type={"number"}
                min={1}
                max={1000000}
                defaultValue={employeeInfo?.employeeSalaryPerHour || 0}
                onChange={(val) => onChangeEdit("employeeSalaryPerHour", val)}
              />
            </div>{" "}
            <div className="edit-wage-hourlyPay" style={{ width: 250 }}>
              <label>
                <strong style={{ color: "red" }}>*</strong>Periudha:
              </label>
              <div
                className="pick-month"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Zgjidh Muajin: */}
                </span>{" "}
                <DatePicker
                  style={{
                    width: 254,
                    background: "white",
                  }}
                  defaultValue={dayjsInstance.start}
                  picker="month"
                  allowClear={false}
                  format={"MMMM YYYY"}
                  onChange={(val) => onMonthChange(val)}
                />
              </div>
            </div>
            <div className="personal-paycheck">
              {" "}
              <PersonalPaycheckPreview {...{ employeeInfo, dayjsInstance }} />
            </div>
          </div>
        </div>
        <div>
          <Actions
            getActionsData={getActionsData}
            employeeInfo={employeeInfo}
            employeeId={employeeInfo?.employeeId}
            timeperiod={[
              dayjsTZ(dayjsInstance.start).valueOf(),
              dayjsTZ(dayjsInstance.end).valueOf(),
            ]}
            momentInstance={dayjsInstance}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "40px",
            marginBottom: "20px",
          }}
        >
          {loading && <BreedingRhombusSpinner color={"#02686b"} size={52} />}
        </div>
        <br />
      </div>
    </Modal>
  );
}

export default EditEmployeeWage;
