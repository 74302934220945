import { Tooltip } from "antd";
import { IconExcel } from "../../../../assets/icons";
import * as XLSX from "xlsx";
import { excelCellFitToColumn } from "./excelCellFitToColumn";
import moment from "moment";
import dayjs from "dayjs";
import { htmlParser } from "../../../../utils";
import { formatChanges } from "../../../punonjesit/EmployeeView/components/ActivityModal/formatChanges";
import dayjsTZ from "../../../../utils/dayjs";

const AgGridToExcel = ({
  gridApi,
  title,
  tableCols,
  modifyRows,
  className,
  style,
}) => {
  //if export certain cols
  const handleClick = () => {
    let filteredRows = [];
    gridApi.forEachNodeAfterFilter((e) => filteredRows.push(e.data));

    if (!filteredRows || filteredRows.length === 0) {
      return;
    }

    if (!!modifyRows && typeof modifyRows === "function") {
      filteredRows = modifyRows(filteredRows);
    }

    const rows = [];
    filteredRows?.forEach((row) => {
      let tableRow = {};
      tableCols?.forEach((col) => {
        let value = row[col?.en || col];
        let formattedValue = `${
          !!value
            ? Array.isArray(value)
              ? value.join(", ")
              : dayjs.isDayjs(value) ||
                value instanceof dayjs ||
                dayjsTZ(value).isValid() ||
                dayjsTZ(value, dayjs.ISO_8601).isValid()
              ? dayjsTZ(value)
                  .locale("al")
                  .format(col?.format || "DD/MM/YYYY HH:mm")
              : typeof value === "string" || value instanceof String
              ? !!htmlParser(value)?.props?.children
                ? htmlParser(value)?.props.children
                : formatChanges(value)
              : formatChanges(value)
            : ""
        }`;
        tableRow = { ...tableRow, [col?.al || col]: formattedValue };
      });
      rows.push(tableRow);
    });

    const worksheet = XLSX.utils.json_to_sheet(rows);

    // worksheet["!cols"] = excelCellFitToColumn([rows]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, title);

    // Save the workbook to a file
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };
  return (
    <div
      className={`headerIcon ${className}`}
      data-testid="headerExcel"
      onClick={handleClick}
      style={style}
    >
      <Tooltip
        placement="top"
        overlayClassName="global-icon-tooltip"
        title={"Eksporto në Excel"}
      >
        <IconExcel width={20} height={20} fill={style?.fill || "#323338"} />
      </Tooltip>
    </div>
  );
};

export default AgGridToExcel;
