import { Button } from "antd";
import moment from "moment-timezone";
import dayjsTZ from "../../../../utils/dayjs";
import React from "react";
import { htmlParser } from "../../../../utils";
import MobileHtmlTabel from "../../../../utils/MobileComponents/MobileHtmlTabel";

function MobileSistemiTurnet({ tableData, setVisible }) {
	console.log(tableData);
	return (
		<div className="base-mobile-with-header">
			<div className="header-div">
				{" "}
				<Button onClick={() => setVisible(true)} className="btn-header">
					Krijo një orar të ri
				</Button>{" "}
			</div>
			<MobileHtmlTabel
				tableData={tableData?.map?.(
					({ shiftName, shiftDescription, shiftStartTime, shiftEndTime, createdAt }) => ({
						statusHeaderKey: shiftName,
						shiftDescription: htmlParser(shiftDescription),
						shiftStartTime: dayjsTZ(shiftStartTime).format("HH:mm"),
						shiftEndTime: dayjsTZ(shiftEndTime).format("HH:mm"),
						createdAt: dayjsTZ(createdAt).format("DD/MM/YYYY"),
					})
				)}
				header={[
					"Titulli i orarit",
					"Përshkrimi i orarit",
					"Ora fillimit",
					"Ora Mbarimit",
					"Krijuar",
				]}
				isHeader={true}
				isLink={false}
			/>
		</div>
	);
}

export default MobileSistemiTurnet;
